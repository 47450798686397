import Handle from "./Handle";
import Script from "src/component/script/Script";
import { IInfo } from "../ExecScript";
import { IGuiType } from "../IExecConfig";

/**
 *
 *
 * @class HandleAgg
 */
class HandleAgg extends Handle {
    aggs: { [key: string]: boolean };
    backAggs: { [key: string]: boolean };
    value: { [key: string]: any };

    constructor(script: Script<any>, aggs: string[] = []) {
        super(script);
        this.aggs = {};
        aggs.map((agg) => {
            this.aggs[agg] = true;
        });
        this.backAggs = { ...this.aggs };
        this.value = {};
    }

    exec(info: IInfo, parentId?: string) {
        if (info.value !== undefined) {
            //处理数据分配时的特殊情况（修改map映射关系）
            if (this.script.type === IGuiType.DataAllot) {
                this.value[info.name] = info.toName || info.name;
            } else {
                this.value[info.toName || info.name] = info.value;
            }
        }
        if (parentId) delete this.aggs[parentId];

        //检测是否已经执行结束
        if (Object.keys(this.aggs).length === 0) {
            if (info.value === void 0) {
                const useCache = (this.script as any).useCache;
                if (useCache && useCache()) {
                    info.value = this.value;
                }
            } else {
                info.value = this.value;
            }
            this.aggs = { ...this.backAggs };
            return super.exec({ name: "value", value: info.value, isExec: info.isExec, isAgg: true, callback: info.callback });
        }
        return false;
    }
}

export default HandleAgg;
