import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import { Toast } from "antd-mobile";
import { IInfo } from "src/component/props/PropsGGEditor/exec/ExecScript";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";

interface IToastScript {
    key?: string;
    duration?: number;
    defaultValue?: string;
    mask?: boolean;
    useDefault?: boolean;
}

/**
 *
 *
 * @class ToastScript
 */
@UIManifest.declare("ToastScript", "Toast脚本", UIManifest.Type.Script)
class ToastScript extends Script<IToastScript> {
    exec(info: IInfo) {
        const { key, duration = 2, mask = false, defaultValue, useDefault } = this.props;
        let message = info.value;
        if (key && typeof info.value === "object") message = PropsUtils.getValue(info.value, key) || defaultValue;
        if (typeof message !== "string") message = defaultValue ? defaultValue : JSON.stringify(message);
        if (useDefault) message = defaultValue;
        Toast.show(message, Number(duration), mask);
        return true;
    }
}

export default ToastScript;
