/**
 * content-type
 *
 * @enum {number}
 */
enum CONTENT_TYPE {
    JSON = "application/json;",
    FORM = "application/x-www-form-urlencoded;",
    DEFAULT = "",
    UPLOAD_FILE = "multipart/form-data;",
    JSONP = "jsonp"
}
/**
 * charset
 *
 * @enum {number}
 */
enum CHARSET {
    UTF8 = "charset=utf-8"
}

enum METHOD {
    GET = "GET",
    POST = "POST",
    DELETE = "DELETE"
}

export { CONTENT_TYPE, CHARSET, METHOD };
