import Http from "src/lib/http/Http";
import IPConfig from "src/IPConfig";
import { METHOD } from "src/lib/http/HttpEmun";
import SourceHandle from "../SourceHandle";
import GameConfig from "src/GameConfig";
import Config from "src/engine/const/Config";

export interface IModuleOpt {
    /** 类型：增、改、删 */
    type: "c" | "m" | "d";

    value: any;
}

enum LState {
    /** 默认状态 */
    DEFAULT = -1,
    /** 加载中 */
    LOADING = 0,
    /** 加载失败 */
    LOADED = 1,
}

export interface IModuleItem {
    /** 模块ID */
    moduleName: string;
    /** 加载状态  */
    loading: LState;

    pageSize: number;

    pageNum: number;

    handle: SourceHandle;
    /** 原始排序 */
    exist: { [key: string]: number };
    /** 编辑 */
    edit: boolean;
    /** 最大排序值 */
    maxRank: number;
    /** 加载网络数据个数 */
    count: number;
    /** 存储更新的数据 */
    updateIds: { [key: string]: boolean };
}

export default class ModuleMgr {
    /**
     * 单列对象
     */
    static Instance: ModuleMgr = new ModuleMgr();

    mCache: { [key: string]: IModuleItem } = {};

    /**
     * 注册网络数据
     * @param name
     * @param store
     */
    register(moduleId: string, handle: SourceHandle, props: any = {}, headers: any) {
        // console.log("[ModuleMgr]", this.mCache[moduleId], props, headers);
        if (this.mCache[moduleId]) {
            // if (headers.pageNum) this.loadMore(moduleId, headers.pageNum);
            return false;
        }
        const moduleItem = (this.mCache[moduleId] = {
            moduleName: moduleId,
            loading: 1,
            pageSize: 10,
            pageNum: 1,
            ...props,
            handle,
            count: 0,
            edit: false,
            maxRank: 0,
            exist: {},
            updateIds: {},
        });
        this.initModule(moduleItem);
        return true;
    }

    /**
     * 加载更多
     * @param moduleId
     */
    loadMore = (moduleId: string, pageNum?: number) => {
        //如果没有存在
        const item = this.mCache[moduleId];
        if (!item || item.loading === LState.LOADING) return;
        //加载中
        item.loading = LState.LOADING;
        if (pageNum) item.pageNum = pageNum;
        //加载下一页
        else item.pageNum++;
        this.ajax(item);
    };

    updateEditItem(name: string, item: any = {}) {
        if (!this.mCache[name] || !item.id) return;
        this.mCache[name].updateIds[item.id] = true;
    }

    updateEdit(name: string) {
        if (!this.mCache[name]) return;
        this.mCache[name].edit = true;
    }

    getEdit(callback: any) {
        const keys = Object.keys(this.mCache);
        const edit = {};
        keys.map((key: string) => {
            const editItem = this.mCache[key];
            // const item =
            callback(this.mCache[key], editItem.handle.manager.getSourcesData(key)?.data);
        });
        return edit;
    }

    /**
     * 加载数据
     * @param item IModuleItem
     */
    private initModule(item: IModuleItem) {
        //如果正在加载中，则退出
        if (item.loading === LState.LOADING) return;
        //加载中
        item.loading = LState.LOADING;
        //加载数据
        this.ajax(item);
    }

    private handleData = (item: IModuleItem, { list = [], totalNum = 0 }: any) => {
        //处理组件销毁的情况
        if (!this.mCache[item.moduleName]) return;
        //置为加载成功
        item.loading = LState.LOADED;
        //缓存rank值
        list?.map((listItem: any) => {
            item.count++;
            item.exist[listItem.id] = listItem.sourceRank;
            item.maxRank = Math.max(item.maxRank, listItem.sourceRank);
        });
        // if(item.)
        item.handle.execManagerSource({ total: totalNum, data: list, count: item.pageSize, start: item.pageNum, isModule: false });
    };

    private ajax(item: IModuleItem) {
        const { pageSize, pageNum, moduleName } = item;
        const sname = GameConfig.server.replace("PC", "");
        const gameId = GameConfig.gameId;
        let neatUrl = `${Config.API}${IPConfig.API}/source/neatQuerySources`;
        // 由于6000租户线上转发问题，导致默认neatQuerySources路径被修改
        if (`${gameId}` === "6000" && IPConfig.release && !IPConfig.isLocal) neatUrl = `${Config.IP}frontapi/cloudplt/api/${IPConfig.API}/source/neatQuerySources`;
        Http.ajax({
            url: neatUrl,
            headers: { sname, gameId: GameConfig.gameId, from: 2, moduleId: moduleName, pageSize, pageNum },
            method: METHOD.GET,
            // success: this.handleData.bind(this, item),
            // error: () => {
            //     //处理
            // }
        })
            .then((data: any) => {
                this.handleData(item, data.DATA);
            })
            .catch(() => {
                item.loading = LState.LOADED;
            });
    }

    /**
     * 重置
     */
    reset() {
        const keys = Object.keys(this.mCache);
        //重新刷新数据
        keys.map((key) => {
            const item = this.mCache[key];
            if (!item.edit) return;
            //将start重置为1
            item.pageNum = 1;
            item.count = 0;
            item.maxRank = 0;
            item.exist = {};
            //置为未修改状态
            item.edit = false;
            this.ajax(item);
        });
        // //清空修改缓存
        // this.mCache = {};
    }

    /**
     * 取消注册
     * @param name
     */
    unregister(name: string) {
        delete this.mCache[name];
    }
}
