import Script from "../Script";
import UIManifest from "src/engine/ui/UIManifest";
import Utils from "src/lib/utils/Utils";
import EndecodeCore from "./endecodeCore";
import Formmatter from "../DataToExcel/Formmatter";

interface IHandleScript {
    //*xxx*
    formmatter?: "string";
    toBoolean?: boolean;
    formatters?: Array<{ label: string; value: string }>;

    decryption?: boolean;
    // 加解密方式
    decryptype?: "encode" | "decode";
    // 加解密类型
    endecodeMold?: "Hex" | "Unicode" | "Base64" | "URL";
    arrayJoin?: string;
    stringSplit?: string;
    isFillString?: boolean;
    // 填充类型
    fullType?: "start" | "end";
    // 总长度
    fullMaxLength?: number;
    // 填充的字符串
    fullString?: string;
    // 截取的位置（string.slice的参数）
    slice?: string;

    toNumber?: boolean;

    // 转换成json
    toJSON?: boolean;
}

/**
 *
 *
 * @class HandleScript
 */
@UIManifest.declare("HandleScript", "字符串处理", UIManifest.Type.Script)
class HandleScript extends Script<IHandleScript> {
    private handleUrl(url: string, headers: any) {
        if (url.indexOf("[") >= 0) {
            const list = Utils.parser(/\[(.+?)\]/gim, url, 1);
            list.map((item) => {
                if (headers[item] !== undefined) {
                    url = url.replace(`[${item}]`, headers[item]);
                }
            });
        }
        return url;
    }

    private json(value: any, type: "parse" | "stringify" = "parse") {
        const { jsonKey } = this.props as any;
        try {
            if (jsonKey && value?.[jsonKey]) {
                value[jsonKey] = JSON[type](value[jsonKey]);
                return value;
            }
            return JSON[type](value);
        } catch (err) {
            console.log("[HandleScript]:", err);
            return value;
        }
    }

    private formmater(type: string, value: any) {
        if (Utils.stringStartsWith(type, "xxx,xxx.0")) {
            const floatString = type.split(".")[1];
            return Formmatter.formatCurrency(value, floatString.length);
        }
        switch (type) {
            case "xxx,xxx":
                if (!isNaN(Number(value))) return (Math.round(Number(value)) + "").replace(/\d{1,3}(?=(\d{3})+$)/g, "$&,");
                return value;
            case "xxx,xxx.00":
                return Formmatter.formatCurrency(value, 2);
            case "[nowTime]":
                return Math.floor(new Date().valueOf() / 1000);
            case "YYYY-MM-DD":
                return Utils.format(value, type);
            case "[percent]":
                // console.log('[HandleScript]:value', value, Number(value) * 10000 / 100);
                return (Number(value) * 10000) / 100;
            case "[StringToJson]":
                return this.json(value);
            case "[JsonToString]":
                return this.json(value, "stringify");
            default:
                if (typeof value === "object") return this.handleUrl(type, value);
                return type.replace("xxx", value);
        }
    }

    formmaters(formatter: string, formatters: Array<{ label: string; value: string }>, value: string) {
        if (value) {
            formatters.map((item) => {
                formatter = formatter.replace(item.label, value[item.value]);
            });
        }
        return formatter;
    }

    formmatersString(formatter: string, formatters: Array<{ label: string; value: string }>) {
        formatters.map((item) => {
            const { value = "" } = item;
            formatter = formatter.replace(item.label, value);
        });
        return formatter;
    }

    pipe(value: any, props: IHandleScript) {
        const transformFn = {
            arrayJoin() {
                const { arrayJoin } = props;
                if (arrayJoin && Array.isArray(value)) {
                    value = value.join(arrayJoin);
                }
                return transformFn;
            },
            toJSON() {
                const { toJSON } = props;
                if (toJSON) {
                    try {
                        value = JSON.stringify(value);
                    } catch (e) {}
                }
                return transformFn;
            },
            slice() {
                const { slice } = props;
                if (slice) {
                    value = value + "";
                    value = value.slice(...slice.split(","));
                }
                return transformFn;
            },
            stringSplit() {
                const { stringSplit } = props;
                if (stringSplit) {
                    value = value?.split(stringSplit) || [];
                }
                return transformFn;
            },
            isFillString() {
                const { isFillString, fullType, fullMaxLength, fullString } = props;
                if (isFillString && fullType && fullMaxLength && fullString) {
                    value = Utils.fullString(fullType, value, fullMaxLength, fullString);
                }
                return transformFn;
            },
            formmatter(script: HandleScript) {
                const { formatters, formmatter } = props;
                if (formatters && formmatter && value) {
                    value = script.formmaters(formmatter, formatters, value);
                } else if (formatters && typeof value === "string") {
                    value = script.formmatersString(value, formatters);
                } else if (formmatter) {
                    value = script.formmater(formmatter, value !== undefined ? value : "xxx");
                } else if (typeof value === "object" && value !== null && value.formmatter) {
                    value = script.formmater(value.formmatter, value.value !== undefined ? value.value : "xxx");
                }
                return transformFn;
            },
            toBoolean() {
                const { toBoolean = false } = props;
                if (toBoolean) {
                    value = value === "true" ? true : false;
                }
                return transformFn;
            },
            decryption() {
                const { decryption = false, endecodeMold, decryptype } = props;
                if (decryption && value && endecodeMold && decryptype) {
                    const fn = EndecodeCore[endecodeMold];
                    if (fn) value = fn(decryptype, value);
                }
                return transformFn;
            },
            toNumber() {
                const { toNumber = false } = props;
                if (toNumber) value = Number(value);
                return transformFn;
            },
            get() {
                return value;
            },
        };
        return transformFn;
    }

    handle(value: any) {
        return (
            this.pipe(value, this.props)
                // 字符串格式化为指定格式
                .formmatter(this)
                // 数组通过 join 转换成字符串
                .arrayJoin()
                // 对象转成json字符串
                .toJSON()
                // 字符串截取 (slice)
                .slice()
                // 字符串分割 (split)
                .stringSplit()
                // 字符串补全
                .isFillString()
                // 转为 boolean
                .toBoolean()
                // 字符串加解密
                .decryption()
                // 转化成数字
                .toNumber()
                .get()
        );
        // const {
        //     formmatter,
        //     formatters,
        //     toBoolean = false,
        //     decryption = false,
        //     decryptype,
        //     endecodeMold,
        //     arrayJoin,
        //     stringSplit,
        //     isFillString,
        //     fullType,
        //     fullMaxLength,
        //     fullString,
        //     slice,
        //     toNumber = false,
        // } = this.props;

        // if (arrayJoin && Array.isArray(value)) {
        //     value = value.join(arrayJoin);
        // }
        // if (slice) {
        //     value = value + "";
        //     value = value.slice(...slice.split(","));
        // }
        // if (stringSplit) {
        //     value = value.split(stringSplit);
        // }
        // if (isFillString && fullType && fullMaxLength && fullString) {
        //     value = Utils.fullString(fullType, value, fullMaxLength, fullString);
        // }

        // if (formatters && formmatter && value) {
        //     value = this.formmaters(formmatter, formatters, value);
        // } else if (formatters && typeof value === "string") {
        //     value = this.formmatersString(value, formatters);
        // } else if (formmatter) {
        //     if (typeof value === "object") value = this.handleUrl(formmatter, value);
        //     else value = this.formmater(formmatter, value !== undefined ? value : "xxx");
        // } else if (typeof value === "object" && value !== null && value.formmatter) {
        //     value = this.formmater(value.formmatter, value.value !== undefined ? value.value : "xxx");
        // }

        // if (toBoolean) {
        //     value = value === "true" ? true : false;
        // }

        // if (decryption && value && endecodeMold && decryptype) {
        //     const fn = EndecodeCore[endecodeMold];
        //     if (fn) value = fn(decryptype, value);
        // }
        // if (toNumber) value = Number(value);
        // return value;
    }
}

export default HandleScript;
