import { CONTENT_TYPE } from "src/lib/http/HttpEmun";
import IUIItem from "src/engine/interface/IUIItem";
import IGameInfo from "src/engine/interface/IGameInfo";
// import EditConfig from "src/engineEditor/config/EditConfig";
import PCSource from "./PCSource";
import EditConfig from "src/engineEditor/config/EditConfig";
import IPConfig from "src/IPConfig";
import UIImport from "src/engine/pages/source/UIImport";
import GameConfig from "src/GameConfig";
import PublicUIMgr from "src/engineEditor/ui/PublicUIMgr";
import WebYun from "src/cms/WebYun";
import Storage from "src/lib/utils/storage/Storage";
import StorageType from "src/lib/utils/storage/StorageType";

// const lzwCompress = require("lzwcompress");

function filterRadioToForms(forms: IUIItem[]) {
    for (const ui of forms) {
        const { ename } = ui.info;
        if (ename === "artFlag") {
            const { dataSource = [] } = ui.props;
            return dataSource.map((item: any) => {
                return {
                    ...item,
                    key: item.label,
                };
            });
            return ui.props?.dataSource || [];
        }
    }
    return [];
}

export default class TempUpload extends PCSource {
    //重写正确code
    code = 200;

    gameInfo: IGameInfo;

    dataSource = "manager";

    autoMessage = false;

    /** 缓存记录 */
    cache: any;
    /** 临时缓存记录 */
    mTempCache: any;

    pages: any;

    constructor(gameInfo: IGameInfo) {
        super();
        this.gameInfo = gameInfo;
        this.ajax = {
            url: `${EditConfig.tempUrl}?gameId=${gameInfo.gameId}&sName=${gameInfo.server}`,
            //  http://iad.test.jj.cn/factory/temp?sName=officialWebsite&gameId=1
            //       http://iad.test.jj.cn/factory/temp?gameId=1&sName=officialWebsite
            // url: "http://iad.test.jj.cn/factory/temp?gameId=1&sName=officialWebsite",
            dataType: CONTENT_TYPE.JSON,
        };
    }

    handleDataRight(flag: boolean = true) {
        const keys = Object.keys(this.mTempCache);
        keys.map((key: string) => {
            this.cache[key] = this.mTempCache[key];
        });
        EditConfig.stat("访问", `保存模板`, { sname: GameConfig.server, gameId: GameConfig.gameId, editList: this.pages.join(",") });
        flag && this.message("保存模板成功");
    }

    handleDataError(data: any) {
        this.message(`保存模板失败(${data.MSG})`, false);
    }

    createTempFile(cache: { [key: string]: string }, pageList: any[], content: any, name: string, path = "src", li?: any) {
        if (!content) return false;

        let contentString = `${JSON.stringify(content)}`;

        const type = content.type === "pageInit" ? name : content.type;
        const action = cache[type] ? "update" : "create";
        if (cache[type] && cache[type].length === contentString.length && cache[type] === contentString) return false;

        if (content.type === "pageInit") {
            this.pages.push(`${content.page.info.cname}(${name})`);
        }
        if (content.type === "pageInit" || content.type === "pagesInit") {
            //修改更新时间
            if (li) {
                (content.page || content).props._li = li;
                contentString = `${JSON.stringify(content)}`;
            }
        }
        cache[type] = contentString;
        pageList.push({
            id: -1,
            action,
            cname: content.type === "pageInit" ? content.page.info.cname : undefined,
            templateFile: {
                content: contentString,
                tempFilePath: path,
                tempFileName: `${name}.json`,
            },
        });
        return true;
    }

    createMediaFile(cache: { [key: string]: string }, pageList: any[], modulesInfo: any, pagesInfo: any, storeCache: any, name: string, path = "src") {
        const { pages = [] } = pagesInfo.props;

        const type = "mediaInit";
        const cacheString = cache[type] || "{}";
        const mediaInfo = {
            type,
            medias: [{}],
            ...JSON.parse(cacheString),
        };
        if (!Array.isArray(mediaInfo.medias) || !(mediaInfo.medias[0] as any).sname) {
            mediaInfo.medias = [{}];
        }
        const { modules = {} } = modulesInfo || {};

        // const totalStoreArray = mediaInfo.medias.list || [];
        // const totalStoreMap = mediaInfo.medias.map || {};
        // 下标指针
        // let indexPointer: number = 0;

        const firstItem = mediaInfo.medias[0];
        firstItem.sname = GameConfig.server;
        firstItem.gameId = GameConfig.gameId;
        firstItem.medias = firstItem.medias || {};
        for (let i = 0, length = pages.length; i < length; i++) {
            const page = pages[i];
            const { ename, cname } = page.info;
            // 只存储现有的页面
            const pageStoreCache = [];
            if (storeCache[ename]) {
                // 新模式
                const pageStore = storeCache[ename];
                for (const storeItem of pageStore) {
                    if (storeItem._id === -1 && storeItem.props?.module === true) {
                        const isArtical = storeItem.ui === "SourceArtical";
                        // 文章类型统一展示类型不再单独上传冗余数据
                        let store = storeItem;
                        if (isArtical) {
                            store.props = store.props || {};
                            store = {
                                ...store,
                                props: {
                                    artKind: "1",
                                    ...store.props,
                                    showUI: [],
                                },
                            };
                        }
                        const { info, ...other } = store;
                        const item = {
                            ...other,
                            ...info,
                            artical: isArtical,
                            _fromPage: ename,
                            _fromPageCname: cname,
                        };
                        pageStoreCache.push(item);
                    }
                }
            } else if (modules[ename]) {
                // 兼容老模式
                const pageStore = modules[ename];
                for (const storeItem of pageStore) {
                    // 筛选符合条件的 module
                    // 只读的去掉
                    if (storeItem.props.readOnly === "true" || storeItem.props.readOnly === true) continue;
                    // 不在当前页面编辑的去掉
                    if (storeItem.props.pages && storeItem.props.pages !== ename) continue;
                    // 没有表单设置的去掉（没表单说明不在当前页面编辑）
                    if (!storeItem.props.form || storeItem.props.form.length === 0) continue;

                    const isArtical = storeItem.props.type === "ArtExpend";
                    // 新老表单key值兼容
                    if (isArtical) {
                        storeItem.props.forms = filterRadioToForms(storeItem.props.form);
                        storeItem.props.showUI = [];
                        storeItem.props.artKind = "1";
                    } else {
                        storeItem.props.forms = storeItem.props.form;
                        storeItem.props.showUI = storeItem.props.childUI;
                    }
                    delete storeItem.props.form;
                    delete storeItem.props.childUI;
                    const { info, ...other } = storeItem;

                    const item = {
                        ...other,
                        ...info,
                        artical: isArtical,
                        _fromPage: ename,
                        _fromPageCname: cname,
                    };
                    pageStoreCache.push(item);
                }
            }

            if (pageStoreCache.length > 0) {
                firstItem.medias[ename] = { data: pageStoreCache, sort: i };

                continue;
            }
            if (firstItem.medias[ename]) {
                firstItem.medias[ename].sort = i;
            }
        }
        const contentString = `${JSON.stringify(mediaInfo)}`;
        const action = cache[type] ? "update" : "create";
        if (cache[type] && cache[type].length === contentString.length && cache[type] === contentString) return false;

        cache[type] = contentString;
        pageList.push({
            id: -1,
            action,
            templateFile: {
                content: contentString,
                tempFilePath: path,
                tempFileName: `${name}.json`,
            },
        });
        return true;
    }

    handleHeaders(other: any, dataSource: any) {
        const { props = {} } = dataSource[0];
        const {
            pages = [],
            uis,
            isPC,
            seo,
            // maxFontSize,
            mobUrl,
            title,
            rootStyle,
            bodyStyle,
            htmlStyle,
            icon,
            offset,
            modules = {},
            style,
            sources,
            singleExit,
            bridgeUI,
            staticly,
            icons,
            isResolution = true,
            noJsonToast,
            pageUpdateTime = {},
            pageTemps,
            stores,
            pagesStyle,
            sourceStyle,
            extSourceStyle,
        } = props;
        this.cache = props.cache;
        const cache = (this.mTempCache = { ...props.cache });
        // this.mTempCache = {...cache};
        // const path = gameInfo.gameId ? `` : `${gameInfo.server}`;

        if (pages.length === 0) {
            this.message("没有页面信息可以保存", false);
            return false;
        }

        //获取当前操作人
        const cookies = new Storage(StorageType.Cookie);
        const name = cookies.get("admin_u_name") ?? "未知";
        const id = cookies.get("admin_employeenum") ?? "xxx";

        const pageList: any[] = [];

        const pagesInfo: any = {
            type: "pagesInit",
            props: { pages: [], pageUpdateTime },
        };
        const modulesInfo: any = { type: "modulesInit", modules };
        const storeCache = {};
        const item: any = {};
        const pagesMaps = {};
        this.pages = [];
        pages.map((page: IUIItem) => {
            const act = pageTemps[page.info.ename];
            pagesMaps[page.info.ename] = true;
            //如果加载成功
            if (act) {
                page = { _id: 1, ...act, props: { ...act.props } };

                //判断页面是否含有模块
                if (page.props.module) {
                    //公共模板的时候，不对module进行简化
                    if (PublicUIMgr.PublicServer !== GameConfig.server) {
                        //更新模块到modulesInfo
                        modulesInfo.modules[page.info.ename] = page.props.module;
                        //简化页面内的模块信息，缩减前端见到的模板
                        page.props.module = page.props.module.map((mod: any) => {
                            const { headers = {} } = mod.props || {};
                            return { info: mod.info, ui: "", props: { headers } };
                        });
                    }
                }

                if (page.props?.stores?.length > 0) {
                    storeCache[page.info.ename] = [...page.props.stores];
                }

                //增加页面最后更新时间，最后更新人
                const _li = { ...(page.props._li || {}), t: new Date().valueOf(), id, name };
                //产检页面
                if (this.createTempFile(cache, pageList, { type: "pageInit", page }, page.info.ename, "src", _li)) {
                    pageUpdateTime[page.info.ename] = new Date().valueOf();
                    //增加页面最后更新时间，最后更新人
                    act.props._li = _li;
                }
            }
            if (item[page.info.ename]) return;
            item[page.info.ename] = page;

            let pageExt: any = {};
            //如果是uimanager,且设置了图标
            if (GameConfig.server === PublicUIMgr.UIManager && page.props._preview) {
                pageExt._preview = page.props._preview;
            }
            // 增加 publicui 的列表分类整理
            if (GameConfig.server === PublicUIMgr.PublicServer) {
                if (page.props._preview) pageExt._preview = page.props._preview;
                if (page.props.tags) pageExt.tags = page.props.tags;
                if (page.props._compType) pageExt._compType = page.props._compType;
            }
            // 增加 逻辑块 的列表分类整理
            if (GameConfig.server === PublicUIMgr.Logic) {
                pageExt = page.props;
            }
            //移除content
            pagesInfo.props.pages.push({
                _id: 1,
                info: page.info,
                ui: page.ui,
                props: { menu: page.props.menu, ...pageExt },
            });

            // //移除content
            // pagesInfo.props.pages.push({
            //     _id: 1,
            //     info: page.info,
            //     ui: page.ui,
            //     props: { menu: page.props.menu }
            // });
        });

        if (isPC) pagesInfo.props.isPC = isPC;
        if (title) pagesInfo.props.title = title;
        if (icon) pagesInfo.props.icon = icon;
        if (seo) pagesInfo.props.seo = seo;
        if (stores) pagesInfo.props.stores = stores;
        if (sourceStyle) pagesInfo.props.sourceStyle = sourceStyle;
        if (extSourceStyle) pagesInfo.props.extSourceStyle = extSourceStyle;
        if (icons) pagesInfo.props.icons = icons;
        // if (maxFontSize) pagesInfo.props.maxFontSize = maxFontSize;
        if (mobUrl) pagesInfo.props.mobUrl = mobUrl;
        if (offset) pagesInfo.props.offset = offset;
        if (rootStyle) pagesInfo.props.rootStyle = rootStyle;
        if (bodyStyle) pagesInfo.props.bodyStyle = bodyStyle;
        if (htmlStyle) pagesInfo.props.htmlStyle = htmlStyle;
        if (pagesStyle) pagesInfo.props.pagesStyle = pagesStyle;
        if (style) pagesInfo.props.style = style;
        if (singleExit) pagesInfo.props.singleExit = singleExit;
        if (bridgeUI) pagesInfo.props.bridgeUI = bridgeUI;
        if (staticly) pagesInfo.props.staticly = staticly;
        if (props.loadingUI) pagesInfo.props.loadingUI = props.loadingUI;
        if (noJsonToast) pagesInfo.props.noJsonToast = noJsonToast;
        if (props.loadErrorUI) pagesInfo.props.loadErrorUI = props.loadErrorUI;
        if (props.config) pagesInfo.props.config = props.config;
        if (props.ggValue) pagesInfo.props.ggValue = props.ggValue;
        pagesInfo.props.isResolution = isResolution;
        if (sources) pagesInfo.props.sources = sources || [];
        if (props._li) pagesInfo.props._li = props._li;

        //存储页面列表
        const li = { t: new Date().valueOf(), id, name };
        //如果更新
        if (this.createTempFile(cache, pageList, pagesInfo, `pages`, "src", li)) props._li = li;
        //存储引入组件模块
        this.createTempFile(
            cache,
            pageList,
            {
                type: "uiInit",
                uis: new UIImport(uis, pagesMaps).parserUIS(dataSource[0]),
            },
            `uis`
        );
        //存储模块
        this.createTempFile(cache, pageList, modulesInfo, `modules`);
        this.createMediaFile(cache, pageList, modulesInfo, pagesInfo, storeCache, `media`);
        this.createTempFile(cache, pageList, { type: "groupInit", group: props.group || [] }, `group`);
        const { server, gameId } = this.gameInfo;

        const projectName = `${IPConfig.release ? (server === "cloudcms" ? "prod_" : "product_") : ""}template`;

        if (pageList.length === 0) {
            this.message("无模板修改");
            return false;
        }
        // return false;
        if (WebYun.IS && WebYun.useLocal(GameConfig.gameId, GameConfig.server)) {
            try {
                WebYun.saveFileToLocal(pageList, GameConfig.server, GameConfig.gameId);
                this.message("保存模板成功(本地)");
            } catch (err) {
                throw err;
            }
            return false;
        }
        return { pageList, branchName: `${server}_${gameId}`, projectName };
    }
}
