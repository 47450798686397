/**
 *
 *
 * @class ButtonCheck
 */
class ButtonCheck {
    static mClickTime = 0;

    static check = (check: number = 300) => {
        const nowTime = new Date().valueOf();
        if (nowTime - ButtonCheck.mClickTime < check) {
            return false;
        }
        ButtonCheck.mClickTime = nowTime;
        return true;
    };

    static mContinuTime = 0;
    static checkContinu = (check: number = 300) => {
        const nowTime = new Date().valueOf();
        if (nowTime - ButtonCheck.mContinuTime < check) {
            ButtonCheck.mContinuTime = nowTime;
            return false;
        }
        ButtonCheck.mContinuTime = nowTime;
        return true;
    };

    private sameClickTime = 0;

    public check = (doubleClickTime?: number) => {
        if (doubleClickTime) {
            const nowTime = new Date().valueOf();
            if (this.sameClickTime && nowTime - this.sameClickTime < doubleClickTime) return false;
            this.sameClickTime = nowTime;
        }
        return true;
    };
}

export default ButtonCheck;
