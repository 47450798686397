export default class EventTarget {
    listeners: any;

    constructor() {
        this.listeners = {};
    }

    addListener(type: string, listener: any) {
        if (this.listeners[type] === undefined) {
            this.listeners[type] = [];
        }
        this.listeners[type].push(listener);
        return () => {
            this.removeListener(type, listener);
        };
    }

    fire(event: any, args = {}) {
        event = typeof event === "string" ? { type: event } : { type: event.type };
        const type = event.type;

        //回调参数为字符串或数字
        args = typeof args === "object" ? args : { [type]: args };
        for (const key in args) {
            if (args[key] !== undefined && args[key] !== null) {
                event[key] = args[key];
            }
        }
        event.target = this;

        if (!type) {
            throw new Error("Event object missing 'type' property.");
        }
        if (this.listeners[type] instanceof Array) {
            const listeners = this.listeners[type];
            for (const listener of listeners) {
                listener.call(this, event);
            }
        }
    }

    removeAllListener(type: string) {
        delete this.listeners[type];
    }

    remove() {
        this.listeners = {};
    }

    removeListener(type: string, listener: any) {
        if (this.listeners[type] instanceof Array) {
            const listeners = this.listeners[type];
            for (let i = 0; i < listeners.length; i++) {
                if (listeners[i] === listener) {
                    listeners.splice(i, 1);
                    break;
                }
            }
        }
    }
}
