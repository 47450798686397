import Exec from "./Exec";
import { ISourceBean } from "../Manager";
// import jsBridge from "src/mobile/bridge/jsBridge";
import jsBridge from "../../../mobile/bridge/jsBridge";
// import BridgeConfig from "../../../mobile/bridge/BridgeConfig";
import Utils from "src/lib/utils/Utils";
/**
 *
 *
 * @class Local
 */
class Bridge extends Exec {
    //页面加载状态
    loading: { [key: string]: boolean } = {};
    bridgeCallBack = (bean: ISourceBean, callback: any = () => { }, bridgeData: any) => {
        //bridgeData:{type: "leaveWebPage", target: EventTarget}
        //type:leaveWebPage(两个均为)
        const { bridgeTypeData, againstTypeData, againstType = "", bridgeType = "" } = bean.source;
        //bridgeTypeData:{leaveWebPage:1}
        //againstTypeData:{enterWebPage:1}

        //单纯的回调，如锁屏离开/网络变化
        if (bridgeData.type === bridgeType && bridgeTypeData && bridgeTypeData[bridgeType]) {
            //是离开回调
            bridgeData[bridgeType] = bridgeTypeData[bridgeType];
        }
        if (bridgeData.type === againstType) {
            //是对应的进入回调
            bridgeData[bridgeType] = againstTypeData[againstType];
        }
        //处理数据(旧方式如上传图片返回为字符串)
        if (bean.source.handleData && typeof bridgeData !== "string") {
            bridgeData = bean.source.handleData(bridgeData, bean.data.data);
        }
        delete this.loading[bean.name];
        if (bridgeData) {
            bridgeData.key = new Date().valueOf();
            this.mManager.update(bean, {}, bridgeData);
            if (callback) {
                callback(bridgeData);
            }
        }
    };
    handleHeader = (bean: ISourceBean, data: any) => {
        const changeMap = bean.source.changeMap || [];
        const isHttps = window.location.protocol.indexOf("https") > -1;

        changeMap.map((i: any) => {
            if (data[i.label]) {
                data[i.label] = i.value.replace("xxx", data[i.label]);
                let url = data[i.label];
                if (url.indexOf("[") > 0) {
                    const list = Utils.parser(/\[(.+?)\]/gim, url, 1);
                    list.map(item => {
                        const value = data[item];
                        if (data[item] !== undefined) {
                            url = url.replace(`[${item}]`, value);
                            if (url.indexOf("http") < 0) {
                                if (isHttps) {
                                    url = `https:${url}`;
                                } else {
                                    url = `http:${url}`;
                                }
                            }
                        }
                    });
                    data[i.label] = url;
                }
            }
        });

        return data;
    };
    exec(bean: ISourceBean, data: any, callback: any = () => { }) {
        let { bridgeType = "" } = bean.source;
        const { bridgeTypeMap = {}, againstType } = bean.source;

        //执行桥连接方法转换
        bridgeType = bridgeTypeMap[data.bridgeType] || bridgeType;
        if (!bridgeType) return;
        data = this.handleHeader(bean, data);
        this.execBridge(bridgeType, bean, data, callback);
        if (againstType) {
            //对应方法（离开网页的对应方法为进入网页）
            this.execBridge(againstType, bean, data, callback);
        }
    }
    execBridge = (bridgeType: string, bean: ISourceBean, data: any, callback: any = () => { }) => {
        if (jsBridge[bridgeType]) {
            jsBridge[bridgeType](data, this.bridgeCallBack.bind(this, bean, callback));
        } else {
            let notSub = true;
            if (bridgeType === "savePictureToCamera" || bridgeType === "saveFile") notSub = false;
            jsBridge.send(bridgeType, data, this.bridgeCallBack.bind(this, bean, callback), undefined, { notSub });
            // callback({ result: 0, message: "保存失败" });
        }
    };
}

export default Bridge;
