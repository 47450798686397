import Utils from "../Utils";

/**
 *
 */
class Url {
    static maps = Utils.getUrlParamsMaps();

    static getItem(name: string) {
        const map = Url.maps;
        return map[name];
    }

    static setItem(name: string, value: any) {}

    static removeItem(name: string) {}
}

export default Url;
