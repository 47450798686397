import * as React from "react";
import "./PageLoading.scss";
// import Button from "../../../components/Button/Button";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import Loading from "src/mobile/ui/common/Loading";
import Utils from "src/lib/utils/Utils";
import Manifest from "src/engine/Manifest";
import IUIItem from "src/engine/interface/IUIItem";
import ButtonExt from "src/components/ButtonExt";
import { SourceContext } from "src/engine/SourceCenter/SourceContext";
import AutoContext from "src/engine/decorator/AutoContext";

interface IPageLoading extends IBaseUI {
    getJson?: any;
    isPC?: any;
    pageError?: boolean;
    loadErrorUI?: IUIItem[];
}

class PageLoading extends BaseUI<IPageLoading> {
    componentDidMount() {
        this.getJson();
    }
    getJson = () => {
        const { getJson, uiItem } = this.props;
        if (getJson) getJson(uiItem.info.ename, uiItem.props.updateTime);
    };

    @AutoContext(SourceContext, "sourceContext")
    render() {
        // if (this.props.isPC === "true") return null;
        const { loadErrorUI = [] } = this.props;
        if (!Utils.isMobile()) return null;
        const { pageError } = this.props;
        const loadErrorChild = loadErrorUI[0];
        return (
            <div className={`react-page-loading page-loading-${pageError}`}>
                {pageError ? (
                    <>
                        <div className="page-error">
                            <span className="line-one">糟糕，加载失败~</span>
                            <span className="line-two">请确保网络连接正常</span>
                            <span>
                                {/* <ButtonExt
                                    onClick={this.getJson}
                                    uiItem={{
                                        info: { ename: "pageLoaidngError", cname: "页面失败刷新" },
                                        props: {},
                                        ui: "Button",
                                    }}
                                >
                                    刷新
                                </ButtonExt> */}

                                <ButtonExt
                                    onClick={this.goBack}
                                    uiItem={{
                                        info: { ename: "pageLoaidngError", cname: "页面失败刷新" },
                                        props: {},
                                        ui: "Button",
                                    }}
                                >
                                    返回
                                </ButtonExt>
                            </span>
                        </div>
                        {loadErrorChild ? this.renderChildren([loadErrorChild], { refresh: this.getJson }) : null}
                    </>
                ) : (
                    <div className="page-loading">
                        <Loading uiItem={Manifest.ui()} />
                        {/* <div className="loading-name">页面加载中</div> */}
                        <ButtonExt
                            onClick={this.goBack}
                            uiItem={{
                                info: { ename: "pageLoaidngBack", cname: "页面加载返回" },
                                props: {},
                                ui: "Button",
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default PageLoading;
