import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import UIManifest from "src/engine/ui/UIManifest";
import IUIItem from "src/engine/interface/IUIItem";
import Utils from "src/lib/utils/Utils";
import RuleShow, { IRuleShow } from "../PCForm/FormRules/RuleShow";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import Media from "react-media";

// interface IFilter {
//     key:string;
//     type:string;
//     value:string;
// }

interface IJList extends IBaseUI {
    headers: IUIItem[];
    footers: IUIItem[];
    nullUis?: IUIItem[];
    sort?: boolean;
    pageSize?: number;
    fliterList?: IRuleShow[];
}
interface IState {
    curPage: number;
    filterString: string;
}

@UIManifest.declareGG(["ggValue", "dataValue"], "JList", "JList组件")
class JList extends BaseUI<IJList, IState> {
    state = {
        curPage: 1,
        filterString: "",
    };

    mColumns: number;

    getCount() {
        return this.getData().length;
    }

    getData() {
        const { dataSource, data } = this.props as any;
        return data || dataSource || [];
    }

    renderNullItem() {
        const { nullUis = [] } = this.props;
        return this.renderChildren(nullUis);
    }

    getPagination() {
        const { headers = {}, start, count } = this.props as any;
        return {
            pageSize: Number(headers.pageSize || count || 10),
            pageNum: Number(headers.pageNum || start || 1),
        };
    }

    getMergeData(data: any, merge: any) {
        if (Utils.isObject(data)) {
            return { data: { ...data, ...merge } };
        } else {
            return { data, mergeData: merge };
        }
    }

    filterChange = (filterString: string) => {
        this.setState({ filterString });
    };

    filter(data: any = {}) {
        const { splitKeys = [], fliterList = [], toLowerCase = true, filter } = this.props as any;
        if (filter && typeof filter === "function") return filter(data);
        if (fliterList.length > 0) {
            let show = false;
            fliterList.map((fliter: IRuleShow) => {
                const ret: any = {};
                new RuleShow(fliter).exec(data, ret);
                show = ret.show;
            });
            if (!show) return false;
        }
        if (splitKeys.length === 0) return true;
        const { filterString } = this.state;
        if (filterString) {
            for (const key of splitKeys) {
                const value = PropsUtils.getValue(data, key);
                if (Utils.includes(value, filterString, toLowerCase)) return true;
            }
            return false;
        }
        return true;
    }

    getItemStyle(index: number, columns: number) {
        const { autoBorder = "default" } = this.props as any;
        const style: any = {};
        //添加样式
        style.display = "inline-block";
        style.verticalAlign = "top";
        const itemWidth = Math.floor(1000 / columns) / 10;
        style.width = `${itemWidth}%`;
        const line = Math.floor(index / columns);
        const column = index % columns;
        switch (autoBorder) {
            //去除外边框
            case "no-wrapper-border":
                style.borderLeft = "none";
                style.borderBottom = "none";
                //去除首行，顶部
                if (line === 0) style.borderTop = "none";
                //尾列去除右边边border
                if (column === columns - 1) style.borderRight = "none";
                break;
            //单边框
            case "border":
                //非首行去除顶部border
                if (line !== 0) style.borderTop = "none";
                //非首列去除左边border
                if (column !== 0) style.borderLeft = "none";
                break;
            case "default":
                break;
        }
        return style;
    }

    renderUIItem(uiItem: IUIItem, context: any, props: any = {}): any {
        const { index, autoGrid } = props;
        // const { autoGrid = false } = this.props as any;
        //如果支持Grid自动布局
        if (autoGrid && this.mColumns > 0) {
            props._hookStyle = this.getItemStyle.bind(this, index, this.mColumns);
        }
        return super.renderUIItem(uiItem, context, props);
    }

    renderData() {
        const { sort = false, children, pagination, mergeData = {}, max, autoGrid = false } = this.props as any;
        // const data = this.getData() || [];
        if (!children || children.length === 0) return null;
        return this.panel((tContext) => {
            const data = this.getData() || [];
            if (!children || children.length === 0 || data.length === 0) return this.renderNullItem();
            const list = [];
            if (!sort) {
                const { pageSize, pageNum } = this.getPagination();
                let start = 0,
                    end = data.length;
                if (max) end = Math.min(end, max);

                if (pagination && pageSize) {
                    start = (pageNum - 1) * pageSize;
                    end = Math.min(pageNum * pageSize, data.length);
                }
                for (let i = start; i < end; i++) {
                    if (this.filter(data[i])) {
                        const item: any = { key: i, index: i, autoGrid, ...this.getMergeData(data[i], mergeData) };
                        if (i === end - 1) item.isLast = true;
                        list.push(this.renderUIItem(children[0], tContext, item));
                    }
                }
            } else {
                for (let i = data.length - 1; i >= 0; i--) {
                    if (this.filter(data[i]))
                        list.push(this.renderUIItem(children[0], tContext, { key: i, index: i, autoGrid, ...this.getMergeData(data[i], mergeData) }));
                }
            }
            if (list.length === 0) return this.renderNullItem();
            return list;
        });
    }

    gotoPage = (page: number) => {
        const { pageNum } = this.getPagination();
        // const { curPage } = this.state;
        // const data = this.getData();
        if (pageNum === page) return;
        const { source, uiItem } = this.props as any;
        //如果往回退，则不需要重新获取数据
        // if (page * pageSize <= data.length) return;

        if (source || uiItem.props.dataSource) this.sourceContext?.execSource(source || uiItem.props.dataSource, { pageNum: page });
    };

    // onChange=(page: number))=>{
    //     this.gotoPage
    // }

    getFootProps() {
        const { total, footerData, pagination } = this.props as any;
        let props: any = {};
        const { pageSize, pageNum = 1 } = this.getPagination();

        if (pagination && pageSize) {
            const size = pageSize;
            const pagin: any = {};
            pagin.start = pageNum;
            pagin.pages = Math.ceil(total / size);
            pagin.next = this.gotoPage.bind(this, Math.min(pageNum + 1, pagin.pages));
            pagin.pre = this.gotoPage.bind(this, Math.max(pageNum - 1, 1));
            pagin.onChange = this.gotoPage;
            pagin.total = total;
            pagin.pageSize = pageSize;
            pagin.current = pageNum;
            props.pagination = pagin;
        } else {
            props = { start: pageNum, current: pageNum, total, pageSize, onChange: this.gotoPage, count: this.getCount(), data: footerData };
        }
        return props;
    }

    renderList() {
        const { headers = [], footers = [] } = this.props as any;

        return (
            <div className="react-jlist" style={this.getStyle()}>
                {this.renderChildren(Array.isArray(headers) ? headers : [], { filterChange: this.filterChange })}
                {this.renderData()}
                {this.renderChildren(footers, this.getFootProps())}
            </div>
        );
    }

    renderAct() {
        const { autoGrid, queries, columns = 2 } = this.props as any;

        //如果支持自动grid，且配置了响应式布局
        this.mColumns = columns;
        if (autoGrid && queries) {
            return (
                <Media queries={queries}>
                    {(matches) => {
                        Object.keys(matches).forEach((key: string) => {
                            if (matches[key] === true && Number(key) > 0) {
                                this.mColumns = Number(key);
                            }
                        });
                        return this.renderList();
                    }}
                </Media>
            );
        }
        return this.renderList();
    }
}

export default JList;
