import { IInfo } from "../ExecScript";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import { IGuiType } from "../IExecConfig";
import IUIItem from "src/engine/interface/IUIItem";
import { IAllotItem } from "src/engine/ui/BaseUI";

/**
 *
 *
 * @class HandleEnd
 */
class HandleEnd {
    endKey: string;
    props: any;

    uiItem: IUIItem;

    constructor(uiItem: IUIItem, props: any, endKey?: string) {
        this.endKey = endKey || "value";
        this.props = props;
        this.uiItem = uiItem;
    }

    execCheckEnd(ret: { [key: string]: any }, info: IInfo) {
        const arr = PropsUtils.getValue(this.props, this.endKey) || [];
        for (const item of arr) {
            if (item.info && item.info.ename === this.uiItem.info.ename) {
                if (ret[this.endKey]) {
                    ret[this.endKey].push(item);
                } else {
                    ret[this.endKey] = [item];
                }
                break;
            }
        }
        if (!ret[this.endKey] || ret[this.endKey].length === 0) {
            ret[this.endKey] = [arr[0]];
        }
    }

    getValue(key: string, keyValue: IAllotItem[] | string, check: boolean = false): IAllotItem {
        return { key: check && key === keyValue ? "value" : key, keyValue };
    }

    execDataAllot(ret: { [key: string]: any }, info: IInfo) {
        if (!ret.dataAllot) ret.dataAllot = {};
        const itemName = this.uiItem.info.ename;

        const item: IAllotItem = { keyValue: info.name, key: info.toName || "value" };

        const value = info.value;
        if (typeof value === "object" && info.isAgg) {
            const list = [];
            for (const key in value) {
                if (value[key]) {
                    list.push({ keyValue: key, key: value[key] });
                }
            }
            if (list.length > 0) item.keyValue = list;
        }
        // console.log("[HandleEnd]:", ret, info, item);
        // if (item.key === "value" && item.keyValue === "value") return;

        if (!ret.dataAllot[itemName]) ret.dataAllot[itemName] = [];
        ret.dataAllot[itemName].push(item);
    }

    execEnd(ret: { [key: string]: any }, info: IInfo) {
        const key = info.toName || info.name;
        PropsUtils.setValue(ret, key, info.value);
    }

    exec(ret: { [key: string]: any }, info: IInfo, type: string) {
        switch (type) {
            case IGuiType.UICheck:
                this.execCheckEnd(ret, info);
                break;
            case IGuiType.DataAllot:
                if (info.isExec) break;
                this.execDataAllot(ret, info);
                break;
            default:
                this.execEnd(ret, info);
        }
    }
}

export default HandleEnd;
