/**
 *
 *
 * @class SyncUtils
 */
class SyncUtils {
    private times: { [key: string]: NodeJS.Timeout } = {};

    private duration: number;

    constructor(duration: number = 100) {
        this.duration = duration;
    }

    sync(ename: string, callback = () => {}) {
        if (this.times[ename]) {
            //清除之前的修改延时
            clearTimeout(this.times[ename]);
            delete this.times[ename];
        }

        this.times[ename] = setTimeout(callback, this.duration);
    }

    clear(ename: string) {
        //清除之前的修改延时
        clearTimeout(this.times[ename]);
    }
}

export default SyncUtils;
