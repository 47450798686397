import BridgeConfig from "./BridgeConfig";

export default class UnityBridge {
    constructor(eventTarget, isIos) {
        this.eventTarget = eventTarget;
        this.register();
        this.isIos = isIos;
        setTimeout(() => {
            eventTarget.fire(BridgeConfig.Events.INIT);
        }, 50);
    }

    register() {
        window.UnityToJsMessage = (dataString) => {
            try {
                const outData = JSON.parse(dataString);
                const { method, data = {} } = outData;
                if (this && this.eventTarget) {
                    this.eventTarget.fire(method, data);
                }
            } catch (e) {
                console.log(`[UnityToJsMessage]接收消息格式错误`, e);
            }
        };
    }

    send = (inter, args = {}, callback) => {
        if (!inter) return;
        window.console.log(`[UnityBridge - ${this.isIos}]:send `, JSON.stringify({ method: inter, params: args }));
        // todo: 处理数据
        if (this.isIos) {
            window.location.href = "uniwebview://" + encodeURIComponent(JSON.stringify({ method: inter, params: args }));
        } else {
            if (window.jjSDK && window.jjSDK.SendMsgToUnity) {
                window.jjSDK.SendMsgToUnity(JSON.stringify({ method: inter, params: args }));
            } else {
                window.console.log("[UnityBridge] 不是 安卓unity 环境");
            }
        }
    };
}
