import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import { IInfo } from "src/component/props/PropsGGEditor/exec/ExecScript";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import Utils from "src/lib/utils/Utils";
import Formmatter from "../DataToExcel/Formmatter";

interface IValueScript {
    key: string;
    defaultValue: string;
    // 只传递value
    onlyTransmit?: boolean;
    valueType?: string;
}

/**
 *
 *
 * @class ValueScript
 */
@UIManifest.declare("ValueScript", "Value操作脚本", UIManifest.Type.Script)
class ValueScript extends Script<IValueScript> {
    exec(info: IInfo) {
        const { key, defaultValue, onlyTransmit = false, valueType } = this.props;
        if (onlyTransmit) {
            if (Utils.isArray(info.value)) {
                info.value = [...info.value];
            } else if (Utils.isObject(info.value)) {
                info.value = { ...info.value };
            } else {
                info.value = info.value;
            }
            console.log(`[value]`, info.value);
            return true;
        }
        if (key) {
            info.value = PropsUtils.getValue(info.value, key);
        }
        if (info.value === undefined) info.value = defaultValue;
        if (valueType) {
            info.value = Formmatter.strFormmatter(info.value, valueType);
        }
        return true;
    }
}

export default ValueScript;
