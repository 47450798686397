import Http from "src/lib/http/Http";
import Config from "src/engine/const/Config";
import IUIItem from "src/engine/interface/IUIItem";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import PublicUIMgr from "src/engineEditor/ui/PublicUIMgr";

const HandleConfig = {
    config: {},
    init: false,
    propsConfig: {},
    _parseConfig(stores: any, config: any) {
        stores.map((uiItem: IUIItem) => {
            config[uiItem.info.ename] = uiItem.props.initData;
        });
        this.handleConfig(config);
        return config;
    },
    handleConfig(configs: any) {
        for (const type in configs) {
            //Interfaces
            if (configs[type]) {
                const config = configs[type];
                for (const k in config) {
                    //Interfaces的配置
                    if (config[k]) {
                        if (config[k].sort) {
                            config[k].argsSort = config[k].sort.split(",");
                            delete config[k].sort;
                        }
                        if (k.indexOf(".") > -1) {
                            const value = config[k];
                            delete config[k];
                            PropsUtils.setValue(config, k, value);
                        }
                    }
                }
                this.handleProps(configs[type], type);
            }
        }
        return configs;
    },
    handleProps(configs: any, ctype: string) {
        this.propsConfig[ctype] = [] as any;
        const configList = Object.keys(configs);
        configList.map((type: string) => {
            const config = configs[type];
            if (config.method) {
                const props = { label: config.desc || config.method, value: type };
                this.propsConfig[ctype].push(props);
            }
        });
    },
    getConfig(callback: any, isProps?: boolean) {
        if (this.init) {
            callback(isProps ? this.propsConfig : this.config);
            return this.config;
        }
        Http.ajaxJson({ url: Config.getConfig(PublicUIMgr.UIManager, PublicUIMgr.GameId, "BridgeConfig") + `?time=${new Date().valueOf()}` }).then((data) => {
            try {
                let page: any = data || {};
                if (typeof data === "string") {
                    page = JSON.parse(data as string);
                }
                // const page =
                const { stores = [] } = page.page.props;
                const config = {};
                this._parseConfig(stores, config);
                this.config = config;
                this.init = true;
                if (callback) {
                    callback(isProps ? this.propsConfig : this.config);
                }
            } catch (error) {}
        });
        return isProps ? this.propsConfig : this.config;
    },
};
export default HandleConfig;
