import IUIItem from "../interface/IUIItem";
import ISource from "../interface/ISource";
// import UIMainfest from "../ui/UIManifest";

/**
 *
 *
 * @class PanelConfig
 */
class PanelConfig {
    width: number = 300;

    uiItems: IUIItem[] = [];

    sources: { [key: string]: ISource } = {};


    stores?: IUIItem[] = [];

    eprops?: any = {};
    //扩展属性时，body样式
    bodyStyle?: any;
}

export default PanelConfig;
