import IUIItem from "src/engine/interface/IUIItem";
import RuleRequired from "./FormRules/RuleRequired";
import IRule from "./FormRules/IRule";
import RuleShow from "./FormRules/RuleShow";
import RuleArray from "./FormRules/RuleArray";
import RuleNumber from "./FormRules/RuleNumber";
import RulePlatform from "./FormRules/RulePlatform";
import RuleRegExp from "./FormRules/RuleRegExp";
import RuleString from "./FormRules/RuleString";
import RuleCompareFormValue from "./FormRules/RuleCompareFormValue";
import RuleCompareDefaultValue from "./FormRules/RuleCompareDefaultValue";
import Manifest from "src/engine/Manifest";

class FormRule {
    /** Form表单配置 */
    mUIItem: IUIItem;

    constructor(uiItem: IUIItem = Manifest.ui()) {
        this.mUIItem = uiItem;
    }

    handleRules(list: IRule[], uiItem: IUIItem, expend: any) {
        //补全是否为requird
        const { required = {} } = this.mUIItem.props;
        if (required[uiItem.info.ename] || uiItem.props.required) {
            list = [new RuleRequired({ required: true }), ...list];
            expend.required = true;
        }
        return list;
    }

    transform(ruleList: any[]) {
        const list: IRule[] = [];
        const rules = {
            RuleRequired,
            RuleShow,
            RuleArray,
            RuleCompareValue: RuleCompareFormValue,
            RuleNumber,
            RulePlatform,
            RuleRegExp,
            RuleString,
            RuleCompareDefaultValue,
        };
        // console.log('[FormRule]:', ruleList);
        ruleList.map((rule) => {
            if (rule.type === "RuleRequired") rule.required = true;
            if (rules[rule.type]) list.push(new rules[rule.type](rule));
        });
        return list;
    }

    execRule(list: IRule[], fromData: any, expend: any, uiItem: IUIItem) {
        const ret: any = [];
        list.map((item) => {
            const execRet = item.exec(fromData, expend, uiItem);
            if (execRet !== false) ret.push(execRet);
        });
        return ret;
    }

    getItemRule(uiItem: IUIItem, formData: any = {}, checkData?: any) {
        //获取列表
        let { ruleList = [] } = uiItem.props;
        const { validateTrigger = "onChange" } = uiItem.props;
        //扩展属性
        const expend = {};
        //转换为处理器
        ruleList = this.transform(ruleList);
        //处理兼容问题
        ruleList = this.handleRules(ruleList, uiItem, expend);
        return {
            rules: this.execRule(ruleList, checkData ? { ...checkData, ...formData } : formData, expend, uiItem),
            validateTrigger,
            ...expend,
        };
    }
}

export default FormRule;
