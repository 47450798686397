import * as React from "react";
import { Pagination } from "antd";
import BaseComponent from "src/engine/ui/BaseComponent";
import UIInject, { IHandle } from "../UIInject";
import UIManifest from "src/engine/ui/UIManifest";
import IUIItem from "src/engine/interface/IUIItem";

interface IAntPagination {
    uiItem: IUIItem;
    title?: string;
    onShowSizeChange?: any;
    onChange?: any;
    total?: number;
    pageSize?: number;
    current?: number;
}

@UIManifest.declareGG(["ggValue"], "AntPagination", "AntPagination分页器", UIManifest.Type.AntD)
class AntPagination extends BaseComponent<IAntPagination> {
    render() {
        //{ type: "ui", key: "children", uiKey: "children" }
        const { total, pageSize = 0, current = 1 } = this.props as any;
        const handles: IHandle[] = [];
        return <UIInject ui={Pagination} {...this.props} pageSize={Number(pageSize)} current={Number(current)} total={total} handles={handles} />;
    }
}

export default AntPagination;
