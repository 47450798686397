import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import UIManifest from "src/engine/ui/UIManifest";

interface IJA extends IBaseUI {
    //地址
    value?: string;
    src?: string;
    errorImage?: string;
    type?: "image" | "bg";
    addTime?: boolean;
}
interface IState {}

@UIManifest.declareGG(["ggValue"], "JImage", "Image组件", UIManifest.Type.JJ)
class JImage extends BaseUI<IJA, IState> {
    getUrl = () => {
        const { src, value, addTime } = this.props;
        let url = value || src || "";
        if (addTime) {
            if (url.indexOf("?") > -1) {
                url += "&v=";
            } else {
                url += "?v=";
            }
            url += new Date().valueOf();
        }
        return url;
    };

    errorFlag: number = 0;

    onError = (e: any) => {
        if (this.errorFlag > 1) return;
        this.errorFlag++;
        const { errorImage, src } = this.props;
        if (e && e.target) {
            const url = errorImage || src;
            if (url) e.target.src = url;
        }
    };

    render() {
        const { className, type = "image" } = this.props;
        const url = this.getUrl();
        if (!url || url === "") return null;
        return type === "image" ? (
            <img className={className} style={this.getStyle()} src={this.getUrl()} onError={this.onError} />
        ) : (
            <div style={{ ...this.getStyle(), backgroundImage: `url(${this.getUrl()})` }} />
        );
    }
}

export default JImage;
