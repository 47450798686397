import * as React from "react";
import Style from "src/lib/utils/Style";

export interface ITouchProps {
    disabled?: boolean;
    activeStyle?: any;
    hoverStyle?: any;
    children?: any;
    hoverChildren?: any;
    onHover?: any;
    data?: any;
    index?: number;
    hoverKey?: string;
    hovertToEvent?: boolean;
    hoverChildrenNotClick?: boolean;
    eventHoverChild?: boolean;
}
// const hoverMap: any = {};
// let hoverIndex: any;
export interface ITouchState {
    active: boolean;
    hover: boolean;
}
export default class EventTouch extends React.Component<ITouchProps, ITouchState> {
    box: React.RefObject<HTMLDivElement>;
    hoverDom: React.RefObject<HTMLDivElement>;
    appendC: HTMLElement;
    constructor(props: ITouchProps) {
        super(props);
        this.box = React.createRef();
        this.hoverDom = React.createRef();
    }
    static defaultProps = {
        disabled: false
    };
    state = {
        active: false,
        hover: false
    };
    componentDidMount() {
        const dom = this.box.current;
        if (!dom) return;
        // dom.onmouseenter = this.onMouseOver;
        dom.onmouseleave = this.onMouseOut;
        dom.onmouseover = this.onMouseOver;
        this.appendC = document.createElement("div");
        // dom.onmouseout = this.onMouseOut;
    }
    onMouseOver = (e: any) => {
        e.stopPropagation();
        const dom: any = this.box.current;
        if (!dom) return;
        this.theStyle("hover", dom);
        this.renderHoverChild("hover");
    };
    onMouseOut = (e: any) => {
        const dom: any = this.box.current;
        if (!dom) return;
        this.theStyle("", dom);
        this.renderHoverChild();
    };

    renderHoverChild = (type?: string) => {
        if (this.props.eventHoverChild && this.props.hoverChildren) {
            const hoverdom = this.hoverDom.current;
            if (hoverdom) {
                if (type === "hover") {
                    hoverdom.style.display = "block";
                } else {
                    hoverdom.style.display = "none";
                }
            }
        }
    };

    theStyle = (type?: string, dom?: any) => {
        const { hoverStyle } = this.props;
        const child = React.Children.only(this.props.children);
        let { style } = child.props;
        const hoverS = Style(hoverStyle);
        if (type === "hover") {
            style = { ...style, ...hoverS };
            if (!dom) return;
            Object.keys(style).map((key: string) => {
                dom.style[key] = style[key];
            });
        } else {
            Object.keys(hoverS).map((key: string) => {
                dom.style[key] = "";
            });
        }
    };
    render() {
        const child = React.Children.only(this.props.children);
        if (this.props.eventHoverChild && this.props.hoverChildren) {
            child.props.children.push(
                <span ref={this.hoverDom} style={{ display: "none" }}>
                    {React.cloneElement(this.props.hoverChildren)}
                </span>
            );
        }
        return React.cloneElement(child, {
            ref: this.box
        });
    }
}
