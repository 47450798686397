import { IGuiData } from "./IExecConfig";
import UIManifest from "src/engine/ui/UIManifest";
import Script from "src/component/script/Script";
import IUIItem from "src/engine/interface/IUIItem";
import Handle from "./handle/Handle";
import HandleAgg from "./handle/HandleAgg";
import HandleCall from "./handle/HandleCall";
import HandleCheck from "./handle/HandleCheck";
import HandleEnd from "./handle/HandleEnd";
import { ISourceContext } from "src/engine/SourceCenter/SourceContext";
import { IPageContext } from "src/engine/pages/ui/PageContext";
import HandleAdd from "./handle/HandleAdd";
import Utils from "src/lib/utils/Utils";

export interface IInfo {
    name: string;
    toName?: string;
    checkValue?: string;
    checkType?: string;
    renderForNull?: boolean;
    value?: any;
    isAgg?: boolean;
    isExec?: boolean;
    params?: any;
    callback?: any;
}

export interface IContext {
    context: {
        sourceContext?: ISourceContext;
        pageContext?: IPageContext;
        updateUI?: (state?: any, upProps?: boolean) => void;
        checkList?: string[];
    };
    props: any;
}

/**
 *
 *
 * @class ExecScript
 */
class ExecScript {
    /**
     * 保存数据
     */
    data: IGuiData;

    /**
     *
     */
    context: IContext;

    nodesInfo: any;

    constructor(data: IGuiData, context: any, props: any, nodesInfo?: any) {
        this.data = data;
        // this.context = context;
        // this.context.props = props;
        this.context = {
            context: context || {},
            props
        };
        this.nodesInfo = nodesInfo;
        if (this.nodesInfo) {
            this.nodesInfo._maps = {};
            this.nodesInfo._list = [];
        }
        // this.scriptType={};
    }

    private execInfo(key: string, value: any) {
        if (this.nodesInfo) {
            value = typeof value === "object" ? Utils.deepClone(value) : value;
            // this.nodesInfo[key] = value;
            const { _maps, _list } = this.nodesInfo;
            _maps[key] = { value, index: _list.length };
            _list.push(value);
        }
    }

    updateSource = (state?: any) => {
        if (!state) {
            this.context.props = (this.context.context as any).props;
        } else {
            if (this.context.context.updateUI) this.context.context.updateUI(state);
        }
    };

    exec() {
        const { ret } = this.data;
        if (!ret) return {};
        const start = ret.start;
        const data = { ret: {}, handles: {} };
        //集合字段
        start.map((id: string) => {
            this.execItem(data, id);
        });
        // console.
        this.execInfo("ret", data.ret);
        return data.ret;
    }

    getScript(script: any, uiItem: IUIItem): Script<any> {
        return new script(uiItem, this.context, this.data.type + "");
    }

    getScriptItem(uiItem: IUIItem) {
        const script = UIManifest.maps[uiItem.ui as string];
        return script ? this.getScript(script.ui, uiItem) : null;
    }

    private getHandle(handles: any, uiItem: IUIItem, id: string): Handle | undefined {
        const script = this.getScriptItem(uiItem);
        //判断是否已经创建过
        if (handles[id]) return handles[id];

        if (!script) {
            console.log("[HandleLogic]:", `${uiItem.ui}脚本没有申明`);
            return;
        }
        //获取类型
        const type = UIManifest.getTypeByUI(uiItem.ui);

        let handle;
        const { relation } = this.data.ret;
        switch (type) {
            case UIManifest.Type.ScriptAgg:
                handle = new HandleAgg(script, relation[`${id}_agg`]);
                handles[id] = handle;
                break;
            case UIManifest.Type.ScriptCheck:
                handle = new HandleCheck(script, () => { }, this.data);
                break;
            case UIManifest.Type.scriptCall:
                handle = new HandleCall(script, this.updateSource, this.data);
                break;
            case UIManifest.Type.ScriptAddProps:
                handle = new HandleAdd(script, () => { }, this.data);
                break;
            default:
                handle = new Handle(script, () => { }, this.data);
                break;
        }
        return handle;
    }

    private execEdge = (id: string, info: IInfo) => {
        const { edges } = this.data.ret;
        if (id && edges[id]) {
            const item = this.getScriptItem(edges[id]);
            if (item) {
                const ret = item.exec(info, () => { });
                this.execInfo(id, info);
                return ret;
            }
        }
        return true;
    };

    private execItem(ret: any, id: string, info: IInfo = { name: "value" }, parentId?: string) {
        const { nodes, relation } = this.data.ret;
        const ids = id.split(",");
        const nodeId = ids[0];
        const node = nodes[nodeId];
        if (node) {
            // console.group(`[ExecScript] 执行：${node.ui}`);
            // console.log(`[ExecScript] 执行**********：${node.ui}`);
            //执行边
            this.execEdge(ids[1], info);
            // console.log(`[ExecScript]执行边info:`, { ...info });
            //判断为结束
            if (node.props.nodeType === "end") {
                //获取类型
                new HandleEnd(node, this.context.props, this.data.endKey).exec(ret.ret, info, this.data.type);
                // console.log(`[ExecScript] end:`, ret.ret);
                // console.groupEnd();
                return;
            }

            const handle = this.getHandle(ret.handles, node, nodeId);



            if (handle) {
                //设置下一步, 执行下一步函数
                handle.setNext(relation[nodeId], this.execItem.bind(this, ret), this.execEdge, nodeId, ret.ret);
                //执行
                handle.exec(info, parentId, () => {
                    this.execInfo(nodeId, info);
                    // if (node.ui === "BaseProps") this.execInfo(node.info.ename, info);
                });
            }

            // else this.execInfo(nodeId, info);
            // console.log(`[ExecScript]修改后info:`, { ...info });
            // console.log(`[ExecScript] 执行end：${node.ui}`);
            // console.groupEnd();
        }
    }
}

export default ExecScript;
