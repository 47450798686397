/*
 * @@Author: Do not edit
 * @@Date: Do not edit
 * @@Last Modified by: Do not edit
 * @@Last Modified time: Do not edit
 */
import IosOldBridge from "./IosOldBridge";
import AndroidBridge from "./AndroidBridge";
import IosBridge from "./IosBridge";
import Utils from "src/lib/utils/Utils";
import Cookie from "src/lib/utils/storage/Cookie";
import Storage from "src/lib/utils/storage/Storage";
import StorageType from "src/lib/utils/storage/StorageType";
import RNBridge from "./RNBridge";
import UnityBridge from "./UnityBridge";
import { BridgeType } from "./RNUtils";
// import { BridgeType } from "./RNUtils";

class BridgeUtil {
    constructor(bridge) {
        this.bridge = bridge;
        this.apkVer = Utils.getUrlParams("apkVer");
        this.appVersion = Utils.getUrlParams("appVersion");
        this.sdkVersion = Utils.getUrlParams("sdkVersion");
        this.sdkVer = Utils.getUrlParams("sdkVer");
        const localStorage = new Storage(StorageType.LocalStorage);
        this.hideback = localStorage.get("@NavigateBarStatus") || Utils.getUrlParams("hideback");
        this.appVer = Cookie.getItem("appVer");
        //发现版本dolphinVersion在url中获取
        this.dolphinVersion = Cookie.getItem("dolphinVersion");
        this.discoverVersion = Utils.getUrlParams("discoverVersion");
        this.colorTheme = localStorage.get("colorTheme");
        this.rnVersion = Cookie.getItem("rnVersion");
        // this.isJJSDK = getUrlParams("OPEN");
        this.RootUrl = window.location.href;
        //兼容论坛jjsdk
        if (Utils.getUrlParams("open") === "jjsdk") {
            this.hideback = "true";
            this.apkVer = 555;
            this.isJJSDK = true;
        }
        this.unitywebview = false;
        if (Utils.getUrlParams("unitywebview") === "true" || Cookie.getItem("unitywebview") === "true") {
            this.unitywebview = true;
        }
        this.isMac = Utils.getIos();
        // url中themeId主题，目前用了theme_mode的值最后后一位：1（白天）2（晚上），前面的代表id。比如周年庆：theme_mode白天是91，晚上92
        // 发现url中：channel：渠道，userType：用户类型（如游客等），dolphinVersion:海豚版本号
        //     :connectionType:wifi/cellular(流量),unknown(l联网状态异常),none（离线）
        //现在小海豚不强制升级
        //notchHeight 刘海屏高度，bottomHeight：底部高度（iPhone X）
    }
    //使用新的连接桥方式进行连接(小海豚发现版连接桥返回条默认隐藏，其他需调用方法隐藏)
    useNewConnect() {
        //发现
        if (this.unitywebview) {
            return BridgeType.UnityBridge;
        }
        if (this.discoverVersion) {
            // rn改版，版本号重新计算， 120000000 变为 0
            if (this.discoverVersion >= 0) {
                return BridgeType.RNPostMessage;
            }
            return BridgeType.PostMessage;
        } else {
            if (this.isMac) {
                if (this.dolphinVersion >= 556 || this.apkVer >= 556) {
                    return true;
                }
            }
        }
        return false;
    }
    setRoot(rootUrl) {
        this.RootUrl = rootUrl;
    }
    getApkVer = () => {
        return this.apkVer;
    };
    registerBack(callback) {
        this.modeBack = callback;
    }
    isDolphin() {
        if (window.JavaScriptInterface || (this.apkVer && this.apkVer !== "null")) {
            return false;
        } else {
            return true;
        }
    }
    //入口页退出方法
    closeApp = () => {
        const type = this.useNewConnect();
        if (type === BridgeType.RNPostMessage || type === BridgeType.PostMessage || type === BridgeType.UnityBridge) {
            this.bridge.send(this.bridge.BridgeConfig.Interfaces.closeWebView, null, null);
        } else {
            if (this.isMac !== true && this.isDolphin()) {
                this.bridge.send(this.bridge.BridgeConfig.Interfaces.closeApp, null, null, 2);
                this.bridge.send("closeWebView");
            } else {
                this.bridge.send(this.bridge.BridgeConfig.Interfaces.closeApp, null, null, 1);
                this.bridge.send("closeWebView");
            }
        }
    };
    webOpenVideo = (params, callback) => {
        params = params || {};
        if (params.isCheck) {
            if (this.appVersion >= 50808 && this.discoverVersion >= 0 && this.sdkVersion > 20) {
                callback({ isCheck: true });
                return true;
            }
            callback({ isCheck: false });
            return false;
        }
        this.bridge.send(this.bridge.BridgeConfig.Interfaces.webOpenVideo, params, callback);
    };
    //是否可以分享
    canShareDolphin = (params, isCheck) => {
        if (params && params.param && params.param.url) {
            params.param.url = `${params.param.url}&NavigationH=${window.NavigationH}&NotchH=${window.NotchH}`;
        }
        //小海豚
        if (this.isDolphin() && this.rnVersion && this.rnVersion >= 110010201) {
            if (isCheck) {
                return true;
            }
            this.bridge.send(this.bridge.BridgeConfig.Interfaces.shareHT, params);
            //大厅
        } else if (this.apkVer && this.apkVer >= 536) {
            if (isCheck) {
                return true;
            }
            this.bridge.send(this.bridge.BridgeConfig.Interfaces.shareHT, params);
        }
        return false;
    };
    //isCheck:是否判断，isStartWeb是否应用内打开新窗口
    //isOpenService,startweb用于打开客服
    //isChangePage:普通的在新窗口打开连接
    startWeb = (params = {}, isCheck, isOpenService) => {
        if (params.url) {
            params.url = `${params.url}&apkVer=${this.apkVer}&sdkVer=${this.sdkVer}&open=${Utils.getUrlParams("open")}`;
        }

        if (!this.isDolphin()) {
            if (this.isMac && this.apkVer && this.apkVer >= 522) {
                if (isCheck) {
                    return true;
                }
                params.url = `${params.url}&hideback=true`;
                this.bridge.send(this.bridge.BridgeConfig.Interfaces.startWeb, params);
            } else if (this.apkVer && this.apkVer >= 50202) {
                if (isCheck) {
                    return true;
                }
                if (isOpenService) {
                    params.url = `${params.url}&hideback=false`;
                } else {
                    params.url = `${params.url}&hideback=true`;
                }
                this.bridge.send(this.bridge.BridgeConfig.Interfaces.startWeb, params);
            }
        }
        return false;
    };
    createBridge = (events, OriginListener, connect = this.useNewConnect()) => {
        // const connect = this.useNewConnect();
        if (connect === BridgeType.PostMessage) {
            return new RNBridge(events, { ...OriginListener.Ios, ...OriginListener.Android });
        } else if (connect === BridgeType.RNPostMessage) {
            return new RNBridge(events, { ...OriginListener.Ios, ...OriginListener.Android }, true);
        } else if (connect === BridgeType.UnityBridge) {
            return new UnityBridge(events, this.isMac);
        } else {
            if (this.isMac) {
                if (connect) {
                    return new IosBridge(events, OriginListener.Ios);
                }
                return new IosOldBridge(events, OriginListener.Ios);
            } else {
                return new AndroidBridge(events, OriginListener.Android);
            }
        }
    };
    getUsePageBack = () => {
        let dolphin = this.dolphinVersion >= 536 && this.dolphinVersion < 100000000;

        //海豚
        if (this.isDolphin() && dolphin) {
            //2:隐藏小海豚返回条，1隐藏大厅返回条
            this.bridge.send(this.bridge.BridgeConfig.Interfaces.hideBackBar, null, null, 2);
            return true;
        }
        //大厅
        if (!this.isDolphin() && this.hideback) {
            this.bridge.send(this.bridge.BridgeConfig.Interfaces.hideBackBar, null, null, 1);
            return true;
        }
        if (!this.dolphinVersion && !this.hideback) {
            return true;
        }
        return false;
    };
    isRootPage = (str) => {
        if (this.isDolphin() && this.RootUrl.indexOf(str) > -1) {
            return true;
        }
        return false;
    };
    //打开相册方法
    openPhoto = (params, callback) => {
        if (this.isJJSDK || this.appVer >= 5) {
            let argument = {};
            if (this.colorTheme && this.colorTheme === "model-night") {
                argument = {
                    isOriginal: false,
                    isToPhList: true,
                    maxCount: params,
                    mode: this.isMac ? (this.isJJSDK ? 2 : true) : 0,
                    needCut: this.isMac ? false : 0,
                };
            } else {
                argument = {
                    isOriginal: false,
                    isToPhList: true,
                    maxCount: params,
                    mode: this.isMac ? (this.isJJSDK ? 1 : false) : 1,
                    needCut: this.isMac ? false : 0,
                };
            }
            callback(argument, 2);
        } else {
            callback({ maxCount: params, isOriginal: false, needCut: this.isMac ? false : 0 }, 1);
        }
    };
    //页面返回方法
    goBack = (pageStr) => {
        this.closeApp();
        // if (this.modeBack) {
        //     var ret = this.modeBack();
        //     if (!ret) {
        //         this.modeBack = null;
        //     }
        // } else {
        //     //入口页退出
        //     if (this.RootUrl.indexOf(pageStr) > -1) {
        //         this.closeApp();
        //     } else {
        //         window.history.go(-1);
        //     }
        // }
    };
}

export default BridgeUtil;
