import IRule, { IRuleProps } from "./IRule";
import IUIItem from "src/engine/interface/IUIItem";
import Utils from "src/lib/utils/Utils";

interface IRuleStringProps extends IRuleProps {
    // 字符串最大长度
    max?: number;
    min?: number;
    // 是否是字节长度
    isSize?: boolean;
}

/**
 * 校验 【字符串】
 */
class RuleString implements IRule {
    type: "string";

    props: IRuleStringProps;

    constructor(props: IRuleStringProps) {
        this.props = props;
    }

    defineMessage(str: "字节" | "字符") {
        const { max, min } = this.props;
        let selfM = `输入长度不正确，应`;
        if (min !== void 0 && max === void 0) {
            selfM += `大于${min}个${str}`;
        } else if (max !== void 0 && min === void 0) {
            selfM += `小于${max}个${str}`;
        } else if (max !== void 0 && min !== void 0) {
            selfM += `在${min}~${max}个${str}之间`;
        }
        return selfM;
    }

    exec(
        // 表单值
        formData: any,
        // 扩展属性, rules同级
        expend: any,
        // 当前表单 UIItem
        uiItem: IUIItem
    ) {
        const { message, max, min, isSize = false } = this.props;

        if (!isSize) {
            return { message: message ? message : this.defineMessage("字符"), max, min, type: this.type };
        }
        return {
            validator: (rule: any, value: any, callback: any) => {
                try {
                    const size = Utils.stringByteSize(value);
                    if ((max && size > max) || (min && size < min)) {
                        callback(message ? message : this.defineMessage("字节"));
                    }
                } catch (err) {
                    callback(err);
                    return;
                }
                callback();
            },
        };
    }
}

export default RuleString;
