export const EmojiCodeMap: any = {
    "[微笑]": { dataCode: "{:E000:}", desc: "[微笑]", index: 0 },
    "[呲牙]": { dataCode: "{:E001:}", desc: "[呲牙]", index: 1 },
    "[龇牙]": { dataCode: "{:E001:}", desc: "[龇牙]", index: 1 },
    "[调皮]": { dataCode: "{:E002:}", desc: "[调皮]", index: 2 },
    "[阴险]": { dataCode: "{:E003:}", desc: "[阴险]", index: 3 },
    "[阴脸]": { dataCode: "{:E003:}", desc: "[阴脸]", index: 3 },
    "[偷笑]": { dataCode: "{:E004:}", desc: "[偷笑]", index: 4 },
    "[憨笑]": { dataCode: "{:E005:}", desc: "[憨笑]", index: 5 },
    "[酷毙了]": { dataCode: "{:E006:}", desc: "[酷毙了]", index: 6 },
    "[酷]": { dataCode: "{:E006:}", desc: "[酷]", index: 6 },
    "[流汗]": { dataCode: "{:E007:}", desc: "[流汗]", index: 7 },
    "[拜拜]": { dataCode: "{:E008:}", desc: "[拜拜]", index: 8 },
    "[再见]": { dataCode: "{:E008:}", desc: "[再见]", index: 8 },
    "[敲打]": { dataCode: "{:E009:}", desc: "[敲打]", index: 9 },
    "[擦汗]": { dataCode: "{:E010:}", desc: "[擦汗]", index: 10 },
    "[流泪]": { dataCode: "{:E011:}", desc: "[流泪]", index: 11 },
    "[抓狂]": { dataCode: "{:E012:}", desc: "[抓狂]", index: 12 },
    "[疯了]": { dataCode: "{:E012:}", desc: "[疯了]", index: 12 },
    "[嘘…]": { dataCode: "{:E013:}", desc: "[嘘…]", index: 13 },
    "[嘘]": { dataCode: "{:E013:}", desc: "[嘘]", index: 13 },
    "[委屈]": { dataCode: "{:E014:}", desc: "[委屈]", index: 14 },
    "[猪头]": { dataCode: "{:E015:}", desc: "[猪头]", index: 15 },
    "[玫瑰]": { dataCode: "{:E016:}", desc: "[玫瑰]", index: 16 },
    "[大哭]": { dataCode: "{:E017:}", desc: "[大哭]", index: 17 },
    "[便便]": { dataCode: "{:E018:}", desc: "[便便]", index: 18 },
    "[可爱]": { dataCode: "{:E019:}", desc: "[可爱]", index: 19 },
    "[愉快]": { dataCode: "{:E019:}", desc: "[愉快]", index: 19 },
    "[色眯眯]": { dataCode: "{:E020:}", desc: "[色眯眯]", index: 20 },
    "[色]": { dataCode: "{:E020:}", desc: "[色]", index: 20 },
    "[害羞]": { dataCode: "{:E021:}", desc: "[害羞]", index: 21 },
    "[得意]": { dataCode: "{:E022:}", desc: "[得意]", index: 22 },
    "[吐了]": { dataCode: "{:E023:}", desc: "[吐了]", index: 23 },
    "[吐]": { dataCode: "{:E023:}", desc: "[吐]", index: 23 },
    "[怒了]": { dataCode: "{:E024:}", desc: "[怒了]", index: 24 },
    "[发怒]": { dataCode: "{:E024:}", desc: "[发怒]", index: 24 },
    "[尴尬]": { dataCode: "{:E025:}", desc: "[尴尬]", index: 25 },
    "[惊恐]": { dataCode: "{:E026:}", desc: "[惊恐]", index: 26 },
    "[汗]": { dataCode: "{:E027:}", desc: "[汗]", index: 27 },
    "[冷汗]": { dataCode: "{:E027:}", desc: "[冷汗]", index: 27 },
    "[爱心]": { dataCode: "{:E028:}", desc: "[爱心]", index: 28 },
    "[吻]": { dataCode: "{:E029:}", desc: "[吻]", index: 29 },
    "[嘴唇]": { dataCode: "{:E029:}", desc: "[嘴唇]", index: 29 },
    "[白眼]": { dataCode: "{:E030:}", desc: "[白眼]", index: 30 },
    "[傲慢]": { dataCode: "{:E031:}", desc: "[傲慢]", index: 31 },
    "[不高兴]": { dataCode: "{:E032:}", desc: "[不高兴]", index: 32 },
    "[难过]": { dataCode: "{:E032:}", desc: "[难过]", index: 32 },
    "[吃惊]": { dataCode: "{:E033:}", desc: "[吃惊]", index: 33 },
    "[惊讶]": { dataCode: "{:E033:}", desc: "[惊讶]", index: 33 },
    "[疑问]": { dataCode: "{:E034:}", desc: "[疑问]", index: 34 },
    "[睡觉]": { dataCode: "{:E035:}", desc: "[睡觉]", index: 35 },
    "[睡]": { dataCode: "{:E035:}", desc: "[睡]", index: 35 },
    "[亲亲]": { dataCode: "{:E036:}", desc: "[亲亲]", index: 36 },
    "[衰人]": { dataCode: "{:E037:}", desc: "[衰人]", index: 37 },
    "[衰]": { dataCode: "{:E037:}", desc: "[衰]", index: 37 },
    "[撇嘴]": { dataCode: "{:E038:}", desc: "[撇嘴]", index: 38 },
    "[憋嘴]": { dataCode: "{:E038:}", desc: "[憋嘴]", index: 38 },
    "[奋斗]": { dataCode: "{:E039:}", desc: "[奋斗]", index: 39 },
    "[发呆]": { dataCode: "{:E040:}", desc: "[发呆]", index: 40 },
    "[右嫌弃]": { dataCode: "{:E041:}", desc: "[右嫌弃]", index: 41 },
    "[右哼哼]": { dataCode: "{:E041:}", desc: "[右哼哼]", index: 41 },
    "[抱抱]": { dataCode: "{:E042:}", desc: "[抱抱]", index: 42 },
    "[坏笑]": { dataCode: "{:E043:}", desc: "[坏笑]", index: 43 },
    "[鄙视]": { dataCode: "{:E044:}", desc: "[鄙视]", index: 44 },
    "[晕了]": { dataCode: "{:E045:}", desc: "[晕了]", index: 45 },
    "[大兵]": { dataCode: "{:E046:}", desc: "[大兵]", index: 46 },
    "[悠闲]": { dataCode: "{:E046:}", desc: "[悠闲]", index: 46 },
    "[撒娇]": { dataCode: "{:E047:}", desc: "[撒娇]", index: 47 },
    "[可怜]": { dataCode: "{:E047:}", desc: "[可怜]", index: 47 },
    "[强]": { dataCode: "{:E048:}", desc: "[强]", index: 48 },
    "[弱]": { dataCode: "{:E049:}", desc: "[弱]", index: 49 },
    "[握手]": { dataCode: "{:E050:}", desc: "[握手]", index: 50 },
    "[胜利]": { dataCode: "{:E051:}", desc: "[胜利]", index: 51 },
    "[抱拳]": { dataCode: "{:E052:}", desc: "[抱拳]", index: 52 },
    "[凋谢]": { dataCode: "{:E053:}", desc: "[凋谢]", index: 53 },
    "[米饭]": { dataCode: "{:E054:}", desc: "[米饭]", index: 54 },
    "[饭]": { dataCode: "{:E054:}", desc: "[饭]", index: 54 },
    "[蛋糕]": { dataCode: "{:E055:}", desc: "[蛋糕]", index: 55 },
    "[OK]": { dataCode: "{:E056:}", desc: "[OK]", index: 56 },
    "[咖啡]": { dataCode: "{:E057:}", desc: "[咖啡]", index: 57 },
    "[刀子]": { dataCode: "{:E058:}", desc: "[刀子]", index: 58 },
    "[刀]": { dataCode: "{:E058:}", desc: "[刀]", index: 58 },
    "[拳头]": { dataCode: "{:E059:}", desc: "[拳头]", index: 59 },
    "[心碎了]": { dataCode: "{:E060:}", desc: "[心碎了]", index: 60 },
    "[心碎]": { dataCode: "{:E060:}", desc: "[心碎]", index: 60 },
    "[礼物]": { dataCode: "{:E061:}", desc: "[礼物]", index: 61 },
    "[饿了]": { dataCode: "{:E062:}", desc: "[饿了]", index: 62 },
    "[困了]": { dataCode: "{:E063:}", desc: "[困了]", index: 63 },
    "[困]": { dataCode: "{:E063:}", desc: "[困]", index: 63 },
    "[骂人]": { dataCode: "{:E064:}", desc: "[骂人]", index: 64 },
    "[咒骂]": { dataCode: "{:E064:}", desc: "[咒骂]", index: 64 },
    "[抠鼻子]": { dataCode: "{:E065:}", desc: "[抠鼻子]", index: 65 },
    "[抠鼻]": { dataCode: "{:E065:}", desc: "[抠鼻]", index: 65 },
    "[鼓掌]": { dataCode: "{:E066:}", desc: "[鼓掌]", index: 66 },
    "[糗大了]": { dataCode: "{:E067:}", desc: "[糗大了]", index: 67 },
    "[左嫌弃]": { dataCode: "{:E068:}", desc: "[左嫌弃]", index: 68 },
    "[左哼哼]": { dataCode: "{:E068:}", desc: "[左哼哼]", index: 68 },
    "[哈欠]": { dataCode: "{:E069:}", desc: "[哈欠]", index: 69 },
    "[快哭了]": { dataCode: "{:E070:}", desc: "[快哭了]", index: 70 },
    "[闭嘴]": { dataCode: "{:E071:}", desc: "[闭嘴]", index: 71 },
};
export const EmojiImgMap: any = {
    "{:E000:}": { dataCode: "{:E000:}", desc: "[微笑]", index: 0 },
    "{:E001:}": { dataCode: "{:E001:}", desc: "[呲牙]", index: 1 },
    "{:E002:}": { dataCode: "{:E002:}", desc: "[调皮]", index: 2 },
    "{:E003:}": { dataCode: "{:E003:}", desc: "[阴险]", index: 3 },
    "{:E004:}": { dataCode: "{:E004:}", desc: "[偷笑]", index: 4 },
    "{:E005:}": { dataCode: "{:E005:}", desc: "[憨笑]", index: 5 },
    "{:E006:}": { dataCode: "{:E006:}", desc: "[酷毙了]", index: 6 },
    "{:E022:}": { dataCode: "{:E022:}", desc: "[得意]", index: 22 },
    "{:E007:}": { dataCode: "{:E007:}", desc: "[流汗]", index: 7 },
    "{:E010:}": { dataCode: "{:E010:}", desc: "[擦汗]", index: 10 },
    "{:E011:}": { dataCode: "{:E011:}", desc: "[流泪]", index: 11 },
    "{:E012:}": { dataCode: "{:E012:}", desc: "[抓狂]", index: 12 },
    "{:E013:}": { dataCode: "{:E013:}", desc: "[嘘…]", index: 13 },
    "{:E014:}": { dataCode: "{:E014:}", desc: "[委屈]", index: 14 },
    "{:E017:}": { dataCode: "{:E017:}", desc: "[大哭]", index: 17 },
    "{:E032:}": { dataCode: "{:E032:}", desc: "[不高兴]", index: 32 },
    "{:E030:}": { dataCode: "{:E030:}", desc: "[白眼]", index: 30 },
    "{:E031:}": { dataCode: "{:E031:}", desc: "[傲慢]", index: 31 },
    "{:E019:}": { dataCode: "{:E019:}", desc: "[可爱]", index: 19 },
    "{:E020:}": { dataCode: "{:E020:}", desc: "[色眯眯]", index: 20 },
    "{:E021:}": { dataCode: "{:E021:}", desc: "[害羞]", index: 21 },
    "{:E008:}": { dataCode: "{:E008:}", desc: "[拜拜]", index: 8 },
    "{:E009:}": { dataCode: "{:E009:}", desc: "[敲打]", index: 9 },
    "{:E066:}": { dataCode: "{:E066:}", desc: "[鼓掌]", index: 66 },
    "{:E071:}": { dataCode: "{:E071:}", desc: "[闭嘴]", index: 71 },
    "{:E023:}": { dataCode: "{:E023:}", desc: "[吐了]", index: 23 },
    "{:E024:}": { dataCode: "{:E024:}", desc: "[怒了]", index: 24 },
    "{:E025:}": { dataCode: "{:E025:}", desc: "[尴尬]", index: 25 },
    "{:E026:}": { dataCode: "{:E026:}", desc: "[惊恐]", index: 26 },
    "{:E027:}": { dataCode: "{:E027:}", desc: "[汗]", index: 27 },
    "{:E070:}": { dataCode: "{:E070:}", desc: "[快哭了]", index: 70 },
    "{:E047:}": { dataCode: "{:E047:}", desc: "[撒娇]", index: 47 },
    "{:E062:}": { dataCode: "{:E062:}", desc: "[饿了]", index: 62 },
    "{:E063:}": { dataCode: "{:E063:}", desc: "[困了]", index: 63 },
    "{:E045:}": { dataCode: "{:E045:}", desc: "[晕了]", index: 45 },
    "{:E064:}": { dataCode: "{:E064:}", desc: "[骂人]", index: 64 },
    "{:E065:}": { dataCode: "{:E065:}", desc: "[抠鼻子]", index: 65 },
    "{:E067:}": { dataCode: "{:E067:}", desc: "[糗大了]", index: 67 },
    "{:E068:}": { dataCode: "{:E068:}", desc: "[左嫌弃]", index: 68 },
    "{:E041:}": { dataCode: "{:E041:}", desc: "[右嫌弃]", index: 41 },
    "{:E069:}": { dataCode: "{:E069:}", desc: "[哈欠]", index: 69 },
    "{:E043:}": { dataCode: "{:E043:}", desc: "[坏笑]", index: 43 },
    "{:E044:}": { dataCode: "{:E044:}", desc: "[鄙视]", index: 44 },
    "{:E046:}": { dataCode: "{:E046:}", desc: "[大兵]", index: 46 },
    "{:E033:}": { dataCode: "{:E033:}", desc: "[吃惊]", index: 33 },
    "{:E034:}": { dataCode: "{:E034:}", desc: "[疑问]", index: 34 },
    "{:E035:}": { dataCode: "{:E035:}", desc: "[睡觉]", index: 35 },
    "{:E036:}": { dataCode: "{:E036:}", desc: "[亲亲]", index: 36 },
    "{:E037:}": { dataCode: "{:E037:}", desc: "[衰人]", index: 37 },
    "{:E038:}": { dataCode: "{:E038:}", desc: "[撇嘴]", index: 38 },
    "{:E039:}": { dataCode: "{:E039:}", desc: "[奋斗]", index: 39 },
    "{:E040:}": { dataCode: "{:E040:}", desc: "[发呆]", index: 40 },
    "{:E015:}": { dataCode: "{:E015:}", desc: "[猪头]", index: 15 },
    "{:E048:}": { dataCode: "{:E048:}", desc: "[强]", index: 48 },
    "{:E049:}": { dataCode: "{:E049:}", desc: "[弱]", index: 49 },
    "{:E056:}": { dataCode: "{:E056:}", desc: "[OK]", index: 56 },
    "{:E059:}": { dataCode: "{:E059:}", desc: "[拳头]", index: 59 },
    "{:E050:}": { dataCode: "{:E050:}", desc: "[握手]", index: 50 },
    "{:E051:}": { dataCode: "{:E051:}", desc: "[胜利]", index: 51 },
    "{:E052:}": { dataCode: "{:E052:}", desc: "[抱拳]", index: 52 },
    "{:E029:}": { dataCode: "{:E029:}", desc: "[吻]", index: 29 },
    "{:E042:}": { dataCode: "{:E042:}", desc: "[抱抱]", index: 42 },
    "{:E016:}": { dataCode: "{:E016:}", desc: "[玫瑰]", index: 16 },
    "{:E053:}": { dataCode: "{:E053:}", desc: "[凋谢]", index: 53 },
    "{:E061:}": { dataCode: "{:E061:}", desc: "[礼物]", index: 61 },
    "{:E054:}": { dataCode: "{:E054:}", desc: "[米饭]", index: 54 },
    "{:E055:}": { dataCode: "{:E055:}", desc: "[蛋糕]", index: 55 },
    "{:E028:}": { dataCode: "{:E028:}", desc: "[爱心]", index: 28 },
    "{:E060:}": { dataCode: "{:E060:}", desc: "[心碎了]", index: 60 },
    "{:E057:}": { dataCode: "{:E057:}", desc: "[咖啡]", index: 57 },
    "{:E058:}": { dataCode: "{:E058:}", desc: "[刀子]", index: 58 },
    "{:E018:}": { dataCode: "{:E018:}", desc: "[便便]", index: 18 },
};
export default {
    EmojiCodeMap,
    EmojiImgMap,
};
