import IUIItem from "src/engine/interface/IUIItem";

/**
 *
 *
 * @class Manifest
 */
class Manifest {
    static ui(ename: string = "ename", cname: string = "cname", props: any = {}, ui: any = ""): IUIItem {
        return {
            info: { ename, cname },
            ui,
            props,
        };
    }

    static UIItem(ename: string, cname: string, ui: React.ReactType | string, props: any = {}, eprops: any = {}) {
        const uiItem: IUIItem = {
            info: { ename, cname },
            ui,
            ...eprops,
            props,
        };
        return uiItem;
    }

    static checkISUIItemList(item: any) {
        return Array.isArray(item) && item.length > 0 && item[0].info && typeof item[0].ui === "string";
    }
}

export default Manifest;
