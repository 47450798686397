import Config from "src/engine/const/Config";
import { StatType } from "src/engine/pages/ui/Page";

const EditConfig = {
    IP: Config.IP,
    //内容保存接口
    contentSave: `${Config.API}official-media/manage/source/batchSave`,
    // 德昌新写的批量保存接口，  添加了tosName
    // newContentSave: `${Config.API}tulip/manage/batchSave`,
    //模板保存url
    tempUrl: `${Config.IP_NOPORT}cloudplt/api/factory/temp`,
    //创建服务
    createUrl: `${Config.IP_NOPORT}cloudplt/api/factory/postj`,
    //上传图片地址
    // UPLOAD_URL: `${Config.API}media-library/manage/upload`,
    UPLOAD_URL: `${Config.API}oss/upload`,
    //编辑器模板使用sname
    EDITOR_TEMPLATE_SERVER: "editor",
    //编辑器模板类型
    RENDER_TEMPLATE_TYPE: "page",

    stat: (pageId: string, clickId: string, extend: any = {}) => {
        const aWindow: any = window;
        if (!aWindow.Logger) return;
        // const intent = this.getIntent();
        const statInfo = {
            //活动id
            activityId: `cloudcms`,
            //页面id
            pageId,
            clickId,
            ...extend,
        };
        //按钮统计
        aWindow.Logger.profession(StatType.CLICK, statInfo);
    },
};

export default EditConfig;
