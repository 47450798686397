import "src/components/PCPageMgr";import "src/components/Layout";import "src/components/View";import "src/components/Text";import "src/components/JList";import "src/components/Image";import "src/components/ButtonExt";import "src/component/script/BaseProps";import "src/component/script/GGEdge";import "src/component/script/ClickGoUrl";import "src/component/script/AggregationScript";import "src/component/script/CallbackScript";import "src/components/Button";import "src/component/script/ClickScript";import "src/component/script/MapScript";import "src/component/script/CheckScript";import "src/components/HtmlRicher";import "src/components/SwiperJs";import "src/component/script/HandleScript";import "src/component/source/SourceNet";import "src/component/script/ValueScript";import "src/component/script/GetSourceScript";import "src/components/Pagination";import "src/components/Layout";import "src/components/FlexArea";import "src/components/DataUI";import "src/components/Label";import "src/components/DataItem";import "src/components/PCListUI";import "src/components/HtmlTrans";import "src/component/script/TimeScript";import "src/component/script/ExtStyleScript";import "src/component/source/SourceArtical";import "src/component/jj/JImage";import "src/component/antd/AntPagination";import "src/components/PCForm";import "src/component/antd/AntInput";import "src/component/antd/AntRadio";import "src/component/antd/AntUpload";import "src/components/Modal";import "src/component/script/ClickGoSource";import "src/component/script/ToastScript";
        const UILoadingManifest = (key: string) => {
            switch (key) {
                
                default:
                    return null;
            }
        };
        
        UILoadingManifest.PageManifest ={
            home:["PCPage","View","ButtonExt","ClickGoUrl","CallbackScript","ClickScript","Text","JList","Image","BaseProps","GGEdge","AggregationScript","CheckScript","HandleScript"],aboutus:["PCPage","View","ButtonExt","ClickGoUrl","CallbackScript","ClickScript","Image","Text","BaseProps","MapScript","GGEdge","HtmlRicher","ExtStyleScript","CheckScript","SwiperJs","Button","JList"],gongyi:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","JList","Image","AggregationScript","BaseProps","GGEdge","Pagination","HandleScript","ClickGoUrl"],detail:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","BaseProps","ClickGoUrl","TimeScript","HtmlRicher","GGEdge","SourceNet","ValueScript","GetSourceScript"],apply:["PCPage","SourceLocal","Layout","View","ButtonExt","ClickGoUrl","CallbackScript","ClickScript","FlexArea","Button","DataUI","Label","DataItem","Image","PCListUI","HtmlTrans","AggregationScript","BaseProps","GGEdge","Text","CheckScript"],index:["PCPage","View","ButtonExt","ClickGoUrl","CallbackScript","ClickScript","JList","Text","BaseProps","AggregationScript","GGEdge","JImage","AntPagination","SourceArtical","SourceNet"],gameInfo:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","BaseProps","ClickGoUrl","TimeScript","HtmlTrans","GGEdge","SourceNet","ValueScript","GetSourceScript"],permit:["PCPage","JImage"],report:["PCPage","SourceNet","View","Text","ButtonExt","ClickGoUrl","CallbackScript","PCForm","AntInput","AntRadio","AntUpload","Modal","BaseProps","ToastScript","CheckScript","ClickGoSource","ValueScript","GGEdge","ClickScript"],comics:["PCPage","View","ButtonExt","ClickGoUrl","CallbackScript","ClickScript","JList","Text","BaseProps","AggregationScript","GGEdge","JImage","AntPagination","SourceArtical"],comInfo:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","BaseProps","ClickGoUrl","TimeScript","HtmlTrans","GGEdge","SourceNet","ValueScript","GetSourceScript"],
        }

        export default UILoadingManifest;