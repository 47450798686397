import * as React from "react";
// import UIManifest from "src/engine/ui/UIManifest";
import "swiper/dist/css/swiper.min.css";
import "./swiperJs.scss";
// import Swiper from "swiper";
import IUIItem from "src/engine/interface/IUIItem";
import { IPanelContext } from "src/engine/context/PanelContext";
import BaseComponent from "src/engine/ui/BaseComponent";

interface ICoreTabs {
    dataChildren?: any;
    // 单个容器宽度
    slideWidth?: string;
    coverflowEffect?: boolean;
    children?: IUIItem[];
    dataSource?: any;
}

interface IProps extends ICoreTabs {
    context: IPanelContext;
    index?: number;
    prevIndex?: number;
    nextIndex?: number;
    renderUIItem: any;
}

export default class CorenderTabs extends BaseComponent<IProps> {
    getPosition = (key: number) => {
        let position = "hidden";
        if (this.props.prevIndex === key) {
            position = "prev";
        } else if (this.props.index === key) {
            position = "active";
        } else if (this.props.nextIndex === key) {
            position = "next";
        }
        return position;
    };

    getSlideWidth = (item: IUIItem) => {
        if (!item) return false;
        const style = item.props.style;
        if (!style) return false;
        if (style.width) return style.width;
    };

    render() {
        const { children = [], dataChildren = [], slideWidth } = this.props;
        let { dataSource = [] } = this.props;
        if (!children || children.length === 0) {
            if (dataChildren[0]) {
                const widthStyle = {};
                if (this.props.coverflowEffect) {
                    if (slideWidth) widthStyle["width"] = slideWidth;
                    else widthStyle["width"] = this.getSlideWidth(dataChildren[0]);
                }
                if (!Array.isArray(dataSource)) dataSource = [];
                return dataSource.map((data: any, key: number) => {
                    const position = this.getPosition(key);
                    let sendData: any = {};
                    if (Object.prototype.toString.call(data) === "[object Object]") {
                        sendData = { ...data, index: key };
                    } else {
                        sendData = { data, index: key };
                    }
                    return (
                        <div className={`swiper-slide ${key}`} style={widthStyle} key={key}>
                            {this.props.renderUIItem(dataChildren[0], this.props.context, {
                                key,
                                index: key,
                                data: sendData,
                                itemIndex: key,
                                position,
                                nowIndex: this.props.index,
                            })}
                        </div>
                    );
                });
            }
        } else {
            return children.map((item: IUIItem, key: number) => {
                const widthStyle = {};
                if (this.props.coverflowEffect) {
                    widthStyle["width"] = this.getSlideWidth(item);
                }
                const position = this.getPosition(key);
                return (
                    <div className="swiper-slide" style={widthStyle} key={key}>
                        {this.props.renderUIItem(item, this.props.context, {
                            key,
                            index: key,
                            itemIndex: key,
                            nowIndex: this.props.index,
                            position,
                        })}
                    </div>
                );
            });
        }
    }
}
