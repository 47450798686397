import IUIItem from "src/engine/interface/IUIItem";
import IGameInfo from "src/engine/interface/IGameInfo";
import JJHistory from "./JJHistory";
// import SourceHelper from "src/engineEditor/pages/SourceHelper";
import Utils from "src/lib/utils/Utils";
import UIManifest from "src/engine/ui/UIManifest";
import { IRenderType } from "src/engine/context/PanelContext";

/**
 * 页面管理类型
 *
 */
export enum Types {
    /**
     * pageMgr信息初始化
     */
    PagesInit = "pagesInit",

    /**
     * 打开页面
     */
    StartPage = "startPage",

    /**
     * 加载单个页面模板
     */
    PageInit = "pageInit",

    ModulesInit = "modulesInit",

    GroupInit = "groupInit",

    /**
     * 组件属性
     */
    UisInit = "uisInit",

    /**
     * 单个页面销毁
     */
    PageUninit = "pageUninit",

    Link = "link",

    Back = "back",

    GoForward = "goForward",

    refresh = "refresh",

    uiInit = "uiInit",
    update = "update",
}

const StaticTemps = () => {
    return (window as any).__temps?.() || {};
};

/**
 *
 *
 * @class AppRoot
 */
class AppRoot {
    /**
     * 页面模板管理器
     *
     * @type {IUIItem}
     * @memberof AppRoot
     */
    private root: IUIItem;

    /**
     * 页面栈
     *
     * @type {JJHistory}
     * @memberof AppRoot
     */
    private history: JJHistory;

    /**
     * 页面模板缓存
     *
     * @type {{ [key: string]: IUIItem[] }}
     * @memberof AppRoot
     */
    private pageTemps: { [key: string]: IUIItem } = { ...StaticTemps() };

    // private pagePropsTemps: { [key: string]: IUIItem } = {};

    private mCache: { [key: string]: string } = {};

    private mMenusInfo: { [key: string]: any } = {};

    constructor(gameInfo: IGameInfo, renderType: IRenderType, other?: any) {
        //初始化页面历史记录管理器
        this.history = new JJHistory(gameInfo.noHash || renderType !== "app", gameInfo, this.startPage);
        //全局变量
        if (gameInfo.server !== "cloudcms") {
            window["goHistory"] = this.history;
        }
        //初始化root组件
        this.root = {
            _id: 1,
            info: { ename: "PCPageMgr", cname: "页面管理器" },
            source: "manager",
            // dataSource: "manager",
            ui: "PCPageMgr",
            props: {
                init: false,
                pages: [],
                gameInfo,
                cache: this.mCache,
                // pagePropsTemps: this.pagePropsTemps,
                pageTemps: this.pageTemps,
                isResolution: true,
                isPC: "false",
                history: this.history.history,
                jjHistory: this.history,
                ...other,
            },
        };

        //如果定义了静态模板
        if (StaticTemps().pages) this.pagesInit(StaticTemps().pages);
    }

    data() {
        return this.root;
    }

    /**
     * 管理器初始化 (主要获取有多少页面)
     * @param data
     */
    private pagesInit(data: any = {}) {
        const isResolution = this.root.props.isResolution;
        //同步管理器属性信息
        this.root.props = {
            ...this.root.props,
            ...data.props,
            init: true,
        };
        if (isResolution === false) this.root.props.isResolution = false;
        //将素有pages置为未初始化
        this.root.props.pages.map((page: any) => {
            page.props.init = false;
            this.mMenusInfo[page.info.ename] = page.props.menu ?? {};
            return page;
        });
        //页面信息初始化
        // this.handlePages(data.props);
        const { props } = data;

        if (props.pages.length > 0 && this.history.length() === 0) {
            //注入启动页面
            const homePageNoHash = (window as any).HOEM_PAGE_NO_HASH;
            if (homePageNoHash) {
                this.startPage({ ename: homePageNoHash, pagesInit: true });
            } else {
                this.startPage({ ename: props.pages[0].info.ename, pagesInit: false });
            }
        }
    }

    private update(props: any) {
        this.root.props = { ...this.root.props, ...props };
    }

    private modulesInit({ modules }: any) {
        this.root.props.modules = modules;
    }
    private uiInit({ uis }: any) {
        this.root.props.uis = uis;
    }

    // private handlePages(props: any) {
    //     //初始化模板
    //     if (props.pages) {
    //         props.pages.map((page: IUIItem) => {
    //             this.pageTemps[page.info.ename] = page;
    //             this.pageTemps[page.info.ename].props.init = false;
    //         });
    //     }
    //     if (props.pages.length > 0 && this.history.length() === 0) {
    //         //注入启动页面
    //         this.startPage({ ename: props.pages[0].info.ename });
    //     }
    // }

    //前进功能
    private goForward = () => {
        const goHistory = this.history.getForwardList();
        if (goHistory.length === 0) {
            return;
        }
        const history = goHistory.pop() || { ename: "", backListener: [] };
        //前进栈有变化则执行注册的渲染方法
        this.history.events.fire("history");
        if (history.ename) {
            this.startPage({ ename: history.ename, props: history.props, init: false, openNew: false });
        }
    };

    //刷新当前页
    private refresh = (count: any = {}) => {
        this.root.props.history = this.history.refresh(count.count);
    };

    /**
     *
     * @param ename
     * @param props
     */
    private startPage = ({ ename, props, init, openNew = false, pagesInit = false }: any) => {
        const menu = this.mMenusInfo[ename];
        //兼容不修改hash值页面
        if (menu?.noHash) {
            props = { ...props, wsback: true };
        }
        this.root.props.history = this.history.push(ename, props, init, false, openNew, pagesInit);
    };
    /**
     * 页面模板存储
     *
     * @param {*} [data={}]
     * @memberof AppRoot
     */
    private pageInit(data: { page?: IUIItem } = {}) {
        const { page } = data;
        if (!page) return;
        //判断页面是否包含模块
        const modules = this.root.props.modules || {};
        if (modules[page.info.ename]) {
            page.props.module = modules[page.info.ename];
        }
        this.pageTemps[page.info.ename] = page;
        //将root下的数据替换
        this.root.props.pages = this.root.props.pages.map((item: IUIItem) => {
            if (page.info.ename === item.info.ename) return page;
            return item;
        });
        // const old: any = this.pageTemps[page.info.ename];
        // page.props.updateTime = old.props.updateTime;
        // page.props.menu = old.menu;
        // this.pageTemps[page.info.ename].props = page.props;
    }

    private back(props?: any) {
        this.history.pop(props);
    }

    private groupInit({ group }: any) {
        const type = UIManifest.Type.Group;
        UIManifest.list(type, group);
        this.root.props.group = UIManifest.list(type);
    }

    private link({ uiItem }: any) {
        // const props = this.getEditItemProps();
        const type = UIManifest.Type.Group;
        // uis.push(Utils.deepClone(editUIItem));
        UIManifest.register(Utils.deepClone(uiItem) as IUIItem, type);
        this.root.props.group = UIManifest.list(type);
    }

    /**
     * 处理数据
     * @param type
     * @param data
     */
    handle(type: Types, data: any, cache?: any) {
        if (!type) return;

        if (cache) {
            if (type === "pageInit") {
                this.mCache[data.page.info.ename] = cache;
            } else {
                this.mCache[type] = cache;
            }
        }
        //根据类型调用方法
        if (this[type]) {
            this[type](data);
        }

        // if (type.indexOf("Init") < 0 && type !== "startPage" && editUIItem !== this.root && type !== "link") {
        //     this.changePageEdit();
        // }
        //返回数据
        return this.data();
    }
}

export default AppRoot;
