/**
 *
 *
 * @class SourceHelper
 */
class SourceHelper {
    static remove(list: any[], index: number) {
        if (index < 0 || index >= list.length) return list;
        return list.slice(0, index).concat(list.slice(index + 1, list.length));
    }

    static insert(list: any[] = [], index: number, value: any) {
        const item = list.slice(0, index);
        item.push(value);
        return item.concat(list.slice(index, list.length));
    }

    static change(list: any[] = [], { sindex, dindex }: { sindex: number; dindex: number }) {
        if (sindex > dindex) {
            list.splice(dindex, 0, list[sindex]);
            list.splice(sindex + 1, 1);
        } else {
            //如果当前元素在拖动目标位置的上方，先将当前元素从数组拿出，数组长度-1，我们直接给数组拖动目标位置+1的地方新增一个和当前元素值一样的元素，
            //这时，数组len不变，我们再把数组之前的那个拖动的元素删除掉，下标还是index
            list.splice(dindex + 1, 0, list[sindex]);
            list.splice(sindex, 1);
        }
        return list;
    }
}

export default SourceHelper;
