// import ContextMenus from './WebYun/ContextMenus';

const webYun = (window as any).WebYun;


const Config = {
    TempPath: "F:/workspace/webyun/",

    LocalProjects:{
        "0_setting":true,
        "0_cloudcmsExt":true,
        "0_cloudcms":true,
    }
};
/**
 * 
 */
const WebYun = {
    /**
     * 是否
     */
    IS: !!webYun,

    ...webYun,

    useLocal(gameId:string,sname:string){
        if(!Config.LocalProjects[`${gameId}_${sname}`]) return false;
        return true;
    },

    

    loadJson({ url }: any) {
        url = url.replace("http://iad.test.jj.cn/source_code/", Config.TempPath);//.replaceAll("/", "\\\\");
        console.log('[WebYun]:', url);
        return new Promise((resolve) => {
            const data = webYun.FS.readSync(url);
            // console.log('[WebYun]:', webYun, data);
            try {
                resolve(JSON.parse(data))
            } catch (e) {
                resolve(data);
            }
            // webYun.FS.read(url, ({ data, CODE }: any) => {
            //     console.log('[WebYun]:', data, CODE);
            //     if (CODE === 0)
            //         resolve(data);
            // })
        }).catch((e) => {
            console.log('[WebYun]:error', e);
        })
    },

    saveFileToLocal(pageList: any[], server: string, gameId: string) {
        
        pageList.map((page) => {
            const { templateFile } = page;
            const path = `${Config.TempPath}${gameId}/${server}/temp/${templateFile.tempFilePath}/${templateFile.tempFileName}`;
            webYun.FS.write(path, templateFile.content);
        })
    }
};


if (WebYun.IS) {
    console.log('[WebYun]:webyu', WebYun);
    //初始化右键菜单
    // new ContextMenus().initMenu(WebYun.getRemote());
    // ContextMenus
    // document.addEventListener('contextmenu', function (e) {
    //     // 右键事件触发
    //     e.preventDefault();
    //     WebYun.electron.ipcRenderer.send('contextMenu');
    // })
}

(window as any).Electron = WebYun;


export default WebYun;