/* 基于 fetch 封装的 请求
 * @Author: zhangyg
 * @Date: 2018-10-26 16:37:38
 * @Last Modified by: zhanghao
 * @Last Modified time: 2021-06-17 18:53:15
 */
import "whatwg-fetch";
import IHttp, { IHttpProps } from "./IHttp";
import fetchJsonp from "fetch-jsonp";
import { CHARSET, CONTENT_TYPE, METHOD } from "./HttpEmun";
import Utils from "../utils/Utils";

export default class Http {
    /**
     * fetch(input[, init])方法参数 https://www.w3cschool.cn/fetch_api/fetch_api-hukn2n2v.html
     * @param {IHttp} info
     * @param {any} cb 回调函数
     * @returns
     * @memberof Http
     */
    request(info: IHttp, cb: (response: any) => void) {
        const { dataType = CONTENT_TYPE.JSON } = info;
        if (dataType === CONTENT_TYPE.JSONP) {
            this.requestJsonP(info, cb);
        } else {
            this.requestCors(info, cb);
        }
    }

    /**
     * 有序执行
     * @param infos 多个url请求
     * @param common
     */
    static ajaxAll(infos: IHttp[], common?: IHttpProps) {
        const alls = infos.map((info) => {
            return Http.ajax({ ...common, ...info });
        });
        return Promise.all(alls);
    }

    static async ajax(info: IHttp) {
        return new Promise((resolve, reject) => {
            const http = new Http();
            http.request(info, (resp) => {
                if (resp && resp.result) {
                    resolve(resp.data);
                } else {
                    reject(resp);
                }
            });
        });
    }

    static ajaxJson(info: IHttp) {
        return Http.ajax({
            single: false,
            method: METHOD.GET,
            dataType: CONTENT_TYPE.FORM,
            cache: "default",
            mode: "cors",
            ...info,
        });
    }

    /**
     * 解决IE9的cors跨域问题
     * XmlHttprequest在IE9 IE8下不支持cors，XDomainRequest可以替代XmlHttprequest在IE9 IE8上执行
     * @param url
     * @param options
     */
    static fetchIE9(info: IHttp) {
        // https://developer.mozilla.org/en-US/docs/Web/API/XDomainRequest
        // 只支持 GET 和 POST mehtod
        // 不支持带 cookie
        // 不能设置 responseType, 通信双方需要约定数据格式
        // request and response content type should be JSON
        // 响应没有 response status code

        return new Promise((resolve, reject) => {
            const url = info.url;
            const method = info.method || "GET";
            const timeout = info.timeout || 30000;
            let data = info.headers || {};
            if (data instanceof Object) {
                data = JSON.stringify(data);
            }
            const XDR = new (window as any).XDomainRequest();
            XDR.open(method, url);
            XDR.timeout = timeout;
            XDR.onload = () => {
                try {
                    const json = JSON.parse(XDR.responseText);
                    // console.log("[XDomainRequest]", XDR.responseText)
                    return resolve(json);
                } catch (e) {
                    reject(e);
                }
                return reject({});
            };
            XDR.onprogress = () => {};
            XDR.ontimeout = () => reject("XDomainRequest timeout");
            XDR.onerror = () => reject("XDomainRequest error");
            setTimeout(() => {
                XDR.send(data);
            }, 0);
        });
    }

    /**
     * 延时
     * 延时timeout不是请求连接超时的含义，而是表示请求的response时间，包括请求的连接、服务器处理及服务器响应回来的时间；fetch的timeout即使超时发生了，本次请求也不会被abort丢弃掉，它在后台仍然会发送到服务器端，只是本次请求的响应内容被丢弃而已
     * @param {*} fetchPromise  fetch请求返回的Promise
     * @param {number} timeout 单位：毫秒
     * @returns 返回Promise
     * @memberof Http
     */
    private timeoutFetch(fetchPromise: any, timeout: number) {
        let timeoutFn: any = null;
        const timeoutPromise = new Promise((resolve, reject) => {
            timeoutFn = () => {
                reject({ msg: "请求超时!" });
            };
        });
        //这里使用Promise.race，以最快 resolve 或 reject 的结果来传入后续绑定的回调
        const abortablePromise = Promise.race([fetchPromise, timeoutPromise]);
        setTimeout(() => {
            timeoutFn();
        }, timeout);
        return abortablePromise;
    }

    private getResponse(type: string, res: any) {
        if (type === "json") {
            return res.json();
        } else if (type === "blob") {
            return res.blob();
        } else return res.text();
    }

    private proxySetCredentials(init: any) {
        if (process.env.NODE_ENV === "development" && Utils.getUrlParams("isproxy") === "true") {
            if (init.url.indexOf("cloudplt/api/") > 0) {
                init.credentials = "include";
            }
        }
    }

    private handleNullHeaders(headers: any) {
        Object.keys(headers).map((key: string) => {
            if (typeof headers[key] === "undefined" || headers[key] === "" || headers[key] === "undefined" || headers[key] === null) delete headers[key];
        });
    }

    /**
     * cors跨域请求方式
     *
     * @private
     * @param {IHttp} info
     * @param {*} cb
     * @returns
     * @memberof Http
     */
    private requestCors(info: IHttp, cb: any) {
        const { url, headers, bodyType = "json", headersNull = false } = info;
        if (headersNull) {
            this.handleNullHeaders(headers);
        }
        let { method = METHOD.POST } = info;
        method = method.toUpperCase() as METHOD;
        //首先，判断是jsonp还是cors  //force-cache
        const init: any = {
            timeout: 15000,
            mode: "cors",
            credentials: "same-origin",
            cache: "no-store",
            method: method.toUpperCase(),
            ...info,
            headers: {
                // "Content-Type": dataType + CHARSET.UTF8,
                Accept: "application/json",
            },
            url: method === METHOD.GET || method === METHOD.DELETE ? this.handelGetUrl(url, headers) : url,
        };
        // if (init.url.indexOf("mmis") > 0) {
        //     init.headers["Access-MisUserID"] = "3680";
        // }
        this.proxySetCredentials(init);
        //然后，post请求时 content-type仅json时 添加body
        if (method === METHOD.POST) {
            //POST请求默认是json
            const { dataType = CONTENT_TYPE.JSON } = info;
            if (dataType === CONTENT_TYPE.JSON) {
                init.body = JSON.stringify(headers);
            } else if (dataType === CONTENT_TYPE.FORM) {
                init.body = this.handelKV(headers);
            } else if (dataType === CONTENT_TYPE.UPLOAD_FILE) {
                init.body = this.handelFormData(headers);
            }
            init.headers["Content-Type"] = dataType + CHARSET.UTF8;
        } else if (method === METHOD.GET || method === METHOD.DELETE) {
            //GET请求默认是form表单
            const { dataType = CONTENT_TYPE.FORM } = info;
            init.headers["Content-Type"] = dataType + CHARSET.UTF8;
        }
        return this.timeoutFetch(fetch(init.url, init), init.timeout || 0)
            .then((response) => {
                // console.log('[Http]: Net', response, init);
                if (response.status === 200) {
                    try {
                        this.getResponse(bodyType, response).then((data: any) => {
                            cb({ response, data, result: true });
                        });
                    } catch (error) {
                        cb({ response, data: response.data, result: true });
                    }
                } else {
                    this.getResponse(bodyType, response)
                        .then((data: any = {}) => {
                            cb({ response, data, result: false });
                        })
                        .catch((e: any) => {
                            cb({ response, result: false, data: { CODE: response.status } });
                        });
                }
            })
            .catch((res) => {
                console.log("[Http]: Net Error", res, init);
                cb({ result: false, data: { CODE: 404 } });
            });
    }

    /**
     * jsonp跨域请求方式
     *
     * @private
     * @param {IHttp} info
     * @param {*} cb
     * @returns
     * @memberof Http
     */
    private requestJsonP(info: IHttp, cb: any) {
        const init: any = {
            timeout: 15000,
            ...info,
            url: this.handelGetUrl(info.url, info.headers),
        };
        return this.timeoutFetch(fetchJsonp(init.url, init), init.timeout || 0)
            .then((response) => {
                response.json().then((data: any) => {
                    if (response.ok) {
                        cb({ response, data, result: true });
                    } else {
                        cb({ response, data });
                    }
                });
            })
            .catch(cb);
    }
    /**
     * 处理get请求参数
     *
     * @param {string} url
     * @param {*} params
     * @returns {string}
     * @memberof Http
     */
    private handelGetUrl(url: string, params: any): string {
        if (params && Object.keys(params).length > 0) {
            const paramsArray: string[] = [];
            Object.keys(params).forEach((key) => {
                const value = params[key];
                if (Object.prototype.toString.call(value) !== "[object Object]") {
                    paramsArray.push(`${key}=${value}`);
                }
            });
            if (url.search(/\?/) === -1) {
                url += `?${paramsArray.join("&")}`;
            } else if (paramsArray.length > 0) {
                url += `&${paramsArray.join("&")}`;
            }
        }
        return url;
    }

    /**
     *
     * 处理formData格式数据
     * @private
     * @param {*} params
     * @returns {FormData}
     * @memberof Http
     */
    private handelFormData(params: any): FormData {
        const formData = new FormData();
        if (params) {
            Object.keys(params).forEach((key: string) => {
                formData.append(key, params[key]);
            });
        }
        return formData;
    }
    /**
     *
     * 处理formData k-v格式数据
     * @private
     * @param {*} params
     * @returns {FormData}
     * @memberof Http
     */
    private handelKV(params: any): string {
        let str = "";
        const keys = Object.keys(params);
        if (params) {
            for (let i = 0; i < keys.length; i++) {
                let val = params[keys[i]];
                if (typeof val === "object") {
                    val = JSON.stringify(val);
                }
                if (i > 0) {
                    str += "&";
                }
                str += `${keys[i]}=${val}`;
            }
        }
        return str;
    }
}
