import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import UIManifest from "src/engine/ui/UIManifest";
import SyncUtils from "src/engineEditor/props/SyncUtils";
import AutoBind from "src/engine/decorator/AutoBind";
import AutoConfig from "src/engine/decorator/AutoConfig";
import Utils from "src/lib/utils/Utils";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";

const IS_MOBILE = Utils.isMobile();

interface IView extends IBaseUI {
    // 是否不要外部div包裹壳
    removeBox?: boolean;
    //是否包含状态值控制
    state?: boolean;

    configProvider?: boolean;

    configConsumer?: boolean;
    transProps?: string[];
    delay?: number;
    /** 页面管理器下 */
    pageLast?: boolean;
    lastPageName?: string;
    onResume?: any;
    //默认配置值
    _config?: any;
    designWidth?: number;
    designHeight?: number;
    scale?: boolean;
}
interface IState {
    value?: any;
    render?: boolean;
}

@UIManifest.declareGG(["ggValue", "dataValue", "uiCheck"], "View", "View组件")
class View extends BaseUI<IView, IState> {
    constructor(props: IView) {
        super(props);
        this.state = {};
        //默认配置值
        if (props._config) this.state = { value: props._config };
        if (this.props.delay) {
            this.mrenderUtils.sync("render", () => {
                this.setState({ render: true });
            });
        }
    }

    mSyncUtils = new SyncUtils(10);
    mrenderUtils = new SyncUtils(this.props.delay || 0);

    onChange = (value: any) => {
        this.mSyncUtils.sync("active", () => {
            this.setState({ value });
        });
    };

    @AutoBind
    clearValue() {
        this.setState({ value: undefined });
    }

    componentWillUnmount() {
        this.mSyncUtils.clear("active");
        this.mrenderUtils.clear("render");
    }

    @AutoConfig.Consumer("configConsumer")
    render(props: any = {}) {
        return this.renderItem(props);
    }

    mTarget: any;

    @AutoBind
    target() {
        return this.mTarget;
    }

    getScale() {
        const DesignWidth = this.props.designWidth || 360;
        const DesignHeight = this.props.designHeight || 640;
        const DesignRatio = DesignWidth / DesignHeight;
        let width = document.documentElement.clientWidth,
            height = document.documentElement.clientHeight;
        if (window.orientation == null || window.orientation === 180 || window.orientation === 0) {
            //竖屏状态
        } else {
            [width, height] = [height, width];
        }
        let ratio = width / height;
        let scale; //通过计算实际手机宽高比和效果图的宽高比得出最终的所让比例
        if (ratio > DesignRatio) {
            scale = height / (width / DesignRatio);
        } else if (ratio < DesignRatio) {
            scale = width / (height * DesignRatio);
        } else {
            scale = 1;
        }
        return scale;
    }

    getStyle() {
        const style = super.getStyle() || {};
        const { width } = this.props as any;
        if (typeof width !== "undefined") {
            style.width = `${Math.max(95 - Number(width) * 2, 3)}%`;
        }
        return style;
    }

    @AutoBind
    getProp(props: any) {
        const { norenderS = false, classNames = [], id, className = "", scale } = this.props as any;
        const prop: any = {
            className: `${norenderS ? "_remove_children_flag_" : ""} ${classNames.join(" ")} ${className}`,
            style: this.getStyle(),
        };
        if (scale && IS_MOBILE) {
            props.style = { ...props.style, zoom: this.getScale() };
        }
        if (id) prop.id = id;
        if (props.onClick) prop.onClick = props.onClick;
        return prop;
    }

    checkView() {
        const { checkView, checkPropsKey } = this.props as any;
        const item = PropsUtils.getValue(this.props, checkPropsKey);
        if (checkView && (typeof item === "undefined" || item === "")) {
            return this.renderChildren(checkView);
        }
        return null;
    }

    componentDidUpdate(prevProps: IView) {
        if (this.props.onResume && prevProps.pageLast === false && this.props.pageLast === true) {
            this.props.onResume?.();
            this.props[`onResume${prevProps.lastPageName}`]?.();
        }
    }

    @AutoConfig.Provider(["onChange", "target", "state.value,value", "clearValue,clear"], "configProvider", "extList")
    renderItem(props: any = {}) {
        const { delay, configProvider = false, removeBox = false, autoLoading, loading, loadingUI = [], transProps } = this.props as any;
        // if (this.props.uiItem.info.ename === "hwyT") {
        //     console.log(`[hwyT]`, this.props.loading);
        // }
        const prop = this.getProp(props);
        if (configProvider) {
            props.onChange = this.onChange;
            props.state = this.state.value;
            props.clear = this.clearValue;
        }
        if (transProps) {
            for (const key of transProps) {
                props[key] = this.props[key];
            }
        }
        const { render } = this.state;
        let canrender = false;
        if (delay) {
            if (render) {
                canrender = true;
            }
        } else {
            canrender = true;
        }
        return removeBox ? (
            canrender ? (
                this.renderChildren(undefined, props)
            ) : (
                ""
            )
        ) : (
            <div ref={Utils.ref(this, "mTarget")} {...prop}>
                {canrender ? this.renderChildren((autoLoading || loading) && loadingUI.length > 0 ? loadingUI : undefined, props) : ""}
                {this.checkView()}
            </div>
        );

        // return <div style={this.getStyle()}>
        //     {this.renderChildren(buttons,{onSubmit:})}
        // </div>
    }
}

export default View;
