import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import { IInfo } from "src/component/props/PropsGGEditor/exec/ExecScript";
import Utils from "src/lib/utils/Utils";

interface IClickGoSource {
    source: string;
    params?: { [key: string]: any };
    clear?: boolean;
    /**
     * 是否监听回调
     */
    execCallback?: boolean;
    // 是否不更新source
    notUpRequest?: boolean;
    // 不接收value
    notMergeVal?: boolean;
    headerToCallBack?: boolean;
    changeLoading?: boolean;
    valueToSource?: boolean;
}

/**
 *
 *
 * @class ClickGoSource
 */
@UIManifest.declare("ClickGoSource", "执行Source", UIManifest.Type.Script)
class ClickGoSource extends Script<IClickGoSource> {
    private execSource(source: string, params: any, info: IInfo, callback: (info: IInfo) => any) {
        if (!this.context.context.sourceContext) return;
        const { execCallback, clear = false, notUpRequest = false, headerToCallBack, changeLoading = false } = this.props;
        if (changeLoading) info.callback?.(true);
        this.context.context.sourceContext.execSource(
            source,
            { ...params },
            (data: any, netData: any) => {
                if (headerToCallBack) {
                    netData = { ...netData, ...params };
                }
                if (execCallback) {
                    if (data) {
                        callback({ ...info, value: netData });
                    } else {
                        //是否触发
                        callback({ ...info, value: netData });
                    }
                }
                if (changeLoading) info.callback?.(false);
            },
            { notUpRequest },
            undefined,
            false,
            clear
        );
    }

    exec(info: IInfo, callback: (info: IInfo) => any) {
        // config.name = "onClick";
        //
        // return () => {
        let { value } = info;
        let { source, valueToSource } = this.props;
        if (valueToSource) {
            //如果未定义，则直接结束
            if (Utils.isNull(value?.source)) return true;
            source = value.source;
            value = value.value;
        }
        const { execCallback, notMergeVal = false } = this.props;
        if (source) {
            let { params = {} } = this.props;
            if (value && !notMergeVal) params = { ...params, ...value };
            source.split(",").map((item) => {
                this.execSource(item, params, info, callback);
            });
        }
        //如果执行回调触发false
        if (execCallback) return false;
        return true;
    }
}

export default ClickGoSource;
