import IRule, { IRuleProps } from "./IRule";

interface IRuleRequired extends IRuleProps {
    required: boolean;
    requireTrim?: boolean;
}

/**
 * 校验是否必须
 */
class RuleRequired implements IRule {
    type: "required";

    props: IRuleRequired;

    constructor(props: IRuleRequired) {
        this.props = props;
    }

    exec() {
        if (!this.props.required) return false;
        const message = this.props.message || "该项为必填项";
        let rulemap: any = { required: true, message };
        if (this.props.requireTrim) {
            rulemap = {
                message,
                validator: function (rule: any, value: any, callback: any) {
                    if (value === void 0 || value === null || value === "") {
                        callback(message);
                        return;
                    }
                    if (value !== void 0 && value !== null && value !== "") {
                        try {
                            if (typeof value === "string" && value.trim() === "") {
                                callback(this.props.message || "不能全部为空格");
                                return;
                            }
                        } catch (err) {
                            callback(err);
                            return;
                        }
                        callback();
                    }
                    callback();
                },
            };
        }
        return rulemap;
    }
}

export default RuleRequired;
