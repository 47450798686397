import Exec from "./Exec";
import { ISourceBean } from "../Manager";
import { DEVICE } from "src/components/DownGame/down";

export default class DownGame extends Exec {
    exec(bean: ISourceBean, headers: any, callback: any = () => {}) {
        const downCtrl = bean.source.downCtrl;
        if (downCtrl) {
            downCtrl.active(headers);
            callback(DEVICE);
        }
    }
}
