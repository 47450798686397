import { METHOD } from "src/lib/http/HttpEmun";
import IGameInfo from "src/engine/interface/IGameInfo";
import Config from "src/engine/const/Config";
import ToastSource from "src/engine/interface/ToastSource";
// import DispatchPageFinishFn from "src/components/DispatchPageFinish/DispatchPageFinishFn";

interface ISort {
    sortkey: string;
    sortType: "up" | "down";
}

export interface IModuleProps {
    shouldDispatchModule?: boolean;
    sort?: { [key: string]: ISort };
}

export default class ModuleQuery extends ToastSource {
    game: IGameInfo;

    constructor(gameInfo: IGameInfo) {
        super();
        this.game = gameInfo;
        let queryUrl = `${Config.contentQuery}?from=2&i=${new Date().valueOf()}`;
        this.ajax = {
            url: queryUrl,
            method: METHOD.GET,
            single: false,
        };
    }
    // headers = {};
    data = {
        edit: {},
    };
    dataSource = "manager";
    toSource = "dataList";
    toSourceRet = true;
    pageNum = {};
    handleHeaders(headers: any = {}, dataSource = {}) {
        let pageNum = headers.start || headers.pageNum || 1;
        if (!this.pageNum[headers.moduleId]) {
            pageNum = 1;
        }
        const cache = { ...headers };
        if (!cache.sname) cache.sname = this.game.server.replace("PC", "");
        if (!cache.gameId) cache.gameId = this.game.gameId;
        return {
            ...cache,
            moduleId: headers.moduleId,
            pageSize: headers.count || headers.pageSize || 10,
            pageNum,
            pageName: headers.pageName,
        };
    }
    message(msg: string, success: boolean = true, code: number = this.code) {
        if (!success) {
            super.message(msg, success, code);
        }
    }
    handleDataRight(list: any, oldData?: any, headers?: any, statFunc?: any, moduleprops: any = {}) {
        const content = { edit: {}, pagination: {} };
        const sortMap = moduleprops.sort;
        for (const key in list) {
            if (list[key]) {
                const edit = {
                    count: 0,
                    maxRank: 0,
                    exist: {},
                    edit: false,
                    moduleId: list[key].moduleId,
                    moduleName: key,
                };
                let sourceList = list[key].sourceList || [];
                if (sortMap) {
                    // 时间排序
                    const itemSort = sortMap[key];
                    if (itemSort && itemSort.sortkey) {
                        sourceList = sourceList.sort((a: any, b: any) => {
                            if (itemSort.sortType === "up") {
                                return a[itemSort.sortkey] - b[itemSort.sortkey];
                            } else {
                                return b[itemSort.sortkey] - a[itemSort.sortkey];
                            }
                        });
                    }
                }
                content[key] = sourceList.map((item: any) => {
                    edit.count++;
                    edit.maxRank = Math.max(edit.maxRank, item.sourceRank);
                    edit.exist[item.id] = item.sourceRank;

                    if (typeof item.sourceStyle === "string") {
                        const toItem = { ...JSON.parse(item.sourceStyle), id: item.id, sourceRank: item.sourceRank };
                        if (item.sourceLink) toItem.sourceLink = item.sourceLink;
                        if (item.artFlag) toItem.artFlag = item.artFlag;
                        if (item.navId) toItem.navId = item.navId;
                        return toItem;
                    }
                    return item;
                });

                content.edit[key] = edit;
                this.pageNum[headers.moduleId] = headers.start || headers.pageNum || 1;
                content.pagination[key] = {
                    totalNum: list[key].totalNum,
                    pageSize: headers.count || headers.pageSize || 10,
                    moduleId: headers.moduleId,
                    pageNum: this.pageNum[headers.moduleId],
                };
            }
        }

        if (headers.pageNum > 1) {
            if (headers.loadMore) {
                return { type: "more", content, moduleId: headers.moduleId };
            }
            return { type: "pagination", content };
        }

        return { type: "init", content };
    }
}
