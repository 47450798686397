import ISource from "./ISource";
import IHttp from "src/lib/http/IHttp";
import IUIItem from "./IUIItem";

export class Source implements ISource {
    uiItem?: IUIItem;

    protected code: number = 0;
    islocal?: boolean;
    saveInLocalStorage?: boolean; //是否将数据存储到localStorage
    validity?: string;
    validityMap?: any;
    bridgeType?: string;
    bridgeTypeMap?: any;
    changeMap?: any;
    utilsTimer?: number;
    isUtils?: string | boolean;
    ename = "";
    cname = "";
    /**
     * 初始化数据
     *
     * @type {*}
     * @memberof ISource
     */
    data?: any;

    init?: boolean;

    /**
     * 网络请求信息
     *
     * @type {IHttp}
     * @memberof ISource
     */
    ajax?: IHttp;

    /**
     * 在headers里头，需要获取的其他数据源
     *
     * @type {string}
     * @memberof ISource
     */
    dataSource?: string;

    /**
     * 将数据保存到其他source中，即执行其他数据接口的handleData方法
     *
     * @type {string}
     * @memberof ISource
     */
    toSource?: string;

    /**
     * 是否刷新其他请求接口
     *
     * @type {string}
     * @memberof ISource
     */
    refreshOption?: string;

    /**
     * 默认参数
     *
     * @type {{[key:string]:any}}
     * @memberof ISource
     */
    headers?: { [key: string]: any };

    extendSource: string;

    autoMessage?: boolean = true;

    /**
     * 处理上传参数
     *
     * @param headers
     * @param dataSource
     */
    handleHeaders(headers: any = {}, dataSource?: any) {
        return headers;
    }

    /**
     * 处理返回数据
     *
     * @param data 返回结果
     * @param oldData 原始数据
     * @param headers 请求参数
     */
    handleData(data: any, oldData?: any, headers?: any, statFunc?: any, otherProps?: any): any {
        //
        if (typeof data !== "object") return data;
        if (data.CODE === undefined) data.CODE = this.code;
        if (data.CODE === this.code || data.data) {
            if (this.autoMessage) this.message(data.MSG, true, data.CODE);
            const hDATA = data.DATA || data.DATA === 0 || data.DATA === false ? data.DATA : data.data;
            return this.handleDataRight(hDATA, oldData, headers, statFunc, otherProps);
            //论坛
        } else if (this.islocal) {
            //本地数组存储的数据处理情况，返回当前传入数据，不继承
            return data;
        } else {
            if (this.autoMessage) this.message((data.res && data.res.MSG) || data.MSG, false, data.CODE);

            if (data && data.res) {
                this.handleDataError(data.res);
                return { loading: false, msg: data.res.MSG, error: true, code: data.res.CODE };
            } else if (data && data.CODE !== void 0) {
                this.handleDataError(data);
                return { loading: false, msg: data.MSG, error: oldData && oldData.length ? false : true, code: data.CODE };
            }
            return { loading: false, error: true };
        }
    }

    handleDataError(data: any) {}

    /**
     *
     * @param msg
     * @param success
     */
    message(msg: string, success: boolean = true, code: number = this.code) {}

    /**
     * 处理正常返回结果
     * 处理所有返回请重写 handleData(data: any, oldData?: any, headers?: any)
     *
     * @param data
     */
    handleDataRight(data: any, oldData?: any, headers?: any, statFunc?: any, otherProps?: any) {}
}
