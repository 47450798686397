import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import { IContext, IInfo } from "src/component/props/PropsGGEditor/exec/ExecScript";
import { Types } from "src/engine/pages/source/AppRoot";
import { OPEN_NEW_PAGE_TYPE } from "src/lib/utils/Const";
import IUIItem from "src/engine/interface/IUIItem";

interface IClickScript {
    type?: string;

    comFromValue?: boolean;
    /**
     * 回退参数
     */
    count?: number;
    isNew?: boolean;
    /**
     * 1. 为 new 时 使用改变 pathname 的跳转
     * 2. 为 self 时 使用默认的系统内部的改变 hash 的跳转方式
     * 3. 为 "inherit" 或者不存在时 是否有全局的改变pathname跳转方式的 flag, 有就使用该方式， 没有就 hash
     */
    itemLinkType?: "static" | "hash" | "inherit";

    init?: boolean | number;

    nocachePage?: boolean;

    extendsParams?: boolean;

    // 不接受value作为参数传参
    noValueToParams?: boolean;
    showLocalPage?: boolean;
}

const globalSpa = (window as any)._GLOBAL_SINGLE_PAGE_;

/**
 *
 *
 * @class ClickScript
 */
@UIManifest.declare("ClickScript", "基本页面操作", UIManifest.Type.Script)
class ClickScript extends Script<IClickScript> {
    GO_MAP: any;
    constructor(uiItem: IUIItem, context: IContext, type: string, editing: boolean) {
        super(uiItem, context, type, editing);
        const { count = 1 } = this.props;
        this.GO_MAP = {
            back: () => {
                this.goBack(count);
            },
            backModal: () => {
                this.goBack(count);
            },
            forward: () => {
                this.goManger(Types.GoForward);
            },
            refresh: () => {
                this.goManger(Types.refresh);
            },
            refreshWindow: () => {
                window.location.reload();
            },
        };
    }

    private defaultGo = (info: IInfo, ename: string) => {
        const { init = false, nocachePage, isNew = false, itemLinkType = "inherit", extendsParams, showLocalPage } = this.props;
        let staticPage = false;
        if (itemLinkType === "static") {
            staticPage = true;
        } else if (itemLinkType === "inherit" && (window as any)[OPEN_NEW_PAGE_TYPE] === true) {
            staticPage = true;
        }

        let { value: props = {} } = info;
        props = { ...props };
        if (staticPage) props.staticLinkType = true;
        if (init !== false) props.init = init;
        if (nocachePage || globalSpa) {
            props.ncpage = true;
            props.init = -1;
        }
        if (extendsParams) {
            const hashParam = this.getHashParams();
            props = { ...hashParam, ...props };
        }
        if (showLocalPage) {
            props.showLocalPage = true;
        }
        this.goManger(Types.StartPage, { ename, props, openNew: isNew, init });
    };

    private goManger = (type: string | Types, props?: any) => {
        if (this.context.context.sourceContext) this.context.context.sourceContext.execSource("manager", { type, ...props });
    };

    private goBack = (count: number = 1): any => {
        this.goManger(Types.Back);
        if (count > 1) this.goBack(count - 1);
    };

    private getHashParams() {
        const hash = window.location.hash;
        const url = hash.substr(hash.indexOf("?"));
        const theRequest = new Object();
        if (url.indexOf("?") !== -1) {
            const str = url.substr(1);
            const strs = str.split("&");
            for (const item of strs) {
                if (item) theRequest[item.split("=")[0]] = unescape(item.split("=")[1]);
            }
        }
        return theRequest;
    }

    private operate(info: IInfo) {
        // const { type = "back", count = 1, init = false, nocachePage, isNew = false, itemLinkType = "inherit", comFromValue = false } = this.props;
        const { type = "back", comFromValue = false, noValueToParams = false } = this.props;

        let ename = type;
        if (comFromValue) {
            if (info.value && info.value.type) {
                ename = info.value.type;
                info.value = info.value.value ?? {};
            } else {
                ename = info.value;
                info.value = {};
            }
        }
        if (!ename) return;
        if (this.GO_MAP[ename]) {
            return this.GO_MAP[ename]();
        }
        if (noValueToParams) {
            info.value = {};
        }
        this.defaultGo(info, ename);
        // switch (ename) {
        //     case "back":
        //         this.goBack(count);
        //         break;
        //     case "backModal":
        //         this.goBack(count, true);
        //         break;
        //     case "forward":
        //         this.goManger(Types.GoForward);
        //         break;
        //     case "refresh":
        //         this.goManger(Types.refresh);
        //         break;
        //     case "refreshWindow":
        //         window.location.reload();
        //         break;
        //     default:
        //         // const props = info.value || {};
        //         const { value: props = {} } = info;
        //         if (staticPage) props.staticLinkType = true;
        //         if (nocachePage) props.ncpage = true;
        //         if (init !== false) props.init = init;
        //         if (globalSpa) {
        //             props.ncpage = true;
        //             props.init = -1;
        //         }
        //         this.goManger(Types.StartPage, { ename, props, openNew: isNew, init });
        //         break;
        // }
    }

    exec(info: IInfo) {
        this.operate(info);
        return true;
    }
}

export default ClickScript;
