import { Source } from "src/engine/interface/Source";
import { message as antMessage } from "antd";

/**
 *
 *
 * @class PCSource
 */
class PCSource extends Source {
    /**
     *
     * @param msg
     * @param success
     */
    message(msg: string, success: boolean = true) {
        if (success) {
            antMessage.success(msg);
        } else {
            antMessage.error(msg);
        }
    }
}

export default PCSource;
