import * as React from "react";
import { Radio } from "antd";
import BaseComponent from "src/engine/ui/BaseComponent";
import UIInject, { IHandle } from "../UIInject";
import UIManifest from "src/engine/ui/UIManifest";
import IUIItem from "src/engine/interface/IUIItem";
import Style from "src/lib/utils/Style";
import Formmatter from "src/component/script/DataToExcel/Formmatter";
import "./index.scss";

interface IAntRadio {
    uiItem: IUIItem;
    showType: "radio" | "button";
    buttonStyle?: "outline" | "solid";
    valueKey?: string;
    mapKey?: string;
    data?: any;
    style?: any;
    onChange?: (value: any) => any;
    value?: any;
    button?: any;
    defaultValue?: any;
    defaultValueFirst?: boolean;
    valueType?: string;
    didMountChange?: boolean;
}

@UIManifest.declareGG(["ggValue"], "AntRadio", "AntRadio组件", UIManifest.Type.AntD)
class AntRadio extends BaseComponent<IAntRadio> {
    renderItem = (data: any, index: number) => {
        const { showType = "radio", valueKey = "value", mapKey = "key", button, valueType = "" } = this.props;
        const { itemStyle } = Style.getMainStyle(["itemStyle"], this.props);
        const UI = { radio: Radio, button: Radio.Button }[showType] || Radio;
        return (
            <UI key={index} {...button} value={Formmatter.strFormmatter(data[valueKey], valueType)} style={itemStyle}>
                {data[mapKey] || data[valueKey]}
            </UI>
        );
    };

    getData() {
        const { data, dataSource } = this.props as any;
        if (dataSource?.length > 0) return dataSource;
        // return (Formmatter.strFormmatter(data, valueType));
        if (data && data[0] && typeof data[0] === "string") {
            return this.transDataSource(data);
        }
        return data;
    }

    onChange = (e: any) => {
        const { onChange, valueType } = this.props as any;
        onChange?.(Formmatter.strFormmatter(e.target.value, valueType));
    };

    transDataSource(data: any) {
        return data.map((str: string) => ({ key: str, value: str }));
    }

    /**
     * didMountChange 开关为true时，可自动渲染
     */
    componentDidMount() {
        const { defaultValue, didMountChange = false, onChange } = this.props;
        if (didMountChange) onChange?.(defaultValue);
    }

    render() {
        //{ type: "ui", key: "children", uiKey: "children" }
        const { total, pageSize = 0, current = 1, defaultValueFirst, data, valueKey, defaultValue, valueType, radioTheme = "" } = this.props as any;
        const handles: IHandle[] = [{ type: "data", key: "children", uiKey: "children", dataKey: "data", uiParent: this.renderItem }];
        return (
            <UIInject
                className={`${radioTheme}`}
                ui={Radio.Group}
                {...this.props}
                defaultValue={Formmatter.strFormmatter((defaultValueFirst && data?.[0]?.[valueKey]) || defaultValue, valueType)}
                onChange={this.onChange}
                data={this.getData()}
                pageSize={Number(pageSize)}
                current={Number(current)}
                total={total}
                handles={handles}
            />
        );
    }
}

export default AntRadio;
