import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import "./Layout.scss";
import UIManifest from "src/engine/ui/UIManifest";

interface ILayout extends IBaseUI {
    background: string;
    backgroundColor: string;
    backgroundImage: string;
    overflow: string;
    data: any;
    style?: any;
    ename?: string;
    cname?: string;
    index?: any;
    moreClass: string;
    pageHide?: boolean;
    id?: string;
    hiddenBar?: boolean;
    dataValue?: any;
}

@UIManifest.declareGG(["dataValue"], "Layout", undefined, UIManifest.Type.Discard)
class Layout extends BaseUI<ILayout> {
    // styleSheet=[StyleSheet.bgImg];

    render() {
        const { className = "absolute", moreClass = "", pageHide = false, id, hiddenBar } = this.props;
        const pageLast = this.props["pageLast"];
        return (
            <div
                className={`react-layout ${className} ${moreClass} ${pageHide ? `react-all-hide-${pageLast === false}` : ""} ${
                    hiddenBar ? "react-layout-hidden-bar" : ""
                }`}
                style={this.getStyle()}
                id={id}
            >
                {this.renderChild()}
            </div>
        );
    }

    renderChild() {
        const {
            data,
            dataSource,
            className,
            moreClass,
            dataExtend,
            background,
            backgroundColor,
            backgroundImage,
            overflow,
            uiItem,
            dataKey,
            loading,
            source,
            style,
            ename,
            cname,
            index,
            dataAllot,
            dataValue,
            id,
            ...other
        } = this.props;
        let { children } = this.props;
        const eprops: any = other;
        if (data) {
            eprops.data = data;
        }
        delete eprops.children;
        if (dataSource && dataSource.length > 0 && dataSource[0] && dataSource[0].info) {
            children = dataSource;
        }
        return this.renderChildren(children, eprops);
    }
}

export default Layout;
