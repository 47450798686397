import { Source } from "src/engine/interface/Source";
import { METHOD } from "src/lib/http/HttpEmun";
import IGameInfo from "src/engine/interface/IGameInfo";
import Config from "src/engine/const/Config";

export default class SourceContentDetail extends Source {
    constructor(gameInfo: IGameInfo) {
        super();
        this.ajax = {
            url: `${Config.contentDetail}?sname=${gameInfo.server.replace("PC", "")}&gameId=${gameInfo.gameId}`,
            method: METHOD.GET
        };
    }

    handleHeaders(headers: any, dataSource = {}) {
        if (headers.id) {
            return headers;
        }
        return false;
    }
    handleDataRight(list: any, oldData?: any, headers?: any) {
        return { type: "detail", list };
    }
}
