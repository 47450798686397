import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";

import { IPanelContext } from "src/engine/context/PanelContext";

import { List } from "antd";
import "./PCListUI.scss";
import UIManifest from "src/engine/ui/UIManifest";

const { Item } = List;

interface IPCListUI extends IBaseUI {
    // pagination?: {
    //     pageSize: number;
    //     operateType: string;
    //     totalKey: string;
    // };
    pagination: boolean;
    pageSize: number;
    listKey?: string;
    grid: string;
    source?: string;
    split?: boolean;
}
/**
 *
 *
 * @class PCListUI  PC端list组件
 * @extends {BaseUI<IPCListUI>}
 */

@UIManifest.declareGG(["dataValue"], "PCListUI", undefined, UIManifest.Type.PC)
class PCListUI extends BaseUI<IPCListUI> {
    onItemClick = (data: any, index: string) => {
        const { source } = this.props;
        if (source) {
            this.sourceContext.execSource(source, { data, index });
        }
    };

    renderItems(context: IPanelContext, data: any, key: number) {
        const { children, source, handleRet } = this.props as any;
        if (!children || children.length === 0 || !data) return <div />;
        if (typeof data === "object") {
            data.curNum = key; //增加序列ID，可以用于盖楼等数据
        }
        const item = <Item key={key}>{this.renderUIItem(children[0], context, { handleRet, data, key })}</Item>;
        if (!source) return item;
        return <div onClick={this.onItemClick.bind(this, data, key)}>{item}</div>;
    }

    onChange = (pageNum: any, pageSize: number) => {
        const { uiItem } = this.props;
        this.sourceContext.execSource((uiItem.props && uiItem.props.dataSource) || "", {
            pageNum,
            start: pageNum,
            count: pageSize,
            pageSize
        });
    };

    getGrid() {
        const { grid } = this.props;
        if (!grid) return;
        const gridInfo = grid.split(",");
        if (gridInfo.length <= 1) return;
        const list = ["gutter", "xs", "sm", "md", "lg", "xl", "xxl"];
        const ret = {};
        list.map((item, index) => {
            return (ret[item] = Number(gridInfo[index] || gridInfo[gridInfo.length - 1]));
        });
        return ret;
    }

    renderAct() {
        const { dataSource = [], total, loading, listKey, pagination, pageSize = 10, split = true, noDataChild } = this.props as any;
        const propsMap: any = {};

        if ((pagination === true || pagination === "true") && total) {
            propsMap.pagination = {
                onChange: this.onChange,
                pageSize,
                total,
                showQuickJumper: true,
                showTotal: (all: any) => {
                    return `共 ${all} 个`;
                }
            };
        }
        return (
            <div style={this.getStyle()}>
                {this.panel((context: IPanelContext) => {
                    return noDataChild && dataSource.length === 0 ? (
                        this.renderUIItem(noDataChild[0], context)
                    ) : (
                        <List
                            key={(listKey && this.props[listKey]) || "list-pc-ui"}
                            grid={this.getGrid()}
                            locale={{ emptyText: "暂无数据" }}
                            split={split === "false" ? false : true}
                            loading={loading}
                            {...propsMap}
                            className="react-pc-table"
                            dataSource={dataSource}
                            renderItem={this.renderItems.bind(this, context)}
                        />
                    );
                })}
            </div>
        );
    }
}

export default PCListUI;
