import Exec from "../Exec";
import { IDataType } from "src/engine/SourceCenter/Manager";
import Storage from "src/lib/utils/storage/Storage";
import StorageType from "src/lib/utils/storage/StorageType";
import ModuleMgr from "./ModuleMgr";

/**
 *
 *
 * @class Handle
 */
class Handle extends Exec {
    callback: any;

    propsListener() {
        return ["initData", "dataValue", "initHandle", "type", "mapKey", "mapValueKey", "sync"];
    }

    storages = {};

    getStorage(type: StorageType) {
        let storage = this.storages[type];
        if (!storage) {
            storage = this.storages[type] = new Storage(Number(type));
        }
        return storage;
    }

    syncKey(key: string = "area", sync: Array<StorageType | any> = [], saveValue?: any) {
        for (let item of sync) {
            if (typeof item === "object") {
                key = item.key || key;
                item = item.type;
            }
            const storages = this.getStorage(item);
            if (saveValue !== undefined) {
                storages.set(key, typeof saveValue === "object" ? JSON.stringify(saveValue) : saveValue);
            } else {
                let value = storages.get(key);
                if (typeof value !== "undefined") {
                    if (typeof value === "string") {
                        if (value.indexOf("{") > -1 || value.indexOf("[") > -1) {
                            value = JSON.parse(value);
                        }
                    }
                    return value;
                }
            }
        }
        return;
    }

    moduleName: string;

    init(data: any, props: any): IDataType {
        return { loading: true, data };
    }

    exec(headers: any, data: IDataType, props: any, callback: any = () => {}): any | IDataType {
        const typeHeaders = Array.isArray(headers);
        //触发模块获取数据
        if (props.module && headers.isModule != false) {
            const { ename } = props.uiItem.info;
            this.moduleName = ename;
            ModuleMgr.Instance.register(ename, this.mHandle, { pageSize: props.uiItem.props.pageSize || 10 }, headers);
            return;
        }
        if (Object.keys(headers).length === 0) {
            if (Array.isArray(headers)) {
                data.data = headers;
            }
            return data;
        }
        let ret: IDataType;
        console.log("[Handle]", headers, data, props);
        if (headers.data && headers.start) {
            ret = { ...data, ...headers };
            headers = headers.data;
        } else {
            ret = { ...data };
        }
        if (Array.isArray(headers)) {
            //如果为传递的参数只有一个数组，意味着修改数据，直接替换数据
            if (typeHeaders) ret.data = headers;
            //如果为分页
            else if (ret.start && ret.start > 1) ret.data = [...data.data, ...headers];
            else ret.data = headers;
        } else {
            ret.data = { ...data.data, ...headers };
        }
        return ret;
    }

    destory() {
        if (this.moduleName) ModuleMgr.Instance.unregister(this.moduleName);
    }
}

export default Handle;
