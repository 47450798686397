import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import UIManifest from "src/engine/ui/UIManifest";
/**
 *
 *
 * @interface IScrollContext
 */
export interface IScrollContext {
    getScrollDom: () => any;
}

export const ScrollContext = React.createContext<IScrollContext>({
    getScrollDom: () => {},
});

interface IScorllFixPos extends IBaseUI {
    childrenScroll?: any;
    fixId: string;
}

@UIManifest.declare("ScorllFixPos", "Scroll定位组件")
class ScorllFixPos extends BaseUI<IScorllFixPos> {
    state: any = {
        scroll: null,
    };
    setScrollDom(dom: any) {
        this.state.scroll = dom;
    }
    getScrollDom() {
        const { fixId } = this.props;
        const dom = this.state.scroll;
        const fixDom = fixId && dom && dom.querySelector("#" + fixId);
        if (dom && dom.scrollTo && fixDom) {
            dom.scrollTo(0, fixDom.offsetTop);
        }
        return this.state.scroll;
    }
    renderAct() {
        const { childrenScroll = [], data, children } = this.props;
        const ep: any = {};
        if (data) {
            ep.data = data;
        }
        return (
            <ScrollContext.Provider value={{ getScrollDom: this.getScrollDom.bind(this) }}>
                {this.renderChildren(childrenScroll, { setScrollDom: this.setScrollDom.bind(this), ...ep })}
                {this.renderChildren(children, ep)}
            </ScrollContext.Provider>
        );
    }
}
export default ScorllFixPos;
