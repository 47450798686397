import Exec from "./Exec";
import { ISourceBean } from "../Manager";
import IHttp from "src/lib/http/IHttp";
import PluginHttp from "src/lib/http/PluginHttp";
import ISource from "src/engine/interface/ISource";

export const QUERY_FAIL_CODE = -500;

/**
 *
 *
 * @class Net
 */
class Net extends Exec {
    //页面加载状态
    loading: { [key: string]: number } = {};

    count: number = 1;

    /**
     * 网络成功处理
     * @param bean
     * @param callback
     * @param http
     * @param data
     */
    success(bean: ISourceBean, http: IHttp, callback: any, netData: any, key: number, sourceprops: ISource = {}) {
        //如果key不相当，则直接放弃数据
        if (bean.name !== "manager" && this.loading[bean.name] !== key && bean.name !== "syncModuleList") {
            return;
        }

        delete this.loading[bean.name];
        let data = netData.data || netData;
        //处理数据
        if (bean.source.handleData) {
            if (bean.name === "syncModuleList")
                data = bean.source.handleData(data, bean.data.data, http.headers, this.mManager.stat, sourceprops.moduleProps || {});
            else data = bean.source.handleData(data, bean.data.data, http.headers, this.mManager.stat);
        }

        if (data) {
            const { notUpRequest = false } = sourceprops;
            //判断数据处理完之后，交由其他数据再次处理
            if (typeof bean.source.toSource === "string" && bean.source.toSource !== bean.name) {
                //更新toSource
                this.mManager.execSource(bean.source.toSource, data);
                // this.mManager.update(bean.source.toSource, {}, data);
                //如果只是更新给指定source，则直接退出
                if (bean.source.toSourceRet !== true) {
                    if (!notUpRequest) this.mManager.update(bean, http.headers, data);
                    return data;
                }
            }
            //更新
            if (!notUpRequest) this.mManager.update(bean, http.headers, data);
            let refreshOption: any = bean.source.refreshOption;
            //刷新指定数据单元
            if (refreshOption) {
                refreshOption = refreshOption.split(",");
                refreshOption.map((item: string) => {
                    if (item) {
                        this.mManager.refresh(item);
                    }
                });
            }
            callback(data, netData.data || data);
        } else {
            callback(false, netData.data || data);
        }
    }

    /**
     * 网络错误请求
     * @param bean
     * @param callback
     * @param http
     * @param data
     */
    error(bean: ISourceBean, http: IHttp, callback: any, data: any, key: number, sourceprops: ISource = {}) {
        //如果key不相当，则直接放弃数据
        if (this.loading[bean.name] !== key) {
            return;
        }
        delete this.loading[bean.name];
        const headers = {};
        const netData = (data = {
            CODE: (data && data.data && data.data.CODE) || QUERY_FAIL_CODE,
            MSG: (data && data.data && data.data.MSG) || "网络请求失败，请重试！",
            REV: false,
            res: data && data.data,
        });

        //处理数据
        if (bean.source.handleData) data = bean.source.handleData(data, bean.data.data, http.headers, this.mManager.mNet);
        //处理数据
        const { notUpRequest = false } = sourceprops;
        if (!notUpRequest) this.mManager.update(bean, headers, data);
        callback(false, netData);
    }

    // 下载文件
    private createDownZip = (blob: any, name: string) => {
        if (!blob) return;
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    /**
     * 执行网络请求
     * @param bean 数据单元
     * @param http 网络请求
     * @param callback 请求回调
     */
    exec(bean: ISourceBean, http: IHttp, callback: any = () => {}, delay: number, sourceprops: ISource = {}) {
        if (http.single !== false && this.loading[bean.name]) return;
        const key = this.count + 1;

        this.loading[bean.name] = this.count = key;

        const startTime = new Date().valueOf();

        const { source = {} } = bean;

        if (source["postDown"]) {
            PluginHttp.ajax({}, () => {}, {
                ...http,
                bodyType: "blob",
                success: (netData: any) => {
                    let name = Date.now() + "";
                    if (source["downType"]) {
                        name += `.${source["downType"]}`;
                    }
                    this.createDownZip(netData.data, name);
                },
                error: (netData: any) => {
                    setTimeout(() => {
                        this.error(bean, http, callback, netData, key);
                    }, Math.max(delay - (new Date().valueOf() - startTime), 1));
                },
            });
            return;
        }
        PluginHttp.ajax({}, () => {}, {
            ...http,
            success: (netData: any) => {
                setTimeout(() => {
                    this.success(bean, http, callback, netData, key, sourceprops);
                }, Math.max(delay - (new Date().valueOf() - startTime), 1));
            },
            error: (netData: any) => {
                setTimeout(() => {
                    this.error(bean, http, callback, netData, key, sourceprops);
                }, Math.max(delay - (new Date().valueOf() - startTime), 1));
            },
        });
    }
}

export default Net;
