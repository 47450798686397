import TempUpload from "src/engineEditor/pages/source/TempUpload";

export class TempHandler {
    tempUpload: TempUpload;

    constructor() {
        this.tempUpload = new TempUpload({ gameId: "test", server: "test" });
    }

    getActions(manager: any) {
        // const tempUpload = new TempUpload({ gameId: "test", server: "test" });
        const ret: any = this.tempUpload.handleHeaders({}, manager);
        if (!ret) return false;
        const rets: any = { commit_message: "编辑了  " };
        rets.actions = ret.pageList.map((item: any, index: number) => {
            const file = item.templateFile;
            if (item.cname) rets.commit_message += `[${item.cname}(${file.tempFileName})];`;
            return { action: item.action, content: file.content, file_path: `${file.tempFilePath}/${file.tempFileName}` };
        });
        return rets;
    }

    success = () => {
        this.tempUpload.handleDataRight(false);
    };
}
