import Utils from "../Utils";
import GameConfig from "src/GameConfig";
import IPConfig from "src/IPConfig";
import WebYun from "src/cms/WebYun";

/**
 *
 */
class Const {
    static maps = {
        //
        gameId: GameConfig.gameId,
        //服务名
        server: GameConfig.server,
        sname: GameConfig.server.replace("PC", ""),
        cmsSname: GameConfig.cmsSname,
        //是否在微信
        weChat: Utils.inWeChat(),
        /** 是否为ios */
        ios: Utils.getIos(),

        activityId: GameConfig.getActivityId(),

        release: IPConfig.release,
        /** 是否内网 */
        in: IPConfig.isLocal,

        WebYun: WebYun.IS,
        /** 是否为编辑状态 */
        renderType: "app",
        // 是否是运营可编辑状态
        isMan: false,

        isEdit: false,

        zoom: 1,
    };

    static changeServer(server: string, gameId: string, cmsSname: string) {
        Const.maps.server = server;
        Const.maps.sname = server.replace("PC", "");
        Const.maps.cmsSname = cmsSname;
        Const.maps.gameId = gameId;
    }

    static getItem(name: string) {
        return Const.maps[name];
    }

    static setItem(name: string, value: any) {
        Const.maps[name] = value;
    }

    static removeItem(name: string) {}
}

export default Const;

(window as any).CMSConst = Const;
