import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import UIManifest from "src/engine/ui/UIManifest";
import Utils from "src/lib/utils/Utils";

export interface IDataCheck {
    //key
    label: string;
    //比较方式
    type: string;
    //比较值
    value: string;
    //ui组件索引值
    index: number;
    labelKeySearch?: string;
    //来源数据源
    sourceName?: string;
    valueType?: string;
    labelIsValue?: string;
    includesValue?: string;
    includesType?: any;
    noNeedOutBox?: boolean;
}

interface IDataUI extends IBaseUI {
    map: IDataCheck[];
    dataSourceMap?: any;
    dataString: string;
    valueIsKey?: boolean; //是否与之对比的值也当做key处理
    value: any;
    finishSource?: string;
    checkLoading?: boolean;
    lenTrim?: boolean;
}

@UIManifest.declareGG(["uiCheck"], "DataUI", undefined, UIManifest.Type.Discard)
class DataUI<T extends IBaseUI, S = {}> extends BaseUI<IDataUI> {
    static defaultProps = {
        dataExtend: "true"
    };
    getChildDataKey() {
        const { dataString = "data" } = this.props;
        return dataString;
    }

    getChildData() {
        return this.props.data || this.props.dataSource;
    }

    finish = (data: any) => {
        const { finishSource } = this.props;
        if (finishSource) {
            this.sourceContext.execSource(finishSource, data);
        }
    };

    getMap = () => {
        let { map = [] } = this.props;
        const { checkLoading = false } = this.props;

        if (checkLoading) {
            map = [...map];
        }
        return map;
    };

    getIndex = () => {
        const { data, dataSource = {}, valueIsKey = false, value: oValue } = this.props;

        const map = this.getMap();
        try {
            for (const item of map) {
                const { label = "", sourceName } = item;
                const list = (label && label.split(".")) || [];
                const initValue = oValue || data || dataSource || {};
                let value = sourceName ? (this.props[sourceName] || this.props[sourceName] === 0 ? this.props[sourceName] : initValue) : initValue;
                let toValue;

                if (item.valueType === "dataSource") {
                    if (dataSource) {
                        toValue = dataSource[item.value];
                    } else {
                        toValue = item.value;
                    }
                } else if (item.valueType === "sourceName") {
                    toValue = sourceName ? (this.props[sourceName] ? this.props[sourceName][item.value] : item.value) : item.value;
                } else if (item.valueType === "compareNowTime") {
                    // 与当前时间进行比较
                    toValue = new Date().valueOf();
                } else {
                    toValue = item.value;
                }
                list.map((key: string, index: number) => {
                    if (value !== undefined && value !== null) {
                        if (typeof value === "string" && (value.indexOf("{") > -1 || value.indexOf("[") > -1)) {
                            try {
                                value = JSON.parse(value);
                            } catch (error) {}
                        }
                        value = value[key];
                        if (item.labelIsValue === "true") {
                            value = item.label;
                        }
                    }
                    if (this.props.lenTrim && list[1] === "length" && index === 0 && value) {
                        value = value && value.replace && value.replace(/(^\s*)/g, "");
                    }
                });
                if (item.labelKeySearch === "true") {
                    //用label的值作为key,进行搜索
                    value = dataSource[value];
                }
                if (valueIsKey && data) {
                    const uval = Utils.getValue(data, toValue.split("."));
                    toValue = uval || uval === 0 ? uval : toValue;
                }
                if (Utils.compare(item.type, value, toValue)) {
                    return item.index;
                }
            }
        } catch (error) {
            console.log("[DataUI]:", error);
        }

        return 0;
    };

    getChild() {
        const index = this.getIndex();
        const { checkLoading = false } = this.props;
        if (checkLoading) {
        }
        const { children = [] } = this.props as any;
        return children[Number(index)];
    }

    renderAct() {
        const { children = [], uiItem, cname, ename, map = [], style, uiCheck, noNeedOutBox = false, ...other } = this.props as any; //需要把map排除掉，避免嵌套DataUI被替换掉
        const child = this.getChild();
        if (!child) return null;
        return !noNeedOutBox ? (
            <div style={this.getStyle()}>{this.renderChildren([child], { ...other, finish: this.finish })}</div>
        ) : (
            this.renderChildren([child], { ...other, finish: this.finish })
        );
    }
}

export default DataUI;
