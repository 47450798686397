const underscoreName = (name: string) => {
    let result = "";
    if (name != null && name.length > 0) {
        // 将第一个字符处理成大写
        result += name.substring(0, 1).toUpperCase();
        // 循环处理其余字符
        for (let i = 1; i < name.length; i++) {
            const s = name.substring(i, i + 1);
            // 在大写字母前添加下划线
            if (s === s.toUpperCase()) {
                result += "_";
            }
            // 其他字符直接转成大写
            result += s.toUpperCase();
        }
    }
    return result;
};

interface ICorrectCountDownParams {
    // 倒计时总时间
    ms: number;
    // 是否开启误差日志
    log: boolean;
    // 倒计时间隔时间
    interval?: number;
    // 是否立即出发回调
    immediately?: boolean;
    // 每次触发时的回调
    cb: (restTime: number) => any;
}

export default class Utils {
    static isNull(value: any) {
        if (typeof value === "undefined" || value === null || value === "") return true;
        return false;
    }

    clearUndefined(value: object) {
        const maps = {};
        Object.keys(value).map((key) => {
            if (value[key] !== undefined) maps[key] = value[key];
        });
        return maps;
    }

    static if(check: any, trueTab: any, falseTab: any) {
        if (check) return trueTab;
        else return falseTab;
    }

    static map(list: object, callback: (value: any, index: any) => any) {
        if (Array.isArray(list)) {
            return list.map(callback);
        } else {
            const keys = Object.keys(list);
            const ret = {};
            for (const key of keys) {
                // const key = keys[i];
                ret[key] = callback(list[key], key);
            }
            return ret;
        }
    }

    /**
     * 创建一个action type
     * @param {*} name
     * @param {*} key
     */
    static createAction(name: string, key: string) {
        return `${name.toUpperCase()}_${underscoreName(key)}`;
    }

    static isArray(array: any) {
        if (Array.isArray) return Array.isArray(array);
        return Object.prototype.toString.call(array) === "[object Array]";
    }

    static isObject(obj: any) {
        return Object.prototype.toString.call(obj) === "[object Object]";
    }

    static objectValues(obj: any) {
        if (Object.values) return Object.values(obj);
        if (obj !== Object(obj)) throw new TypeError("Object.values called on a non-object");
        const val = [];
        let key;
        for (key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                val.push(obj[key]);
            }
        }
        return val;
    }

    // static toMapJS(map: Map<string, any>) {
    //     const ret = {};
    //     if (!map) {
    //         return ret;
    //     }

    //     map.map((value: any, key: string) => {
    //         ret[key] = value;
    //         return value;
    //     });
    //     return ret;
    // }
    // static toListJS(map: List<object>) {
    //     const ret: object[] = [];
    //     if (!map) {
    //         return ret;
    //     }

    //     map.map((value: object, key: number) => {
    //         ret.push(value);
    //         return value;
    //     });
    //     return ret;
    // }

    static getWH(url: string = "") {
        // http://webcloudplt.oss.jjbisai.com/fw/1/5d714f5be02c29e3_113301_331x140.png
        const splits = url.split("_")[2] || "1x1";
        const splitsArr = [splits[0], splits[1].split(".")[0]];
        return splitsArr;
    }
    //将emoji表情编码转为表情
    static handleHtml(str: string = "") {
        if (!str) return "";
        const reg = /\*#表情.*?;/g;
        const result = str.replace(reg, (char: any) => {
            char = char.replace("表情", "");
            let H: any, L: any, code: any;
            if (char.length === 9) {
                code = parseInt(char.match(/[0-9]+/g), 10);
                H = Math.floor((code - 0x10000) / 0x400) + 0xd800;
                L = ((code - 0x10000) % 0x400) + 0xdc00;
                return unescape("%u" + H.toString(16) + "%u" + L.toString(16));
            } else {
                return char;
            }
        });
        return result;
    }

    static handleUrl(url: string, params: any) {
        if (params) {
            const paramsArray: string[] = [];
            Object.keys(params).forEach((key) => {
                if (params[key] !== undefined) paramsArray.push(`${key}=${params[key]}`);
            });
            if (url.search(/\?/) === -1) {
                url += `?${paramsArray.join("&")}`;
            } else if (paramsArray.length > 0) {
                url += `&${paramsArray.join("&")}`;
            }
        }
        return url;
    }

    static includes(aString: string | any[] = "", bString: any = "", lower?: boolean) {
        if (typeof aString === "string" && lower) {
            aString = aString.toLowerCase();
        }
        if (typeof bString === "string" && lower) {
            bString = bString.toLowerCase();
        }
        if (!aString) return false;

        if (typeof aString === "string") return aString?.indexOf?.(bString) >= 0;

        // 数组里的string也使用模糊匹配
        if (Array.isArray(aString)) {
            if (typeof bString === "string") {
                for (const item of aString) {
                    if (item?.indexOf?.(bString) >= 0) {
                        return true;
                    }
                }
            } else {
                return aString?.indexOf?.(bString) >= 0;
            }
        }
        return false;
    }

    static compare(type: string, value: string | number, tValue: string | number, props?: any): any {
        let flag = false;
        if (type !== "===" && type !== "define" && type !== "undefined") {
            if (value === undefined) value = 0; //避免undefined和0无法比较的情况
        }
        switch (type) {
            case "includes":
                if (typeof value === "number") {
                    value = `${value}`;
                }
                if (value.indexOf) {
                    let cType = ">",
                        cValue = -1;
                    if (props && props.includesValue) {
                        if (props.includesValue) {
                            cValue = props.includesValue;
                        }
                        if (props.includesType) {
                            cType = props.includesType;
                        }
                        flag = Utils.compare(cType, value.indexOf(`${tValue}`), cValue);
                    } else {
                        flag = value.indexOf(`${tValue}`) > -1;
                    }
                }
                break;
            case "<":
                flag = Number(value) < Number(tValue);
                break;
            case "<=":
                flag = Number(value) <= Number(tValue);
                break;
            case ">":
                flag = Number(value) > Number(tValue);
                break;
            case ">=":
                flag = Number(value) >= Number(tValue);
                break;
            case "define":
                flag = typeof value !== "undefined";
                break;
            case "undefined":
                flag = typeof value === "undefined";
            case "!!":
                flag = String(!!value) === tValue;
                break;
            case "!=":
                flag = `${value}` !== `${tValue}`;
                break;
            case "=":
            default:
                flag = `${value}` === `${tValue}`;
                break;
        }
        return flag;
    }

    static calculat(type: any, val1: number | string, val2: number | string) {
        let val = 0;
        val1 = Number(val1);
        val2 = Number(val2);
        val1 = isNaN(val1) ? 0 : val1;
        val2 = isNaN(val2) ? 0 : val2;
        switch (type) {
            case "+":
                val = val1 + val2;
                break;
            case "-":
                val = val1 - val2;
                break;
            case "*":
                val = val1 * val2;
                break;
            case "/":
                val = val1 / val2;
                break;
            default:
                val = 0;
                break;
        }
        return val;
    }

    static getValue(data: any = {}, array: string[] = [], defaultValue?: any): any {
        if (!data || !array || array.length === 0) return defaultValue;
        if (typeof data === "string" && (data.indexOf("{") > -1 || data.indexOf("[") > -1)) {
            data = JSON.parse(data);
        }
        const ret = data[array[0]];
        array = array.slice(1);
        if (array.length > 0) {
            return Utils.getValue(ret, array, defaultValue);
        } else {
            return ret;
        }
    }

    static setValue(data: any = {}, keyArray: string[] = [], value: any): any {
        if (!keyArray || !Array.isArray(keyArray) || keyArray.length === 0) return data;
        function core(d: any, k: any, v: any): any {
            d[k] = v;
            return d[k];
        }
        let cache = data;
        let mid = data;
        for (let i = 0, length = keyArray.length; i < length; i++) {
            const key = keyArray[i];
            if (i + 1 === length) {
                cache = core(cache, key, value);
            } else {
                mid = mid[key] || {};
                cache = core(cache, key, mid);
            }
        }
        return data;
    }

    //将str中[]内的key替换为data下对应的值
    static replace(str: string, data: any) {
        if (str.indexOf("[") >= 0 && data) {
            const list = Utils.parser(/\[(.+?)\]/gim, str, 1);
            list.map((item) => {
                if (data[item] !== undefined) {
                    str = str.replace(`[${item}]`, data[item]);
                }
            });
        }
        return str;
    }
    static parser(reg: any, htmlstr: string = "", num: number) {
        // const reg = /<img.+?src=('|")?([^'"]+)('|")?(\s+|>)/gim;
        const imgs = [];
        let count = 0;
        while (true) {
            count += 1;
            const tem: any = reg.exec(htmlstr);
            if (count > 100 || !tem) break;
            imgs.push(tem[num]);
        }

        return imgs;
    }

    static getIosVersion() {
        const ua = navigator.userAgent.toLowerCase();
        let version = null;
        if (ua.indexOf("like mac os x") > 0) {
            const reg = /os [\d._]+/gi;
            const v_info = ua.match(reg);
            version = (v_info + "").replace(/[^0-9|_.]/gi, "").replace(/_/gi, "."); //得到版本号9.3.2或者9.0
            version = parseInt(version.split(".")[0]); // 得到版本号第一位
        }
        return version;
    }

    static getAndroidVersion() {
        const ua = navigator.userAgent.toLowerCase();
        let version = null;
        if (ua.indexOf("android") > 0) {
            const reg = /android [\d._]+/gi;
            const v_info = ua.match(reg);
            version = (v_info + "").replace(/[^0-9|_.]/gi, "").replace(/_/gi, "."); //得到版本号4.2.2
            version = parseInt(version.split(".")[0]); // 得到版本号第一位
        }
        return version;
    }

    static getLowDevice() {
        try {
            const platform = navigator.userAgent;
            if (/(Android)/i.test(platform)) {
                const version = Utils.getAndroidVersion() || 10;
                if (version < 6) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    static getIos() {
        const platform = navigator.userAgent;
        return /(iPhone|iPad|iPod|iOS|Mac)/i.test(platform);
    }

    static inWeChat() {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("micromessenger") !== -1;
    }

    static inQQApp() {
        const platform: string = navigator.userAgent.toLowerCase();
        let isqq = platform.indexOf("qq") >= 0;
        if (platform.indexOf("mqqbrowser") >= 0) {
            // QQ浏览器独有
            isqq = false;
        }
        return isqq;
    }

    static isQQBrowser(): boolean {
        const platform: string = navigator.userAgent.toLowerCase();
        return platform.indexOf("qq") >= 0;
    }

    static getDevice() {
        const platform = navigator.userAgent;
        if (/(iPhone|iPad|iPod|iOS)/i.test(platform)) {
            return "ios";
        } else if (/(Windows Phone)/i.test(platform)) {
            return "winphone";
        } else if (/(Android)/i.test(platform) || /(HarmonyOS)/i.test(platform)) {
            // 安装包通用 鸿蒙暂时归类到安卓
            return "android";
        } else {
            return "pc";
        }
    }
    static format(time: any, fmt: string) {
        const date = new Date(Number(time) < 10000000000 ? Number(time) * 1000 : Number(time)); //对于只有10位数的时间做*1000处理
        const o = {
            "M+": date.getMonth() + 1, //月份
            "d+": date.getDate(), //日
            "h+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            S: date.getMilliseconds(), //毫秒
        };
        const week = {
            "0": "日",
            "1": "一",
            "2": "二",
            "3": "三",
            "4": "四",
            "5": "五",
            "6": "六",
        };
        const year = date.getFullYear();
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, `${year}`.substr(4 - RegExp.$1.length));
        }
        if (/(E+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? "星期" : "周") : "") + week[date.getDay() + ""]);
        }
        for (const k in o) {
            if (new RegExp(`(${k})`).test(fmt)) {
                fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
            }
        }
        return fmt;
    }
    static formatTimeLong(time: any, fmt: string) {
        if (!time || time === "0" || time === "00:00") {
            return "00:00";
        }
        // const d = Math.floor(time/86400);
        const h = Math.floor(time / 3600);
        const m = Math.floor((time - h * 3600) / 60);
        const s = Math.floor(time - h * 3600 - m * 60);

        const o = {
            "h+": h, //小时
            "m+": m, //分
            "s+": s, //秒
        };

        for (const k in o) {
            if (new RegExp(`(${k})`).test(fmt)) {
                if (k === "h+" && h <= 0) {
                    fmt = fmt.replace(`${RegExp.$1}:`, "");
                } else {
                    fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
                }
            }
        }
        return fmt;
    }

    /**
     * 产生随机整数，包含下限值，包括上限值
     * @param {Number} lower 下限
     * @param {Number} upper 上限
     * @return {Number} 返回在下限到上限之间的一个随机整数
     */
    static random(lower: number, upper: number) {
        return Math.floor(Math.random() * (upper - lower + 1)) + lower;
    }

    static formatDateToHaitun(type: string, time: string | number) {
        time = Number(time) < 10000000000 ? Number(time) * 1000 : Number(time);
        let flag = false;
        switch (type) {
            case "today": {
                const timeS = new Date(Utils.format(new Date(), "yyyy/MM/dd")).getTime();
                flag = timeS <= time && time < timeS + 86400 * 1000;
                break;
            }
            case "yesterday": {
                const timeE = new Date(Utils.format(new Date(), "yyyy/MM/dd")).getTime();
                flag = timeE > time && time >= timeE - 86400 * 1000;
                break;
            }
            case "week": {
                let day = new Date().getDay();
                day = day === 0 ? 7 : day;
                const dt = new Date(Utils.format(new Date(), "yyyy/MM/dd")).getTime();
                const timeS = dt - (day - 1) * 3600 * 24 * 1000;
                flag = timeS <= time && time < timeS + 7 * 3600 * 24 * 1000;
                break;
            }
            case "thisyear": {
                const date = new Date(time);
                const thisYear = new Date().getFullYear();
                flag = thisYear === date.getFullYear();
                break;
            }
            case "notthisyear": {
                const date = new Date(time);
                const thisYear = new Date().getFullYear();
                flag = thisYear !== date.getFullYear();
            }
            default:
                break;
        }
        return flag;
    }

    static randomString(len: number) {
        len = len || 32;
        const $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
        const maxPos = $chars.length;
        let pwd = "";
        for (let i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    }

    /**
     * 获取Object对象的vlaue列表
     *
     * @static
     * @param {object} map
     * @returns
     * @memberof Utils
     */
    static getMapValueList(map: object) {
        return Object.keys(map).map((value, index) => {
            return map[value];
        });
    }
    static htmlDecode(text: string) {
        //1.首先动态创建一个容器标签元素，如DIV
        let temp: any = document.createElement("div"); //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
        temp.innerHTML = text; //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
        let output = temp.innerText || temp.textContent;
        temp = null;
        return output;
    }
    static getUrlParams(name?: string, tolower: boolean = false, hash?: string): string {
        const reg = new RegExp("(^|[&?])" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
        let r: any = window.location.search;
        if (r === "") {
            //分享到小海豚会被html转义
            r = Utils.htmlDecode(window.location.hash);
            r = r.substr(r.indexOf("?"));
        } else {
            let hashr = Utils.htmlDecode(window.location.hash);
            hashr = hashr.substr(hashr.indexOf("?") + 1);
            r += "&" + hashr;
        }
        if (hash) {
            r = hash;
        }
        r = r.substr(1);
        if (!name) {
            return r;
        }
        if (tolower) r = r.toLowerCase();
        r = r.match(reg); //匹配目标参数
        if (r != null) return unescape(r[2]);
        return ""; //返回参数值
    }
    static getUrlParamsMaps() {
        // const url = window.location.search; //获取url中"?"符后的字串
        let url: any = window.location.search;
        if (url === "") {
            url = window.location.hash.substr(window.location.hash.indexOf("?"));
        }
        const theRequest = new Object();
        if (url.indexOf("?") !== -1) {
            const str = url.substr(1);
            const strs = str.split("&");
            for (const item of strs) {
                if (item) theRequest[item.split("=")[0]] = decodeURIComponent(unescape(item.split("=")[1]));
            }
        }
        return theRequest;
    }
    static setUrlParams(name: string, value: any, url?: string) {
        const reg = new RegExp("([?&]" + name + "=)[^&]*(&|$)"); //构造一个含有目标参数的正则表达式对象
        const r: any = url || window.location.href;
        if (reg.test(r)) {
            return r.replace(reg, "$1" + value + "$2");
        } else {
            return r + (r.indexOf("?") > -1 ? "&" : "?") + name + "=" + value;
            // return r + (window.location.search.length > 1 ? "&" : "?") + name + "=" + value;
        }
    }

    static regexUrl(url: string, headers: any) {
        if (url.indexOf("[") > 0) {
            const list = Utils.parser(/\[(.+?)\]/gim, url, 1);
            list.map((item) => {
                if (headers[item] !== undefined) url = url.replace(`[${item}]`, headers[item]);
            });
        }
        return url;
    }

    static ref(info: object, name: string) {
        return (ref: any) => {
            info[name] = ref;
        };
    }
    /// <summary>
    /// 格式化文件大小的JS方法
    /// </summary>
    /// <param name="filesize">文件的大小,传入的是一个bytes为单位的参数</param>
    /// <returns>格式化后的值</returns>
    static renderSize(value: number | string) {
        if (value === "") {
            return;
        }
        const unitArr = new Array("Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB");
        let index = 0;
        const srcsize = parseFloat(value as string);
        index = Math.floor(Math.log(srcsize) / Math.log(1024));
        let size: any = srcsize / Math.pow(1024, index);
        size = size.toFixed(2); //保留的小数位数
        return size + unitArr[index];
    }

    static checkObjectKey(data: any = {}) {
        const otherMap = {};
        if (data) {
            const keys = Object.keys(data);
            for (const key of keys) {
                if (data[key] === null || data[key] === undefined || data[key] === "") {
                    continue;
                }
                otherMap[key] = data[key];
            }
        }
        return otherMap;
    }

    static deepCloneTemp(obj: any) {
        return Utils.deepClone(obj);
    }

    static deepClone(obj: any) {
        const objClone = Array.isArray(obj) ? [] : {};
        if (obj && typeof obj === "object") {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    //判断ojb子元素是否为对象，如果是，递归复制
                    if (obj[key] && typeof obj[key] === "object") {
                        //避免出现循环错误
                        objClone[key] = Utils.deepCloneTemp(obj[key]);
                    } else {
                        if (typeof obj[key] === "undefined") {
                        }
                        //如果不是，简单复制
                        else objClone[key] = obj[key];
                    }
                }
            }
        }
        return objClone;
    }

    static isMobile() {
        if (
            /gt-p1000|iphone|android|phone|mobile|wap|netfront|java|operamobi|operamini|ucweb|windowsce|symbian|series|webos|sony|blackberry|dopod|nokia|samsung|palmsource|xda|pieplus|meizu|midp|cldc|motorola|foma|docomo|up.browser|up.link|blazer|helio|hosin|huawei|novarra|coolpad|webos|techfaith|palmsource|alcatel|amoi|ktouch|nexian|ericsson|philips|sagem|wellcom|bunjalloo|maui|smartphone|iemobile|spice|bird|zte-|longcos|pantech|gionee|portalmmm|jigbrowser|hiptop|benq|haier|^lct|320x320|240x320|176x220|mqqbrowser/i.test(
                navigator.userAgent.toLowerCase()
            )
        ) {
            return true;
        }
        return false;
    }

    static getLineClampStyle(num: number) {
        if (!num) return {};
        num = Number(num);
        if (num <= 0) return {};
        if (num === 1) {
            return {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            };
        }
        return {
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: num,
            textOverflow: "ellipsis",
        };
    }

    static isWIFI(): boolean {
        const nav: any = (window as any).navigator;
        const ua = nav.userAgent.toLowerCase();
        const isWX = /micromessenger/.test(ua);
        let isWifi = false;
        if (isWX) {
            if (/wifi/.test(ua)) {
                isWifi = true;
            }
            return isWifi;
        }
        if (Utils.getUrlParams("connectionType") === "wifi") {
            isWifi = true;
        }
        const connection = nav.connection || nav.webkitConnection || nav.mozConnection || {};
        if (/wifi/.test(connection.type) || !connection.type) {
            isWifi = true;
        }
        return isWifi;
    }

    static arrayRemoveSame(list: any[], old: any[], key: string): any[] {
        // 给出的是新list
        const cacheArray = [];
        for (const item of old) {
            if (key) cacheArray.push(item[key]);
            else cacheArray.push(item);
        }
        const last = [];
        for (const item of list) {
            if ((key && cacheArray.indexOf(item[key]) < 0) || (!key && cacheArray.indexOf(item) < 0)) {
                last.push(item);
            }
        }
        return last;
    }

    static polyWheelDeltaEvent(el: any, fn: any, capture?: boolean) {
        let type = "mousewheel";
        if ((document as any).mozFullScreen !== undefined) {
            type = "DOMMouseScroll";
        }
        const handler = (event: any) => {
            // event.stopPropagation();
            // event.preventDefault();
            event.delta = event.wheelDelta ? event.wheelDelta / 120 : -(event.detail || 0) / 3;
            // event.delta > 0 往上滚
            // event.delta < 0 往下滚
            fn(event);
        };
        el.addEventListener(type, handler, capture || false);
        return function () {
            el.removeEventListener(type, handler, capture || false);
        };
    }

    // 近几天的设置
    static countTimeStamp(day: number) {
        return new Date().setHours(0, 0, 0, 0) - (day - 1) * 24 * 60 * 60 * 1000;
    }

    static getPrefix() {
        const elementStyle = document.createElement("div").style;
        const transformNames = {
            Webkit: "webkitTransform",
            Moz: "MozTransform",
            O: "OTransform",
            ms: "msTransform",
            standard: "transform",
        };

        for (const key in transformNames) {
            if (elementStyle[transformNames[key]] !== undefined) {
                return key;
            }
        }

        return false;
    }

    static prefixStyle(style: string) {
        const vendor = Utils.getPrefix();
        if (vendor === false) {
            return false;
        }
        if (vendor === "standard") {
            return style;
        }
        return vendor + style.charAt(0).toUpperCase() + style.substr(1);
    }

    static concatUnnullObject(target: any, source: any) {
        for (const key in source) {
            if (source.hasOwnProperty(key) && source[key] !== null && source[key] !== void 0) {
                target[key] = source[key];
            }
        }
        return { ...target };
    }

    static timeOnce(fn: any, time: number, context?: any) {
        let timer: any = null;
        return function (...arg: any) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                if (context) {
                    fn.call(context, ...arg);
                } else {
                    fn(...arg);
                }
            }, time);
        };
    }

    static throttleDecorator(time: number = 300) {
        return function (target: any, key: string, descriptor: any) {
            const method = descriptor.value;
            return {
                configurable: true,
                get() {
                    return Utils.timeOnce(method, time, this);
                },
            };
        };
    }

    static stringByteSize(val: string | number) {
        const str = val + "";
        let bytesCount = 0;
        for (let i = 0, n = str.length; i < n; i++) {
            const c = str.charCodeAt(i);
            if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
                bytesCount += 1;
            } else {
                bytesCount += 2;
            }
        }
        return bytesCount;
    }

    static stringEndWidth(str: string, search: string) {
        if (!String.prototype.endsWith) {
            const length = str.length;
            return str.substring(length - search.length, length) === search;
        } else {
            return str.endsWith(search);
        }
    }

    static stringStartsWith(str: string, search: string) {
        if (!String.prototype.startsWith) {
            return str.substring(0, search.length) === search;
        } else {
            if (!str.startsWith) {
                return false;
            }
            return str.startsWith?.(search);
        }
    }

    static supportsHistory() {
        const ua = window.navigator.userAgent;
        if (
            (ua.indexOf("Android 2.") !== -1 || ua.indexOf("Android 4.0") !== -1) &&
            ua.indexOf("Mobile Safari") !== -1 &&
            ua.indexOf("Chrome") === -1 &&
            ua.indexOf("Windows Phone") === -1
        )
            return false;

        return window.history && "pushState" in window.history;
    }

    static checkPhone(str: string) {
        const numStr = str.replace(/(^\s*)|(\s*$)/g, "");
        const reg = /^(1)[3|4|5|6|7|8|9]\d{9}$/;
        return reg.test(numStr);
    }

    static checkRealName(str: string) {
        if (!/^\s*[\u4e00-\u9fa5]{1,}[\u4e00-\u9fa5.?\xc2\xb7]{0,15}[\u4e00-\u9fa5]{1,}\s*$/.test(str) || str.length > 20 || str.indexOf(" ") > -1) {
            return false;
        }
        return true;
    }

    static inQQ() {
        const ua = navigator.userAgent.toLowerCase();
        return !!ua.match(/mqqbrowser|qzone|qqbrowser|qq/i);
    }

    static checkIDCard(str: string) {
        const wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1],
            valideCode = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2];
        let valCode = 0;
        const len15Birthday = () => {
            const year = Number(str.substring(6, 8)),
                month = str.substring(8, 10),
                day = str.substring(10, 12),
                tempDate: any = new Date(year, parseFloat(month) - 1, parseFloat(day));

            if (tempDate.getYear() !== year || tempDate.getMonth() !== parseFloat(month) - 1 || tempDate.getDate() !== parseFloat(day)) {
                return false;
            } else {
                return true;
            }
        };
        const len18 = () => {
            let sum = 0;
            const num: any = str.split("");
            if (num[17].toLowerCase() === "x") {
                num[17] = 10;
            }
            for (let i = 0; i < 17; i++) {
                sum += wi[i] * num[i];
            }
            valCode = sum % 11;
            if (Number(num[17]) !== valideCode[valCode]) {
                return false;
            } else {
                return true;
            }
        };
        const len18Birthday = () => {
            const year = Number(str.substring(6, 10)),
                month = str.substring(10, 12),
                day = str.substring(12, 14),
                tempDate = new Date(year, parseFloat(month) - 1, parseFloat(day));
            if (tempDate.getFullYear() !== year || tempDate.getMonth() !== parseFloat(month) - 1 || tempDate.getDate() !== parseFloat(day)) {
                return false;
            } else {
                return true;
            }
        };
        if (str && str.length === 15) {
            if (!len15Birthday()) return false;
            else return true;
        }
        if (str && str.length === 18) {
            if (!(len18() && len18Birthday())) return false;
            else return true;
        }
        return false;
    }

    static isIE() {
        if (!!window["ActiveXObject"] || "ActiveXObject" in window) return true;
        else return false;
    }

    static booleanString(str: any) {
        if (str === "true") return true;
        else if (str === "false") return false;
        return str;
    }

    static sleep(time: number = 200, cb?: (...ar: any[]) => any) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                try {
                    resolve(time);
                    if (cb && typeof cb === "function") cb();
                } catch (e) {
                    reject(e);
                }
            }, time);
        });
    }

    static stringEndWith(str: string, end: string) {
        if (!str || !end) return false;
        return str.indexOf(end) === str.length - end.length;
    }

    static setDefaultTime(
        type: "relativeNow" | "relativeDef" | "defaultval" | "now" /* 类型: 相对当前时间 | 相对固定时间 | 固定时间 | 当前时间 */,
        countTime: number /* 加减的时间戳（负号为时间减去该时间戳） */,
        relativeDefaultTime: number /* 相对的固定时间戳（相对固定时间时填该字段） */,
        defaultTimeLimitDay: number /* 固定时间(近n天 == n-1天前)(填的是n) */,
        defaultTimeValue: number /* 固定时间（时间戳， 主动输入的形式） */,
        fmt?: string /* 时间格式化形式 */
    ) {
        let value: number | string = new Date().valueOf();
        if (type === "relativeNow") {
            value = new Date().valueOf() + countTime;
        } else if (type === "relativeDef") {
            value = relativeDefaultTime + countTime;
        } else if (type === "defaultval") {
            if (defaultTimeLimitDay && defaultTimeLimitDay > 0) {
                value = new Date().setHours(0, 0, 0, 0) - (defaultTimeLimitDay - 1) * 24 * 60 * 60 * 1000;
            } else if (defaultTimeValue) {
                value = defaultTimeValue;
            }
        }
        if (fmt) value = Utils.format(value, fmt);
        return value;
    }

    static removePathPage(src: string) {
        // src 为移除了 hash 的字符串地址
        const reg = /\/([^\/]*?\.html)(\?|$)/;
        if (reg.test(src)) {
            return src.replace(reg, "/");
        } else {
            return src;
        }
    }

    static stringRepeat(str: string, num: number) {
        num = Math.floor(num);
        if (str.length === 0 || num === 0) {
            return "";
        }
        let rpt = "";
        for (;;) {
            if ((num & 1) === 1) {
                rpt += str;
            }
            num >>>= 1;
            if (num === 0) {
                break;
            }
            str += str;
        }
        return rpt;
    }

    static fullString(type: "start" | "end", old: string | number, length: number, padString: string) {
        length = length >> 0;
        old = old + "";
        if (old.length > length) return old;
        length = length - old.length;
        if (length > padString.length) {
            padString += Utils.stringRepeat(padString, length / padString.length);
        }
        if (type === "start") return padString.slice(0, length) + old;
        else return old + padString.slice(0, length);
    }

    static addStyle(element: any, style: any = {}, type = "add") {
        if (!element) return;
        Object.keys(style).forEach((key: string) => {
            if (type === "add") {
                element.style[key] = style[key];
            } else {
                element.style[key] = null;
            }
        });
    }

    /**
     * 倒计时自纠逻辑 通常用于页面级的倒计时显示等
     * 用于纠正单线程造成的时间差
     * @param { ICorrectCountDownParams } param
     * eg:
     *  correctCountDownFactory({ ms: 50000, cb(ms) { document.body.innerHTML = `倒计时：${ms/1000}s`} })
     */
    static correctCountDownFactory(param: ICorrectCountDownParams) {
        const { log, cb, interval = 1000, immediately } = param;
        const startTime = new Date().getTime();
        let count = 0,
            ms = param.ms,
            timeFlag: any = null;
        if (ms > 0) {
            if (immediately && cb) cb(ms);
            timeFlag = setTimeout(core, interval);
        }
        function core() {
            if (ms < 0) {
                clearTimeout(timeFlag);
                return;
            }
            // 当前循环计数
            count = count + 1;
            // 计算剩余时间
            ms -= interval;
            // 执行逻辑
            if (cb) cb(ms);
            // 误差时间 = 当前时间 - 逻辑时间
            const errorTime = new Date().getTime() - (startTime + count * interval);
            // 下次执行时间
            let nextTime = interval - errorTime;
            // 这种情况下说明当前进程阻塞时间大于了 interval 时间，只能到下个进程去纠正多余时间
            if (nextTime < 0) nextTime = 0;
            timeFlag = setTimeout(core, nextTime);
            if (log) console.log(`误差：${errorTime} ms，下一次执行：${nextTime} ms 后，离开始还有：${ms} ms`);
        }
        return () => {
            if (timeFlag !== null) clearTimeout(timeFlag);
        };
    }

    /**
     * 驼峰与下划线互相转换
     * @param name 命名
     */
    static HumpOrLine(name: string) {
        if (!!~name.indexOf("_")) {
            return name.replace(/\_(\w)/g, function (all, letter) {
                return letter.toUpperCase();
            });
        } else {
            return name.replace(/([A-Z])/g, "_$1").toLowerCase();
        }
    }

    static createHeaderStyle(str: string, id?: string) {
        if (id) {
            const styleEle = document.getElementById(id);
            if (styleEle) return;
        }
        const styleElement = document.createElement("style");
        styleElement.type = "text/css";
        if (id) styleElement.id = id;
        styleElement.appendChild(document.createTextNode(str));
        document.getElementsByTagName("head")[0].appendChild(styleElement);
    }
}

(window as any).Utils = Utils;
