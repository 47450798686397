import ISource from "../interface/ISource";
import Manager from "./Manager";
import Cache from "./utils/Cache";
import { SourceContext, ISourceContext } from "./SourceContext";
import * as React from "react";
import IUIItem from "../interface/IUIItem";
import BaseUI, { IBaseUI } from "../ui/BaseUI";
import AutoBind from "../decorator/AutoBind";
import { Context } from "../decorator/AutoContext";
import PanelContext from "../context/PanelContext";

interface ISourceCenter extends IBaseUI {
    ename: string;
    sources: { [key: string]: ISource };
    updateTheme?: any;
    renderStores?: any;
    stores?: IUIItem[];
    getSourceContext?: any;
}

class SourceCenter extends BaseUI<ISourceCenter> {
    sourceContext: ISourceContext;

    mManager: Manager;

    mCache: Cache = new Cache();

    constructor(props: ISourceCenter) {
        super(props);
        this.mManager = new Manager(props.ename, this.props.updateTheme);
        this.init(props.sources);
        if (props.getSourceContext) {
            props.getSourceContext(this.mManager);
        }
    }

    init(sources: { [key: string]: ISource } = {}) {
        for (const key of Object.keys(sources)) {
            if (!this.mCache.has(key)) {
                this.mCache.add(key, this.mManager.createSource(key, sources[key]));
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISourceCenter) {
        if (nextProps.sources !== this.props.sources) {
            this.init(nextProps.sources);
        }
    }

    /**
     *
     * @param key
     * @param exec
     */
    @AutoBind
    register(key: string, exec: any) {
        if (!this.mCache.has(key)) {
            this.mCache.add(key, this.mManager.createSourceByUIItems(key, exec));
        }
    }

    @AutoBind
    unregister(key: string) {
        this.mCache.clear(key);
    }

    getProps() {
        return {
            register: this.register,
            unregister: this.unregister,
        };
    }

    componentWillUnmount() {
        this.mCache.clearAll();
    }

    renderStores() {
        const { stores = [], renderStores = (child: any) => child } = this.props;
        return (
            <Context context={PanelContext}>
                {(context) => {
                    return stores.map((store, index) => {
                        return renderStores(this.renderUIItem(store, context, this.getProps()), store, index);
                    });
                }}
            </Context>
        );
    }

    render() {
        // const { stores = [], renderStores } = this.props as any;

        return (
            <Context context={SourceContext}>
                {(context) => {
                    this.mManager.setManager(context.mManager);
                    return (
                        <SourceContext.Provider value={{ mManager: this.mManager, execSource: this.mManager.execSource }}>
                            {/* {this.renderChildren(stores, this.getProps(), true, renderStores)} */}
                            <div className="center-stores" key="centerstores">
                                <div>{this.renderStores()}</div>
                            </div>
                            {this.props.children}
                        </SourceContext.Provider>
                    );
                }}
            </Context>
        );
    }
}

export default SourceCenter;
