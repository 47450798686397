import IUIItem from "src/engine/interface/IUIItem";
import RuleCompareFormValue, { IRuleCompareValueBase } from "./RuleCompareFormValue";

interface IRuleCompareValue extends IRuleCompareValueBase {
    // 默认值
    defaultValue?: any;
}

/**
 * 校验 【自定义 值与值的比较】
 */
class RuleCompareDefaultValue extends RuleCompareFormValue {
    props: IRuleCompareValue;

    constructor(props: IRuleCompareValue) {
        super(props);
        this.props = props;
    }

    defineMessage(uiItem: IUIItem) {
        const { compareType = "=", defaultValue } = this.props;
        return `${uiItem.info.cname}${this.defineCompareType(compareType)}${defaultValue}`;
    }

    exec(
        // 表单值
        formData: any,
        // 扩展属性, rules同级
        expend: any,
        // 当前表单 UIItem
        uiItem: IUIItem
    ) {
        const { defaultValue, message } = this.props;
        if (defaultValue === void 0) return false;
        return this.core(defaultValue, message ? message : this.defineMessage(uiItem));
    }
}

export default RuleCompareDefaultValue;
