import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import "./Button.scss";
import UIManifest from "src/engine/ui/UIManifest";
import Utils from "src/lib/utils/Utils";
import { Types } from "src/engine/pages/source/AppRoot";
import { ScrollContext, IScrollContext } from "../../mobile/ui/common/ScorllFixPos";
import TouchFeedback from "./TouchFeedback";
import ToastSource from "src/engine/interface/ToastSource";
import ButtonCheck from "./ButtonCheck";
import { OPEN_NEW_PAGE_TYPE, JJMATCH_STATIC_PAGE } from "src/lib/utils/Const";
import EventTouch from "./EventTouch";
import { Context } from "src/engine/decorator/AutoContext";

export interface IButton extends IBaseUI {
    title?: string;
    margin?: string;
    style?: any;
    width?: string;
    height?: string;
    backgroundImage?: string;
    onClick?: (e: any) => any;
    ename?: string;
    clearSourceName?: string; //接口返回成功需清除的数据源
    execSourceName?: string; //接口返回成功执行的source
    execSourceData?: any; //接口返回成功执行的source数据映射
    ajaxExecCb?: boolean; //接口返回成功后执行onClick回调
    noMergeData?: boolean; //接口返回成功后,执行的source是否更新data
    notExtendClick?: boolean; // 是否不继承传过来的onClick, 默认false, 继承
    otherPropsFn?: string; // onClick触发其他props方法名
    value?: string;
    goto?: string;
    gotoProps?: any;
    isNew?: string;
    children?: any;
    mapData?: any[];
    mapIntent?: any[]; //获取intent中的参数进行click传递
    source?: string;
    iosGoto?: string;
    toStringParams?: string;
    iosBgImg?: string;
    urlPath?: string;
    urlParams?: string;
    fixPosition?: boolean; //是否有scorll定位
    mapProps?: any[];
    deleteNowPage?: number;
    refFun?: any;
    anchor?: string;
    propsNum?: number;
    delaySkipNum?: number; //延迟跳转时间
    assignData?: string; //跳转是否携带所有data
    activeStyle?: any;
    hoverStyle?: any;
    loggerData?: any;
    loggerIntent?: any;
    loggerStatic?: any;
    autoClick?: number;
    checkProps?: any; //检测
    doubleClickTime?: number;
    sameDoubleClickTime?: number;
    succSendAjax?: boolean;
    hoverChildren?: any;
    haveTranstion?: boolean;
    onClickCheck?: any;
    hoverKey?: string;
    linkType?: boolean;
    goConfig?: any;
    hoverChildrenNotClick?: boolean;
    useEventTouch?: boolean;
    eventHoverChild?: boolean;
    noMergeDataSource?: boolean;
    localSource?: any;
    /**
     * 1. 为 new 时 使用改变 pathname 的跳转
     * 2. 为 self 时 使用默认的系统内部的改变 hash 的跳转方式
     * 3. 为 "inherit" 或者不存在时 是否有全局的改变pathname跳转方式的 flag, 有就使用该方式， 没有就 hash
     */
    itemLinkType?: "self" | "new" | "inherit";
    // hover触发事件
    hovertToEvent?: boolean;
    classNames?: string[];
}

function filterIntent(intent: any, map: any) {
    const intentMap = {};
    map.map((item: any) => {
        if (intent[item.label] !== undefined) {
            intentMap[item.value] = intent[item.label];
        }
    });
    return intentMap;
}

function filterData(data: any, mapS: any) {
    const map = {};
    mapS.map((item: any) => {
        let resVal = data[item.label];
        if (/(.+)/.test(item.label)) {
            resVal = Utils.getValue(data, item.label.split("."));
        }

        if (item.dataType === "string") {
            //转string
            resVal = resVal + "";
        }
        if (resVal) map[item.value || data[item.label]] = resVal;
    });
    return map;
}

@UIManifest.declareGG(["dataValue", "goConfig"], "Button", "Button组件", UIManifest.Type.Discard)
class Button extends BaseUI<IButton> {
    ScrollContext: IScrollContext;
    buttonDom: any;

    buttomCheck: ButtonCheck = new ButtonCheck();

    componentDidMount() {
        const { autoClick } = this.props;
        if (autoClick !== undefined) setTimeout(this.onClick, autoClick);
    }

    goUrl = (url: string, params = {}) => {
        let paramstr = "";
        if (Object.keys(params).length > 0) {
            for (const key of Object.keys(params)) {
                if (params[key] || params[key] === 0) {
                    paramstr += `${key}=${params[key]}&`;
                }
            }
        }
        if (paramstr) url = `${url}?${paramstr}`;
        const { isNew } = this.props;
        if (isNew === "true") {
            const newWindow = window.open(url);
            if (newWindow) newWindow.opener = null;
        } else if (isNew === "replace") {
            window.location.replace(url);
        } else {
            window.location.href = url;
        }
    };

    goAutoChangeUrl = (page: string, params = {}) => {
        const pathname = window.location.pathname;
        const search = window.location.search;
        let url = "";

        if (Utils.stringEndWidth(pathname, "/")) {
            url = `${pathname}${page}.html`;
        } else {
            const index = pathname.lastIndexOf("/");
            url = `${pathname.substring(0, index)}/${page}.html`;
        }

        if (search) url += search;
        url += `#/${page}`;

        return this.goUrl(url, params);
    };

    gotButton(callback: (context: IScrollContext) => any) {
        return <Context context={ScrollContext}>{callback}</Context>;
    }
    canClick() {
        return true;
    }
    getUrl() {
        const { urlPath = "goto", data } = this.props;
        return Utils.getValue(this.props, urlPath.split("."), "") || Utils.getValue(data, urlPath.split("."), "");
        // return this.props.goto;
    }

    go(flag?: any) {
        const {
            gotoProps = {},
            data = {},
            urlParams,
            deleteNowPage,
            mapData = [],
            mapIntent = [],
            assignData = "true",
            linkType = false,
            isNew,
            itemLinkType,
            goConfig,
        } = this.props;
        const url = this.getUrl();
        if (!url) return;
        const params = (urlParams === "true" ? flag : assignData === "true" ? { ...data, ...gotoProps } : { ...gotoProps }) || {};
        if (mapData.length > 0) {
            const cache = { ...data, ...gotoProps };
            mapData.map((item: any) => {
                if (cache[item.label]) params[item.value] = cache[item.label];
                if (item.dataType === "string") {
                    //转string
                    params[item.value] = params[item.value] + "";
                }
            });
        }
        if (mapIntent.length > 0) {
            //增加intent参数
            const intentData = this.getIntent();
            mapIntent.map((item) => {
                if (intentData[item.label] !== undefined) {
                    params[item.value] = intentData[item.label];
                }
            });
        }

        if (linkType) {
            return this.goUrl(`#/${url}`, { ...params, ...goConfig });
        }
        const staticPage =
            (window as any)[JJMATCH_STATIC_PAGE] && (itemLinkType === "new" || ((window as any)[OPEN_NEW_PAGE_TYPE] === true && itemLinkType !== "self"));
        if (url === "back") {
            this.goBack(gotoProps ? gotoProps.count : 1);
        } else if (url === "backModal") {
            this.goBack(gotoProps ? gotoProps.count : 1);
        } else if (url === "forward") {
            this.goManger(Types.GoForward);
        } else if (url === "refresh") {
            this.goManger(Types.refresh);
        } else if (url.indexOf("http") !== 0 && url.indexOf("/") !== 0) {
            //找不到http
            if (deleteNowPage) {
                params.init = deleteNowPage;
            }
            if (isNew === "true") {
                params.openNew = true;
            } else if (isNew === "localJump") {
                params.localHref = true;
            }
            if (this.props["nocachePage"] === true) {
                params.ncpage = true;
            }
            if (staticPage) {
                // 静态跳转
                params.staticLinkType = true;
            }
            this.startPage(url, params);
        } else {
            this.goUrl(url);
        }
    }
    static circle: any = (data: any, arr: any, value: any, add?: false) => {
        if (arr.length > 1) {
            return data[Button.circle(data[arr[0]], arr.slice(1), value, add)];
        } else if (arr.length === 1) {
            if (add) {
                data[arr[0]] = Number(data[arr[0]]) + Number(value);
            } else {
                data[arr[0]] = value;
            }
            return data;
        } else {
            return value;
        }
    };
    getOtherMap = () => {
        const intentData = this.getIntent();
        const { data, mapData = [], mapProps = [], mapIntent = [] } = this.props;
        let map = {};

        if (mapIntent.length > 0) {
            //增加intent参数

            mapIntent.map((item) => {
                if (intentData[item.label] !== undefined) {
                    map[item.value] = intentData[item.label];
                }
            });
        }

        if (mapData.length > 0) {
            mapData.map((item) => {
                let resVal = data[item.label];
                if (/(.+)/.test(item.label)) {
                    resVal = Utils.getValue(data, item.label.split("."));
                }

                if (item.dataType === "string") {
                    //转string
                    resVal = resVal + "";
                }
                if (resVal !== undefined) map[item.value || data[item.label]] = resVal;
                if (item.reverse === "true" && item.fixedVal !== undefined) {
                    map[data[item.label]] = item.fixedVal;
                }
                if (item.valueHandle) {
                    map[item.value] = item.valueHandle.replace("xxx", map[item.value]);
                }
            });
        } else {
            map = { ...data };
        }

        if (mapProps.length > 0) {
            for (const item of mapProps) {
                const resVal = Utils.getValue(this.props, item.label.split("."));
                const { type, num } = item;
                if (type && (num || num === 0)) {
                    map[item.value] = Utils.calculat(type, resVal, num);
                } else {
                    map[item.value] = resVal;
                }
            }
        }
        return map;
    };
    checkFun = () => {
        const { checkProps, data = {} } = this.props;
        let toast = "";
        let flag = false;
        if (checkProps) {
            checkProps.map((item: any) => {
                const value = item.label;
                let toValue = data[item.dataKey] || "";
                if (item.valueType === "props") {
                    toValue = Utils.getValue(this.props, item.dataKey.split(".")) || "";
                }
                if (Utils.compare(item.type, value, toValue)) {
                    flag = true;
                    toast = item.value;
                }
            });
        }
        // msgType
        // 当前版本暂不支持
        //discoverVersion >=
        if (toast) {
            new ToastSource().message(toast);
        }
        return flag;
    };
    clickTime: number;
    onClick = (e: any) => {
        // const nowTime = new Date().valueOf();
        // if (doubleClickTime) {
        //     if (this.clickTime && nowTime - this.clickTime < doubleClickTime) return;
        //     this.clickTime = nowTime;
        // }

        const { doubleClickTime, onClickCheck, sameDoubleClickTime } = this.props;
        // 检查相同按钮间的重复点击
        if (!this.buttomCheck.check(sameDoubleClickTime)) return;
        // 检查不同按钮间的重复点击
        if (!ButtonCheck.check(doubleClickTime)) return;

        if (onClickCheck && !onClickCheck()) return;

        //需进行网络检测且检测不通过
        if (this.checkFun()) return;
        if (this.ScrollContext) this.ScrollContext.getScrollDom();
        const {
            value,
            gotoProps,
            uiItem,
            data,
            source,
            ename = "",
            toStringParams = "",
            clearSourceName,
            anchor,
            propsNum,
            noMergeData = false,
            ajaxExecCb = false,
            execSourceName = "",
            execSourceData = [],
            delaySkipNum = 0,
            notExtendClick = false,
            loggerIntent = [],
            loggerData = [],
            loggerStatic = [],
            succSendAjax,
            otherPropsFn,
            goConfig,
            localSource,
        } = this.props;
        const onClick = this.props.onClick || (data && data["onClick"]);
        if (!this.canClick()) return;
        //阻止冒泡
        if (onClick && e) e.stopPropagation();
        const intentData = this.getIntent();
        if (this.pageContext && uiItem.info.cname !== "") {
            const intentMap = filterIntent(intentData, loggerIntent),
                dataMap = filterData(data, loggerData);
            const staticMap = {};
            loggerStatic.map((item: any) => {
                staticMap[item.label] = item.value;
            });
            this.pageContext.stat({ clickId: uiItem.info.cname, ...staticMap, ...intentMap, ...dataMap });
        }
        if (source) {
            const map = this.getOtherMap();
            if (toStringParams) {
                const strArr = toStringParams.split(",");
                strArr.forEach((element) => {
                    map[element] = map[element] && JSON.stringify(map[element]);
                });
            }
            let props = {};
            if (propsNum) {
                if (propsNum === 1) {
                    props = { ...gotoProps, ...goConfig };
                } else if (propsNum === 2) {
                    props = { ...map, ...gotoProps, ...goConfig };
                }
            } else {
                props = { ...map, ...gotoProps, [ename]: value, ...goConfig };
            }
            if (source.indexOf(",") > 0) {
                source.split(",").map((itemsource: string) => {
                    this.sourceContext.execSource(itemsource, props);
                });
            } else {
                this.sourceContext.execSource(source, props, (flag) => {
                    if (ajaxExecCb && onClick) {
                        onClick(flag);
                    }
                    if (flag !== false) {
                        if (flag.error) return;
                        if (clearSourceName) {
                            //接口返回成功后清除的数据
                            this.sourceContext.mManager.clearSource(clearSourceName);
                        }
                        if (localSource) {
                            this.sourceContext.execSource(localSource, props);
                        }
                        if (execSourceName) {
                            //接口返回成功后执行的source，本地更新
                            const execMap = {};
                            if (execSourceData.length > 0) {
                                execSourceData.forEach((item: any) => {
                                    if (item.type === "reverse") {
                                        //反转数据，用key的值作为key
                                        execMap[data[item.label]] = item.value;
                                    } else if (item.type === "add") {
                                        //原值上加减数据
                                        const temp = item.value.split(".");
                                        if (temp.length > 1) {
                                            Button.circle(data[temp[0]], temp.slice(1), item.realData, true);
                                            execMap[temp[0]] = Number(data[temp[0]]);
                                        } else {
                                            execMap[temp[0]] = Number(data[temp[0]]) + Number(item.realData);
                                        }
                                    } else {
                                        //简单赋值
                                        const temp = item.value && item.value.split(".");
                                        const val = item.realData !== (undefined || null) ? item.realData : Utils.getValue(data, item.label);
                                        if (temp.length > 1) {
                                            Button.circle(data[temp[0]], temp.slice(1), val);
                                            execMap[temp[0]] = data[temp[0]];
                                        } else {
                                            execMap[temp[0]] = val;
                                        }
                                    }
                                });
                            }
                            let resData = noMergeData ? execMap : { ...data, ...execMap };
                            if (this.props.noMergeDataSource) {
                                (this.props.dataSource || []).map((item: any, index: number) => {
                                    if (item.artId === resData.artId) {
                                        this.props.dataSource.splice(index, 1, resData);
                                    }
                                });
                                resData = this.props.dataSource;
                            }
                            const ajax = { url: "" };
                            if (succSendAjax) delete ajax.url;
                            this.sourceContext.execSource(execSourceName, resData, undefined, {
                                ajax,
                            });
                        }
                        const that = this;
                        if (delaySkipNum) {
                            setTimeout(() => {
                                that.go.call(that, flag);
                            }, delaySkipNum);
                        } else {
                            this.go(flag);
                        }
                    }
                });
            }
            // return;
        }

        if (anchor) {
            const parent: Element | null = this.findParentPage(this.buttonDom);
            if (parent) {
                const dom: Element = parent.querySelectorAll(anchor)[0];
                if (dom) {
                    dom.scrollIntoView();
                }
            }
        } else {
            const that = this;
            if (delaySkipNum) {
                setTimeout(() => {
                    that.go.call(that);
                }, delaySkipNum);
            } else {
                this.go();
            }
        }

        if (onClick && !ajaxExecCb && !notExtendClick) {
            const arg = [gotoProps || value || e];
            if (this.props["clickSecParam"]) {
                arg.push({ ...gotoProps, ...this.getOtherMap() });
            }
            onClick(...arg);
        }
        if (otherPropsFn) {
            const propsFn = Utils.getValue(this.props, otherPropsFn.split("."));
            if (propsFn && typeof propsFn === "function") {
                propsFn(gotoProps || value || e);
            }
        }
    };
    getStyleObject() {
        const { backgroundImage, width, height, margin } = this.props;
        return this.getStyle({ width, height, backgroundImage, margin });
    }
    refFn = (ref: any) => {
        const { refFun } = this.props;
        this.buttonDom = ref;
        if (refFun) {
            refFun(ref);
        }
    };
    findParentPage = (dom: Element): any => {
        if (!dom) return null;
        const parent: Element | null = dom.parentElement;
        if (!parent) return null;
        const reg = /(^|\s)react-page(\s|$)/;
        if (reg.test(parent.className)) {
            return parent;
        } else {
            return this.findParentPage(parent);
        }
    };

    renderButton() {
        const {
            title,
            className = "",
            data,
            activeStyle,
            hoverChildrenNotClick,
            hoverStyle,
            hoverChildren,
            dataExtend,
            haveTranstion,
            hoverKey,
            hovertToEvent,
            classNames = [],
        } = this.props;
        const children = [];

        if (title) children.push(title);
        const item = this.renderChildren();
        let hoverUI;
        if (hoverChildren) {
            hoverUI = this.renderChildren(hoverChildren);
        }
        if (item !== null) children.push(item);
        let Comp: any = TouchFeedback;
        if (this.props.useEventTouch) {
            Comp = EventTouch;
        }
        return (
            <Comp
                disabled={!hoverStyle && !activeStyle && !hoverChildren}
                onHover={this.onClick}
                data={dataExtend === "true" ? data : ""}
                hoverChildren={hoverUI}
                hoverChildrenNotClick={hoverChildrenNotClick}
                hovertToEvent={hovertToEvent}
                hoverKey={hoverKey}
                activeStyle={activeStyle}
                hoverStyle={hoverStyle}
                eventHoverChild={this.props.eventHoverChild}
            >
                <div
                    className={`react-button ${className} ${classNames.join(" ")} ${haveTranstion ? "react-button-transtion" : ""}`}
                    ref={this.refFn}
                    style={this.getStyleObject()}
                    onClick={this.onClick}
                >
                    {children}
                </div>
            </Comp>
        );
    }

    renderAct() {
        const { fixPosition } = this.props;
        return fixPosition
            ? this.gotButton((Scroll: IScrollContext) => {
                  this.ScrollContext = Scroll;
                  return this.renderButton();
              })
            : this.renderButton();
    }
}

export default Button;
