import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import UIManifest from "src/engine/ui/UIManifest";
import "swiper/dist/css/swiper.min.css";
import "./swiperJs.scss";
// import Swiper from "swiper";
import IUIItem from "src/engine/interface/IUIItem";
import Style from "src/lib/utils/Style";
import CorenderTabs from "./CorenderTabs";
import { IPanelContext } from "src/engine/context/PanelContext";

const Swiper = require("swiper/dist/js/swiper.js");

interface ISwiperJs extends IBaseUI {
    dataChildren?: any;
    nextButton?: any;
    prevButton?: any;
    coverflowEffect?: boolean;
    coverERotate?: number;
    coverEstretch?: number;
    coverEdepth?: number;
    coverEmodifier?: number;
    coverEShadow?: boolean;
    loop?: boolean;
    autoplay?: boolean;
    autopdelay?: number;
    autopstopOnLastSlide?: boolean;
    noDisableOnInteraction?: boolean;
    activeBar?: any;
    normalBar?: any;
    // active状态的bar的style
    activeBarStyle?: any;
    barBox?: any;
    barBoxStyle?: any;
    // 鼠标模拟手机触摸。默认为true，Swiper接受鼠标点击、拖动。
    simulateTouch?: boolean;
    // 开启鼠标滚轮控制Swiper切换。可设置鼠标选项，或true使用默认值。
    mousewheel?: boolean;
    // 切换触发的数据
    changeSource?: string;
    // 其他子组件（继承方法）
    otherChild?: any;
    isOpenresize?: boolean;
    // 单个容器宽度
    slideWidth?: string;
    autoHeight?: boolean;
    // 是否淡入淡出切换
    isFade?: boolean;
    // 在一些特殊情况下控制重新渲染
    reRender?: any;
    // 是否每个tabs需要index的值（一般不需要，用于渲染优化）
    useIndexperTab?: boolean;
    slidesPerView?: number;
}
interface IState {
    index: number;
    prevIndex?: number | null;
    nextIndex?: number | null;
}

@UIManifest.declareGG(["ggValue"], "SwiperJs", "SwiperJs轮播")
export default class SwiperJs extends BaseUI<ISwiperJs, IState> {
    swiperRef: React.RefObject<HTMLDivElement>;
    nextBtnRef: React.RefObject<HTMLDivElement>;
    prevBtnRef: React.RefObject<HTMLDivElement>;

    constructor(props: ISwiperJs) {
        super(props);
        this.swiperRef = React.createRef();
        this.nextBtnRef = React.createRef();
        this.prevBtnRef = React.createRef();
        this.state = {
            index: 0,
            nextIndex: 1,
        };
    }

    mySwiper: any;

    resizeEvent: any;

    componentDidMount() {
        this.renderSwiper();

        if (this.props.isOpenresize) {
            // const nextTick = () => {
            //     console.log("[swiper] nextTick");
            //     if (window["resizeChangeZoom"]) {
            //         setTimeout(this.renderSwiper, 100);
            //     } else {
            //         this.renderSwiper();
            //     }
            // };
            // window.addEventListener("resize", nextTick, false);
            // this.resizeEvent = () => {
            //     window.removeEventListener("resize", nextTick, false);
            // };
        }
    }

    fireChangeSource = (changeSourceName: string, params: any) => {
        const sourceArray = changeSourceName.split(".");
        for (const source of sourceArray) {
            this.sourceContext.execSource(source, params);
        }
    };

    renderSwiper = () => {
        if (this.mySwiper) {
            this.mySwiper.destroy(true, false);
        }
        const dom = this.swiperRef.current;
        if (!dom) return;
        const navigation: any = {};
        if (this.nextBtnRef.current) {
            navigation.nextEl = this.nextBtnRef.current;
        }
        if (this.prevBtnRef.current) {
            navigation.prevEl = this.prevBtnRef.current;
        }
        let effect: any = {};
        if (this.props.coverflowEffect) {
            effect["effect"] = "coverflow";
            const { coverERotate = 0, coverEstretch = 60, coverEdepth = 100, coverEmodifier = 5, coverEShadow = false } = this.props;
            effect["coverflowEffect"] = {
                rotate: coverERotate,
                stretch: coverEstretch,
                depth: coverEdepth,
                modifier: coverEmodifier,
                slideShadows: coverEShadow,
            };
            effect["slidesPerView"] = "auto";
        }
        if (this.props.isFade) {
            effect = {
                effect: "fade",
                fadeEffect: { crossFade: true },
            };
        }
        let autoplay: any = {};
        if (this.props.autoplay) {
            const { autopdelay = 3000, autopstopOnLastSlide = false, noDisableOnInteraction } = this.props;
            autoplay["delay"] = autopdelay;
            autoplay["stopOnLastSlide"] = autopstopOnLastSlide;
            autoplay["disableOnInteraction"] = !noDisableOnInteraction;
        } else {
            autoplay = false;
        }
        const { loop = false, simulateTouch = false, mousewheel = false, autoHeight = false, slidesPerView = 1 } = this.props;
        this.mySwiper = new Swiper(dom, {
            loop,
            autoplay,
            autoHeight,
            slidesPerView,
            centeredSlides: true,
            autoplayDisableOnInteraction: false,
            // slidesPerView: "auto",
            simulateTouch: !simulateTouch,
            observeParents: true,
            observer: true,
            mousewheel,
            ...effect,
            navigation,
        });
        this.slideChange();
        this.mySwiper.on("slideChange", () => {
            this.slideChange();
        });
    };

    slideChange = () => {
        const index = this.mySwiper.realIndex;
        const [prevIndex, nextIndex = 1] = this.computePosition(index);
        this.setState({ index, prevIndex, nextIndex });
        if (this.props.changeSource) {
            const { dataSource = [] } = this.props;
            const data = dataSource[index] || {};
            let param = {};
            if (typeof data === "object") {
                param = { ...data, index };
            } else {
                param = { data, index };
            }
            this.fireChangeSource(this.props.changeSource, param);
        }
    };

    childComponentWillUnmount() {
        if (this.mySwiper) {
            this.mySwiper.destroy(true, false);
        }
        if (this.resizeEvent) this.resizeEvent();
    }

    componentDidUpdate(p: ISwiperJs, s: IState) {
        if (p.dataSource !== this.props.dataSource || p.reRender !== this.props.reRender) {
            // datasource更新
            this.renderSwiper();
        }
    }

    getSlideWidth = (item: IUIItem) => {
        if (!item) return false;
        const style = item.props.style;
        if (!style) return false;
        if (style.width) return style.width;
    };

    getPosition = (key: number) => {
        let position = "hidden";
        if (this.state.prevIndex === key) {
            position = "prev";
        } else if (this.state.index === key) {
            position = "active";
        } else if (this.state.nextIndex === key) {
            position = "next";
        }
        return position;
    };

    computePosition = (index: number) => {
        const { children = [], dataSource = [], loop = false } = this.props;
        let length = 0;
        if (!children || children.length === 0) {
            length = dataSource.length;
        } else {
            length = children.length;
        }
        let prev = null,
            next = null;
        if (!loop) {
            if (index > 0) {
                prev = index - 1;
            }
            if (index < length - 1) {
                next = index + 1;
            }
        } else {
            prev = index - 1;
            next = index + 1;
            if (index === 0) {
                prev = length - 1;
            }
            if (index === length) {
                next = 0;
            }
        }
        return [prev, next];
    };

    renderUIItem = (uiItem: IUIItem, context: IPanelContext, props: any = {}): any => {
        return super.renderUIItem(uiItem, context, props);
    };

    getData = () => {
        const { dataSource, data } = this.props;
        if (Array.isArray(dataSource)) return dataSource;
        if (Array.isArray(data)) return data;
        return [];
    };

    renderTabs = (context: any) => {
        const { children = [], dataChildren = [], slideWidth, useIndexperTab } = this.props;
        let props = { children, dataChildren, slideWidth, dataSource: this.getData(), context, renderUIItem: this.renderUIItem };
        if (useIndexperTab) {
            props = { ...props, ...this.state };
        }
        return <CorenderTabs {...props} />;
    };

    slideTo = (index: any) => {
        if (!this.mySwiper) return;
        let map: any;
        if (typeof index === "object") map = index;
        if (map) {
            const value = map["toIndex"];
            index = value || value === 0 ? value : index;
        }
        if (this.props.loop) {
            this.mySwiper.slideToLoop(index);
        } else {
            this.mySwiper.slideTo(index);
        }
    };

    renderBar = (context: any) => {
        const { activeBar = [], normalBar = [], barBoxStyle = {}, activeBarStyle } = this.props;
        if (normalBar.length === 0) return;
        const dataSource = this.getData();
        return (
            <div className="bar-box" style={Style(barBoxStyle)}>
                {dataSource.map((data: any, key: number) => {
                    // 优化bar, 通过修改style减少配置、减少
                    const props: any = { key, index: key, data };
                    let comp = normalBar[0];
                    if (key === this.state.index) {
                        if (activeBarStyle) props.style = activeBarStyle;
                        else if (activeBar.length > 0) comp = activeBar[0];
                    } else {
                        props.onClick = this.slideTo.bind(this, key);
                    }
                    return this.renderUIItem(comp, context, props);
                })}
            </div>
        );
    };

    renderAct = () => {
        const { prevButton, nextButton, coverflowEffect, otherChild, coverEShadow = true, slidesPerView = 1 } = this.props;
        return this.panel((context) => {
            return (
                <>
                    <div
                        className={`swiper-js-box ${coverflowEffect ? "swiper-types-cover-one" : ""} ${!coverEShadow ? "no-shadow" : ""} ${
                            slidesPerView > 1 ? "auto-width" : ""
                        }`}
                        style={this.getStyle()}
                    >
                        <div className="swiper-container" ref={this.swiperRef}>
                            <div className="swiper-wrapper">{this.renderTabs(context)}</div>
                        </div>
                        {prevButton ? (
                            <div className="swiper-button-prev-self " ref={this.prevBtnRef}>
                                {this.renderChildren(prevButton)}
                            </div>
                        ) : null}

                        {nextButton ? (
                            <div className="swiper-button-next-self " ref={this.nextBtnRef}>
                                {this.renderChildren(nextButton)}
                            </div>
                        ) : null}
                        {this.renderBar(context)}
                    </div>
                    {otherChild
                        ? this.renderChildren(otherChild, {
                              onClick: this.slideTo,
                              index: this.state.index,
                          })
                        : null}
                </>
            );
        });
    };
}
