/**
 * Storage构造类型
 *
 * @enum {number}
 */
enum StorageType {
    /**
     * cookie存储
     */
    Cookie = 1,
    /**
     * LocalStorage存储
     */
    LocalStorage = 2,
    /**
     * SessionStorage存储
     */
    SessionStorage = 3,

    /**
     * url上参数获取
     */
    Url = 4,

    /**
     * 常量
     */
    Const = 5,

    /**
     * 常量
     */
    Cms = 6,
}

export default StorageType;
