import Config from "src/engine/const/Config";
// import Http from "src/lib/http/Http";
import IUIItem from "src/engine/interface/IUIItem";
import { message } from "antd";
import JsonCenter from "src/component/props/PropsExpend/JsonCenter";
import Handle from "src/component/source/SourceGitlib/Handle";
import IPConfig from "src/IPConfig";
import UIManifest from "src/engine/ui/UIManifest";

/**
 *
 *
 * @class PublicUIMgr
 */
class PublicUIMgr {
    public static PublicServer = "publicui";

    public static GameId = "0";

    public static UIManager = "uimanager";

    public static UITemp = "uitemp";

    public static Logic = "logic";

    public static LogicList: any[];

    private mPages: any = null;

    static maps: { [key: string]: IUIItem[] } = {};

    gameId: string;

    server: string;

    constructor(server: string = PublicUIMgr.PublicServer, gameId: string = PublicUIMgr.GameId) {
        this.server = server;
        this.gameId = gameId;
        // setTimeout(this.init, 5000);
        this.syncIcons();
    }

    syncIcons() {
        if (UIManifest.UIIconInit) return;
        UIManifest.UIIconInit = true;
        this.getItem("pages", PublicUIMgr.UIManager).then((data: any) => {
            try {
                data = typeof data === "string" ? JSON.parse(data) : data;
                const pages = data.props.pages;
                pages.map((uiItem: IUIItem) => {
                    if (uiItem.props._preview) UIManifest.UIIconMaps[uiItem.info.ename] = uiItem.props._preview;
                });
            } catch (error) {
                console.log("[PublicUIMgr]:syncIcons", error);
            }
        });
    }

    syncLogic(callback: any) {
        if (PublicUIMgr.LogicList) {
            setTimeout(() => {
                callback(PublicUIMgr.LogicList);
            });
            return;
        }
        this.getItem("pages", PublicUIMgr.Logic).then((data: any) => {
            try {
                data = typeof data === "string" ? JSON.parse(data) : data;
                const pages = data.props.pages;
                const list: any[] = [];
                pages.map((uiItem: IUIItem) => {
                    const { content = [] } = uiItem.props;
                    if (!content[0]) return;
                    const item = content[0];
                    item.info = uiItem.info;
                    item.props._preview = uiItem.props._preview;
                    list.push(item);
                });
                PublicUIMgr.LogicList = list;
                callback(PublicUIMgr.LogicList);
            } catch (error) {
                console.log("[PublicUIMgr]:", error);
            }
        });
    }

    sync(uiItem: any, other: any = {}) {
        // message.info("开始同步公共组件");
        return this.getItem("pages").then((data: any) => {
            try {
                this.mPages = typeof data === "string" ? JSON.parse(data) : data;
                let { pages = [] } = this.mPages.props;
                //新增也页面
                pages.push({ info: { ...uiItem.info, cname: uiItem.info.cname.replace("(布局层)", "") }, props: {}, ui: "PCPage", _id: 1 });
                pages = JSON.stringify(this.mPages);
                const addPage = JSON.stringify({
                    type: "pageInit",
                    page: { info: uiItem.info, props: { content: [uiItem], ...other }, ui: "PCPage", _id: 1 },
                });

                //生成一个git提交
                const handle = new Handle();

                handle.handleDataRight = (data: any) => {
                    if (data.data.Success === false) message.success("模板市场已存在同ename模块");
                    else message.success("成功添加到模板市场！！");
                };

                handle.exec(
                    {
                        actions: [
                            //修改pages.json
                            { action: "update", content: pages, file_path: "src/pages.json" },
                            //增加文件
                            { action: "create", content: addPage, file_path: `src/${uiItem.info.ename}.json` },
                        ],
                        branch: `${this.server}_${this.gameId}${IPConfig.release ? "_master" : ""}`,
                        commit_message: `提交公共组件${uiItem.info.cname}_${uiItem.info.ename}`,
                    },
                    {},
                    { argActions: true, domain: "http://git.web.jjweb.cn/", projectId: 292, repositoryId: 3 }
                );
            } catch (error) {
                console.log("[PublicUIMgr]:errrrr", error);
            }
        });
    }

    getItem(ui: string, server: string = this.server) {
        // return Http.ajaxJson({ url: Config.getConfig(server, this.gameId, ui) + `?time=${new Date().valueOf()}` });
        return new Promise((resolve, reject) => {
            JsonCenter.Instance.ajax(
                Config.getConfig(server, this.gameId, ui),
                (data: any, CODE: number) => {
                    if (CODE === 200) resolve(data);
                },
                false
            );
        });
    }
}

export default PublicUIMgr;
