function getName(url: string) {
    if (!url) return "";
    // url.replace(/(.*\/)*([^.]+).*/ig,"$2")
    return url.replace(/(.*\/)*([^.]+).*/gi, "$2") + "." + url.replace(/.+\./, "");
}

export function getFiles(value: string | string[] = []) {
    if (typeof value === "string") {
        if (value) value = value.split(",");
        else return [];
    }
    return value.map((url, key) => {
        const base: any = { name: getName(url) || "error.png", status: "done", uid: "-1_" + key };
        if (url) base.url = url;
        else base.status = "error";
        return base;
    });
}
