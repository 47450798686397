import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./mobile/App";
import "./index.scss";
import Es6Promise from "es6-promise";
import { JJMATCH_STATIC_PAGE } from "./lib/utils/Const";
import GameConfig from "./GameConfig";

Es6Promise.polyfill();

const win: any = window;
//处理ios手机下，屏幕双击，向上滑动的bug
const iosCheck = () => {
    const agent = navigator.userAgent.toLowerCase(); //检测是否是ios
    let iLastTouch: any = null; //缓存上一次tap的时间
    if (agent.indexOf("iphone") >= 0 || agent.indexOf("ipad") >= 0) {
        document.body.addEventListener(
            "touchend",
            (event: any) => {
                let iNow = new Date().getTime();
                iLastTouch = iLastTouch || iNow + 1 /** 第一次时将iLastTouch设为当前时间+1 */;
                let delta = iNow - iLastTouch;
                if (delta < 500 && delta > 0) {
                    event.preventDefault();
                    return false;
                }
                iLastTouch = iNow;
                return true;
            },
            false
        );
    }
};
iosCheck();

let rootId = "root";
if (win[JJMATCH_STATIC_PAGE]) {
    rootId = "root2";
}

if (win.Logger && win.Logger.setBaseUpOptions) {
    win.Logger.setBaseUpOptions({
        type: ["share", "net", "browser", "device", "sdk", "page"],
    });
    win.Logger.baseProfessionProps.activityId = `${GameConfig.server}_${GameConfig.gameId}`;
}

ReactDOM.render(<App />, document.getElementById(rootId) as HTMLElement);
