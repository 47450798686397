import UIManifest from "src/engine/ui/UIManifest";
import Handle from "./Handle";
import BaseSource from "../BaseSource";

@UIManifest.declareGG(["ggValue"], "SourceNet", "SourceNet组件", UIManifest.Type.Data)
class SourceNet extends BaseSource {
    getExec() {
        return new Handle();
    }
}

export default SourceNet;
