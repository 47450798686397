import * as React from "react";
import BaseComponent from "src/engine/ui/BaseComponent";
import IUIItem from "src/engine/interface/IUIItem";
import PanelContext, { IPanelContext } from "src/engine/context/PanelContext";
import Style from "src/lib/utils/Style";
import { IAllotItem } from "src/engine/ui/BaseUI";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import AutoContext from "src/engine/decorator/AutoContext";

export interface IHandle {
    type: "ui" | "data";
    /**
     * 属性key
     */
    key: string;
    /**
     * ui属性key
     */
    uiKey: string;

    getUIItem?: (item: any, index: number) => IUIItem | void;
    getUIProps?: (item: any, index: number) => IUIItem | void;

    /**
     * 在渲染组件的时候套壳
     */
    uiParent?: (data: any, child: any) => React.ReactNode | null;

    /**
     * 数据属性key
     */
    dataKey?: string;

    defaultValue?: any;

    props?: any;
}

interface IUIInject {
    ui: any;

    uiItem: IUIItem;

    handles: IHandle[];

    // forwardedRef?: any;

    /**
     * 数据分配
     */
    dataAllot?: { [key: string]: IAllotItem[] };

    [key: string]: any;
}
interface IState {
    mPanelContext?: IPanelContext;
}

class UIInject<T extends IUIInject, S = {}> extends BaseComponent<IUIInject, IState> {
    state: IState = {};

    /**
     * 黑名单
     */
    protected static BlackList = ["editId", "ggValue", "dataValue", "styleProps", "editing", "extStyle", "_renderUIItem", "gameId"];

    /**
     * 重写方法
     * @override
     * @param props
     */
    protected getUIProps(props: any) {
        const { ui, uiItem, handles = [], dataAllot, _updateKey, ...other } = props;
        const ret = { ...other };
        handles.map((handle: IHandle) => {
            //移除下uikey，不往下传递
            delete ret[handle.uiKey];
            //移除下数据字段，不往下传递
            if (handle.dataKey) delete ret[handle.dataKey];
            const item = this[handle.type](handle) || handle.defaultValue;
            if (item) ret[handle.key] = item;
        });
        return ret;
    }

    private getChildrenProps(allot: IAllotItem[] = [], props: any) {
        const ret = {};
        allot.map((item) => {
            if (Array.isArray(item.keyValue)) {
                ret[item.key] = this.getChildrenProps(item.keyValue, props);
            } else {
                try {
                    const keys = item.key.split(".");
                    ret[keys[keys.length - 1]] = PropsUtils.getValue(props, item.keyValue as string);
                } catch (error) {
                    console.log("[BaseUI]:error item", item, props);
                }
            }
        });
        return ret;
    }

    private renderUIItem(uiItem: IUIItem, props: any = {}, Parent?: (data: any, child: any) => React.ReactNode | null): any {
        const { dataAllot = {}, uiItem: parent } = this.props;
        //根据分配器分配数据
        const allot = dataAllot[uiItem.info.ename];
        if (allot) {
            props = { ...props, ...this.getChildrenProps(allot, this.props) };
        }
        if (this.mPanelContext) {
            const child = this.mPanelContext.renderUIItem(uiItem, props, parent);
            if (Parent) return Parent(props, child);
            else return child;
        } else return null;
    }

    /**
     * 渲染组件
     * @param key
     * @param props
     */
    ui(handle: IHandle) {
        const uiItems = this.props[handle.uiKey] || [];
        if (!Array.isArray(uiItems) || uiItems.length === 0) return;
        return uiItems.map((item: IUIItem, index: number) => {
            item = handle.getUIItem ? handle.getUIItem(item, index) || item : item;
            const props = handle.getUIProps ? handle.getUIProps(item, index) : {};
            return this.renderUIItem(item, { key: `${item.info.ename}_${index}`, index, ...handle.props, ...props }, handle.uiParent);
        });
    }

    /**
     * 根据data，ui渲染组件
     * @param handle 数据key
     */
    data(handle: IHandle) {
        const data = this.props[handle.dataKey || "data"] || [];
        if (!Array.isArray(data)) return;
        const uiItems = this.props[handle.uiKey] || [];
        if (!Array.isArray(data) || (!uiItems[0] && !handle.uiParent)) return;
        return data.map((item, index) => {
            const uiItem = handle.getUIItem ? handle.getUIItem(item, index) || uiItems[0] : uiItems[0];
            //判断是否为uiItem
            if (uiItem) {
                return this.renderUIItem(uiItem, { data: item, key: `_${index}`, ...handle.props }, handle.uiParent);
            } else if (handle.uiParent) {
                return handle.uiParent(item, index);
            }
        });
    }

    // /**
    //  * 绑定
    //  * @param context
    //  * @param key
    //  */
    // private consumer(context: any, key: string) {
    //     return (
    //         <context.context.Consumer key={key}>
    //             {(item: any) => {
    //                 this[`m${key}`] = item;
    //                 this.setState({ [`m${key}`]: item } as any);
    //                 return null;
    //             }}
    //         </context.context.Consumer>
    //     );
    // }

    private remove(props: any, black: string[] = []) {
        const ret = { ...props };
        black.map((item) => {
            delete ret[item];
        });
        // console.log("[UIInject]:", ret);
        return ret;
    }

    /**
     * 处理
     * @param oldStyle
     */
    private getStyle(oldStyle = {}) {
        const { style, extStyleString } = this.props as any;
        let { _styleList = [] } = this.props as any;
        if (extStyleString) _styleList = [..._styleList, ...(extStyleString || "").split(",")];
        let extStyle = {};
        _styleList.map((key: string) => {
            if (this.props[key]) extStyle = { ...oldStyle, ...this.props[key] };
        });
        return Style({ ...oldStyle, ...style, ...extStyle });
    }

    mPanelContext: IPanelContext;

    @AutoContext(PanelContext, "mPanelContext")
    render() {
        const { ui: UI, setRef } = this.props as any;
        return (
            <UI
                ref={(ref: any) => {
                    if (setRef) {
                        setRef(ref);
                    }
                }}
                {...this.remove(this.getUIProps(this.props as any), UIInject.BlackList)}
                style={this.getStyle()}
                {...Style.getMainStyle(this.props.uiItem.ui as string, this.props)}
            />
        );
    }
}

export default UIInject;
