import Handle from "./Handle";
import { IInfo } from "../ExecScript";

/**
 *
 * @class HandleAdd
 * 便捷添加props， 某些特定功能脚本不再通过边上填写name的方式添加props，方便使用
 *
 */
export default class HandleAdd extends Handle {
    exec(info: IInfo) {
        if (this.script.exec(info, this.execNext.bind(this))) {
            this.ret[info.name] = info.value;
            this.execNext(info);
        }
        return true;
    }
}
