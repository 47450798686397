import { IPublishType } from "./IPublishProps";

/**
 *
 *
 * @class TYml
 */
class Yml {
    static createYml(gameId: string, server: string, release: IPublishType, tarBranch?: string, isLocal?: boolean): string {
        return `variables:
    game_id: ${gameId}
    sname: ${server}
    ${tarBranch ? `br: ${tarBranch}` : ""}
    ${isLocal ? "not_cdn: 1" : ""}
    release: ${release === "master" ? 0 : 1}
    ${gameId == "1" && server === "cloudcms" ? "build_arg: build" : ""}
    
include:
  -  remote: https://webcloudplt.jjmatch.cn/CI_CD/templates/front_web_plt${tarBranch ? "2" : ""}.yml`;
    }

    static createTemplateYml(gameId: string, server: string, release: IPublishType, tarBranch?: string): string {
        return `variables:
    game_id: ${gameId}
    sname: ${server}
    ${tarBranch ? `br: ${tarBranch}` : ""}
    release: ${release === "master" ? 0 : 1}
    domain_pro: source_code
    
include:
  -  remote: https://webcloudplt.jjmatch.cn/CI_CD/templates/temp_web_plt.yml`;
    }

    static create121Template(gameId: string, server: string, release: IPublishType, tarBranch: string): string {
        return `variables:
    game_id: ${gameId}
    sname: ${server}
    br: ${tarBranch}
    release: ${release === "master" ? 0 : 1}
    CLOUD_TEMPLATE_PATH: template
    HOST_ARRAY_PROD_FRONT: 10.30.150.121
    domain_pro: source_code
    
include:
    -  remote: https://webcloudplt.jjmatch.cn/CI_CD/templates/temp_web_plt.yml`;
    }
}

export default Yml;
