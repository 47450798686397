import UIManifest from "src/engine/ui/UIManifest";
import Handle from "./Handle";
import BaseSource from "../BaseSource";

@UIManifest.declareGG(["ggValue"], "SourceArtical", "SourceArtical组件", UIManifest.Type.Data)
class SourceArtical extends BaseSource {
    getExec() {
        return new Handle();
    }
}

export default SourceArtical;
