import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import "./FlexArea.scss";
import IUIItem from "src/engine/interface/IUIItem";
import { IPanelContext } from "src/engine/context/PanelContext";
import UIManifest from "src/engine/ui/UIManifest";
import { EmojiImgMap } from "src/mobile/ui/emoji/BBSEmoji";
import Style from "src/lib/utils/Style";

interface IFlexArea extends IBaseUI {
    goButton: IUIItem[];
    data: any;
    value: any;
    childUI: IUIItem[];
    childWidth: string;
    minHeight: string;
    showCount: number;
    noData: string;
    hasIndex: string;
    indexPlus?: number;
    openChildren: IUIItem[];
    openCount: number;
    backgroundImage: string;
    onChange?: any;
    emoji: boolean; //表情相关处理
    page?: number;
    titleName: string; //title字段名
    startIndex: number;
    curClickIndex?: number;
    hasClickStatus?: boolean; //是否加点击的状态标识
    curIndex?: number;
    chooseChildren?: any;
    hasItemBox?: boolean;
    borderInner?: boolean;
    nodataChild?: any;
    hideParams?: any;
}
interface IState {
    curClickIndex?: number;
    page: number;
    type: string;
}

@UIManifest.declareGG(["ggValue", "dataValue"], "FlexArea")
export default class FlexArea<T extends IBaseUI, S = any> extends BaseUI<IFlexArea, IState> {
    constructor(props: IFlexArea) {
        super(props);
        this.state = { ...this.getDefaultState({ ...props }), curClickIndex: props.curIndex }; //设置当前点击的btn序号
    }

    getDefaultState({ openChildren = [] }: IFlexArea) {
        return { page: this.props.page || 1, type: openChildren.length > 0 ? "open" : "show" };
    }
    change = (data: any) => {
        if (!this.props.onChange) {
            return;
        }
        this.props.onChange(data);
    };
    //如果是多个btn，添加点击的状态点击
    changeClickIndex = (index: number) => {
        this.setState({
            curClickIndex: index,
        });
    };
    // getBorder=()=>{
    //     const {borderInner} = this.props;
    // }
    renderData(childUI?: IUIItem, data: any[] = []) {
        const {
            childWidth = "50%",
            lineCount = 0,
            hasClickStatus = false,
            hasIndex = "false",
            indexPlus = 0,
            emoji,
            titleName,
            hasItemBox = false,
        } = this.props as any;
        const widths = childWidth.split(",");
        if (!data.map) return null;
        return this.panel((context: IPanelContext) => {
            const list: any = [];
            let line: any = [];
            let count = 0;
            const { flexItemStyle } = Style.getMainStyle(this.props.uiItem.ui as string, this.props);
            const dataLength = data.length;
            data.map((item: any, index: number) => {
                if (typeof item === ("string" || "number" || undefined || null)) {
                    item = { value: item };
                } else if (hasIndex === "true") {
                    //往数据里面添加index值
                    // item = { ...item, index: index + 1 };
                    item.index = indexPlus ? index + 1 + Number(indexPlus) : index + 1;
                } else {
                }
                const props: any = {
                    data: { ...item, index },
                    index,
                    isLast: index === dataLength - 1,
                    key: index,
                    onChange: this.change,
                };
                if (emoji && EmojiImgMap[item.value || item]) {
                    const obj = EmojiImgMap[item.value || item];
                    props.gotoProps = obj[titleName];
                    props.className = titleName;
                    props.style = {};
                    props.onClick = this.change.bind(this, item.value || item);
                    props.style.backgroundPosition = `center -${obj.index * 0.56}rem`;
                }
                //添加多个按钮的点击的状态标识，可以标记展现点击选中或者未被选中的样式
                if (hasClickStatus) {
                    props.onClick = this.changeClickIndex.bind(this, index);
                    if (props.data) {
                        props.data.curClickedStatus = index === this.state.curClickIndex ? 1 : 0;
                    }
                }
                if (this.props["onClick"] && this.props["propsClick"]) {
                    props.onClick = this.props["onClick"];
                }
                // if (this.props.borderInner) {
                //     props = this.getBorderStyle(props, index, widths.length, this.getBorder(widths[index % widths.length], data.length, index));
                // }
                const itemChild = !hasItemBox ? (
                    <div className={`flex-area`} key={index} style={{ width: widths[index % widths.length], ...flexItemStyle }}>
                        {this.renderUIItem(childUI || item, context, props)}
                    </div>
                ) : (
                    this.renderUIItem(childUI || item, context, props)
                );

                if (lineCount > 0) {
                    count++;
                    line.push(itemChild);
                    if (line.length === lineCount || count === data.length) {
                        if (line.length < lineCount) line.push(<div className={`flex-area`} style={flexItemStyle} />);
                        list.push(<div className={`flex-area-line`}>{line}</div>);
                        line = [];
                    }
                } else {
                    list.push(itemChild);
                }
            });

            return list;
        });
    }
    getBorderStyle = (props: any, index: number, len: number, last: any) => {
        props.style = { border: "1px solid rgba(0, 0, 0, 0.1)", borderBottom: 0, borderRight: 0 };
        if (last === true) {
            props.style.borderBottom = "1px solid";
        }
        if (last === "last") {
            props.style.borderRight = "1px solid";
        }
        if (index % 3 === 0) {
            props.style.borderLeft = "0";
        }
        if (index === 0 || index === 1 || index === 2) {
            props.style.borderTop = "0";
        }
        return props;
    };
    //论坛首页特殊处理边框
    getBorder = (widths: string, len: number, index: number) => {
        const numbitem = Number(widths.replace("%", ""));
        if (!numbitem) return;
        const oneLineNum = Math.floor(100 / numbitem); //一行展示数量
        const lastNum = len % oneLineNum; //最后一行个数
        if (lastNum === 0) {
            return;
        }
        for (let i = oneLineNum; i > 0; i--) {
            if (index === len - i) {
                for (let k = lastNum; k > 0; k--) {
                    if (index !== len - k) {
                        //倒数第二行但是下一行对应位置没有块
                        return true;
                    } else {
                        //最后一行的块
                        return "last";
                    }
                }
            }
        }
        return false;
    };
    renderItem(data: any[]) {
        const { childUI = [], noData, showCount = 6, openCount = 6, startIndex = 0, nodataChild } = this.props;
        const { page } = this.state;
        const count = this.state.type === "show" ? Number(showCount) : openCount;
        let showData = data || [];
        //如果没有子元素
        if (childUI.length === 0) return null;
        if (startIndex && showData.length > 0) {
            showData = data.slice(startIndex);
        }

        if (noData === "false") {
            if (!data || !data.slice || data.length === 0) {
                if (nodataChild) return this.renderChildren(nodataChild);
                return null;
            }
            return this.renderData(childUI[0], showData.slice(Math.max(0, page - 1) * count, page * count));
        } else {
            return this.renderData(undefined, childUI.slice(0, count));
        }
    }

    onChange = (page: number, pageSize: number) => {
        this.setState({ page });
    };

    renderBottom = (data: any = []) => {
        const { goButton = [], showCount = 6, uiItem } = this.props;
        if (data.length <= 0 || goButton.length === 0) return null;
        // const item = goButton[0];
        const { dataSource = "" } = uiItem.props;
        return (
            <div className="bottom-btn-more">
                {this.renderChildren([goButton[0]], {
                    dataSource: "dataList.pagination",
                    tempKey: dataSource.replace("dataList.", ""),
                    pageSize: Number(showCount),
                })}
            </div>
        );
    };

    openItemChange = () => {
        this.setState({ type: this.state.type === "show" ? "open" : "show" });
    };

    openItem() {
        const { openChildren = [] } = this.props;
        if (openChildren.length > 0) {
            return this.renderChildren(openChildren, {
                onClick: this.openItemChange,
                data: { select: this.state.type },
            });
        }
        return null;
    }
    renderChoose = () => {
        const { chooseChildren, data = [] } = this.props;
        const { curClickIndex = 0 } = this.state;
        if (!chooseChildren || data.length === 0) return null;
        return this.renderChildren(chooseChildren, {
            data: data[curClickIndex],
            index: curClickIndex,
        });
    };
    getData = () => {
        const { hideParams, value, data, dataSource } = this.props;
        let item = value || dataSource || data;
        if (item && !Array.isArray(item) && item.data) {
            item = item.data;
        }
        const datas: any = [];
        if (hideParams && item?.length > 0) {
            item.map((i: any, index: number) => {
                if (i[hideParams] && i[hideParams] !== "false") {
                    datas.push(i);
                }
            });
        }
        return hideParams ? datas : item;
    };
    renderAct() {
        const { backgroundImage, minHeight } = this.props;
        const item = this.getData();
        // let item = value || dataSource || data;
        // if (item && !Array.isArray(item) && item.data) {
        //     item = item.data;
        // }
        return (
            <div className={`react-flex-layout`} style={this.getStyle({ backgroundImage, minHeight })}>
                {this.renderItem(item)}
                {this.renderChoose()}
                {this.openItem()}
                {this.renderBottom(item)}
            </div>
        );
    }
}
