import Utils from "./Utils";
import StyleManifest from "src/engineEditor/props/utils/StyleManifest";

export enum StyleSheet {
    bgImg = "backgroundImage",
    w = "width",
    h = "height",
    margin = "margin",
    minHeight = "minHeight",
}

const Style = (props: any = {}) => {
    props = { ...props };
    const { backgroundImage } = props;
    if (backgroundImage && backgroundImage.indexOf("url") <= -1 && backgroundImage.indexOf("linear-gradient") < 0) {
        props["backgroundImage"] = `url(${props["backgroundImage"]})`;
    }
    if (props["transform"]) {
        const trans = Utils.prefixStyle("transform") || "transform";
        props[trans] = props["transform"];
    }
    if (props["transition"]) {
        const trans = Utils.prefixStyle("transition") || "transition";
        props[trans] = props["transition"];
    }
    if (props.height === "window") {
        props.height = window.innerHeight;
    }
    if (props.width === "window") {
        props.width = window.innerWidth;
    }
    return props;
};

Style.getMainStyle = (ui: string | string[], props: any) => {
    const { theme } = props;
    let ret: any = {};
    let list;
    if (Array.isArray(ui)) list = ui;
    else list = [...(StyleManifest.Map[ui] || [])];

    if (props.styleProps) list.push("styleProps");
    list.map((item: any) => {
        const oKey = item.value || item;
        if (oKey === "style") return;
        let style = props[oKey] || {};
        for (const key in theme || {}) {
            if (theme[key]) {
                if (props[theme[key] + oKey]) style = { ...style, ...props[theme[key] + oKey] };
            }
        }
        ret[oKey] = style;
    });
    if (ret.styleProps) {
        ret = { ...ret, ...ret.styleProps };
        delete ret.styleProps;
    }
    // console.log("[getMainStyle]:", ui, StyleManifest.Map[ui] || [], ret, props);
    return ret;
};

export default Style;
