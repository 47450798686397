/* eslint-disable */
/**
 * t: current time（当前时间）；
 * b: beginning value（初始值）；
 * c: change in value（变化量）；
 * mC: final value (最后值)
 * d: duration（持续时间）。
 */
const tweenFunctions = {
    linear(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return (c * t) / d + b;
    },
    easeInQuad(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return c * (t /= d) * t + b;
    },
    easeOutQuad(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return -c * (t /= d) * (t - 2) + b;
    },
    easeInOutQuad(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        t /= d / 2;
        if (t < 1) {
            return (c / 2) * t * t + b;
        } else {
            return (-c / 2) * (--t * (t - 2) - 1) + b;
        }
    },
    easeInCubic(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return c * (t /= d) * t * t + b;
    },
    easeOutCubic(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return c * ((t = t / d - 1) * t * t + 1) + b;
    },
    easeInOutCubic(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        t /= d / 2;
        if (t < 1) {
            return (c / 2) * t * t * t + b;
        } else {
            return (c / 2) * ((t -= 2) * t * t + 2) + b;
        }
    },
    easeInQuart(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return c * (t /= d) * t * t * t + b;
    },
    easeOutQuart(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return -c * ((t = t / d - 1) * t * t * t - 1) + b;
    },
    easeInOutQuart(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        t /= d / 2;
        if (t < 1) {
            return (c / 2) * t * t * t * t + b;
        } else {
            return (-c / 2) * ((t -= 2) * t * t * t - 2) + b;
        }
    },
    easeInQuint(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return c * (t /= d) * t * t * t * t + b;
    },
    easeOutQuint(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
    },
    easeInOutQuint(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        t /= d / 2;
        if (t < 1) {
            return (c / 2) * t * t * t * t * t + b;
        } else {
            return (c / 2) * ((t -= 2) * t * t * t * t + 2) + b;
        }
    },
    easeInSine(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return -c * Math.cos((t / d) * (Math.PI / 2)) + c + b;
    },
    easeOutSine(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return c * Math.sin((t / d) * (Math.PI / 2)) + b;
    },
    easeInOutSine(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b;
    },
    easeInExpo(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return t === 0 ? b : c * Math.pow(2, 10 * (t / d - 1)) + b;
    },
    easeOutExpo(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return t === d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
    },
    easeInOutExpo(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        if (t === 0) {
            return b;
        }
        if (t === d) {
            return b + c;
        }
        t /= d / 2;
        if (t < 1) {
            return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
        } else {
            return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
        }
    },
    easeInCirc(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b;
    },
    easeOutCirc(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
    },
    easeInOutCirc(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        t /= d / 2;
        if (t < 1) {
            return (-c / 2) * (Math.sqrt(1 - t * t) - 1) + b;
        } else {
            return (c / 2) * (Math.sqrt(1 - (t -= 2) * t) + 1) + b;
        }
    },
    easeInElastic(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        let a, p, s;
        s = 1.70158;
        p = 0;
        a = c;
        t /= d;
        if (t === 0) {
            return b;
        } else if (t === 1) {
            return b + c;
        }
        if (!p) {
            p = d * 0.3;
        }
        if (a < Math.abs(c)) {
            a = c;
            s = p / 4;
        } else {
            s = (p / (2 * Math.PI)) * Math.asin(c / a);
        }
        return -(a * Math.pow(2, 10 * (t -= 1)) * Math.sin(((t * d - s) * (2 * Math.PI)) / p)) + b;
    },
    easeOutElastic(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        let a, p, s;
        s = 1.70158;
        p = 0;
        a = c;
        t /= d;
        if (t === 0) {
            return b;
        } else if (t === 1) {
            return b + c;
        }
        if (!p) {
            p = d * 0.3;
        }
        if (a < Math.abs(c)) {
            a = c;
            s = p / 4;
        } else {
            s = (p / (2 * Math.PI)) * Math.asin(c / a);
        }
        return a * Math.pow(2, -10 * t) * Math.sin(((t * d - s) * (2 * Math.PI)) / p) + c + b;
    },
    easeInOutElastic(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        let a, p, s;
        s = 1.70158;
        p = 0;
        a = c;
        t /= d / 2;
        if (t === 0) {
            return b;
        } else if (t === 2) {
            return b + c;
        }
        if (!p) {
            p = d * (0.3 * 1.5);
        }
        if (a < Math.abs(c)) {
            a = c;
            s = p / 4;
        } else {
            s = (p / (2 * Math.PI)) * Math.asin(c / a);
        }
        if (t < 1) {
            return -0.5 * (a * Math.pow(2, 10 * (t -= 1)) * Math.sin(((t * d - s) * (2 * Math.PI)) / p)) + b;
        } else {
            return a * Math.pow(2, -10 * (t -= 1)) * Math.sin(((t * d - s) * (2 * Math.PI)) / p) * 0.5 + c + b;
        }
    },
    easeInBack(t: number, b: number, mC: number, d: number, s: number) {
        const c = mC - b;
        if (s === void 0) {
            s = 1.70158;
        }
        return c * (t /= d) * t * ((s + 1) * t - s) + b;
    },
    easeOutBack(t: number, b: number, mC: number, d: number, s: number) {
        const c = mC - b;
        if (s === void 0) {
            s = 1.70158;
        }
        return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
    },
    easeInOutBack(t: number, b: number, mC: number, d: number, s: number) {
        const c = mC - b;
        if (s === void 0) {
            s = 1.70158;
        }
        t /= d / 2;
        if (t < 1) {
            return (c / 2) * (t * t * (((s *= 1.525) + 1) * t - s)) + b;
        } else {
            return (c / 2) * ((t -= 2) * t * (((s *= 1.525) + 1) * t + s) + 2) + b;
        }
    },
    easeInBounce(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        let v;
        v = tweenFunctions.easeOutBounce(d - t, 0, c, d);
        return c - v + b;
    },
    easeOutBounce(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        t /= d;
        if (t < 1 / 2.75) {
            return c * (7.5625 * t * t) + b;
        } else if (t < 2 / 2.75) {
            return c * (7.5625 * (t -= 1.5 / 2.75) * t + 0.75) + b;
        } else if (t < 2.5 / 2.75) {
            return c * (7.5625 * (t -= 2.25 / 2.75) * t + 0.9375) + b;
        } else {
            return c * (7.5625 * (t -= 2.625 / 2.75) * t + 0.984375) + b;
        }
    },
    easeInOutBounce(t: number, b: number, mC: number, d: number) {
        const c = mC - b;
        let v;
        if (t < d / 2) {
            v = tweenFunctions.easeInBounce(t * 2, 0, c, d);
            return v * 0.5 + b;
        } else {
            v = tweenFunctions.easeOutBounce(t * 2 - d, 0, c, d);
            return v * 0.5 + c * 0.5 + b;
        }
    }
};

module.exports = tweenFunctions;
