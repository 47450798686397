/*
 * @Author: wugz
 * @Date: 2018-10-25 17:35:01
 * @Last Modified by: wugz
 * @Last Modified time: 2018-12-18 19:49:38
 */

import * as React from "react";

/**
 *
 *
 * @class BaseComponent
 * @extends {React.Component<P, S>}
 * @template P
 * @template S
 */
class BaseComponent<P = {}, S = {}> extends React.Component<P, S> {
    // 定义一个存放方法的数组
    private lifes: Array<() => void>;

    public childComponentWillUnmount() {}

    constructor(props: Readonly<P>) {
        super(props);
        this.lifes = [];
    }

    private _calc(thisProps: any = {}, nextProps: any = {}, bool = false) {
        thisProps = thisProps === null ? {} : thisProps;
        nextProps = nextProps === null ? {} : nextProps;
        if (Object.keys(thisProps).length !== Object.keys(nextProps).length) {
            return true;
        }
        for (const key in nextProps) {
            if (typeof thisProps[key] !== "function" && thisProps[key] !== nextProps[key]) {
                return true;
            }
        }
        return false;
    }

    getType(msg?: string) {
        const nextProps: any = this.props;
        const ret = nextProps.uiItem && (nextProps.uiItem.ui === "Button" || nextProps.uiItem.ui === "Page");
        return ret;
    }

    public shouldComponentUpdate(nextProps: any = {}, nextState = {}) {
        // if (this.getType()) {
        // }
        if (this._calc(this.props, nextProps)) {
            // this.getType("****pROPS****");
            return true;
        }
        if (this._calc(this.state, nextState)) {
            // this.getType("****state****");
            return true;
        }

        // this.getType("****false falsefalse****");

        return false;
    }

    public componentWillUnmount(): void {
        //执行方法
        this.lifes.map((call: () => void) => {
            call();
            return call;
        });
        this.lifes = [];
        if (this.childComponentWillUnmount) {
            this.childComponentWillUnmount();
        }
    }

    /**
     * 延时器，在组件销毁时，自动销毁
     * @param callback
     * @param delay
     */
    setTimeout(callback: any, delay: number = 1) {
        const timer = setTimeout(() => {
            callback();
        }, delay);

        this.lifePush(() => {
            clearTimeout(timer);
        });
    }

    public componentDidCatch(err: any, info: any) {
        return false;
    }
    /**
     * 生命周期管理方法，在组件释放时调用
     * @param {*function} call 触发调用的方法
     */
    lifePush = (call: () => void) => {
        if (!this.lifes) {
            this.lifes = [];
        }
        this.lifes.push(call);
    };

    public render(): JSX.Element | null | any {
        return <div />;
    }
}

export default BaseComponent;
