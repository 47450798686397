/*
 * @@Author: Do not edit
 * @@Date: Do not edit
 * @@Last Modified by: Do not edit
 * @@Last Modified time: Do not edit
 */
export default class BridgeConfig {
    //连接桥中可监听的事件
    /**
     *
     *
     * @static
     * @memberof BridgeConfig
     */
    static Events = {
        //JsBridge初始化事件
        //回退事件
        BACK: "Back",
        INIT: "init",
        NACKBARCHANGE: "NackBarChange",
        SHOWBACK: "showBack",
    };

    //原生端回调注册
    static OriginListener = {
        Android: {
            //PC端会触发此方法
            CanCloseLobbyWindow: "CanCloseLobbyWindow",
            getPicData_Str: "getPicData_Str",
            lobby_notify_web: 'lobby_notify_web',
            //camera接口回调监听函数
            modify_result: "modify_result",

            //照片上传成功后的回调函数
            prepicsdata: "prepicsdata",
            receiveMessage: "receiveMessage", //rn回调函数
            screenCaptureCallback: "screenCaptureCallback",

            //
            share_on_resume: "share_on_resume",
            //
            //监听回退事件
            web_gotopage: "web_gotopage",
            web_url_reload: "web_url_reload",

            webview: {
                on_pause: "on_pause",
                on_resume: "on_resume",
            },

        },
        Ios: {
            getPicData_Str: "getPicData_Str",
            lobby_notify_web: 'lobby_notify_web',
            //camera接口回调监听函数
            modify_result: "modify_result",
            prepicsdata: "prepicsdata",
            receiveMessage: "receiveMessage", //rn回调函数
            screenCaptureCallback: "screenCaptureCallback",

            thread_post_callback: "thread_post_callback",
            web_gotopage: "web_gotopage",

            web_url_reload: "web_url_reload",
            //监听回退事件
        },
    };
    /**
     *
     *
     * @static
     * 1:大厅方法，2：小海豚方法
     * @memberof BridgeConfig
     */
    // 连接桥支持可调用的方法

    static ConfigInterfaces = {
        closeApp: {
            1: { method: "and_gotopage", argsSort: [{ name: "type", value: 1 }] },
            2: { method: "quitCommune" },
        },

        //获取得到的图片list
        getPicDataStr: { method: "getPicDataStr", event: "getPicData_Str" },
        //跳转url
        goUrl: { method: "and_gotopage", argsSort: [{ name: "type", value: 2 }, "url"] },
        //隐藏返回条
        hideBackBar: {
            1: { method: "and_gotopage", argsSort: [{ name: "type", value: 105 }] },
            2: { method: "setHideBack", argsSort: [{ name: "type", value: true }] },
        },
        //登录失败
        onLoginFail: "onLoginFail",
        //打开图库
        openPhotoLib: {
            1: { method: "openPhotoLib", argsSort: ["maxCount", "isOriginal", "needCut"], event: "prepicsdata" },
            2: {
                argsSort: ["maxCount", "isOriginal", "needCut", "isToPhList", "mode"],
                event: "prepicsdata",
                method: "openPhotoLib",
            },
        },

        //打开摄像头头像摄像头
        openCamera: { method: "openCamera", argsSort: ["needCut", "isOriginal"], event: "prepicsdata" },

        //设置标题
        setTitle: { method: "and_gotopage", argsSort: [{ name: "type", value: 3 }, "title"] },
        //分享
        share: {
            argsSort: ["uid", "params"],
            method: "share_json",
        },
        shareHT: {
            argsSort: ["params"],
            method: "onWebBackJson",
        },
        //以新开webView的方式跳转url
        startWeb: { argsSort: ["url", "title"], method: "startweb" },
    };
    /**
     * @static
     * 1:大厅方法，2：小海豚方法
     * 新连接桥支持可调用的方法
     * @memberof BridgeConfigNew
     */
    static ConfigInterfacesNew = {
        closeApp: {
            1: { argsSort: [{ name: "type", value: 1 }], method: "gotoPage" },
            2: { method: "quitCommune" },
        },

        //rn 关闭网页
        closeWebView: {
            method: "closeWebView",
        },

        //下载游戏
        //返回：gameId，-1,下载失败（msg:错误原因），11游戏未下载，20大厅不存在此游戏，12：游戏版本最新，13：游戏需要更新，14：下载暂停，15：等待安装，16：正在下载，17：等待下载（多个游戏下载时需处理），18：下载结束，19：安装中,最后会到12或0，即下载完成显示打开游戏
        downloadGame: {
            argsSort: ["gameId"],
            event: "downloadGame",
            method: "downloadGame",
        },

        //跳转到直播
        enLive: {
            argsSort: ["roomId"],
            event: "enLive",
            method: "enLive",
        },
        //进入网页（和leaveWebPage对应）
        enterWebPage: {
            event: "enterWebPage",
            method: "enterWebPage",
        },
        //获取得到的图片list
        getPicDataStr: { method: "getPicDataStr", event: "getPicData_Str" },

        //获取可分享的平台id
        //返回：params: {platrforms: [{"name":"WX","id":1},{"name":"QQ","id":3}, {"name":"Dolphin","id":5}]}
        getSharePlatforms: {
            event: "getSharePlatforms",
            method: "getSharePlatforms",
        },
        //跳转url
        goUrl: {
            argsSort: [
                { name: "type", value: 2 },
                { key: "param", value: "url" },
            ],
            method: "gotoPage",
        },
        //隐藏返回条
        hideBackBar: {
            1: { argsSort: [{ name: "type", value: 105 }], method: "gotoPage" },
            2: { argsSort: [{ name: "type", value: true }], method: "setHideBack" },
        },
        //游戏是否下载 返回：{params:{gameId,result:0:游戏不存在，1游戏未下载，2已下载}}
        isGameExist: {
            argsSort: ["gameId"],
            event: "isGameExist",
            method: "isGameExist",
        },
        //离开网页（和enterWebPage对应）
        leaveWebPage: {
            event: "leaveWebPage",
            method: "leaveWebPage",
        },
        //网络变化
        //{ netState: { connecttype: 0, WIFIstrength: -59 }, msgType: 4 }
        // WIFIstrength = 0; // 得到的值是一个0到-100的区间值，是一个int型数据，其中0到-50表示信号最好
        // { netState: { connecttype: 0, gsmstrength: 22 }, msgType: 4 }
        // gsmstrength = 0; // g网信号强度，0到31，值越高信号越好
        // connecttype 0：wifi 1：G网
        // msgType: 1断开连接
        // msgType: 2连接成功
        // msgType: 3连接失败
        // msgType: 4网络状态变化
        networkChange: {
            event: "networkChange",
            method: "networkChange",
        },
        //通知RN投票结果 返回：{sourceId:''投票资源id,choiceId://用户选中id}
        notifyVoteResult: {
            argsSort: ["choiceId", "sourceId"], //用户选中id,投票资源id
            event: "notifyVoteResult",
            method: "notifyVoteResult",
        },
        //登录失败
        onLoginFail: "onLoginFail",
        //打开摄像头头像摄像头
        openCamera: { argsSort: ["needCut", "isOriginal"], event: "prepicsdata", method: "openCamera" },
        //打开图库
        openPhotoLib: {
            1: { argsSort: ["maxCount", "isOriginal", "needCut"], event: "prepicsdata", method: "openPhotoLib" },
            2: {
                argsSort: ["maxCount", "isOriginal", "needCut", "isToPhList", "mode"],
                event: "prepicsdata",
                method: "openPhotoLib",
            },
        },
        //打开外部链接
        openUrl: {
            argsSort: ["url"],
            event: "openUrl",
            method: "openUrl",
        },
        //暂停大厅背景音乐
        pauseBg: {
            event: "pauseBg",
            method: "pauseBg",
        },
        //继续播放大厅背景音乐
        resumeBg: {
            event: "resumeBg",
            method: "resumeBg",
        },
        //rn 保存文件{params:{result:1//保存成功}}
        saveFile: {
            argsSort: ["url"],
            event: "saveFile",
            method: "saveFile",
        },
        //rn 保存图片 {params:{result:1//保存成功}}
        savePictureToCamera: {
            argsSort: ["url"],
            event: "savePictureToCamera", //发现回调不必再上面声明（都走一个回调，拆分处理在BridgeProvider中）
            method: "savePictureToCamera",
        },

        //设置标题
        setTitle: {
            argsSort: [
                { name: "type", value: 3 },
                { key: "param", value: "title" },
            ],
            method: "gotoPage",
        },
        //分享
        share: {
            argsSort: ["uid", "params"],
            method: "shareJson",
        },
        shareCommuneCard: { method: "shareCommuneCard" },
        shareHT: {
            addTag: true, //增加字段,
            argsSort: ["params"],
            method: "onWebBackJson",
            // isToString: true //onWebBackJson和gotoLobby方法参数要通过toString处理
        },
        //发现版分享（qq,微信，海豚）
        //参数：type：微信：1 朋友圈2，  QQ:3  dolphin:5），title标题，url链接，imageurl图，text简介内容
        //返回：result:false,msg错误信息
        shareToPlatform: {
            argsSort: ["type", "title", "url", "imageurl", "text"], //id：转换为url上参数，处理完会被删除,imgHeader:处理完删除
            event: "shareToPlatform",
            method: "shareToPlatform",
        },
        //报名比赛
        //返回：gameId，matchId。message（大厅给不了状态码，只给了message）
        signUp: {
            argsSort: ["gameId", "matchId"],
            event: "signUp",
            method: "signUp",
        },
        startChat: { argsSort: ["userId", "nickname"], method: "startChat" },
        //启动游戏
        //返回：gameId，message
        startGame: {
            argsSort: ["gameId"],
            event: "startGame",
            method: "startGame",
        },
        //以新开webView的方式跳转url
        startWeb: { argsSort: ["url", "title"], method: "startWeb" },
        //以新开webView的方式跳转url
        startWebNew: { argsSort: ["url", "title", "hideback"], method: "startWebNew" },
        //打开rn视频详情页
        webOpenVideo: {
            // argsSort: ["i", 'title', 'images'],
            event: "webOpenVideo",
            method: "webOpenVideo",
        },
        //主题变化
        webThemeMode: {
            event: "webThemeMode",
            method: "webThemeMode",
        },

        //页面加载成功通知rn去掉loading显示网页
        webPageLoadResult: {
            event: "webPageLoadResult",
            method: "webPageLoadResult",
        },
    };
}
