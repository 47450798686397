import React, { Component } from "react";
import PropTypes from "prop-types";

import ListContainer from "./ListContainer";
// import Pointer from "./Pointer";
import "./WrapViewer.scss";

const screenWidth = document && document.documentElement && document.documentElement.clientWidth;
// const screenHeight = document && document.documentElement && document.documentElement.clientHeight;

interface IWrapViewer {
    index: number;
    urls?: string[];
    maxZoomNum?: number;
    zIndex?: number;
    backgroundImage?: string;
    gap?: number;
    speed?: number;
    onClose?: any;
    otherComp?: any;
    scrollSensity?: number; // 左右滑动灵敏度  取值：[0, 0.45)
    renderImgindex?: any;
    style?: any;
    coverStyle?: any;
    renderChild?: any;
    imgKey?: string;
}

class WrapViewer extends Component<IWrapViewer> {
    static propTypes = {
        index: PropTypes.number.isRequired, // 当前显示图片的http链接
        urls: PropTypes.array.isRequired, // 需要预览的图片http链接列表
        maxZoomNum: PropTypes.number, // 最大放大倍数
        zIndex: PropTypes.number, // 组件图层深度
        gap: PropTypes.number, // 间隙
        speed: PropTypes.number // Duration of transition between slides (in ms)
    };

    static childContextTypes = {
        onClose: PropTypes.func
    };

    getChildContext() {
        return { onClose: this.props.onClose };
    }

    static defaultProps = {
        maxZoomNum: 2,
        zIndex: 100,
        index: 0,
        gap: 10,
        speed: 300
    };

    state = {
        index: 0
    };

    componentDidMount() {
        const { index } = this.props;

        this.setState({
            index
        });
    }

    changeIndex = (index: number, type?: string) => {
        const nowIndex = this.state.index;
        const { urls = [] } = this.props;
        if (type === "+") {
            index = Math.min(nowIndex + 1, urls.length - 1);
        } else if (type === "-") {
            index = Math.max(nowIndex - 1, 0);
        }
        this.setState({
            index
        });
    };

    render() {
        const {
            renderChild,
            coverStyle,
            style,
            zIndex,
            urls = [],
            maxZoomNum,
            gap,
            speed,
            otherComp,
            backgroundImage,
            scrollSensity = 0.3,
            imgKey
        } = this.props;

        const { index } = this.state;

        const styles = { ...style, zIndex };
        if (backgroundImage) style["backgroundImage"] = `url(${backgroundImage})`;
        return (
            <div className="wx-image-viewer" style={styles}>
                {/* root */}
                <div className="viewer-cover" style={coverStyle} />
                <ListContainer
                    screenWidth={screenWidth}
                    screenHeight={document && document.documentElement && document.documentElement.clientHeight}
                    changeIndex={this.changeIndex}
                    imgKey={imgKey}
                    urls={urls}
                    maxZoomNum={maxZoomNum}
                    gap={gap}
                    speed={speed}
                    index={index}
                    scrollSensity={scrollSensity}
                />
                {/* <Pointer length={urls.length} index={index} changeIndex={this.changeIndex} /> */}
                {this.renderIndex(index, urls)}
                {renderChild && renderChild({ changeNext: this.changeIndex.bind(this, "", "+"), changeUp: this.changeIndex.bind(this, "", "-") })}
                {/* <div className="save-btn" onClick={this.saveActive}>保存</div> */}
                {urls[index] && otherComp && otherComp(urls[index])}
            </div>
        );
    }
    renderIndex = (index: any, urls: any = []) => {
        const { renderImgindex } = this.props;
        if (renderImgindex) {
            return renderImgindex(index, urls);
        } else {
            return <div className="number">{`${index + 1}/${urls.length}`}</div>;
        }
    };
}

export default WrapViewer;
