import IUIItem from "src/engine/interface/IUIItem";

export enum IGuiType {
    /**
     * 正常处理逻辑
     */
    Normal = "0",
    /**
     * 分配数据
     */
    DataAllot = "1",
    /**
     * UI检测
     */
    UICheck = "2",
}

export const IGuiTypeName = {
    [IGuiType.Normal]: "逻辑处理",
    [IGuiType.DataAllot]: "分配数据",
    [IGuiType.UICheck]: "UI检测",
};

export interface IGuiSavaData {
    /**
     * 节点
     */
    nodes: { [key: string]: IUIItem };
    /**
     * 边
     */
    edges: { [key: string]: IUIItem };
    /**
     * 关系
     */
    relation: { [key: string]: string[] };
    /**
     * start节点
     */
    start: string[];
}

export interface IGuiData {
    ret: IGuiSavaData;
    endKey?: string;
    type: IGuiType;
}

/**
 *
 *
 * @class IExecConfig
 */
class IExecConfig {
    /**
     * 起始节点id
     */
    id: string;
    //初始的propsName
    propName?: string;
    name: string;
    /**
     * 数据分配时使用
     */
    toName?: string;
    value?: any;

    destory: boolean;
}

export default IExecConfig;
