import IGameInfo from "src/engine/interface/IGameInfo";
import AppRoot from "src/engine/pages/source/AppRoot";
import ToastSource from "src/engine/interface/ToastSource";
import { QUERY_FAIL_CODE } from "src/engine/SourceCenter/utils/Net";
import { IRenderType } from "src/engine/context/PanelContext";

export default class TempManager extends ToastSource {
    mApp: AppRoot;

    mIsEdit: boolean = false;

    constructor(gameInfo: IGameInfo, renderType: IRenderType = "app", other?: any) {
        super();
        this.mIsEdit = renderType !== "app";

        //初始化一个页面Root管理器
        this.mApp = new AppRoot(gameInfo, renderType, { ...other, ...this.initProps() });
        //初始化source数据
        this.data = [this.mApp.data()];
        //不触发
        this.init = true;
    }

    initProps() {
        return {};
    }

    // @Annotation.log()
    handleData = (data: any, dataSource: any = {}, headers: any = {}) => {
        // if (headers && headers.path) {
        //     this.mApp.pageHeader = headers.path;
        // }
        // console.log('[TempManagerExt] handleDataExt', data);
        //判断是为新项目
        if (data.CODE === 404 && headers.path === "pages") {
            // data = {};
            data = { type: "pagesInit", props: { pages: [], init: true } };
        }
        if (data.CODE === QUERY_FAIL_CODE && !headers.noJsonToast) {
            setTimeout(() => {
                super.message((data.res && data.res.MSG) || data.MSG, false);
            });
            return false;
        }
        let cache;
        if (typeof data === "string") {
            data = JSON.parse(data);
        }
        if (this.mIsEdit) {
            cache = JSON.stringify(data);
        }
        if (!data.type) return false;

        data = this.handleDataExt(data);

        return [this.mApp.handle(data.type, data, cache)];
    };

    handleDataExt(data: any) {
        return data;
    }
}
