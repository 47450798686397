import * as React from "react";
import UILoadingManifest from "src/components/UILoadingManifest";
import UIManifest from "./UIManifest";

interface IUILoad {
    _ui: string;
}

class UILoad extends React.Component<IUILoad> {
    state = {
        ui: false
    };

    componentDidMount() {
        const { _ui } = this.props;

        const list: any = UILoadingManifest(_ui);
        if (list === null) {
            if (_ui !== "Props")
                console.log(`[UILoad.${_ui}.组件未声明]:`, _ui, UIManifest.maps);
            return;
        }

        list.then((bar: any) => {
            // console.log(`[UILoad.${_ui}.加载成功]:`);
            this.setState({ ui: !this.state.ui });
        });
    }

    render() {
        const { _ui, ...other } = this.props;

        const ui = UIManifest.maps[_ui];
        if (!ui) return null;
        const UI: any = (ui as any).ui;
        return <UI {...other} />;
    }
}

export default UILoad;
