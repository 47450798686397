import * as React from "react";
import IUIItem from "src/engine/interface/IUIItem";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";

interface IFormItem extends IBaseUI {
    /** form项 */
    // form: any;
    /** item项 */
    // uiItem: IUIItem;
    /** 是否显示 */
    show?: boolean;
    /** 是否显示标识 */
    // showLabel?: boolean;
    showLabel?: boolean;
    /** 标识 */
    label?: string;
    /** 排版布局 */
    layout?: "vertical" | "horizontal" | "inline";
    /** 显示lable */
    showCname?: boolean;
    /** 是否必须 */
    required?: boolean;
    /** 是否隐藏必须标识 */
    hideRequiredMark?: boolean;
    /** 错误信息 */
    error?: any;
    /** 错误样式 */
    errorStyle?: any;
    /** item样式 */
    itemStyle?: any;
    /** 组件间传递的 ref **/
    formItemRef?: any;
    /** 组件扩展 */
    titleExt: IUIItem[];
    /** 描述 */
    describe?: string;
}
interface IState {}

class FormItem extends BaseUI<IFormItem, IState> {
    renderLabel() {
        const { label, labelStyle, required, hideRequiredMark, labelAlign, labelPrex = "：", titleExt = [], describe } = this.props as any;
        //如果显示为false
        if (!this.showLabel()) return null;
        // console.log('[FormItem]:-renderLabel-----', labelStyle);
        return (
            <div style={labelStyle} className={`title ${labelAlign} ${required && !hideRequiredMark ? "required" : ""}`}>
                {label}
                {this.renderChildren(titleExt, { label, describe })}
                {labelPrex}
            </div>
        );
    }

    showLabel() {
        const { showLabel = true, showCname = true } = this.props;
        return !(!showLabel || !showCname);
    }

    renderControl() {
        const { children } = this.props as any;
        let { wrapperStyle } = this.props as any;
        // console.log('[FormItem]:---renderControl---', wrapperStyle);
        if (!this.showLabel()) wrapperStyle = { ...wrapperStyle, width: `100%` };
        const error = this.getError();
        return (
            <div style={wrapperStyle} className={`control ${error ? "error" : ""}`}>
                {children}
                {this.renderError(error)}
            </div>
        );
    }

    renderError(error: any) {
        const { errorStyle } = this.props;
        // if (!error || typeof error === "object" &&Array.isArray(error)) return null;
        if (!error) return null;

        return (
            <div className="error" style={errorStyle}>
                {error}
            </div>
        );
    }

    getError() {
        const { error } = this.props;
        // if (!error || typeof error === "object" &&Array.isArray(error)) return null;
        if (!Array.isArray(error)) return;
        return error;
    }

    render() {
        const { itemStyle, show = true, layout, formItemRef } = this.props;
        if (show === false) return null;
        return (
            <div className={`react-form-item ${layout}`} {...{ layout }} style={itemStyle} ref={formItemRef}>
                {/* 渲染，label */}
                {this.renderLabel()}
                {/* 渲染，组件层 */}
                {this.renderControl()}
            </div>
        );
    }
}

export default FormItem;
