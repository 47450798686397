/*
 * @Author: quanyj
 * @Date: 2018-06-21 18:14:20
 * @Last Modified by: zhanghao
 * @Last Modified time: 2020-06-16 18:28:39
 */

import Cookie from "./Cookie";
import StorageType from "./StorageType";
import Url from "./Url";
import Const from "./Const";
import CmsPProps from "./CmsPProps";

export default class Storage {
    private storage: any;

    static StorageType = StorageType;

    constructor(type: StorageType = StorageType.Cookie) {
        this.storage = this.getStorage(type);
        // console.log('[Storage]:', this.storage, type);
    }

    /**
     *
     * @param name
     * @param value 设置(如果不传value，则默认为移除数据数据)
     * @param options
     */
    public set(name: string, value?: string | number, options?: any) {
        if (!this.storage) {
            return;
        }
        try {
            if (value === undefined) {
                this.storage.removeItem(name, options);
            } else {
                this.storage.setItem(name, value, options);
            }
        } catch (e) {
            // console.log(e);
        }
    }

    /**
     * 获取存储数据
     * @param {*} name
     * @param {*} defaultValue 默认值
     */
    get(name: string, defaultValue?: string | number | undefined, options?: any) {
        if (!this.storage) {
            return;
        }
        const ret = this.storage.getItem(name, undefined, options);
        if (ret === void 0 || ret === null) {
            return defaultValue;
        }
        return ret;
    }

    private getStorage(type: StorageType) {
        const { localStorage, sessionStorage } = window;
        switch (type) {
            case StorageType.SessionStorage:
                return sessionStorage;
            case StorageType.Cookie:
                return Cookie;
            case StorageType.Url:
                return Url;
            case StorageType.Const:
                return Const;
            case StorageType.Cms:
                return CmsPProps;
            case StorageType.LocalStorage:
            default:
                return localStorage;
        }
    }
}
