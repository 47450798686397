class CmsPProps {
    /**当前页面信息 */
    static curPageProps: any = {};

    static getItem(name: string) {
        return CmsPProps.getCmsProps()[name];
    }

    static getCmsPropsKeys() {
        return {
            activityId: "",
            gameId: "",
            sname: "",
            projectId: "",
        };
    }

    static getCmsProps() {
        const { gameId, sname } = CmsPProps.curPageProps || ({} as any);
        // const ret = {
        //     activityId: `${sname}_${gameId}`,
        //     gameId,
        //     sname: GameConfig.server,
        //     ...CmsPProps.curPageProps,
        //     // projectId: "appsclub.jj.cn_未定义项目名称",
        //     // activityId: "club",
        // }
        const ret: any = { ...CmsPProps.curPageProps };
        //自动生成activityId
        if (!ret.activityId && gameId && sname) ret.activityId = `${sname}_${gameId}`;
        //兼容为空的情况
        if (ret.projectId === "") {
            delete ret.projectId;
        }
        // console.log('[CmsPProps]:', sname, ret);
        //适配旧项目有projectId，无activityId的情况
        if (ret.projectId) {
            ret["activityId"] = "";
        }

        return ret;
    }

    static setItem(name: string, value: any) {}

    static removeItem(name: string) {}
}

export default CmsPProps;
