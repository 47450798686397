import IUIItem from "../interface/IUIItem";
// import { Source } from "../interface/Source";
import Config from "../const/Config";
import Utils from "src/lib/utils/Utils";
import GameConfig from "src/GameConfig";
import { Source } from "../interface/Source";
import { METHOD, CONTENT_TYPE } from "src/lib/http/HttpEmun";
import ToastSource from "../interface/ToastSource";
import ModuleManager from "../pages/source/ModuleManager";
import ModuleQuery from "../pages/source/ModuleQuery";
import { QUERY_FAIL_CODE } from "../SourceCenter/utils/Net";
import Down from "src/components/DownGame/down";
import { StatType, ITotalSourceParams } from "../pages/ui/Page";
import Cookie from "src/lib/utils/storage/Cookie";
import IPConfig from "src/IPConfig";
import ISource from "../interface/ISource";

const initProps = (source: any, props: any, init: any = {}) => {
    const keys = Object.keys(props);
    keys.map((key) => {
        if (typeof props[key] === "undefined") {
            if (typeof init[key] !== "undefined") source[key] = props[key];
        } else {
            source[key] = props[key];
        }
    });
};

/**
 *
 *
 * @class SourceEngine
 */
class SourceEngine {
    static createByModules(modules: IUIItem[] = [], moduleNum = 10) {
        const maps: { [key: string]: ISource } = {};
        modules.map((module) => {
            const ename = module.info.ename;
            maps[ename] = { ename, headers: { pageSize: moduleNum, ...((module.props || {}).headers || {}) } };
        });
        return maps;
    }

    static chooseStaticFnc(uiItem: IUIItem): string {
        // 网络模块
        if (uiItem.props.url) return "createNet";
        // 连接桥
        else if (uiItem.props.bridgeType) return "createBridge";
        // 下载游戏
        else if (uiItem.props.downType) return "createDownGame";
        //utils
        else if (uiItem.props.isUtils) return "createUtils";
        //cookie
        else if (uiItem.ui === "SourceCookie") return "createCookie";
        else if (uiItem.ui === "SourceStore") return "createSource";

        return "createLocal";
    }

    static createSource(uiItem: IUIItem, intent?: any) {
        const source = new Source();
        initProps(source, uiItem.info);
        source.uiItem = uiItem;
        return source;
    }

    static create(uiItems: IUIItem[], flag: boolean = true, intent?: any, totalParams?: ITotalSourceParams[]) {
        let sources: any = {};
        if (!flag) {
            sources = {
                dataList: new ModuleManager(),
                syncModuleList: new ModuleQuery({
                    server: GameConfig.server,
                    gameId: GameConfig.gameId,
                    cname: GameConfig.server,
                }),
            };
        }
        const totalParamsMap = {};
        if (totalParams && totalParams.length > 0) {
            for (const item of totalParams) {
                const { sources: sourcesString, label, value } = item;
                if (!sourcesString || !label || !value) {
                    continue;
                }
                const sourceArray = sourcesString.split(",");
                sourceArray.map((ename: string) => {
                    if (!totalParamsMap[ename]) {
                        totalParamsMap[ename] = {};
                    }
                    totalParamsMap[ename][label] = value;
                });
            }
        }
        uiItems.map((uiItem) => {
            if (uiItem) {
                const ename = uiItem.info.ename;
                const fnName = SourceEngine.chooseStaticFnc(uiItem);
                if (fnName === "createNet" && totalParamsMap[ename]) {
                    sources[ename] = SourceEngine.createNet(uiItem, intent, totalParamsMap[ename]);
                } else {
                    sources[ename] = SourceEngine[fnName](uiItem, intent);
                }
            }
        });

        //是否更新intent参数
        // if (intent) {
        const intentItem: IUIItem = {
            info: { ename: "intent", cname: "页面参数" },
            ui: "",
            props: { data: intent || {} },
        };
        sources[intentItem.info.ename] = SourceEngine.createLocal(intentItem);
        const utilsItem: IUIItem = {
            info: { ename: "utils", cname: "url上所带参数" },
            ui: "",
            props: { data: intent || {} },
        };
        sources[utilsItem.info.ename] = SourceEngine.createUtils(utilsItem);
        //cookie数据
        const cookieItem: IUIItem = {
            info: { ename: "cookie", cname: "cookie参数" },
            ui: "",
            props: { data: intent || {} },
        };
        sources[cookieItem.info.ename] = SourceEngine.createCookie(cookieItem);
        return sources;
    }

    static createNet(uiItem: IUIItem, intent?: any, totalParams?: any) {
        const {
            onlineUrl,
            method = METHOD.POST,
            dataType = CONTENT_TYPE.JSON,
            notSetGameId,
            notSetSname,
            sname = GameConfig.server,
            jsonFile = "false",
            pageAll = "false",
            cache = "no-store",
            removeSame = false,
            cacheSize = 150,
            sameKey = "",
            addTimes = false,
            stat = false,
            single = true,
            useLocal = false,
            localSaveNum = 15,
            localSaveFlag = "",
            listKey = "list",
            postDown = false,
            downType = "",
            timeout,
            headers = {},
            ...other
        } = uiItem.props;
        let { url } = uiItem.props;
        if (onlineUrl && IPConfig.release) {
            url = onlineUrl;
        }
        if (other.toast && Object.keys(other.toast).length === 0) {
            delete other.toast;
        }
        const source: any = other.toast ? new ToastSource() : new Source();
        const saveLocalName = `SAVE_STORAGE_${url}_${localSaveFlag}_${
            Cookie.getItem("User_Id") || Cookie.getItem("visitorId") || (localStorage && localStorage.getItem("visitorId")) || "none"
        }`;
        initProps(source, uiItem.info);
        if (other.init === "true") other.init = true;
        else other.init = false;
        initProps(source, { ...other, headers: { ...headers, ...totalParams } });
        if (url) {
            const toUrl = url.indexOf("http") === 0 ? url : Config.API + url;
            source.ajax = {
                url: `${toUrl}${toUrl.indexOf("?") > -1 ? "&" : "?"}`,
                method,
                cache,
                single,
                dataType,
            };
            if (addTimes) source["addTimes"] = true;
            if (postDown) source["postDown"] = true;
            if (downType) source["downType"] = downType;
            if (timeout) source.ajax.timeout = timeout;
            if (!notSetSname) {
                source.ajax.url = `${source.ajax.url}sname=${(sname || GameConfig.server).replace("PC", "")}`;
            }
            if (!notSetGameId) {
                source.ajax.url = `${source.ajax.url}&gameId=${GameConfig.gameId}`;
            }

            if (method === METHOD.POST && totalParams) {
                const paramsArray = Object.keys(totalParams).map((key: string) => `${key}=${totalParams[key]}`);
                source.ajax.url = `${source.ajax.url}&${paramsArray.join("&")}`;
            }
        }

        if (jsonFile !== "false") {
            source.handleData = (datas: any, oldData?: any, header?: any) => {
                if (datas && datas.CODE !== QUERY_FAIL_CODE) {
                    return source.handleDataRight(datas, oldData, header);
                } else {
                    source.message("无数据", false, -1);
                    return { loading: false, error: true, CODE: QUERY_FAIL_CODE };
                }
            };
        }

        source.handleDataRight = (dat: any, oldData: any, header: any = {}, statFunc: any) => {
            const dataparam = dat || ({} as any);
            let { list } = dataparam;
            if (!list) list = dataparam[listKey];
            if (Array.isArray(dat)) list = dat; //
            if (dat && list) {
                let newListNum = 0;
                let end = false;
                if (list.length < (other.pageSize || other.count || 10)) {
                    end = true;
                }

                if (stat && statFunc && list.length > 0) {
                    const artId = list.map((item: any) => {
                        return item.i;
                    });
                    statFunc({ artId }, StatType.Exposure);
                }
                if (removeSame && !oldData && useLocal) {
                    // 使用本地缓存第一次进入
                    try {
                        const storage = window.localStorage.getItem(saveLocalName);
                        if (storage) {
                            oldData = JSON.parse(storage);
                        }
                    } catch (e) {}
                }
                if (removeSame && oldData) {
                    list = Utils.arrayRemoveSame(list, oldData, sameKey);
                    newListNum = list.length;
                }
                if (pageAll === "true" && oldData) {
                    const start = header.start || header.pageNum;
                    if (start > 1) {
                        if (!Array.isArray(oldData)) oldData = [];
                        list = oldData.concat(list);
                        if (removeSame && newListNum === 0) {
                            end = true;
                        }
                    } else if (header.start === 1 && removeSame) {
                        list = list.concat(oldData);
                    }
                }
                const returnMap: any = { data: list, total: dat.total || dat.totalNum, end };
                if (removeSame && header.start === 1) {
                    returnMap.newListNum = newListNum;
                }
                if (removeSame && useLocal) {
                    try {
                        window.localStorage.setItem(saveLocalName, JSON.stringify(list.slice(0, localSaveNum)));
                    } catch (e) {}
                }
                return returnMap;
            } else {
                if (stat && Array.isArray(dat.rl) && dat.rl.length > 0) {
                    const artId = dat.rl.map((item: any) => {
                        return item.i;
                    });
                    statFunc({ artId }, StatType.Exposure);
                }
                if (dat === 0 || dat === false) {
                    return dat + "";
                } else {
                    return dat || {};
                }
            }
        };
        source.handleHeaders = (header: any, dataSource?: any) => {
            // header = { pageSize: 10, pageNum: 1, ...header };
            if (header.start) header.pageNum = header.start;
            if (header.count) header.pageSize = header.count;

            if (header && header.attachments) {
                if (!source.attachmentsChangeToString) {
                    if (typeof header.attachments !== "string") {
                        header.attachments = JSON.stringify(header.attachments);
                    }
                }
            }
            return Utils.checkObjectKey(header);
        };
        return source;
    }

    static createLocal(uiItem: IUIItem, intent?: any) {
        const { data, initData } = uiItem.props;
        const source = new Source();
        source.islocal = true;
        initProps(source, uiItem.info);
        initProps(source, uiItem.props);
        //将list数据转为map
        if (data) {
            source.data = data;
        }
        if (initData) {
            source.data = { ...source.data, ...initData };
        }
        source.handleData = (dat: any, oldData: any, header: any = {}) => {
            if (dat) {
                return { ...source.data, ...oldData, ...dat };
            } else {
                return { ...source.data, ...oldData };
            }
        };
        if (source.dataSource) {
            source.handleHeaders = (headers: any, dat: any) => {
                // console.log(`[jobChange - handleHeaders]`, headers, dat);
                if (Utils.isArray(dat)) {
                    return [...dat, ...headers];
                }
                return { ...dat, ...headers };
            };
        }
        return source;
    }

    static createBridge(uiItem: IUIItem, intent?: any) {
        const { toast, utilsData, data = {} } = uiItem.props;
        const source: any = toast ? new ToastSource() : new Source();
        source.islocal = true;
        initProps(source, uiItem.info);
        initProps(source, uiItem.props);
        source.data = data;
        if (utilsData) {
            utilsData.map((item: any) => {
                source.data[item.label] = Utils[item.value](item.key);
            });
        }
        //处理toast
        source.handleData = (bridgeData: any, oldData: any, header: any = {}) => {
            if (toast) {
                const { result } = bridgeData;
                if (result && toast[result]) {
                    source.message(toast[result], false, result);
                }
            }
            if (bridgeData) {
                return { ...source.data, ...oldData, ...bridgeData };
            } else {
                return { ...source.data, ...oldData };
            }
        };
        return source;
    }

    static createDownGame(uiItem: IUIItem, intent?: any) {
        const { downType = "click", andrIn4GPlace, andrInWifiPlace, andrInWechatApp, ipadUrl, iphUrl } = uiItem.props;
        const source: any = new Source();
        source.islocal = true;
        const down = new Down({ debug: true });
        source.downCtrl = down;
        source.headers = { andrIn4GPlace, andrInWifiPlace, andrInWechatApp, ipadUrl, iphUrl, downType, query: intent };
        source.handleData = () => {};
        if (downType !== "click") {
            down.active({ andrIn4GPlace, andrInWifiPlace, andrInWechatApp, ipadUrl, iphUrl });
        }
        return source;
    }

    static createUtils(uiItem: IUIItem, intent?: any) {
        const { data, cookieKey } = uiItem.props;
        const source = new Source();
        source.islocal = true;
        initProps(source, uiItem.info);
        initProps(source, uiItem.props);
        //将list数据转为map
        if (data) {
            source.data = data;
        }
        const utilsData = {
            isIos: Utils.getIos(),
            //     isWifi: Utils.isWIFI(),
            isMobile: Utils.isMobile(),
            os: Utils.getDevice(),
            inWeChat: Utils.inWeChat(),
            inQQ: Utils.inQQ(),
            lowDevice: Utils.getLowDevice(),
            gameId: GameConfig.gameId,
            server: GameConfig.server,
        };
        // if (utilsKey) {
        //     const utilsList: string[] = utilsKey.split(",");
        //     utilsList.map((item: string, index) => {
        //         if (item) {
        //             utilsData[item] = Utils.getUrlParams(item);
        //         }
        //     });
        // }
        if (cookieKey) {
            const cookieList: string[] = cookieKey.split(",");
            cookieList.map((item: string, index) => {
                if (item) {
                    utilsData[item] = Cookie.getItem(item);
                }
            });
        }
        const dataStr = Utils.getUrlParams();
        let dataList;
        if (dataStr) {
            dataList = dataStr.split("&");
            dataList.map((item: any) => {
                //p=1
                if (item) {
                    item = item.split("=");
                    utilsData[item[0]] = decodeURIComponent(item[1]);
                }
            });
        }
        source.data = { ...source.data, ...utilsData };
        source.handleData = (dat: any, oldData: any, header: any = {}) => {
            if (dat) {
                return { ...source.data, ...oldData, ...dat };
            } else {
                return { ...source.data, ...oldData };
            }
        };
        return source;
    }

    static createCookie(uiItem: IUIItem, intent?: any) {
        const { setList = [] } = uiItem.props;
        const source = new Source();
        uiItem.props.isCookie = true;
        initProps(source, uiItem.info);
        initProps(source, uiItem.props);
        setList.map((item: any) => {
            if (item) {
                Cookie.setItem(item.label, item.value);
            }
        });
        source.data = Cookie.getItem();
        source.handleData = (dat: any, oldData: any, header: any = {}) => {
            if (dat) {
                return { ...source.data, ...oldData, ...dat };
            } else {
                return { ...source.data, ...oldData };
            }
        };
        return source;
    }
}

export default SourceEngine;
