import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
// import Utils from "src/lib/utils/Utils";
import UIManifest from "src/engine/ui/UIManifest";
import "./HtmlTrans.scss";
import Utils from "src/lib/utils/Utils";
import { ISourceContext } from "src/engine/SourceCenter/SourceContext";
import Style from "src/lib/utils/Style";

interface IDetailHtml extends IBaseUI {
    data: any;
    ename: any;
    judgeContent: string;
    value: any;
    propsData?: boolean; //从data中获取参数
    contentKey?: string;
    setFlag?: boolean;
    propsDataMap?: any;
    notClick: boolean;
    emojiImg?: string;
    changeBr?: boolean; //将br替换为p标签，br无高度，换为p加一定高度
    changeBrStyle?: any;
    contentStyle?: any;
    exeOnChange?: any;
    onChange?: any;
    useSelfFontSize?: boolean;
    useSelfLineHeight?: boolean;
    classNames?: string[];
    removeImage?: boolean;
}

@UIManifest.declare("HtmlTrans", "详情页html转义(bbs)")
class HtmlTrans extends BaseUI<IDetailHtml> {
    sourceContext: ISourceContext;
    state = { data: {} };
    html: any;
    componentDidMount = () => {
        this.getData();
        const { exeOnChange, onChange } = this.props;
        if (exeOnChange && onChange) {
            onChange(this.html.clientHeight);
        }
    };
    getData = (datas?: any) => {
        const { source = "", propsData, propsDataMap } = this.props;
        const data = datas || this.props.data;
        let param: any = {};
        if (propsData) {
            if (propsDataMap) {
                propsDataMap.map((item: any, index: number) => {
                    param[item.label] = this.getIntent()[item.value] || data[item.value];
                });
            } else {
                param = data;
            }
        } else {
            param = { id: this.getIntent().detailId };
        }
        this.sourceContext.execSource(source, param, (ret) => {
            this.setState({ data: ret });
        });
    };
    UNSAFE_componentWillReceiveProps(next: any) {
        const { propsData } = this.props;
        if (propsData && next.data !== this.props.data) {
            this.getData(next.data);
        }
    }
    // defaultValue
    renderAct() {
        const {
            changeBr,
            changeBrStyle = {},
            emojiImg,
            dataSource,
            data,
            ename,
            classNames,
            value,
            judgeContent,
            contentKey,
            setFlag,
            notClick = true,
            useSelfFontSize = false,
            useSelfLineHeight = false,
            removeImage,
        } = this.props;
        let html,
            flag: boolean = false;
        if (value) {
            html = value;
        } else if (!dataSource) {
            html = data && ename ? data[ename] || this.state.data[ename] : "";
        } else {
            html = dataSource[contentKey || "content"];
        }
        if (judgeContent === "false") {
            flag = data ? true : false;
        } else {
            flag = data && data.content ? true : false;
        }
        //强制设置flag为true
        if (setFlag) {
            flag = setFlag;
        }
        if (!html) html = "";
        html = Utils.handleHtml(html);
        if (notClick) {
            const reg = new RegExp("<a", "g");
            html = html.replace(reg, "<a onClick='return false'");
        }
        //解析\n换行符
        html = html.replace(/\\n/g, "<br/>");
        if (changeBr) {
            let style: string = "";
            for (const key in changeBrStyle) {
                if (key && changeBrStyle[key]) {
                    style = `${style};${key}:${changeBrStyle[key]}`;
                }
            }
            html = html.replace(/<br \/\>|<br\/\>|<br>/g, "<p style=" + style + " /></p>");
        }

        if (removeImage && html) {
            html = html.replace(/<br\/><img id=.*?(?:>|\/>)/gi, "").replace(/<img id=.*?(?:>|\/>)/gi, "");
            html = html.replace(/<br\/><img class="imgAttachment.*?(?:>|\/>)/gi, "").replace(/<img class="imgAttachment.*?(?:>|\/>)/gi, "");
        }

        return (
            <div
                className={`react-html react-emoji-${emojiImg} ${useSelfFontSize ? "react-html-fontsize-inherit" : ""} ${
                    useSelfLineHeight ? "react-html-lineheight-inherit" : ""
                }`}
                ref={(ref) => (this.html = ref)}
                style={this.getStyle()}
            >
                {flag && (
                    <div
                        className={`intro react-richer ${classNames?.join(" ")}`}
                        style={Style(this.props.contentStyle)}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                )}
            </div>
        );
    }
}

export default HtmlTrans;
