import Manager from "../Manager";

/**
 *
 *
 * @class Exec
 */
class Exec {
    mManager: Manager;

    constructor(manager: Manager) {
        this.mManager = manager;
    }
}

export default Exec;
