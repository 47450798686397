import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import { IInfo } from "src/component/props/PropsGGEditor/exec/ExecScript";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import Utils from "src/lib/utils/Utils";

interface ICheckScript {
    checkKey?: string;
    checkType?: "single" | "all";
    renderForNull?: boolean;
    defaultValue?: string;
}

/**
 *
 *
 * @class CheckScript
 */
@UIManifest.declare("CheckScript", "检测/判断", UIManifest.Type.ScriptCheck)
class CheckScript extends Script<ICheckScript> {
    private handleUrl(url: string, headers: any) {
        if (url.indexOf("[") >= 0 && headers) {
            const list = Utils.parser(/\[(.+?)\]/gim, url, 1);
            list.map((item) => {
                if (headers[item] !== undefined) {
                    url = url.replace(`[${item}]`, headers[item]);
                }
            });
        }
        return url;
    }

    exec(info: IInfo, callback: (info: IInfo) => any) {
        const { checkKey, checkType = "single", renderForNull = false, defaultValue } = this.props;
        if (checkKey) {
            info.checkValue = PropsUtils.getValue(info.value, this.handleUrl(checkKey, info.value)) ?? defaultValue;
            info.checkType = checkType;
        } else {
            if (info.value && info.value.checkKey) {
                const checkk = info.value.checkKey;
                info.value = info.value.value;
                info.checkValue = PropsUtils.getValue(info.value, this.handleUrl(checkk, info.value));
                info.checkType = checkType;
            } else {
                info.checkValue = info.value;
            }
        }
        info.renderForNull = renderForNull;
        return true;
    }
}

export default CheckScript;
