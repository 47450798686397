import ISource from "../interface/ISource";
import Net from "./utils/Net";
import Local from "./utils/Local";
import Bridge from "./utils/Bridge";
import Handle from "./utils/Handle";
import EventTarget from "src/lib/utils/EventTarget";
import Storage from "src/lib/utils/storage/Storage";
import StorageType from "src/lib/utils/storage/StorageType";
import Utils from "src/lib/utils/Utils";
import DownGame from "./utils/DownGame";
import GameConfig from "src/GameConfig";
import { StatType } from "../pages/ui/Page";
import Cookie from "src/lib/utils/storage/Cookie";
import SourceHandle from "src/component/source/SourceHandle";

/**
 * 数据格式
 */
export interface IDataType {
    loading: boolean;
    total?: number;
    data?: any;
    start?: number;
    count?: any;
    headers?: any;
    end?: boolean;
}

export type SourceListener = () => void;

export interface ISourceBean {
    init: boolean;
    name: string;
    data: IDataType;
    source: ISource;
    exec?: SourceHandle;
}

let mIndex = 1;

/**
 *
 *
 * @class Manager
 */
class Manager {
    //数据管理器
    mEvent: EventTarget = new EventTarget();

    //数据管理map
    mSourceBeans: { [key: string]: ISourceBean } = {};

    mHandle: Handle = new Handle(this);

    //网络请求
    mNet: Net = new Net(this);

    mLocal: Local = new Local(this);

    mBridge: Bridge = new Bridge(this);

    mCookie: any = Cookie;

    mDownGame: DownGame = new DownGame(this);

    mIndex: number = mIndex++;

    mParentManager: Manager | null = null;

    mStorage = new Storage(StorageType.LocalStorage);

    ename: string = "root";

    updateTheme?: any;

    constructor(ename: string, updateTheme: any) {
        this.ename = ename;
        this.updateTheme = updateTheme;
    }

    setManager(manager: Manager) {
        this.mParentManager = manager;
    }

    stat = (info: any, type: StatType = StatType.PAGE) => {
        const aWindow: any = window;
        if (!aWindow.Logger) return;
        // const intent = this.getIntent();
        const statInfo = {
            //活动id
            activityId: `${GameConfig.server}_${GameConfig.gameId}`,
            //页面id
            pageId: this.ename,
            ...info,
        };
        //按钮统计
        aWindow.Logger.profession(type, statInfo);
    };

    dataList = {};

    listener(name: string, callback: SourceListener, itemKey?: string): any {
        let isModule = false;
        let bean: ISourceBean;
        //如果第一位异步获取
        if (name === "dataList" && itemKey) {
            isModule = true;
            bean = this.getBean(itemKey);
        } else {
            bean = this.getBean(name);
        }

        if (!bean) {
            return this?.mParentManager?.listener(name, callback, itemKey);
        }

        if (isModule) {
            //触发第一数据请求
            if (bean && bean.init !== true) {
                bean.init = true;
                setTimeout(() => {
                    this.execSource("syncModuleList", { ...bean.source.headers, moduleId: itemKey });
                });
            }
            //注册监听器
            if (!this.getBean(name)) {
                if (this.mParentManager && this.mParentManager.listener) {
                    this.mParentManager.listener(name, callback);
                }
                return;
            }
        } else if (bean && bean.init !== true) {
            bean.init = true;
            setTimeout(() => {
                if (bean.source && bean.source.bridgeType) {
                    //传参数是jsBridge可执行以便存target
                    this.execSource(bean.name, { [bean.source.bridgeType]: false });
                } else {
                    this.execSource(bean.name);
                }
            });
        }
        //监听数据
        return this.mEvent.addListener(name, () => {
            // const item = this.getBean(name);
            callback();
        });
    }

    getSources() {
        let list: any = [];
        for (const key in this.mSourceBeans) {
            if (this.mSourceBeans[key]) {
                const bean = this.mSourceBeans[key];
                if (bean.source && bean.source.cname) list.push({ info: { cname: bean.source.cname, ename: bean.name } });
                else if (bean.exec) list.push({ info: bean.exec.mUIItem.info });
            }
        }
        if (this.mParentManager) {
            list = [...list, ...this.mParentManager.getSources()];
        }
        return list;
    }

    getSourcesData(name: string) {
        const bean = this.getSourceBean(name);
        if (bean) {
            return bean.data;
        }
        return;
    }

    getAllSources(): any {
        return {
            ...this?.mParentManager?.getAllSources(),
            ...this.mSourceBeans,
        };
    }

    /**
     *
     * @param bean  ISourceBean | string
     * @param headers
     * @param data
     */
    update(bean: ISourceBean | string, headers: any, data: any, other: any = {}, refresh: boolean = true) {
        if (data === false) return;
        if (typeof bean === "string") bean = this.getBean(bean);
        if (!bean) return;

        //更新数据
        const source = this.mSourceBeans[bean.name];
        if (source) {
            if (bean.exec) {
                source.data = data;
            } else {
                //存储数据
                if (!bean.source.islocal && (data.data || data.total !== undefined)) {
                    source.data = { loading: false, ...data, total: data.total, data: data.data, headers, ...other };
                } else if (data.error || data.loading !== undefined) {
                    source.data = { loading: false, headers, data: bean.data.data, ...data, ...other };
                } else {
                    source.data = { loading: false, headers, data, ...other };
                }
                if (!data.error && bean.source.saveInLocalStorage) {
                    const item = bean.source.validity ? { time: new Date().valueOf(), data } : data;
                    this.mStorage.set(this.getStorageKey(source.source, bean.name), JSON.stringify(item));
                }
            }

            //出发数据刷新
            if (refresh) this.mEvent.fire(bean.name);
            const updateTime = this.getUpdataTheme();
            if (bean.name === "theme" && updateTime) {
                updateTime(bean.data.data);
            }
        }
    }

    updateSource(name: string, headers: any, data: any, other: any = {}) {
        const bean = this.getBean(name);
        if (!bean) {
            if (this.mParentManager && this.mParentManager.update) this.mParentManager.update(name, headers, data, other);
            return;
        }
        this.update(name, headers, data, other);
    }

    getUpdataTheme() {
        return this.updateTheme || this.mParentManager?.updateTheme;
    }

    getBean(name: string) {
        return this.mSourceBeans[name];
    }

    getSourceBean(name: string): ISourceBean | undefined {
        const bean = this.getBean(name);
        if (!bean && this.mParentManager && this.mParentManager.getSourceBean) {
            return this.mParentManager.getSourceBean(name);
        }
        return bean;
    }

    setBean(bean: ISourceBean) {
        this.mSourceBeans[bean.name] = bean;
    }

    getStorageKey(source: ISource, name: string) {
        return `${source.saveByUser ? new Storage().get("User_Id") + "_" : "source_"}${name}`;
    }

    createSourceByUIItems(name: string, exec: SourceHandle, init = false) {
        //注册
        exec.setManager(this);
        this.setBean({ name, data: exec.initData(), exec, init: exec.props?.init || init } as any);
        //返回清除回调
        return () => {
            this.clearSource(name);
        };
    }

    /**
     *
     * @param name
     * @param source
     */
    createSource = (name: string, source: ISource) => {
        //判断是否存在
        if (!this.getBean(name)) {
            //source.bridgeType
            const data: IDataType = { loading: !source.islocal };
            //初始化默认数据
            if (source.data) data.data = source.data;
            //去除默认数据
            if (source.saveInLocalStorage) {
                try {
                    data.data = JSON.parse(this.mStorage.get(this.getStorageKey(source, name)) || "{}") || data.data;
                    if (data.data && data.data.time) {
                        if (new Date().valueOf() - Number(data.data.time) > Number(source.validity) * 1000) {
                            data.data = source.data;
                        } else {
                            data.data = data.data.data;
                        }
                    }
                } catch (error) {}
            }
            if (source.isUtils && source.utilsTimer) {
                this.execTimer(name, source);
            }
            this.setBean({ name, data, source, init: !!source.init });
            if (name === "theme") {
                if (source["initExec"]) {
                    //将utils的数据保存到theme数据中
                    this.execSource(name, data.data, null, {}, 0, true);
                    //注册回调方法
                    this.execSource(name);
                }
            }
            // const updateTime = this.getUpdataTheme();
            // if (name === "theme" && updateTime) {
            //     updateTime(data.data);
            // }
        }
        //返回清除回调
        return () => {
            this.clearSource(name);
        };
    };
    utilsTimer: any;
    //utils定时调用,刷新是否是wifi状态
    execTimer = (name: string, source: ISource) => {
        this.utilsTimer = setInterval(() => {
            this.execSource(name, { isWifi: Utils.isWIFI() });
        }, source.utilsTimer);
    };

    execSource = (
        name: string,
        header?: any,
        callback: any = () => {},
        ajax: ISource = {},
        delay: number = 0,
        notExecBridge?: boolean, //处理纯回调型桥连接函数,只更新数据 不执行桥方法
        clear?: boolean,
        refresh: boolean = true
    ) => {
        if (name.indexOf("dataList.") === 0) {
            header = { ...header, moduleId: name.replace("dataList.", "") };
            name = "syncModuleList";
        }
        if (name.indexOf(".") === 0) {
            header = { ...header, dataId: name.split(".")[1] };
            name = name.split(".")[0];
        }
        const bean = this.getBean(name);
        if (bean && bean.exec) {
            bean.exec.execSource(header, bean.data, callback, clear);
            return;
        }

        const oldHeaders = header;
        if (!bean) {
            if (this.mParentManager && this.mParentManager.execSource) this.mParentManager.execSource(name, header, callback, ajax);
            return;
        }
        if (bean.source.isCookie) {
            header = { ...Cookie.getItem(), ...header };
        }
        //处理参数
        this.mHandle.handleHeaders(bean, { ...header, ...ajax.headers }).then((headers: any) => {
            //
            if (clear) {
                headers.start = headers.pageNum = 1;

                this.update(bean, headers, {}, { loading: true, data: [] }, refresh);
            }

            //如果参数为null，则直接结束
            if (headers === false) {
                callback(false);
                return;
            }

            //获取请求信息
            const http = this.mHandle.handleHttp(bean, headers, ajax.ajax, oldHeaders);

            if (!http) {
                const { bridgeType, bridgeTypeMap = {}, isCookie } = bean.source;
                if (!notExecBridge && (bridgeType || (headers && bridgeTypeMap[headers.bridgeType]))) {
                    this.mBridge.exec(bean, headers, callback);
                } else if (bean.source.downCtrl) {
                    this.mDownGame.exec(bean, headers, callback);
                } else if (isCookie) {
                    if (typeof headers === "object") {
                        if (!Array.isArray(headers)) {
                            const keys = Object.keys(headers);
                            keys.map((item: string) => {
                                if (item) {
                                    this.mCookie.setItem(item, headers[item], { path: "/" });
                                }
                            });
                        }
                    }
                    this.mLocal.exec(bean, headers, callback, undefined, refresh);
                } else {
                    const updateNet = !!bean.source.ajax; //判断下是否是修改的网络数据
                    if (Object.keys(headers).length !== 0) this.mLocal.exec(bean, headers, callback, updateNet, refresh);
                }
            } else {
                const { emojiKey } = bean.source;
                if (emojiKey) {
                    const emojiList = emojiKey.split(",");
                    emojiList.map((item: string) => {
                        if (item && headers[item]) {
                            headers[item] = this.utf16toEntities(headers[item]);
                        }
                    });
                }
                http.url = this.handleUrl(http.url, headers);
                // const { notUpRequest = false } = ajax;
                this.mNet.exec(bean, http, callback, delay, ajax);
            }
        });
    };

    private handleUrl(url: string, headers: any) {
        if (url.indexOf("[") > 0) {
            const list = Utils.parser(/\[(.+?)\]/gim, url, 1);
            list.map((item) => {
                if (headers[item] !== undefined) url = url.replace(`[${item}]`, headers[item]);
            });
        }
        // if (url.indexOf("gameId") <= -1 && headers.gameId) {
        //     url = `${url}&gameId=${headers.gameId}`;
        // }
        return url;
    }
    // 😢
    utf16toEntities(str: string = "") {
        const patt: any = /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g; // 检测utf16字符正则
        str = str.replace(patt, (char) => {
            let H, L, code;
            if (char.length === 2) {
                H = char.charCodeAt(0); // 取出高位
                L = char.charCodeAt(1); // 取出低位
                code = (H - 0xd800) * 0x400 + 0x10000 + L - 0xdc00; // 转换算法
                return "*#表情" + code + ";";
            } else {
                return char;
            }
        });
        return str;
    }
    // 刷新数据
    refresh = (source: string) => {
        const bean = this.getSourceBean(source);
        if (!bean) return;
        // this.refresh(source);
        if (bean.data.headers && bean.data.headers.start > 1) {
            //如果是刷新接口，则对分页置1
            bean.data.headers.start = 1;
        }
        //刷新当前页面上全部moduleId
        if (source === "syncModuleList") {
            const listBean = this.getBean("dataList");
            const modules = listBean.data.data;
            if (bean.data.headers) bean.data.headers.moduleId = "";
            for (const k in modules) {
                if (k !== "edit" && k !== "pagination") {
                    bean.data.headers.moduleId += `${k},`;
                }
            }
        }
        this.execSource(source, bean.data.headers);
    };

    clearSource(name: string) {
        delete this.mSourceBeans[name];
        //出发数据刷新
        this.mEvent.fire(name);
    }
}

export default Manager;
