/**
 *
 *
 * @class Cache
 */
class Cache {
    mCallbacks: { [key: string]: any } = {};

    has(name: string) {
        return !!this.mCallbacks[name];
    }

    /**
     * 添加
     * @param name
     * @param callbacks
     */
    add(name: string, callbacks: any) {
        //判断是否存在
        if (this.mCallbacks[name]) {
            this.clear(name);
        }
        this.mCallbacks[name] = callbacks;
    }

    /**
     * 清除所有
     */
    clearAll() {
        const list = Object.keys(this.mCallbacks);
        for (const name of list) {
            this.clear(name);
        }
    }

    /**
     * 根据名称调用并移除
     * @param name
     */
    clear(name: string) {
        if (this.mCallbacks[name]) {
            const callback = this.mCallbacks[name];
            delete this.mCallbacks[name];
            callback();
        }
    }
}

export default Cache;
