import IRule, { IRuleProps } from "./IRule";
import IUIItem from "src/engine/interface/IUIItem";
import Utils from "src/lib/utils/Utils";

interface IRulePlatform extends IRuleProps {
    platType:
        | "phone"
        // 身份证
        | "idcard"
        // 真实姓名
        | "realname"
        | "email"
        | "url"
        // 9位数字JJID
        | "jjId"
        // 十六进制格式
        | "hex";
}

const PLATFORM_RULES = {
    phone: Utils.checkPhone,
    idcard: Utils.checkIDCard,
    realname: Utils.checkRealName,
    jjId: function (str: string) {
        const numStr = str.replace(/(^\s*)|(\s*$)/g, "");
        const reg = /^\d{9}$/;
        return reg.test(numStr);
    },
};

const PLATTYPE_MESSAGE_MAP = {
    phone: "手机号",
    idcard: "身份证号",
    realname: "姓名",
    email: "email",
    url: "链接",
    hex: "十六进制",
    jjId: "JJID",
};

/**
 * url校验正则：
 *  new RegExp(
 *   '^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
 *   'i',
 * )
 */

/**
 * 校验  【平台统一校验规则】
 */
class RulePlatform implements IRule {
    type: "Platform";

    props: IRulePlatform;

    constructor(props: IRulePlatform) {
        this.props = props;
    }

    defineMessage() {
        const { platType } = this.props;
        const str = PLATTYPE_MESSAGE_MAP[platType];
        if (!str) return "输入格式不符合规则";
        return `输入的${str}格式不正确`;
    }

    exec(
        // 表单值
        formData: any,
        // 扩展属性, rules同级
        expend: any,
        // 当前表单 UIItem
        uiItem: IUIItem
    ) {
        const { message, platType } = this.props;
        if (!platType) return false;
        const selfM = message ? message : this.defineMessage();
        if (platType === "email" || platType === "url" || platType === "hex") return { message: selfM, type: platType };
        return {
            validator(rule: any, value: any, callback: any) {
                if (value) {
                    // 填了之后才能认证
                    try {
                        if (!PLATFORM_RULES[platType](value)) callback(selfM);
                    } catch (err) {
                        callback(err);
                        return;
                    }
                }
                callback();
            },
        };
    }
}

export default RulePlatform;
