import React from "react";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";

import { SourceContext, ISourceContext } from "src/engine/SourceCenter/SourceContext";
import ExecScript from "./ExecScript";
import PageContext, { IPageContext } from "src/engine/pages/ui/PageContext";
import {
    IMMEDIATE_SCRIPT_FLAG,
    MOUNTED_IMMEDIATE_SCRIPT_FLAG,
    UNMOUNT_IMMEDIATE_SCRIPT_FLAG,
    EVENT_RESIZE_SCRIPT_FLAG,
    POLLING_IMMEDIATE_SCRIPT_FLAG,
} from "src/lib/utils/Const";
import { Context } from "src/engine/decorator/AutoContext";

interface ILifeCycle {
    componentWillUnmount?: () => {};
    componentDidMount?: () => {};
    init?: () => {};
}

/**
 *
 *
 * @class GGProps
 */
const GGProps = (ggProps: string[]) => {
    return (GGUI: any) => {
        return class UI extends React.Component {
            checkList: string[];

            static type = "GGProps";

            sourceContext: ISourceContext;

            pageContext: IPageContext;

            lifeCycle: ILifeCycle = {};

            unmountList: any[] = [];

            state = {
                props: {},
            };

            constructor(props: any) {
                super(props);
                this.checkList = [];
                this.state = { props: this.getGGState(props) };
                this.immediateExec(IMMEDIATE_SCRIPT_FLAG);
            }

            immediateExec(type: string = IMMEDIATE_SCRIPT_FLAG) {
                const immeProps = this.state.props[type];
                if (immeProps && typeof immeProps === "function") {
                    // const { info = {} } = (this.props as any).uiItem || {};
                    // console.log(`[${type}] 执行脚本 , ename: ${info.ename} cname: ${info.cname}`);
                    immeProps();
                }
            }

            componentWillUnmount() {
                this.immediateExec(UNMOUNT_IMMEDIATE_SCRIPT_FLAG);
                for (const fn of this.unmountList) {
                    if (typeof fn === "function") fn();
                }
            }

            componentDidMount() {
                // 渲染完成后执行脚本
                this.immediateExec(MOUNTED_IMMEDIATE_SCRIPT_FLAG);
                // 轮询触发脚本
                if (this.state.props[POLLING_IMMEDIATE_SCRIPT_FLAG]) {
                    const time = this.state.props[`${POLLING_IMMEDIATE_SCRIPT_FLAG}_time`];
                    if (time > 1) {
                        const intervalTime = setInterval(() => {
                            this.immediateExec(POLLING_IMMEDIATE_SCRIPT_FLAG);
                        }, time);
                        this.unmountList.push(() => clearInterval(intervalTime));
                    }
                }
                // 页面resize触发脚本
                if (this.state.props[EVENT_RESIZE_SCRIPT_FLAG]) {
                    this.immediateExec(EVENT_RESIZE_SCRIPT_FLAG);
                    const event = () => this.immediateExec(EVENT_RESIZE_SCRIPT_FLAG);
                    window.addEventListener("resize", event, false);
                    this.unmountList.push(() => window.removeEventListener("resize", event, false));
                }
            }

            updateUI(state: any = {}) {
                state._count = (this.state as any)._count || 1;
                state._count += 1;
                if (state.props) {
                    state.props = { ...this.state.props, ...state.props };
                }
                this.setState(state);
            }

            setLifecycle(cycle: ILifeCycle) {
                Object.keys(cycle).forEach((key: string) => {
                    this.lifeCycle[key] = cycle[key];
                });
            }

            getGGState(props: any) {
                let state = {};
                this.checkList = [];
                ggProps.map((ggProp) => {
                    this.checkList.push(ggProp);
                    if (props[ggProp]) {
                        if (props[ggProp].endKey) this.checkList.push(props[ggProp].endKey);
                        //判断是否预处理了数据分配逻辑处理，减少
                        if (props[ggProp].type === "1" && props["dataAllot"]) {
                            // console.log("[GGProps]", "已存在预处理数据，则不再执行", props["dataAllot"]);
                            return;
                        }
                        const ret = new ExecScript(props[ggProp] || {}, this, props).exec();
                        if (Object.keys(this.lifeCycle).length > 0) {
                            state = { ...state, ...ret, lifeCycle: this.lifeCycle };
                        } else {
                            state = { ...state, ...ret };
                        }
                    }
                });

                return state;
            }

            UNSAFE_componentWillReceiveProps(nextProps: any) {
                for (const item of this.checkList) {
                    if (PropsUtils.getValue(nextProps, item) !== PropsUtils.getValue(this.props, item)) {
                        this.setState({ props: this.getGGState(nextProps) });
                        return;
                    }
                }
            }

            render() {
                const { ggValue, setProps, ...other } = this.props as any;

                // console.log('[GGProps]:setProps', setProps);
                // const props = { ...other, ...this.state.props };
                setProps?.(other, { ...this.state.props });

                return [
                    <Context context={SourceContext} key={0}>
                        {(context: ISourceContext) => {
                            this.sourceContext = context;
                            return null;
                        }}
                    </Context>,
                    <Context context={PageContext} key={1}>
                        {(context: IPageContext) => {
                            this.pageContext = context;
                            return null;
                        }}
                    </Context>,
                    <GGUI key={2} {...other} {...this.state.props} />,
                ];
            }
        };
    };
};

export default GGProps;
