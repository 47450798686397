import Script from "src/component/script/Script";
import Utils from "src/lib/utils/Utils";
import UIManifest from "src/engine/ui/UIManifest";

// import Moment from "moment";
// import "moment/locale/zh-cn";
// Moment.locale("zh-cn");
// (window as any).Moment = Moment;

interface ITimeScript {
    formmatter?: string;
    getNowTime?: boolean;
    autoTime?: string;
    otherTrans?: boolean;
    addDay?: number;
    addYear?: number;
    isTheDay?: boolean;
    noShowLocalYear?: string;
    useSecond?: boolean; //使用秒级时间戳
    // isSetTime?: boolean; //设定特定时间
    // setDate?: number; //设定特定日（后续根据实际情况可以设置特定年、月）
    // setQuarter?: 0 | 1 | 2 | 3 | 4; //设定特定季度
}

const ONLY_TIME = "onlyTime";

const DAY_TIMES = 86400000;

function addYearFunc(year: number, now: number, isTheDay: boolean) {
    const date = new Date(now);
    // isTheDay 代表是否是目标年当天。 如果isTheDay为true，则 2020-1-2 一年后是 2021-1-2
    date.setFullYear(date.getFullYear() + year);
    if (year > 0 && !isTheDay) {
        date.setDate(date.getDate() - 1);
    } else {
        date.setDate(date.getDate() + 1);
    }
    return date;
}

/**
 *
 *
 * @class TimeScript
 */
@UIManifest.declare("TimeScript", "时间处理", UIManifest.Type.Script)
class TimeScript extends Script<ITimeScript> {
    compareYear(time: any) {
        const odate = new Date(Number(time) < 10000000000 ? Number(time) * 1000 : Number(time));
        const ndata = new Date();
        return odate.getFullYear() === ndata.getFullYear();
    }
    protected handle(value: any, props: ITimeScript) {
        const { autoTime = false, otherTrans = false, useSecond, getNowTime = false, isTheDay = false, noShowLocalYear } = this.props;
        let { addDay, addYear } = this.props;
        if (getNowTime) return new Date().valueOf();
        let { formmatter = "yyyy-MM-dd" } = this.props;
        if (!value && value !== 0) return "";
        if (autoTime) {
            // if (value.indexOf("+") > 0 && value.indexOf("T") > 0) {
            //     // 2019-12-11T03:07:01.000+0000  ie不兼容
            //     value = value.substring(0, value.indexOf("+"));
            // }
            if (value.indexOf?.("T") >= 0) {
                value = value.replace(/-/g, "/").replace("T", " ");
            }
            value = new Date(value).valueOf();
            if (useSecond) {
                value = Math.floor(value / 1000);
            }
            if (value < 0) value = 0;
        }
        if (addDay) {
            if (value && value.addNumber) {
                addDay = Number(value.addNumber);
                value = value.data;
            }
            if (useSecond) {
                value = Number(value) + (addDay * DAY_TIMES) / 1000;
            } else {
                value = Number(value) + addDay * DAY_TIMES;
            }
        }
        // if (addMonth) {
        //     if (value && value.addNumber) {
        //         addMonth = Number(value.addNumber);
        //         value = value.data;
        //     }
        // let Avalue = Moment(value).add(addMonth, "M");
        // nextMonthFirstDay 为True时表示，在月份增加的时候，如果目标月份不存在原始月份的日期，则自动取下个月第一天
        // 例如：3月31日的下个月默认为4月30日，当nextMonthFirstDay为True时，3月31日的下个月则为5月1日
        // if (nextMonthFirstDay) {
        //     value = Moment(Avalue).format("DD") == Moment(value).format("DD") ? Avalue : Moment(Avalue).add(1, "M").startOf("M");
        // } else {
        //     value = Avalue;
        // }
        // }
        if (addYear) {
            if (value && value.addNumber) {
                addYear = Number(value.addNumber);
                value = value.data;
            }
            console.log("addYear", value, addYear);
            value = addYearFunc(addYear, value, isTheDay);
        }
        // if (isSetTime) {
        //     // setDate为0，则取月末
        //     if (setDate !== undefined) value = setDate ? Moment(value).date(setDate) : Moment(value).endOf("month");
        //     // setQuarter为0，则取季末
        //     if (setQuarter !== undefined) value = setQuarter ? Moment(value).quarter(setQuarter) : Moment(value).endOf("quarter");
        // }
        if (formmatter === ONLY_TIME) {
            return value;
        }
        if (formmatter && !otherTrans) {
            if (!noShowLocalYear) {
                value = Utils.format(value, formmatter);
            } else {
                if (this.compareYear(value)) {
                    value = Utils.format(value, noShowLocalYear);
                } else {
                    value = Utils.format(value, formmatter);
                }
            }
        }

        if (otherTrans) {
            const { type, countTime, relativeDefaultTime, defaultTimeLimitDay, defaultTimeValue, isfmt = false } = this.props as any;
            if (!isfmt) formmatter = "";
            value = Utils.setDefaultTime(type, countTime, relativeDefaultTime, defaultTimeLimitDay, defaultTimeValue, formmatter);
        }
        return value;
    }
}

export default TimeScript;
