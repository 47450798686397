import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import Modal from "../../mobile/ui/common/Modal";
import UIManifest from "src/engine/ui/UIManifest";
import "./HtmlRicher.scss";
import WrapViewer from "./WrapViewer";
import Utils from "src/lib/utils/Utils";

interface IHtmlRicher extends IBaseUI {
    value: string;
    fomatter: string;
    wrapBg: string;
    notClick?: boolean;
    htmlStyle?: string; //html的额外的覆盖样式
    minimage?: boolean;
    minWidth?: number;
    minHeight?: number;
    imgIndexChildren?: any;
    deleteDefault?: boolean;
    tableLayout?: string;
}

interface IState {
    imgs: string[];
    index: number;
    htmlStr: string;
}

@UIManifest.declare("HtmlRicher", "支持图片放大富文本")
class HtmlRicher extends BaseUI<IHtmlRicher, IState> {
    state: IState = {
        imgs: [],
        index: 0,
        htmlStr: ""
    };

    html: any;

    modal: Modal | null;

    imgsDom: HTMLElement[];

    modalCloseFlag: boolean = true;

    static getDerivedStateFromProps(props: IHtmlRicher, state: IState) {
        let value = props.value || "";
        const item = HtmlRicher.formatterValue(value, props.fomatter || "");
        const { minWidth = 480, minHeight = 480 } = props;
        if (props.minimage) {
            const minValue = `/${minWidth}x${minHeight}`;
            try {
                value = item.replace(/<img.+?src=('|")?([^'"]+)('|")?(?:\s*|>)/gim, str => {
                    if (str.indexOf(".oss.jjbisai.com") < 0 || str.indexOf(".gif") >= 0) return str;
                    str = str.trim();
                    const last = str[str.length - 1];
                    if (last === `'` || last === `"`) {
                        return str.substring(0, str.length - 1) + minValue + last;
                    }
                    return str + minValue;
                });
            } catch (e) {
                value = item;
            }
        } else {
            value = item;
        }

        return {
            htmlStr: value,
            imgs: Utils.parser(/<img.+?src=('|")?([^'"]+)('|")?(?:\s*|>)/gim, item, 2)
        };
    }

    componentDidMount() {
        const { htmlStyle = "" } = this.props;
        if (htmlStyle) {
            const dom = document.createElement("style");
            dom["rel"] = "stylesheet";
            dom.type = "text/css";
            dom.innerHTML = htmlStyle + "";
            document.getElementsByTagName("head")[0].appendChild(dom);
        }
        if (this.html) {
            this.html.onclick = this.onImgClick;
            const imgDom = this.html.querySelectorAll("img");
            if (imgDom && imgDom.length) {
                this.imgsDom = Array.prototype.slice.call(imgDom);
            }
        }
    }

    static formatterValue(value: string, fomatter: string) {
        let resVal = value;
        if (fomatter && fomatter.indexOf("xxx") !== -1) {
            resVal = fomatter.replace("xxx", resVal);
        }
        return resVal;
    }
    onImgClick = (ev: any) => {
        if (ev.target && ev.target.nodeName.toLowerCase() === "img") {
            if (this.imgsDom && this.imgsDom.length > 0) {
                this.setState({ index: this.imgsDom.indexOf(ev.target) });
            } else {
                const { imgs } = this.state;
                const src = ev.target.src;
                if (imgs.length === 0) return;
                let compareUrl = "";
                if (imgs.indexOf(src) > -1) {
                    compareUrl = src;
                } else {
                    compareUrl = src.replace("http:", "").replace("https:", "");
                }
                this.setState({ index: imgs.indexOf(compareUrl) });
            }
            if (this.modal) {
                this.modal.openBefore();
                this.modalCloseFlag = false;
            }
        }
    };

    onClose = () => {
        if (this.modal && !this.modalCloseFlag) {
            this.modalCloseFlag = true;
            this.goBack();
        }
        // if (this.modal) this.modal.onClose();
    };

    getImgAndRenderComp = (url: string) => {
        return this.renderChildren(this.props.children, {
            value: url
        });
    };
    renderImgindex = (index: any, urls: any) => {
        if (urls && urls.length && index >= 0) {
            return this.renderChildren(this.props.imgIndexChildren, {
                data: { index, len: urls.length }
            });
        }
    };
    renderAct() {
        const { wrapBg, notClick = true, deleteDefault = false, tableLayout, imgIndexChildren, ...other } = this.props;
        const { index, imgs, htmlStr } = this.state;
        let html = htmlStr; //HtmlRicher.formatterValue(htmlStr, fomatter);
        if (notClick && html && typeof html === 'string') {
            const reg = new RegExp("<a", "g");
            html = html.replace(reg, "<a onClick='return false'");
        }

        return (
            <div className={`react-html  editor-extra-style default-p-${deleteDefault} tableLayout-${tableLayout}`} style={this.getStyle()}>
                {/* 按钮 */}
                <div className="intro react-richer" ref={ref => (this.html = ref)} dangerouslySetInnerHTML={{ __html: html }} />
                <Modal ref={ref => (this.modal = ref)} full={true} uiItem={{ info: { ename: "", cname: "" }, ui: "", props: {} }}>
                    <WrapViewer
                        backgroundImage={wrapBg}
                        onClose={this.onClose}
                        urls={imgs}
                        index={index}
                        renderImgindex={imgIndexChildren ? this.renderImgindex : undefined}
                        otherComp={this.getImgAndRenderComp}
                        {...other}
                    />
                </Modal>
            </div>
        );
    }
}

export default HtmlRicher;
