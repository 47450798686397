import { IPageModal } from "./Page";
import React from "react";

export interface IPageContext {
    intent: any;
    pageName: string;
    registerBackListener: (callback: any, clear?: boolean) => void;
    contextInit?: boolean;
    pageBack: () => any;
    pageRef: () => any;
    stat: (info: any) => any;
    pageLast: boolean;
    moduleNum: any;
    showModal: (modalItem: IPageModal, args: any) => void;
}

const PageContext = React.createContext<IPageContext>({
    intent: {},
    pageName: "",
    contextInit: false,
    registerBackListener: (callback: any, clear?: boolean) => {},
    pageBack: () => {
        return false;
    },
    pageRef: () => {},
    showModal: (modalItem: IPageModal) => {},
    stat: (info: any) => {},
    pageLast: true,
    moduleNum: "",
});

export default PageContext;
