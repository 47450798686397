import IUIItem from "../interface/IUIItem";
import React from "react";

/**
 * 渲染编辑模式
 * page：高级开发者模式，可以进行任何编辑
 * content：内容管理模式，只可以进行内容管理，（现有放弃趋势，采用man模式）
 * man：运营管理模式，可以进行简单的模板编辑和内容管理。
 * app：普通渲染模式，项目发布时，采用此方式渲染
 */
export type IRenderType = "page" | "content" | "man" | "app";

/**
 *
 *
 * @interface IPanelContext
 */
export interface IPanelContext {
    manager: any;
    renderUIItem: (uiItem: IUIItem, props?: any, parent?: IUIItem) => any;
    theme: any;
    updateTheme?: any;
    getUI: (ui: string) => any;
    /**
     * 渲染编辑模式
     */
    renderType: IRenderType;
    //是否为模式
    isApp?: boolean;
    // 全局继承样式
    setSourceStyle?: (value?: any) => void;
    getSourceStyle?: (name?: string) => any;
}

const PanelContext = React.createContext<IPanelContext>({
    renderUIItem: (uiItem: IUIItem) => {
        return null;
    },
    theme: {},
    manager: null,
    getUI: (ui: string) => {},
    renderType: "app",
});

export default PanelContext;
