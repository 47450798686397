/**
 *
 *
 * @class StyleManifest
 */
class StyleManifest {
    static Map = {
        PCPageMgr: ["style", "bodyStyle", "rootStyle", "htmlStyle"],
        Button: ["style", "activeStyle", "hoverStyle"],
        ButtonExt: ["style", "activeStyle", "hoverStyle", "loadingStyle"],
        Tabs: ["style", "tabBarUnderlineStyle", "tabBarBoxStyle", "itemListBoxStyle"],
        Pagination: ["style", "nextStyle", "nextActiveStyle", "numberStyle", "numberActiveStyle", "pageStyle", "boxStyle", "inputStyle"],
        PCPage: ["style", { label: "异形屏样式（支持NotchH代指）", value: "screenStyle" }, "notchHeightStyle", "bottomHeightStyle"],
        AntCarousel: ["style", "selectedStyle", "dotStyle", "dotActiveStyle", "barBoxStyle"],
        Modal: ["style", "maskStyle", "wrapStyle", "contentStyle"],
        SliderUI: ["style", "railStyle", "trackStyle", "dotStyle", "activeDotStyle", "handleStyle"],
        SwiperJs: ["style", { label: "bar容器的style(常用于固定位置)", value: "barBoxStyle" }, { label: "active状态的bar的style", value: "activeBarStyle" }],
        VerifyCode: ["style", "boxStyle", "chooseStyle", "tipsStyle", "topimgStyle"],
        SMSCode: ["style", "disableStyle"],
        PCForm: ["style", "labelStyle", "btnWrapStyle"],
        HtmlTrans: ["style", "contentStyle"],
        AntMTabs: [
            "style",
            { label: "tabBar样式", value: "tabBarStyle" },
            { label: "tabBar下划线样式", value: "tabBarUnderlineStyle" },
            { label: "tabBar文字样式", value: "tabBarTextStyle" },
        ],
        FlexArea: ["style", "flexItemStyle"],
        RegisterForm: ["style", "errorStyle", "lineStyle", "smsBtnStyle", "formErrorStyle", "labelStyle"],
        DPlayer: ["style", "playerStyle"],
        JTabs: [
            "style",
            { label: "TabBar样式", value: "tabBarStyle" },
            { label: "指示器样式", value: "inkStyle" },
            { label: "TabContent样式", value: "contentStyle" },
            { label: "TabPanel样式", value: "tabPanelStyle" },
        ],
        JSpring: ["style", "to"],
        Scroll: [
            "style",
            { label: "底部upwrap-tips的样式", value: "upwrapTipsStyle" },
            { label: "底部icon的样式(upwrap-progress)", value: "upwrapProgressStyle" },
            { label: "顶部downwrap-tips的样式", value: "downwrapTipsStyle" },
            { label: "顶部icon的样式(downwrap-progress)", value: "downwrapProgressStyle" },
            { label: "顶部downwrap-content的样式", value: "downwrapContentStyle" },
        ],
        AntTransfer: ["style", { label: "两个穿梭框的自定义样式", value: "lineStyle" }],
        JJChatBox: ["style", { label: "聊天盒子样式", value: "chatBoxStyle" }, { label: "聊天发送框样式", value: "sendBoxStyle" }],
        JCutText: [
            "style",
            { label: "收起时按钮", value: "btntrueStyle" },
            { label: "展开时按钮", value: "btnfalseStyle" },
            { label: "整体样式", value: "boxStyle" },
        ],
    };

    static getStyleList = (uiName: string, other: string[] = []) => {
        return [...(StyleManifest.Map[uiName] || ["style"]), ...other];
    };
}

export default StyleManifest;
