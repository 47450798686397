import * as React from "react";
import "./Loading.scss";

import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import PageMgrContext, { IPageMgrContext } from "src/engine/pages/ui/PageMgrContext";
import UIManifest from "src/engine/ui/UIManifest";
import { Context } from "src/engine/decorator/AutoContext";

interface ILoading extends IBaseUI {}

@UIManifest.declare("Loading")
class Loading extends BaseUI<ILoading> {
    render() {
        return (
            <Context context={PageMgrContext}>
                {(context: IPageMgrContext) => {
                    if (context.loading && context.loading.length > 0) return this.renderChildren(context.loading);
                    return (
                        <div className="react-loading">
                            {/* <div className="in" />
                            <div className="in" />
                            <div className="in" />
                            <div className="in" />
                            <div className="in" />
                            <div className="in" /> */}
                        </div>
                    );
                }}
            </Context>
        );
    }
}

export default Loading;
