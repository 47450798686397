// import Manager from "./Manager";
import ISource from "../interface/ISource";
import React from "react";

/**
 *
 *
 * @interface ISourceContext
 */
export interface ISourceContext {
    mManager: any;

    /**
     * 操作
     *
     * @memberof ISourceContext
     */
    execSource: (
        option: string,
        data?: { [key: string]: any },
        callback?: (flag: boolean | any, netData: any) => any,
        dsource?: ISource,
        delay?: number,
        notExecBridge?: boolean,
        clear?: boolean,
        refresh?: boolean
    ) => void;
}

export const SourceContext = React.createContext<ISourceContext>({
    mManager: undefined,
    execSource: () => {},
});
