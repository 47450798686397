import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import IUIItem from "src/engine/interface/IUIItem";
import "./Page.scss";
import UIManifest from "src/engine/ui/UIManifest";
import PageContext from "./PageContext";
import SourceEngine from "src/engine/source/SourceEngine";
import GameConfig from "src/GameConfig";
import SourceCenter from "src/engine/SourceCenter";
import Http from "src/lib/http/Http";

import Manifest from "src/engine/Manifest";
import Utils from "src/lib/utils/Utils";

export interface ITotalSourceParams {
    sources: string;
    label: string;
    value: string;
}

interface IPage extends IBaseUI {
    content: IUIItem[];
    hidden: boolean;
    intent: any;
    module: IUIItem[];
    sources: IUIItem[];
    modals: IUIItem[];
    jjHistory: any;
    bridge: any;
    last: boolean;
    registerModal?: any;
    registerExitFunc?: any;
    moduleNum?: any;
    dataStyle?: any; //刘海屏适配
    notchMap?: any;
    hiddenBar?: boolean;
    isPC?: boolean | string;
    preload?: string[];
    // 数据模块是否触发页面完成渲染（静态化属性）
    totalSourceParams?: ITotalSourceParams[];
    mobileStyle?: any; //编辑环境下切换设备类型
    checkLoginType?: "page" | "url";
    loginName?: string; //跳转登录地址
    //限制子元素宽度
    maxWidth?: number;
    minWidth?: number;
    historyItem: any;
    /** 最后页面名称 */
    lastPageName?: string;
    //页面恢复调用方法
    onResume?: any;
    screenStyle?: any;
}

const LOG_INT_KEY = ["version", "time"];

interface IModalItem {
    ename: string;
    modelProps?: any;
    uiItem: IUIItem;
}

export type IPageModal = Omit<IModalItem, "uiItem">;

export enum StatType {
    //页面事件
    PAGE = "page",
    //点击事件
    CLICK = "click",
    //停留时长
    TIME = "time",
    //曝光类型
    Exposure = "exposure",
}

interface IState {
    modalItems: IModalItem[];
    wxShow: boolean;
}

@UIManifest.declareGG(["ggValue"], "PCPage", "页面组件", UIManifest.Type.PageMgr)
class Page extends BaseUI<IPage, IState> {
    //回调监听器
    backListeners: any[] = [];

    childSourceManager: SourceCenter | null;

    constructor(props: IPage) {
        super(props);
        this.state = {
            modalItems: [],
            wxShow: false,
        };
    }

    registerBack = (close: any, clear: boolean) => {
        if (clear) {
            this.props.historyItem.backListener = [];
        }
        this.props.historyItem.backListener.push(close);
    };

    pageBack = (): boolean => {
        const { modalItems = [] } = this.state;
        if (modalItems.length === 0) return true;
        modalItems.pop();
        this.setState({ modalItems: [...modalItems] });
        return true;
    };

    componentDidMount() {
        const { preload = [] } = this.props;

        //添加统计
        this.stat({}, StatType.PAGE);
        this.setTimeout(() => {
            this.preLoadImg(preload);
        }, 300);
        return;
    }

    showModal = (modal: IModalItem, args: any = {}) => {
        modal = { ...modal };
        const { modals = [] } = this.props;
        for (const item of modals) {
            if (item.info.ename === modal.ename) {
                modal.uiItem = item;
                break;
            }
        }
        const { modalItems } = this.state;
        let items = [...modalItems];
        const clear = modal.uiItem.props.clear || args.clear;
        if (clear) items = [];
        items.push(modal);
        this.setState({ modalItems: items });
        //注册回调事件
        this.registerBack(() => {
            this.pageBack();
        }, clear);
    };

    componentDidUpdate(prevProps: IPage) {
        if (prevProps.last === false && this.props.last === true) {
            this.props.onResume?.();
            //判断有没有页面回退，回调函数
            // console.log("[Page]", "prevProps.", prevProps.lastPageName);
            this.props[`onResume${prevProps.lastPageName}`]?.();
        }
    }

    preLoadImg(imgs: any[] = []) {
        const images: any = [];
        for (let i = 0; i < imgs.length; i++) {
            const url = imgs[i].value;
            if (url.indexOf(".json") > 0) {
                Http.ajaxJson({ url });
            } else {
                images[i] = new Image();
                images[i].src = url;
            }
        }
    }

    renderBridge = (uiItems?: IUIItem[], props?: any) => {
        const { bridge, maxWidth, minWidth = 0 } = this.props;
        let ret = {};
        if (bridge && bridge.length > 0) {
            //modalItems:处理旧版使用BridgeProvider的项目的物理键返回关闭modal问题
            ret = this.renderChildren(bridge, {
                props,
                content: uiItems,
                back: this.pageBack,
                modalItems: this.state.modalItems,
                // 兼容旧项目 bridgeProvider 在page中注册的情况
                registerExitFunc: this.props.registerExitFunc,
            });
        } else ret = this.renderChildren(uiItems, props);
        if (maxWidth || (minWidth > 0 && maxWidth && maxWidth > 0)) {
            return <div style={{ maxWidth, minWidth, margin: "0 auto" }}>{ret}</div>;
        }
        return ret;
    };

    renderModules(): JSX.Element | null {
        return null;
    }

    getSources(flag: boolean = false) {
        const { sources = [], intent, module, totalSourceParams, moduleNum } = this.props;
        const ret = {
            ...SourceEngine.createByModules(module, moduleNum),
            ...SourceEngine.create(sources, flag, intent, totalSourceParams),
        };
        return ret;
    }

    renderSource = (uiItems?: IUIItem[], props?: any) => {
        const { uiItem, stores } = this.props as any;
        return (
            <SourceCenter
                uiItem={Manifest.ui()}
                ename={uiItem.info.ename}
                stores={stores}
                sources={this.getSources(false)}
                ref={(refs) => (this.childSourceManager = refs)}
            >
                {this.renderBridge(uiItems, props)}
                {this.renderModals()}
                {this.renderModules()}
            </SourceCenter>
        );
    };

    renderModals() {
        const { modalItems = [] } = this.state;
        return modalItems.map((item) => {
            return this.renderChildren([item.uiItem], { ...item.modelProps, visible: true, showPopup: true, notRegister: true });
        });
    }

    stat(info: any, type: StatType = StatType.CLICK) {
        const aWindow: any = window;
        if (!aWindow.Logger) return;
        const { uiItem, intent = {} } = this.props;
        // const intent = this.getIntent();
        Object.keys(info).forEach((key: string) => {
            if (LOG_INT_KEY.indexOf(key) < 0) {
                info[key] = info[key] + "";
            }
        });
        const statInfo = {
            plt: "webcloud",
            //租户ID
            gameId: GameConfig.gameId,
            // 服务
            server: GameConfig.server,
            //活动id
            activityId: `${GameConfig.server}_${GameConfig.gameId}`,
            //页面id
            pageId: uiItem.info.ename,
            ...info,
        };
        //统计文章信息
        if (!statInfo.artId && intent && intent.i && !isNaN(Number(intent.i))) statInfo.artId = intent.i;
        //按钮统计
        aWindow.Logger.profession(type, statInfo);
    }

    replaceScreenNumber = (screenStyle: any = {}) => {
        const screen = (this.props as any).screen || {};
        const { NotchH, NavigationH } = screen;
        // 都不存在
        if (!NotchH && !NavigationH) return {};
        const newStyle = {};
        // 存在（存在的情况下是在大厅的webview中打开的）
        Object.keys(screenStyle).map((key) => {
            let itemStyle = screenStyle[key];
            if (itemStyle.indexOf("NotchH") > -1) {
                itemStyle = itemStyle.replace(/NotchH/g, NotchH);
            }
            if (itemStyle.indexOf("NavigationH") > -1) {
                itemStyle = itemStyle.replace(/NavigationH/g, NavigationH);
            }
            newStyle[key] = itemStyle;
        });
        return newStyle;
    };

    getNotch = () => {
        const { notchMap, dataSource, screenStyle } = this.props;
        let style = {};
        if (dataSource) {
            if (notchMap && notchMap.length > 0) {
                notchMap.map((item: any) => {
                    if (Number(dataSource[item.label]) > 0) {
                        style = { ...style, ...this.props[item.value] };
                    }
                });
                return style;
            }
        }
        if (screenStyle) {
            return { ...style, ...this.replaceScreenNumber(screenStyle) };
        }
        return {};
    };

    pageRef = () => {
        return this["mPage"];
    };

    renderNotch(children: JSX.Element) {
        return <div style={this.getNotch()}>{children}</div>;
    }

    renderAct() {
        const { content = [], hidden, intent, uiItem, last, lastPageName, hiddenBar, moduleNum } = this.props;

        return (
            <div ref={Utils.ref(this, "mPage")} className={`react-page ${hidden} ${hiddenBar ? "react-page-hidden-bar" : ""}`} style={this.getStyle()}>
                {this.renderNotch(
                    <PageContext.Provider
                        value={{
                            intent: intent || this.props.jjHistory?.hash?.get()?.props || {},
                            registerBackListener: this.registerBack as any,
                            contextInit: true,
                            showModal: this.showModal,
                            pageBack: this.pageBack,
                            pageName: uiItem.info.ename,
                            pageRef: this.pageRef,
                            stat: this.stat.bind(this),
                            pageLast: last,
                            moduleNum,
                        }}
                    >
                        {this.renderSource(content, { jjHistory: this.props.jjHistory, pageLast: last, lastPageName })}
                    </PageContext.Provider>
                )}
            </div>
        );
    }
}

export default Page;
