import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import UIManifest from "src/engine/ui/UIManifest";

interface IText extends IBaseUI {
    value: string;
    style?: any;
    classNames?: string[];
    lineNumber?: number;
    dangerouslySet?: boolean; // 是否支持拼接DOM字符串
}
interface IState { }

@UIManifest.declareGG(["ggValue"], "Text", "Text组件")
class Text extends BaseUI<IText, IState> {
    render() {
        const { classNames = [], lineNumber } = this.props;
        let style = this.getStyle();
        // 解决下边多行省略对props造成的影响
        const classCache = [...classNames];
        if (lineNumber) {
            style = { ...style, WebkitBoxOrient: "vertical" };
            // webkitLineClamp 行内样式不起作用
            classCache.push(`text-overflow-ellipsis-${lineNumber}`);
        }
        let children = this.props.value;
        if (typeof children === "object") children = JSON.stringify(children);
        if (this.props.dangerouslySet) return <div className={`react-text ${classCache.join(" ")}`} style={style} dangerouslySetInnerHTML={{ __html: children }} />
        return (
            <div className={`react-text ${classCache.join(" ")}`} style={style}>
                {children}
            </div>
        );
    }
}

export default Text;
