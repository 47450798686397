import BridgeConfig from "./BridgeConfig";

export default class IosBridge {
    constructor(eventTarget, listeners) {
        this.eventTarget = eventTarget;
        //连接ios
        this.connectIosBridge((bridge) => {
            this.bridge = bridge;
            //注册监听方法
            this.register(listeners);
            eventTarget.fire(BridgeConfig.Events.INIT);
        });
    }

    //注册原生事件监听
    register = (listeners) => {
        //注册监听方法
        const keys = Object.keys(listeners);
        for (let i = 0; i < keys.length; i++) {
            const name = keys[i];
            const eventName = listeners[name];
            if (typeof eventName !== "string") {
                return this.register(eventName);
            }
            window[name] = (...data) => {
                this.eventTarget.fire(eventName, ...data);
            };
            //监听原生回调，并触发event事件
            this.bridge.registerHandler(name, (...data) => {
                this.eventTarget.fire(eventName, ...data);
            });
        }
    };

    //连接方法
    connectIosBridge(callback) {
        const { document } = window;
        const bridge = window.WebViewJavascriptBridgeNew;
        if (bridge) {
            return callback(bridge);
        }
        window.WVJBCallbacks = [callback];
        let WVJBIframe = document.createElement("iframe");
        WVJBIframe.style.display = "none";
        WVJBIframe.src = "wvjbscheme://__BRIDGE_LOADED__";
        document.documentElement.appendChild(WVJBIframe);
        setTimeout(function () {
            document.documentElement.removeChild(WVJBIframe);
        }, 0);
    }

    transformArgs(inter, args = {}) {
        let cache = { ...args };
        if (inter === "shareJson") {
            cache = cache.params;
        }
        return cache;
    }

    /**
     * @param {*} inter
     * @param {*} [args={}]
     * @param {*} callback
     * @returns
     * @memberof IosBridge
     * 消息发送
     */
    send(inter, args = {}, callback) {
        //特殊：需要添加一个{shareHT:true}的参数
        if (inter === "shareHT") {
            args[inter] = true;
        }
        if (!this.bridge) {
            return false;
        }
        args = this.transformArgs(inter, args);
        window.console.log("new ios", inter, args);
        this.bridge.callHandler(inter, args, callback);
        return true;
    }
}
