import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import PanelContext, { IPanelContext } from "src/engine/context/PanelContext";
import IUIItem from "src/engine/interface/IUIItem";
import UIManifest from "src/engine/ui/UIManifest";
import "./PCForm.scss";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import Utils from "src/lib/utils/Utils";
import Style from "src/lib/utils/Style";
import AutoContext from "src/engine/decorator/AutoContext";
import FormItem from "./FormItem";
import FormRule from "./FormRule";
import EditConfig from "src/engineEditor/config/EditConfig";
import StorageType from "src/lib/utils/storage/StorageType";
import Storage from "src/lib/utils/storage/Storage";

const { createForm } = require("rc-form");

export interface IPCForm extends IBaseUI {
    children: IUIItem[];
    label?: boolean;
    layout?: "vertical" | "inline" | "horizontal";
    labelLayout?: "vertical" | "horizontal";
    labelAlign?: "left" | "right";
    labelStyle?: any;
    itemStyle?: any;
    labelCol?: number;
    onChange?: any;
    wrapperCol?: number;
    buttons?: IUIItem[];
    buttonsOut?: boolean;
    required?: { [key: string]: boolean };
    //传递处理方法
    handleRet?: any;
    specialScript?: string;
    specialItem?: string;
    disabledChild?: string[];
    initValues?: any;
    isLastDelete?: boolean;
    noMerge?: boolean;
    errorStyle?: any;
    hiddenRequireLabelFlag?: any;
    noPanelForm?: boolean;
    //额外执行校验数据
    checkData?: { [key: string]: any };
    rankLabel?: boolean;
    autoParse?: boolean;
    // 提交时是否获取错误信息
    submitOnParse?: boolean;
    /* 提交时是否自动滚动到第一个错误字段 */
    scrollToFirstError?: boolean;

    /** 是否有本地缓存 */
    localCache?: boolean;
    /** 存储key */
    storageKey?: string;

    onError?: (msg: string) => void;
}

interface IState {
    flag?: boolean;
    kversion?: number;
    data?: any;
}

@UIManifest.declareGG(["ggValue", "dataValue"], "PCForm", "Form表单", UIManifest.Type.Mobile)
class PCForm extends BaseUI<IPCForm, IState> {
    FormIn: any;

    form: any;

    ISMAN?: boolean;

    private mStorage: Storage;

    private mCacheData: any = {};
    formData?: any;

    private formItemRef: any = {};

    constructor(props: IPCForm) {
        super(props);
        this.state = { flag: false, kversion: 1 };
        this.FormIn = createForm({ onValuesChange: this.onValuesChange })((item: any) => {
            return item.renderForm(item.form);
        });

        this.ISMAN = EditConfig.EDITOR_TEMPLATE_SERVER === "man";

        this.initCache();
    }

    onValuesChange = (props: any, changed: any, all: any) => {
        this.formData = all;
        // console.log("[PCForm]", "aal", all);
        const { localCache, storageKey } = this.props;
        if (!localCache || !storageKey) return;

        const data = Utils.deepClone(all);
        this.storage().set(storageKey, JSON.stringify(data));
    };

    private initCache() {
        const { localCache, storageKey } = this.props;
        if (localCache && storageKey) {
            const ret = this.storage().get(storageKey, "{}");
            try {
                this.mCacheData = JSON.parse(ret);
                this.formData = this.mCacheData;
            } catch (e) {}
        }
    }

    private storage() {
        if (!this.mStorage) this.mStorage = new Storage(StorageType.LocalStorage);
        return this.mStorage;
    }

    private onClearCache = () => {
        const { storageKey } = this.props;
        if (storageKey) this.storage().set(storageKey);
    };

    onSubmit = (callback: any) => {
        const { submitOnParse = false, scrollToFirstError = false } = this.props;
        //判断是否需要检测
        this.form.validateFields((err: any, values: any) => {
            //判断错才执行下一步
            if (!err) {
                const { handleRet, noPanelForm } = this.props;
                // 属性 noPanelForm 是为了兼容在 PCModal 中也传 handleRet 方法，导致后续逻辑失效的问题。
                if (handleRet && !noPanelForm) {
                    // 处理编辑文章的时候 先 create/update, 再 batchSave 的逻辑
                    this.handleRetMiddle();
                } else if (callback) {
                    if (submitOnParse) {
                        callback({ REV: true, DATA: this.onSave() });
                    } else {
                        callback(this.onSave());
                    }
                }
            } else {
                // console.log("formItemRef", this.formItemRef, this.formItemRef[Object.keys(err)[0]]);
                if (scrollToFirstError) this.formItemRef[Object.keys(err)[0]]?.scrollIntoView({ behavior: "smooth", block: "start" });
                const errors = Object.keys(err).map((errKey) => {
                    return err[errKey].errors[0];
                });
                console.log("[PCForm]", "", this.props.onError, errors, errors[0]);
                this.props.onError?.(errors[0]);
                if (submitOnParse) {
                    callback({ REV: false, MSG: err, DATA: this.onSave() });
                }
            }
        });
    };

    /**
     * 校验某个表单项
     * @param callback
     */
    validateItems = (callback: any, oldValue?: any) => {
        const validateArray = oldValue.split(",");
        this.form.validateFields(validateArray, (error: any, value: any) => {
            if (error) return;
            // console.log(value);
            callback(value);
        });
    };

    setValueGroupCache: string[] = [];
    setValueMapCache: string[] = [];
    setFieldsValue = (callback: any, oldValue: { [key: string]: any } = {}) => {
        if (typeof callback === "object" && Object.keys(oldValue).length === 0) {
            oldValue = callback;
        }
        if (typeof oldValue !== "object") {
            console.warn("传入数据错误");
            return;
        }
        const defaultDict: any = {},
            mapDict: any = {},
            groupDict: any = {};
        Object.keys(oldValue).map((key: string) => {
            if (this.setValueGroupCache.indexOf(key) >= 0) {
                groupDict[key] = oldValue[key];
            } else if (this.setValueMapCache.indexOf(key) >= 0) {
                mapDict[key] = oldValue[key];
            } else {
                defaultDict[key] = oldValue[key];
            }
        });
        this.form.setFieldsValue(defaultDict);
        this.setFieldsGroupValue(callback, groupDict);
    };

    // 设置PCFormGroup类型的数据
    setFieldsGroupValue = (callback: any, oldValue: { [key: string]: any[] } = {}) => {
        const keyArray = Object.keys(oldValue);
        if (keyArray.length === 0) return;
        const cache = {};
        for (const key of keyArray) {
            // const group:any[] = ;
            oldValue[key].map((item: any, index: number) => {
                const numberKey = `${key}.${index}`;
                Object.keys(item).map((k: string) => {
                    cache[`${numberKey}.${k}`] = item[k];
                });
            });
        }
        this.setFieldsValue(callback, cache);
    };

    resetFields = (callback: any, oldValue: any) => {
        this.form.resetFields();
        if (callback) {
            callback(oldValue);
        }
    };

    // // 编辑文章时传给button的click回调逻辑
    handleRetMiddle = () => {
        const { handleRet, source, data } = this.props;
        if (!source) {
            handleRet(this.onSave());
        } else {
            let params = {};
            if (Array.isArray(data)) params = this.onSave();
            else params = { ...data, ...this.onSave() };
            this.sourceContext.execSource(source, params, (ret: any) => {
                if (ret === false || ret.error === true) {
                    // window.alert(`网络出错，请重试！`);
                } else {
                    handleRet(ret);
                }
            });
        }
    };

    onSave = (getData?: boolean) => {
        const { data, noMerge, dataSource = {} } = this.props;
        let formData = this.form.getFieldsValue();
        if (!noMerge) {
            formData = { ...dataSource, ...data, ...formData };
        } else {
            formData = Utils.deepClone(formData);
        }
        console.log("[PCForm]:formData", noMerge, formData, "-data-", data);
        if (getData === true) this.setState({ data: formData });
        return formData;
    };

    onSame = () => {
        const { data } = this.props;
        const formData = this.onSave(true);
        const keys = Object.keys(formData);
        for (let key of keys) {
            if (formData[key] !== data[key]) {
                return false;
            }
        }
        return true;
    };

    getData = () => {
        this.onSave(true);
    };

    onClear = () => {
        this.form.resetFields();
    };

    forceClear = () => {
        const data = this.onSave();
        const initdata = this.props.data;
        const cache: any = {};
        Object.keys(data).forEach((key) => {
            cache[key] = void 0;
        });
        this.setFieldsValue(() => {}, { ...cache, ...initdata });
        this.setState({ kversion: (this.state.kversion || 1) + 1 });
    };

    renderButtons(uiItem: IUIItem[] = [], context: IPanelContext) {
        const { onClear, onSubmit, onSave, validateItems, setFieldsValue, resetFields, onSame, forceClear } = this;
        const props: any = {
            onClear,
            onSubmit,
            onClearCache: this.onClearCache,
            onSave,
            validateItems,
            setFieldsValue,
            resetFields,
            onSame,
            forceClear,
            formData: this.formData ?? this.props.data,
            formProps: this.props,
            stateData: this.state.data,
        };
        const { getFieldsValue } = this.form;
        props.getData = this.getData;
        const pProps = this.props as any;
        if (pProps.onClose) props.onClose = pProps.onClose;
        if (pProps.handleRet) props.onClick = onSubmit;
        const { btnWrapStyle } = Style.getMainStyle("PCForm", this.props);
        const getFieldValue = getFieldsValue();
        // Form 表单中 Button 的显示规则
        const uiItemList = uiItem.filter((item) => {
            const ioption: any = this.formRule.getItemRule(item, getFieldValue);
            return ioption.show !== false;
        });
        return <div style={btnWrapStyle}>{this.renderUIItems(uiItemList, context, props)}</div>;
    }

    handleRetClick = () => {
        // const { source } = this.props;
        // console.log(source);
    };

    getInitValue(name: string) {
        const { data, uiItem, dataSource, localCache } = this.props;
        // 增加 this.formData 的数据，否则其他数据修改后render时会恢复旧数据
        const nD = { ...dataSource, ...data, ...this.formData };
        // console.log('[PCForm]:getInitValue', data, name, PropsUtils.getValue(data, name));
        let ret = PropsUtils.getValue(nD, name);
        if (ret === undefined) {
            //如果有缓存
            if (localCache && this.mCacheData) ret = PropsUtils.getValue(this.mCacheData, name) ?? ret;
            if (typeof ret === "undefined") {
                const { data: uData } = uiItem.props;
                //优先检查自定义数据
                if (uData && uData[name]) {
                    return uData[name];
                }
            }
        }
        return ret;
    }

    formRule = new FormRule(this.props.uiItem);

    specialRender = (uiItem: IUIItem, context: IPanelContext): any => {
        return null;
    };

    createName(child: IUIItem) {
        const { ename, cname } = child.info;
        if (!this.ISMAN) {
            return { ename, cname };
        }
        const m_ename = child.props._ename || ename;
        const m_cname = child.props._cname || cname;
        return { ename: m_ename, cname: m_cname };
    }

    renderItem = (child: IUIItem, namePrex: string = "", childProps: any = {}, props: any = {}, noName: boolean = false, rank?: number) => {
        const component = this.specialRender(child, this.mPanelContext);
        if (component !== null) return component;
        const { disabledChild = [], checkData = {}, data, disabledForm, rankLabel } = this.props as any;
        const { getFieldError, getFieldDecorator } = this.form;
        const { ename, cname } = this.createName(child);
        let name = namePrex + (noName === true ? "" : ename);

        const formData = this.formData ?? this.props.data;
        //获取规则
        const ioption: any = this.formRule.getItemRule(child, formData, checkData);
        if (ioption.show === false) return null;
        //如果不满足显示条件
        ioption.initialValue = this.getInitValue(name);
        // if (ioption.initialValue === undefined) delete ioption.initialValue;
        //formItem属性
        props = {
            required: ioption.required,
            showCname: child.props.showCname,
            error: getFieldError(name),
            key: name,
            ...props,
            label: rankLabel ? `${rank ? rank + "." : ""}${cname}` : cname,
            describe: child.props.describe ?? "",
        };
        // 挪到下边单独处理，否则会对 PCForm 上的属性造成影响
        // 子组件上加 childItemStyle 改变 form 统一分配的item样式；
        if (child.props.childItemStyle) props.itemStyle = child.props.childItemStyle;
        if (child.props.childLabelStyle) props.labelStyle = child.props.childLabelStyle;
        if (child.props.childWrapperStyle) props.wrapperStyle = child.props.childWrapperStyle;
        //子组件属性
        childProps = {
            key: this.state.kversion || 1,
            ...ioption.props,
            ...childProps,
            formData,
            oData: data,
            setFieldsValue: this.setFieldsValue,
            onClear: this.onClear,
            resetFields: this.resetFields,
            validateItems: this.validateItems,
        };
        if (disabledChild.indexOf(ename) >= 0 || disabledForm) childProps.disabled = true;
        delete ioption.props;
        // childProps.formData = formData;
        // childProps.disabled = disabledChild.indexOf(ename) >= 0;
        // const childProps: any = { formData };
        let decorator;
        if (child.ui === "PCFormGroup" || child.ui === "PCFormMap") {
            if (child.ui === "PCFormGroup") this.setValueGroupCache.push(name);
            else this.setValueMapCache.push(name);
            childProps.renderItem = this.renderItem;
            childProps.renderFormItem = this.renderFormItem;
            childProps.form = this.form;
            childProps.namePrex = namePrex;
            childProps.initialValue = ioption.initialValue;

            if (typeof childProps.initialValue === "string") {
                try {
                    const { autoParse } = this.props;

                    if (autoParse !== false) childProps.initialValue = JSON.parse(childProps.initialValue);
                } catch (err) {
                    if (child.ui === "PCFormGroup") {
                        childProps.initialValue = [];
                    } else {
                        childProps.initialValue = {};
                    }
                }
            }
            decorator = (ui: any) => ui;
        } else {
            //预防错误
            if (!name || name === "") name = "TEST";
            decorator = getFieldDecorator(name, ioption);
        }

        // console.log('[PCForm]:', name, childProps);
        // return <FormItem  {...props} error={getFieldError(name)} hideRequiredMark={hiddenRequireLabelFlag}>
        //     {decorator(this.renderUIItem(child, this.mPanelContext, childProps))}
        // </FormItem>
        return this.renderFormItem(props, decorator(this.renderUIItem(child, this.mPanelContext, childProps)));
    };

    renderFormItem = (props: any, children: any) => {
        const { hiddenRequireLabelFlag = false, label, layout = "vertical", formItemExt = [] } = this.props as any;
        // const { kversion = 1 } = this.state;
        // props.key = props.key + kversion;
        // console.log("props.key", props.key);
        return (
            <FormItem
                showLabel={label}
                {...this.formItemStyle}
                layout={layout}
                {...props}
                titleExt={formItemExt}
                hideRequiredMark={hiddenRequireLabelFlag}
                formItemRef={(el: any) => (this.formItemRef[props.key] = el)}
            >
                {children}
            </FormItem>
        );
    };

    renderFormItems(chidlren: IUIItem[]) {
        return chidlren.map((child: IUIItem, index: number) => {
            return this.renderItem(child, "", {}, {}, false, index + 1);
        });
    }

    mPanelContext: IPanelContext;

    formItemStyle: any;

    initStyle() {
        const styles = Style.getMainStyle(["labelStyle", "wrapperStyle", "itemStyle", "errorStyle"], this.props);
        const { labelAlign = "left", labelCol, wrapperCol } = this.props;
        if (labelCol && wrapperCol) {
            const labelWidth = Math.round((labelCol / (labelCol + wrapperCol)) * 100);
            styles.labelStyle = { ...styles.labelStyle, width: `${labelWidth}%`, textAlign: labelAlign };
            styles.wrapperStyle = { ...styles.wrapperStyle, width: `${100 - labelWidth}%` };
        }
        this.formItemStyle = styles;
        // console.log('[PCFormItem]:222', styles, this.formItemStyle);
    }

    /**
     * form区域的生成
     */
    @AutoContext(PanelContext, "mPanelContext")
    renderForm() {
        const { children = [], buttons, buttonsOut = false, onError } = this.props;
        this.initStyle();
        return [
            <div key={0} className={`react-form ${onError ? "no-error" : ""}`} style={this.getStyle()}>
                {this.renderFormItems(children)}
                {!buttonsOut ? this.renderButtons(buttons, this.mPanelContext) : null}
            </div>,
            buttonsOut ? this.renderButtons(buttons, this.mPanelContext) : null,
        ];
    }

    renderAct() {
        //绑定FormUI生成
        const FormIn = this.FormIn;
        return (
            <FormIn
                key={this.state.kversion}
                renderForm={(form: any) => {
                    this.form = form;
                    return this.renderForm();
                }}
            />
        );
    }
}

export default PCForm;
