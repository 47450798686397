import IPConfig from "src/IPConfig";

const IP = IPConfig.IP; // "http://iad.test.jj.cn:7001/";
const IP_NOPORT = IPConfig.IP_NOPORT; //"http://iad.test.jj.cn/";
// const API = `${IP}`;
const API = `${IP}cloudplt/api/`;

const Config = {
    IP_NOPORT,
    IP,
    API,

    //详情页接口
    contentDetail: `${API}${IPConfig.API}/source/queryDetail`,
    uploadImg: `${API}bbsd/3.0/imgupload`, //回帖上传图片
    uploadImg2: `${API}oss/batch/uploadPic`, //回帖上传图片2
    //内容获取
    contentQuery: `${API}${IPConfig.API}/source/querySources`,

    getConfig(server: string, gameId: string, file: string) {
        return `${IPConfig.IP_TEMP}${gameId}/${server}/temp/src/${file}.json`;
    },
};

export default Config;
