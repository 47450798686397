import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import { IInfo } from "./ExecScript";

interface IBasePropsScript {
    labelKey: string;
    /**
     * 如果value为方法是否自动执行
     */
    execFun?: boolean;
    /**
     * 回调监听
     */
    execCallback?: boolean;

    execFunArg?: boolean;

    defaultValue?: any;
}

/**
 *
 *
 * @class BasePropsScript
 */
@UIManifest.declare("BaseProps", "基本属性脚本", UIManifest.Type.Script)
class BasePropsScript extends Script<IBasePropsScript> {
    exec(info: IInfo, callback: (info: IInfo) => any) {
        const { defaultValue, labelKey, execFunArg, execIngoreRet } = this.props as any;

        info.name = labelKey || this.mUIItem.info.ename;
        if (this.context.context.checkList) this.context.context.checkList.push(info.name);
        const oldValue = info.value;
        info.value = PropsUtils.getValue(this.context.props, info.name);
        if (typeof info.value === "boolean") info.value = info.value + "";
        // console.log("[BasePropsScript]:", labelKey, { ...info });
        if ((info.value === undefined || info.value === "" || info.value === null) && defaultValue !== undefined) info.value = defaultValue;
        const { execFun = false, execCallback = false } = this.props;
        //是否执行
        if (execFun && typeof info.value === "function") {
            if (execCallback) {
                info.value((data: any) => {
                    info.value = data;
                    callback(info);
                }, oldValue);
                //阻止继续执行
                return false;
            } else {
                info.value = execFunArg ? info.value(oldValue) : info.value();
                if (execIngoreRet) info.value = oldValue;
            }
        }
        return true;
    }
}

export default BasePropsScript;
