import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import Utils from "src/lib/utils/Utils";

interface IAggregationScript {
    isAgg?: boolean;
    merge?: boolean;
    toArray?: boolean;
    checkNull?: boolean;
    unUseCache?: boolean;
}

/**
 *
 *
 * @class AggregationScript
 */
@UIManifest.declare("AggregationScript", "聚合处理", UIManifest.Type.ScriptAgg)
class AggregationScript extends Script<IAggregationScript> {
    handleData(value: any): any {
        let bool = true;
        // 如果value是空对象，直接返回false
        if (!this.props.checkNull && Object.keys(value).length === 0) {
            return false;
        }
        for (const k in value) {
            if (value[k]) {
                if (typeof value[k] === "object") {
                    bool = bool && this.handleData(value[k]);
                } else {
                    bool = bool && !!value[k];
                }
            } else {
                bool = bool && !!value[k];
            }
        }
        return bool;
    }
    public useCache = () => {
        const { unUseCache = false } = this.props;
        return !unUseCache;
    };
    protected handle(value: any, props: IAggregationScript) {
        if (this.props.isAgg) {
            const data = this.handleData(value);
            return data;
        }
        if (this.props.merge) {
            const keys = Object.keys(value) || [];
            const sortArray = keys.sort((a: string, b: string) => {
                const res = Number(a) - Number(b);
                if (isNaN(res)) return 0;
                return res;
            });
            let lastMap = {};
            sortArray.map((key: string) => {
                if (Object.prototype.toString.call(value[key]) === "[object Object]") {
                    lastMap = { ...lastMap, ...value[key] };
                } else {
                    lastMap = { ...lastMap, key: value[key] };
                }
            });
            value = lastMap;
        }
        if (this.props.toArray) {
            value = Utils.objectValues(value);
            // const cacheArray: any = [];
            // const keys = Object.keys(value);
            // for (const index of keys) {
            //     cacheArray[index] = value[index];
            // }
            // value = cacheArray;
            // console.log(value);
        }
        return value;
    }
}

export default AggregationScript;
