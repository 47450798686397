import Utils from "src/lib/utils/Utils";

/**
 * 将数值四舍五入(保留2位小数)后格式化成金额形式
 *
 * @param num 数值(Number或者String)
 * @return 金额格式的字符串,如'1,234,567.45'
 * @type String
 */
function formatCurrency(num: any, len = 2) {
    num = num.toString().replace(/[^\d\.-]/g, ""); //转成字符串并去掉其中除数字, . 和 - 之外的其它字符。
    if (isNaN(num)) num = "0"; //是否非数字值
    let sign = num == (num = Math.abs(num));

    //取小数点
    if (len > 0) {
        const cents = (num - Math.floor(num)).toFixed(len);
        return (
            (sign ? "" : "-") +
            Math.floor(num)
                .toString()
                .replace(/\d{1,3}(?=(\d{3})+$)/g, "$&,") +
            cents.slice(1)
        );
    }

    return (
        (sign ? "" : "-") +
        Math.round(num)
            .toString()
            .replace(/\d{1,3}(?=(\d{3})+$)/g, "$&,")
    );

    // num = Math.floor(num * 100 + 0.50000000001); //下舍入
    // let cents: any = (num % 100).toFixed(len);  //求余 余数 = 被除数 - 除数 * 商
    // cents = (cents < 10) ? "0" + cents : cents; //小于2位数就补齐
    // num = Math.floor(num / 100).toString().replace(/\d{1,3}(?=(\d{3})+$)/g, "$&,");
    // // for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) { //每隔三位小数分始开隔
    // //     //4 ==> 三位小数加一个分隔符，
    // //     num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
    // // }
    // return (((sign) ? '' : '-') + num + '.' + cents);
}

export default class Formmatter {
    static formatCurrency = formatCurrency;

    static formmatter(value: string, type: string) {
        switch (type) {
            case "xxx,xxx":
                return (Math.round(Number(value)) + "").replace(/\d{1,3}(?=(\d{3})+$)/g, "$&,");
            case "xxx,xxx.00":
                return formatCurrency(value, 2);
            case "xxxx-xx-xx":
                value += "";
                return value.slice(0, 4) + "-" + value.slice(4, 6) + "-" + value.slice(6, 8);
            case "[percent]":
                return `${(Number(value) * 10000) / 100}%`;
            case "[percent.00]":
                return `${Number(value).toFixed(2)}%`;
            case "[.00]":
                return `${Number(value).toFixed(2)}`;
            case "[.0]":
                return `${Number(value).toFixed(1)}`;
            case "[(percent*100).00]":
                return `${(Number(value) * 100).toFixed(2)}%`;
            case "toNumber":
                return Number(value);
            case "cardNumToStr":
                return `'${value}`; // 身份证号等导出excel的时候变为字符串格式
            default:
                return Utils.format(value, type);
        }
    }

    static strFormmatter(value: any, type: string) {
        switch (type) {
            case "toBoolean":
                value = value === "true" || value === true ? true : false;
                return value;
            case "toNumber":
                return Number(value);
            case "toSingleArray":
                return value?.split(",");
            default:
                return value;
        }
    }
}
