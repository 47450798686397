/*
 * @Author: quanyj
 * @Date: 2018-06-21 18:57:02
 * @Last Modified by: zhanghao
 * @Last Modified time: 2019-10-23 18:30:18
 */

const pluses = /\+/g;
const config: any = { defaults: {} };

function encode(s: any) {
    return config.raw ? s : encodeURIComponent(s);
}

function decode(s: any) {
    return config.raw ? s : decodeURIComponent(s);
}

function stringifyCookieValue(value: any) {
    return encode(config.json ? JSON.stringify(value) : String(value));
}

function parseCookieValue(s: any) {
    if (s.indexOf('"') === 0) {
        // This is a quoted cookie as according to RFC2068, unescape...
        s = s.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, "\\");
    }

    try {
        // Replace server-side written pluses with spaces.
        // If we can't decode the cookie, ignore it, it's unusable.
        // If we can't parse the cookie, ignore it, it's unusable.
        s = decodeURIComponent(s.replace(pluses, " "));
        return config.json ? JSON.parse(s) : s;
    } catch (e) {
        // console.log(e);
    }
}

const isFunction = (fn: any) => {
    return !!fn && typeof fn !== "string" && !fn.nodeName && fn.constructor !== Array && /^[\s[]?function/.test(fn + "");
};

function read(s: any, converter?: any) {
    const value = config.raw ? s : parseCookieValue(s);
    return isFunction(converter) ? converter(value) : value;
}

export default class Cookie {
    static getItem(key?: any, value?: any, options?: any) {
        let result: any = key ? undefined : {};
        // To prevent the for loop in the first place assign an empty array
        // in case there are no cookies at all. Also prevents odd result when
        // calling $.cookie().
        const cookies: any = document.cookie ? document.cookie.split("; ") : [];
        const l = cookies.length;
        //window.alert(document.cookie);
        for (let i = 0; i < l; i++) {
            const parts = cookies[i].split("=");
            const name = decode(parts.shift());
            let cookie = parts.join("=");

            if (key === name) {
                // If second argument (value) is a function it's a converter...
                result = read(cookie, value);
                break;
            }

            // Prevent storing a cookie that we couldn't decode.
            cookie = read(cookie);
            if (!key && cookie !== undefined) {
                result[name] = cookie;
            }
        }

        return result;
    }

    // static getAllItem(){
    //     let result: any = key ? undefined : {};
    //     const cookies: any = document.cookie ? document.cookie.split("; ") : [];
    //     const l = cookies.length;
    //     //window.alert(document.cookie);
    //     for (let i = 0; i < l; i++) {
    //         const parts = cookies[i].split("=");
    //         const name = decode(parts.shift());
    //         let cookie = parts.join("=");

    //         if (key === name) {
    //             // If second argument (value) is a function it's a converter...
    //             result = read(cookie, value);
    //             break;
    //         }

    //         // Prevent storing a cookie that we couldn't decode.
    //         cookie = read(cookie);
    //         if (!key && cookie !== undefined) {
    //             result[name] = cookie;
    //         }
    //     }
    // }

    static setItem(key: any, value: any, options: any = {}) {
        options = { ...config.defaults, ...options };

        if (typeof options.expires === "number") {
            const days = options.expires;
            const t = (options.expires = new Date());
            t.setMilliseconds(t.getMilliseconds() + days * 864e5);
        }

        window.document.cookie = [
            encode(key),
            "=",
            stringifyCookieValue(value),
            options.expires ? "; expires=" + options.expires.toUTCString() : "", // use expires attribute, max-age is not supported by IE
            options.path ? "; path=" + options.path : "",
            options.domain ? "; domain=" + options.domain : "",
            options.secure ? "; secure" : "",
        ].join("");
    }

    static removeItem(key: any, value?: any, options?: any) {
        Cookie.setItem(key, "", { ...options, expires: -1 });
        return !Cookie.getItem(key);
    }

    static removeAllItem() {
        const cookies: any = document.cookie ? document.cookie.split("; ") : [];
        for (const item of cookies) {
            const arr = item.split("=");
            const key = arr[0];
            Cookie.setItem(key, "", { expires: -1 });
        }
    }
}
