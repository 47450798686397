import * as React from "react";
import DecoratorUtils from "./DecoratorUtils";
import ConfigContext from "./ConfigContext";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import { Context } from "./AutoContext";

const CONFIG_NAME = "_config";

/**
 * 自动注解监听TContext
 * @param checkValue 根据属性[checkValue]的状态值，进行获取
 */
const Consumer = (checkValue?: string) => {
    return DecoratorUtils.decorator((that: any, oldFunc: any) => {
        if (checkValue && !PropsUtils.getValue(that.props, checkValue)) return oldFunc();
        return (
            <Context context={ConfigContext}>
                {(context) => {
                    return oldFunc(context.getConfig());
                }}
            </Context>
        );
    });
};

/**
 * @param propsName
 * @param checkValue 根据属性[checkValue]的状态值，进行获取
 */
const Provider = (propsName: string[] = [], checkValue?: string, extList?: string) => {
    return DecoratorUtils.decorator((that: any, oldFunc: any) => {
        if (checkValue && !PropsUtils.getValue(that.props, checkValue)) return oldFunc();
        const config = {};
        let list = propsName;
        if (extList) {
            list = [...list];
            const getList = PropsUtils.getValue(that.props, extList) || [];
            getList.map((item: string) => {
                list.push(`props.${item},${item}`);
            });
        }
        list.map((item, index) => {
            const arr = item.split(",");
            const value = PropsUtils.getValue(that, arr[0]);
            if (value) config[arr[1] || arr[0]] = value;
        });
        return (
            <Context context={ConfigContext}>
                {(context) => {
                    const getConfig = () => {
                        return {
                            ...context.getConfig(),
                            ...config,
                        };
                    };
                    return <ConfigContext.Provider value={{ config, getConfig }}>{oldFunc()}</ConfigContext.Provider>;
                }}
            </Context>
        );
    });
};

export default { CONFIG_NAME, Consumer, Provider };
