import * as React from "react";

import "./Image.scss";
import UIManifest from "src/engine/ui/UIManifest";

import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import Utils from "src/lib/utils/Utils";

interface IImage extends IBaseUI {
    src: string;
    height?: string;
    margin?: string;
    useImg?: string;
    value?: any;
    maps?: any;
    style?: any;
    defaultImg?: string;
    hasHover?: string;
    autoSize?: string;
    urlFormmater?: string;
    canBrowse?: string; //是否可以跳到浏览器查看
    distriStyle?: any;
    errorImage?: string;
}

@UIManifest.declareGG(["ggValue"], "Image", "图片组件", UIManifest.Type.Mobile, true)
class Image extends BaseUI<IImage> {
    auto(style: any, url: string) {
        const { autoSize = [] } = this.props as any;
        if (!url || autoSize.length === 0) return;
        const wh = Utils.getWH(url);
        const whPer = Number(wh[0]) / Number(wh[1] || 0);
        for (const item of autoSize) {
            if (Utils.compare(item.type, whPer, item.key)) {
                style.backgroundSize = item.value;
                return;
            }
        }
    }

    getImg() {
        const { data, ename, src, value, maps = {}, urlFormmater = "xxx", defaultImg = "" } = this.props as any;

        let img = src || value;
        img =
            maps[img] ||
            img ||
            (typeof data === "object" && !Array.isArray(data) && data[ename]) ||
            (typeof data === "object" && defaultImg) ||
            data ||
            defaultImg;
        img = urlFormmater ? urlFormmater.replace("xxx", img) : img;
        return img;
    }

    getThisStyle = () => {
        const { distriStyle = [] } = this.props;
        if (distriStyle.length === 0) return "style";
        for (const item of distriStyle) {
            const { label = "", type = "=", key = "", valueType = "self", value } = item;
            const v1 = Utils.getValue(this.props, label.split("."));
            let v2 = "";
            if (valueType === "self") {
                v2 = key;
            } else {
                v2 = Utils.getValue(this.props, key.split("."));
            }

            if (Utils.compare(type, v1, v2)) {
                return value;
            }
        }
        return "style";
    };

    onError = (e: any) => {
        const { errorImage } = this.props;
        if (errorImage && e && e.target) {
            e.target.src = errorImage;
        }
    };

    render() {
        const { height, margin, useImg, canBrowse, hasHover = "false", moreClass = "", customValue = "", className } = this.props as any;
        let img = this.getImg();
        const styleString = this.getThisStyle() || "style";
        const style = this.props[styleString] || this.props["style"];
        const computeStyle = this.getComputeStyle();
        const sStyle = this.getStyle({ margin, height: height || "3rem", ...computeStyle, ...style });

        this.auto(sStyle, img);
        let ui;
        img = img || customValue;
        if (useImg === "true") {
            ui = (
                <div className={`react-img-area ${className}` + (hasHover === "true" ? "pc-img-hover-style" : "") + (moreClass ? " " + moreClass : "")}>
                    <img className={`react-image img-tag ${className}`} style={sStyle} src={img} onError={this.onError} />
                </div>
            );
        } else {
            if (img) sStyle.backgroundImage = img.indexOf("url(") === 0 ? `${img}` : `url(${img})`;
            ui = (
                <div
                    className={`react-image ${className} ` + (hasHover === "true" ? "pc-img-hover-style" : "") + (moreClass ? " " + moreClass : "")}
                    style={sStyle}
                />
            );
        }

        if (canBrowse === "true") {
            ui = (
                <a href={img} target="_blank" style={{ display: "block" }} className={moreClass} title="点击查看">
                    {ui}
                </a>
            );
        }
        return ui;
    }
}

export default Image;
