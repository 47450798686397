import BridgeConfig from "./BridgeConfig";

export default class IosBridge {
    constructor(eventTarget, listeners = {}) {
        this.eventTarget = eventTarget;
        //连接ios
        this.connectIosBridge(bridge => {
            this.bridge = bridge;
            //注册监听方法
            this.register(listeners);
            eventTarget.fire(BridgeConfig.Events.INIT);
        });
    }

    //注册原生事件监听
    register = listeners => {
        //注册监听方法
        const keys = Object.keys(listeners);
        for (let i = 0; i < keys.length; i++) {
            const name = keys[i];
            const eventName = listeners[name];
            if (typeof eventName !== "string") {
                return this.register(eventName);
            }
            window[name] = (...data) => {
                this.eventTarget.fire(eventName, ...data);
            };
            //监听原生回调，并触发event事件
            this.bridge.registerHandler(name, (...data) => {
                this.eventTarget.fire(eventName, ...data);
            });
        }
    };

    /**
     * 针对ios连接
     */
    connectIosBridge(callback) {
        const { document } = window;
        const bridge = window.WebViewJavascriptBridge;
        if (bridge) {
            return setTimeout(() => {
                callback(bridge);
            }, 10);
        }
        document.addEventListener(
            "WebViewJavascriptBridgeReady",
            function() {
                callback(window.WebViewJavascriptBridge);
            },
            false
        );
    }

    /**
     * @param {*} inter
     * @param {*} [args={}]
     * @param {*} callback
     * @returns
     * @memberof IosBridge
     */
    //消息发送
    send(inter, args = {}, callback) {
        // window.console.log("zxx--ios-", "-inter-", inter, "-args-", args, "-callback-", callback);
        if (!this.bridge) {
            return false;
        }
        this.bridge.send(
            {
                method: inter,
                params: JSON.stringify([args])
            },
            callback
        );
        return true;
    }
}
