import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import IExecConfig from "src/component/props/PropsGGEditor/exec/IExecConfig";
import Utils from "src/lib/utils/Utils";
import IPConfig from "src/IPConfig";

interface IClickGoUrl {
    isNew?: boolean;

    url?: string;
    releaseUrl?: string;

    params?: { [key: string]: any };

    reload?: boolean;

    isBack?: boolean;

    useTimestamp?: boolean;
    timeKey?: string;

    // hash跳转后刷新页面
    hashOverReload?: boolean;

    // 请求参数为数组，需批量发起请求
    isParamsArray?: boolean;

    // 是否只获取url
    isGetUrl?: boolean;

    noHash?: boolean;
}

/**
 *
 *
 * @class ClickGoUrl
 */
@UIManifest.declare("ClickGoUrl", "跳转Url脚本", UIManifest.Type.Script)
class ClickGoUrl extends Script<IClickGoUrl> {
    protected handleConfig(config: IExecConfig) {
        config.name = "onClick";
        return config;
    }

    setUrlParams(url: string, params: any) {
        for (const key of Object.keys(params)) {
            if (params[key] || params[key] === 0) {
                url = Utils.setUrlParams(key, params[key], url);
            }
        }
        return url;
    }

    private handleUrl(url: string, headers: any) {
        if (url.indexOf("[") > 0) {
            const list = Utils.parser(/\[(.+?)\]/gim, url, 1);
            list.map((item) => {
                if (headers[item] !== undefined) {
                    url = url.replace(`[${item}]`, headers[item]);
                    // delete headers[item];
                }
            });
        }
        return url;
    }

    goUrl = (url: string) => {
        const { isNew = true } = this.props;
        try {
            if (isNew) {
                const newWindow = window.open(url);
                if (newWindow) newWindow.opener = null;
            } else {
                window.location.href = url;
                if (this.props.hashOverReload) window.location.reload();
            }
        } catch (err) {}
    };

    reload() {
        const { useTimestamp, timeKey = "rti", noHash } = this.props;
        if (noHash) {
            window.location.href = location.origin + location.pathname + location.search;
        } else if (useTimestamp || noHash) {
            const location = window.location;
            if (location.origin) {
                const url = Utils.setUrlParams(timeKey, new Date().valueOf(), location.origin + location.pathname + location.search) + location.hash;
                window.location.href = url;
            } else {
                window.location.href = Utils.setUrlParams("rti", new Date().valueOf());
            }
        } else {
            window.location.reload();
        }
    }

    createUrl = (url: string, params = {}) => {
        //处理动态url
        url = this.handleUrl(url, params);
        //拼接参数
        url = this.setUrlParams(url, params);
        return url;
    };

    protected handle(value: any, props: IClickGoUrl) {
        if (this.props.reload) {
            this.reload();
            return;
        }
        if (this.props.isBack) {
            window.history.go(-1);
            return;
        }
        let { url, releaseUrl, isGetUrl = false } = this.props;
        if (IPConfig.release && releaseUrl) url = releaseUrl;
        if (!url && typeof value === "object" && value.url) {
            url = value.url;
            delete value.url;
        }
        if (value && typeof value === "string" && value.indexOf("http") === 0) url = value;
        if (value && typeof value === "string" && value.indexOf("//") === 0) {
            url = `http:${value}`;
        }
        if (!url) return;

        let { params } = this.props;
        if (typeof value === "object" && !this.props.isParamsArray) {
            params = { ...this.props.params, ...value };
        } else if (typeof value === "string" && url !== value) {
            params = { ...this.props.params, value };
        }
        // console.log("url", url, params, value, typeof value, (typeof value === "object" && !this.props.isParamsArray), (typeof value === "string" && url !== value))
        if (Array.isArray(value) && this.props.isParamsArray) {
            params = [...value];
            params.forEach((v: any) => {
                const lastUrl = this.createUrl(`${url}`, v);
                this.goUrl(lastUrl);
            });
            return;
        }

        const lastUrl = this.createUrl(url, params);
        if (!isGetUrl) {
            this.goUrl(lastUrl);
            return;
        } else {
            return lastUrl;
        }
        // };
    }
}

export default ClickGoUrl;
