import IRule, { IRuleProps } from "./IRule";
import IUIItem from "src/engine/interface/IUIItem";

interface IRuleArray extends IRuleProps {
    // 数组最大长度
    max?: number;
    // 数组最小长度
    min?: number;
}

/**
 * 校验 【数组】
 */
class RuleArray implements IRule {
    type: "array";

    props: IRuleArray;

    constructor(props: IRuleArray) {
        this.props = props;
    }

    defineMessage() {
        const { max, min } = this.props;
        let selfM = `数组长度不正确，长度应`;
        if (min !== void 0 && max === void 0) {
            selfM += `大于${min}`;
        } else if (max !== void 0 && min === void 0) {
            selfM += `小于${max}`;
        } else if (max !== void 0 && min !== void 0) {
            selfM += `在${min}~${max}之间`;
        }
        return selfM;
    }

    exec(
        // 表单值
        formData: any,
        // 扩展属性, rules同级
        expend: any,
        // 当前表单 UIItem
        uiItem: IUIItem
    ) {
        const { message, max, min } = this.props;
        // 如果 return false 则不添加规则
        return { message: message ? message : this.defineMessage(), type: this.type, max, min };
    }
}

export default RuleArray;
