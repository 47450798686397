import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import { IInfo } from "src/component/props/PropsGGEditor/exec/ExecScript";

interface IExtStyleScript {
    self?: boolean;
    extStyleString: boolean;
}

/**
 *
 *
 * @class ExtStyleScript
 */
@UIManifest.declare("ExtStyleScript", "扩展样式脚本", UIManifest.Type.Script)
class ExtStyleScript extends Script<IExtStyleScript> {
    exec(config: IInfo, callback: (info: IInfo) => any) {
        const { self = true, extStyleString = "select" } = this.props;
        if (self) {
            config.name = config.toName = "extStyleString";
            config.value = extStyleString;
        } else {
            config.name = config.toName = "_allDataAllot";
            config.value = { extStyleString };
        }
        return true;
    }
}

export default ExtStyleScript;
