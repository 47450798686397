import { Source } from "./Source";
import { Toast } from "antd-mobile";

/**
 *
 *
 * @class ToastSource
 */
class ToastSource extends Source {
    toast: { type: "SHORT" | "LONG"; [key: string]: string };
    // this.message(data.MSG, true);
    message(msg: string, success: boolean = true, code: number = this.code) {
        const toast = this.toast;
        let toastMsg = "";
        if (this.toast && Object.keys(this.toast).length > 0) {
            toastMsg = toast[code] || toast[`${success}`];
        } else {
            toastMsg = msg;
        }
        const { duration = 1, mask = "false" } = toast || {};
        if (toastMsg) {
            Toast.show(toastMsg, Number(duration), mask === "true");
        }
        // msg = toast[code] || toast[`${success}`] || msg;
    }
}

export default ToastSource;
