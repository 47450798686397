import React from "react";
import BaseComponent from "src/engine/ui/BaseComponent";
import { IDataType } from "./Manager";
import Cache from "./utils/Cache";
import { SourceContext, ISourceContext } from "./SourceContext";
import IUIItem from "../interface/IUIItem";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import { Context } from "../decorator/AutoContext";

interface ISourceUI {
    dataSource: string;
    uiItem: IUIItem;
    theme?: { [key: string]: any };
}

interface IState {
    flag: boolean;
}

class SourceUI extends BaseComponent<ISourceUI, IState> {
    //取消监听器列表
    unsub: Cache = new Cache();

    state: IState = { flag: false };

    mMount: boolean = false;

    mSourceContext: ISourceContext;

    componentDidMount() {
        const { dataSource = "" } = this.props;
        if (typeof dataSource === "string") {
            //初始化数据
            const dataKey = dataSource.split(",");
            //获取初始化数据
            for (const key of dataKey) {
                this.register(key);
            }
        }
        this.mMount = true;
    }

    updateData = () => {
        if (this.mMount) this.setState({ flag: !this.state.flag });
    };

    register(key: string) {
        //兼容 dataList.demo 格式
        const name = key.split(".")[0];
        //监听
        this.unsub.add(name, this.mSourceContext.mManager?.listener(name, this.updateData, key.split(".")[1]));
    }

    getProps(context: ISourceContext, dSource?: string) {
        const dataSource = dSource || this.props.dataSource || "";
        if (typeof dataSource !== "string") {
            return false;
        }
        //初始化数据
        const dataKey = dataSource.split(",");
        if (dataKey.length === 0) return false;
        let props: any = {},
            i = 0;
        for (const key of dataKey) {
            const item = key.split(".");
            const bean = context.mManager?.getSourceBean(item[0]);
            // const ui = typeof this.props.ui === "string" ? this.props.ui : "func";
            // if (ui === "Tabs") {
            // }
            if (bean) {
                let dataType: IDataType = bean.data;
                //识别 dataList.demo 格式
                if (item.length === 2) {
                    // console.log("[SourceUI]:", this.props.uiItem.info, dataType, bean);
                    const { pagination = {} } = dataType.data || {};
                    dataType = { data: dataType.data[item[1]] } as IDataType;
                    if (item[0] === "dataList" && dataType.data) {
                        const { totalNum: total = 0, pageSize: start = 1, pageNum: count = 10 } = pagination[item[1]] || {};
                        dataType = { ...dataType, total, start, count };
                    }
                } else if (item.length > 1) {
                    dataType = { data: PropsUtils.getValue(dataType.data, item.slice(1).join(".")), loading: false };
                }
                if (i === 0) {
                    props = { ...dataType };
                } else {
                    props[key] = dataType;
                }
            }
            i++;
        }
        // const { dSourceStr } = this.props;
        //只取dataSource下某个字段的值
        // if (dSourceStr !== undefined && props.data && props.data[dSourceStr]) {
        //     props.data = props.data[dSourceStr];
        // }
        props.dataSource = props.data;
        delete props.data;
        // const ui = typeof this.props.ui === "string" ? this.props.ui : "func";
        return props;
    }
    public shouldComponentUpdate(nextProps: any = {}, nextState = {}) {
        return true;
    }

    componentWillUnmount() {
        this.mMount = false;
        this.unsub.clearAll();
    }

    render() {
        const { children, dataSource, uiItem, ...other } = this.props as any;
        return (
            <Context context={SourceContext}>
                {(context: ISourceContext) => {
                    this.mSourceContext = context;
                    const props = dataSource ? this.getProps(context) || {} : {};
                    return children({ ...other, ...props });
                }}
            </Context>
        );
    }
}

export default SourceUI;
