import Manager, { ISourceBean } from "../Manager";
import IHttp from "src/lib/http/IHttp";
import Utils from "src/lib/utils/Utils";

/**
 *
 *
 * @class Handle
 */
class Handle {
    mManager: Manager;

    constructor(manager: Manager) {
        this.mManager = manager;
    }

    handleHttp(bean: ISourceBean, headers: any, http?: IHttp, oldHeaders?: any): IHttp | false {
        let ajax: IHttp = {} as IHttp;
        //是否需要根据参数生成headers
        if (bean.source.handleUrl) ajax = bean.source.handleUrl(headers, oldHeaders);
        ajax = {
            //继承原始
            ...bean.source.ajax,
            //继承处理
            ...ajax,
            //最后为传入
            ...http,
            //写入headers
            headers,
        };
        return !ajax.url ? false : ajax;
    }

    handleHeaders(bean: ISourceBean, headers: any) {
        const { extendSecond, extendList } = bean.source;
        const extendData = this._getExtendList(extendList);
        const data = bean.data.data;

        if (extendSecond) {
            const extendsKey = extendSecond.split(",");
            //source中存在且继承数据源中存在，优先取source中的
            extendsKey.map((key: string) => {
                if (key && data[key] && extendData[key]) {
                    delete extendData[key];
                }
            });
        }
        headers = {
            ...bean.source.headers,
            //继承原始数据
            ...bean.data.headers,
            //继承继承数据
            ...this._getExtendSource(bean.source.extendSource),
            //继承继承数据
            ...extendData,
            //继承新headers
            ...headers,
        };
        // 处理特殊格式的请求body
        const { jsonHeader, activeHeaderTime = [] } = bean.source;
        if (jsonHeader) {
            try {
                headers = {
                    ...headers,
                    ...JSON.parse(jsonHeader),
                };
            } catch (e) { }
        }
        if (bean.source["redirectUri"]) {
            let redirectUri = bean.source["redirectUri"];
            const redirectUriKey = bean.source["redirectUriKey"];
            const list = Utils.parser(/\[(.+?)\]/gim, redirectUri, 1);
            list.map((item) => {
                if (headers[item] !== undefined) {
                    redirectUri = redirectUri.replace(`[${item}]`, `${item}=${headers[item]}`);
                    delete headers[item];
                } else {
                    redirectUri = redirectUri.replace(`[${item}]`, `${item}=${""}`);
                }
            });

            headers[redirectUriKey] = encodeURIComponent(redirectUri);
        }
        if (activeHeaderTime.length > 0) {
            const timeHeader = {};
            for (const item of activeHeaderTime) {
                const { type, label, countTime, relativeDefaultTime, defaultTimeLimitDay, defaultTimeValue, fmt } = item;
                const value = Utils.setDefaultTime(type, countTime, relativeDefaultTime, defaultTimeLimitDay, defaultTimeValue, fmt);
                // let value: number | string = new Date().valueOf();
                // if (type === "relativeNow") {
                //     value = new Date().valueOf() + countTime;
                // } else if (type === "relativeDef") {
                //     value = relativeDefaultTime + countTime;
                // } else if (type === "defaultval") {
                //     if (defaultTimeLimitDay && defaultTimeLimitDay > 0) {
                //         value = new Date().setHours(0, 0, 0, 0) - (defaultTimeLimitDay - 1) * 24 * 60 * 60 * 1000;
                //     } else if (defaultTimeValue) {
                //         value = defaultTimeValue;
                //     }
                // }
                // if (fmt) value = Utils.format(value, fmt);
                timeHeader[label] = value;
            }
            // 用于先赋值一个默认事件，如果请求参数自己选择了时间，则使用自己选择的时间
            headers = { ...timeHeader, ...headers };
        }
        if (bean.source["addTimes"] === true) headers["addTimes"] = new Date().valueOf();

        headers = this._transform(headers, bean.source.headerTransform);
        // if (bean.name === "jobChange") {
        //     console.log("[jobChange]", bean, headers);
        // }
        //判断是否需要处理参数
        if (bean.source.handleHeaders) {
            const getBean = bean.source.dataSource ? this.mManager.getBean(bean.source.dataSource) : undefined;

            headers = bean.source.handleHeaders(headers, (getBean || bean).data.data);
            //判断是否为Promise
            if (headers.then) {
                return headers;
            }
        }

        return new Promise((resolve) => {
            resolve(headers);
        });

        return headers;
    }

    _transform(headers: any, list: Array<{ label: string; value: string }> = []) {
        list.map((item) => {
            if (headers[item.label]) {
                headers[item.value] = headers[item.label];
                delete headers[item.label];
            }
        });
        return headers;
    }

    _getExtendSource(sourceName?: any) {
        let ret = {};
        if (sourceName) {

            if (typeof sourceName === "string") {
                ret = this.mManager.getSourceBean(sourceName)?.data.headers;
            } else {
                //继承参数源使用
                sourceName.map((item: any) => {
                    const bean = this.mManager.getSourceBean(item.label);
                    if (bean) ret = this.getMap(bean.data.headers, item.value);
                });
            }
        }
        return ret;
    }

    _getExtendList(list: Array<{ label: string; value: string[] }> = []) {
        let ret = {};
        list.map((item) => {
            //item//一个数据源
            const bean = this.mManager.getSourceBean(item.label);
            if (bean) ret = { ...ret, ...this.getMap(bean.data.data, item.value) };
        });

        return ret;
    }

    private getMap(data: any = {}, list: string[] = [], ret = {}): any {
        if (list.length === 0) return { ...ret, ...data };
        list.map((str) => {
            let item: any = [];
            if (str.indexOf("*") > -1) {
                item = str.split("*");
            } else {
                item = str.split("_");
            }
            const newData = Utils.getValue(data, item[0].split("."));
            if (newData !== undefined) {
                ret[item[1] || item[0]] = newData;
            }
        });
        return ret;
    }
}

export default Handle;
