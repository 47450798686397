import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import Button from "../../../components/Button/Button";
import "./Pagination.scss";
import UIManifest from "src/engine/ui/UIManifest";
import AutoBind from "src/engine/decorator/AutoBind";
import Style from "src/lib/utils/Style";
// import IUIItem from "../../../lib/panel/interface/IUIItem";

interface IPagination extends IBaseUI {
    total: number;
    pageSize: number;
    tempKey?: any;
    showLoadMore?: boolean; //显示加载更多还是分页器
    showBeginEnd?: boolean; //显示首页/尾页
    nextStyle?: any;
    numberStyle?: any;
    //第几页的样式
    pageStyle?: any;
    hasJump?: boolean; // 是否有跳转到第几页按钮
    inputStyle?: any;
    style?: any;
    jumpButton?: any;
    boxStyle?: any;
    loadmoreBtn?: any;
    totalChildren?: any;
    rewriteStyle?: boolean;
    // 当前列表页数据的长度
    dataLength?: number;
    onChange?: any;
    start?: number;
}
@UIManifest.declare("Pagination")
class Pagination extends BaseUI<IPagination> {
    uiItem: any;
    totalN: number;
    state = {
        current: this.computeCurrent(),
        totalN: 0,
    };

    jumpInput: React.RefObject<HTMLInputElement>;
    constructor(props: IPagination) {
        super(props);
        this.jumpInput = React.createRef();
    }

    @AutoBind
    computeCurrent() {
        const { dataLength, start } = this.props;
        if (start) return start;
        if (!dataLength) return 1;
        const pageSize = this.getPageSize();
        const current = Math.ceil(dataLength / pageSize);
        return current;
    }

    @AutoBind
    getPageSize() {
        const { dataSource = [], tempKey, pageSize } = this.props;
        let value = pageSize;
        if (!pageSize && dataSource[tempKey]) {
            value = dataSource[tempKey]["pageSize"] || 1;
        }
        return value;
    }

    /**
     * 修复 Pagination 重新render后，当前列表current计数重新从1开始的情况（modules列表出现的该bug）
     * 传递当前dataList的长度去计算当前页数
     */
    @AutoBind
    changePageCore(current: number, cb = (fl: any) => {}) {
        const { tempKey, source = "", onChange } = this.props;
        if (onChange) {
            onChange(current);
        } else {
            this.sourceContext.execSource(
                source || "syncModuleList",
                {
                    pageNum: current,
                    pageSize: this.getPageSize(),
                    loadMore: this.props.showLoadMore,
                    moduleId: tempKey,
                    pageName: this.pageContext.pageName,
                },
                cb
            );
        }
        this.setState({ current });
    }

    change(item: any, index: number) {
        if (item === "...") {
            return;
        }
        let current = item;
        if (item === "上一页") {
            current = Math.max(1, this.state.current - 1);
        }
        if (item === "下一页") {
            current = Math.min(this.getTotal(), this.state.current + 1);
        }
        if (item === "首页") {
            current = 1;
        }
        if (item === "尾页") {
            current = this.getTotal();
        }
        this.changePageCore(current);
    }
    renderItem(item: any, index: number, len: number) {
        const { current } = this.state;

        let className = "normal";
        if (current === item) {
            className = `${className} active`;
        }
        if (index === 0 || index === len - 1 || index === 7) {
            className = `${className} show`;
            if ((index === 0 && current === 1) || ((index === len - 1 || index === 7) && current === this.getTotal())) {
                className = `${className} nomore`;
            }
        }
        const style = this.props[`${item === "下一页" || item === "上一页" || item === "首页" || item === "尾页" ? "next" : "number"}${current === item ? "Active" : ""}Style`];

        return <Button uiItem={this.uiItem} title={`${item}`} style={style} onClick={this.change.bind(this, item, index)} className={className} key={index} />;
    }
    componentDidMount() {
        this.getTotal();
        this.uiItem = {
            info: {
                ename: "pagination",
                cname: "分页",
            },
            ui: "Button",
            props: {},
        };
    }
    UNSAFE_componentWillReceiveProps(newProps: any) {
        const { dataSource = [], tempKey } = this.props;
        // const { total = (dataSource[tempKey] || {}).totalNum } = this.props;
        let { total } = this.props;
        if (!total) total = (dataSource[tempKey] || {}).totalNum;
        const newDataSource = newProps.dataSource || {};
        const { newTotal = (newDataSource[tempKey] || {}).totalNum } = newProps;
        if (total !== newTotal || this.state.totalN !== newTotal) {
            this.getTotal(newProps);
        }
    }
    getTotal(props?: any) {
        const { dataSource = [], tempKey } = props || this.props;
        let { total } = props || this.props;
        if (!total) total = (dataSource[tempKey] || {}).totalNum;
        let totalN = Number(total);
        if (isNaN(totalN)) {
            totalN = 0;
        }
        this.totalN = totalN;
        this.setState({
            totalN,
        });
        return Math.ceil(totalN / this.getPageSize());
    }
    getPages() {
        const { current } = this.state;
        const { showBeginEnd = false } = this.props;
        const totalPage = this.getTotal();
        let num = 1;
        let pages: any = [];
        if (totalPage <= 6) {
            while (num <= totalPage) {
                pages.push(num);
                num++;
            }
        } else {
            pages = [1, 2, 3, 4, 5, 6];
            const tNum = Math.ceil((totalPage - 2) / 3);
            // const tNum = Math.floor(totalPage / 3);
            const nowNum = current <= totalPage - 2 ? Math.ceil(current / 3) : Math.ceil((current - 2) / 3);
            const index0 = (nowNum - 1) * 3 + 1;
            if (nowNum === tNum) {
                let line = totalPage - tNum * 3 + 2;
                while (line > 0) {
                    pages.push(totalPage - line + 1);
                    line--;
                }
                pages = [totalPage - 5, totalPage - 4, totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
            } else {
                pages = [index0, index0 + 1, index0 + 2, "...", totalPage - 1, totalPage];
            }
        }
        pages.splice(0, 0, "上一页");
        pages.push("下一页");
        if (showBeginEnd) {
            pages.splice(0, 0, "首页");
            pages.push("尾页");
        }
        return pages;
    }

    @AutoBind
    jumpButtonClick() {
        const input = this.jumpInput.current;
        if (!input) return;
        const value = Number(input.value);
        if (value) {
            const totalPage = this.getTotal();
            if (value <= 0 || value > totalPage || value === this.state.current) {
                input.value = "";
            } else {
                this.changePageCore(value, () => {
                    input.value = "";
                    input.blur();
                });
            }
        } else {
            input.value = "";
        }
    }

    onKeyUpFlag: boolean = false;

    @AutoBind
    keyup(e: any) {
        if (this.onKeyUpFlag) return;
        const keycode = e.keyCode;
        if (keycode !== 13) return;
        const value = Number(e.target.value);
        if (value) {
            const totalPage = this.getTotal();
            if (value <= 0 || value > totalPage || value === this.state.current) {
                e.target.value = "";
            } else {
                this.onKeyUpFlag = true;
                this.changePageCore(value, () => {
                    const input = this.jumpInput.current;
                    if (!input) return;
                    input.value = "";
                    input.blur();
                    this.onKeyUpFlag = false;
                });
            }
        } else {
            e.target.value = "";
        }
    }

    renderLine() {
        const pages = this.getPages(),
            { rewriteStyle, totalChildren, showLoadMore, pageStyle, style, hasJump = false, jumpButton, inputStyle = {}, loadmoreBtn } = this.props,
            { current, totalN } = this.state;

        console.log("pages", pages)
        const pageSize = this.getPageSize();
        if (showLoadMore) {
            if (pageSize * current < this.totalN) {
                return loadmoreBtn ? (
                    this.renderChildren(loadmoreBtn, { onClick: this.change.bind(this, "下一页") })
                ) : (
                    <div className="loadMore">
                        <Button uiItem={this.uiItem} onClick={this.change.bind(this, "下一页")}>
                            加载更多
                        </Button>
                    </div>
                );
            }
        } else {
            return (
                <div className="page" style={style}>
                    <div className={`pagination-line style-${rewriteStyle}`}>
                        {pages.map((item: any, index: number) => {
                            return this.renderItem(item, index, pages.length);
                        })}
                    </div>
                    {totalChildren ? (
                        this.renderChildren(totalChildren, { data: { pages, change: this.change, totalN, current, totalNum: this.getTotal() } })
                    ) : (
                        <>
                            <div className={"page-current"} style={pageStyle}>{`第${current}页`}</div>
                            <div className={"page-current"} style={pageStyle}>{`共${this.getTotal()}页`}</div>
                        </>
                    )}
                    {hasJump ? (
                        <>
                            <div className={"page-current"} style={pageStyle}>
                                跳至 <input type="text" ref={this.jumpInput} onKeyUp={this.keyup} style={Style(inputStyle)} /> 页
                            </div>
                            {jumpButton ? this.renderChildren(jumpButton, { onClick: this.jumpButtonClick }) : null}
                        </>
                    ) : null}
                </div>
            );
        }
        return null;
    }
    renderAct() {
        const { boxStyle = {} } = this.props;
        return this.state.totalN === 0 ? null : (
            <div className="react-pagination" style={Style(boxStyle)}>
                {this.renderLine()}
            </div>
        );
    }
}

export default Pagination;
