import * as React from "react";
import * as ReactDOM from "react-dom";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import UIManifest from "src/engine/ui/UIManifest";
import "./Modal.scss";
import IUIItem from "src/engine/interface/IUIItem";
import { Modal as AntModal } from "antd-mobile";
import ButtonCheck from "src/components/Button/ButtonCheck";
import Utils from "src/lib/utils/Utils";
import Manifest from "src/engine/Manifest";

interface IModal extends IBaseUI {
    //弹框标题字符串
    title?: string;
    /**
     *
     * 是否允许点击蒙版关闭
     * @type {boolean}
     * @memberof IButtonDialog
     */
    maskClosable?: boolean;

    refreshOption?: string; //刷新数据源
    /**
     *
     * 弹窗数据
     * @type {*}
     * @memberof IModal
     */
    data?: any;
    renderChildren?: boolean;
    showChildren?: IUIItem[];
    showPop?: boolean;
    visible?: boolean;
    documentStyle?: any;
    full?: boolean;
    aniType?: string;
    maskTransparent?: string; // 遮罩是否透明
    openBefore?: any;
    openAfter?: any;
    style?: any;
    afterClose?: any;
    classNames?: string;
    animationType?: string;
    maskBg?: string;
    maskStyle?: any;
    contentStyle?: any;
    wrapStyle?: any;
    notRegister?: boolean;
    extUI?: IUIItem[];
    popupover?: boolean;
    transparentWidthFull?: boolean;
    bodyStyle?: any;
    scrollTarget?: string;
    clear?: boolean;
}

function closest(el: any, selector: any) {
    const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
    while (el) {
        if (matchesSelector.call(el, selector)) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
}

interface IState {
    visible: any;
    left?: number;
    top?: number;
}

@UIManifest.declareGG(["ggValue", "dataValue"], "Modal", "手机Modal组件")
class Modal extends BaseUI<IModal, IState> {
    static style = {
        wrapStyle: {},
    };

    constructor(props: IModal) {
        super(props);
        this.state = { visible: this.props.showPop || this.props.visible || false };
    }

    componentDidMount() {
        if (!this.props.notRegister && this.state.visible) {
            this.pageContext.registerBackListener(this.handleVisibleChange.bind(this, false), false);
        }
    }

    mBtn: any;

    handleVisibleChange = (visible: boolean) => {
        const { refreshOption, dataSource = {}, data = {}, openBefore, openAfter } = this.props;
        let state: any = { visible };
        if (this.mBtn && this.props.popupover) {
            const button: any = ReactDOM.findDOMNode(this.mBtn);
            if (button) {
                const { left, top } = button.getBoundingClientRect();
                state = { ...state, left, top };
            }
        }

        this.setState(state);
        const before = dataSource.openBefore || data.openBefore || openBefore;
        const after = dataSource.openAfter || data.openAfter || openAfter;
        if (visible) {
            if (before) {
                before();
            }
        } else {
            if (after) {
                after();
            }
        }
        if (refreshOption && !visible) {
            //关闭弹窗刷新数据源
            this.sourceContext.execSource(refreshOption);
        }

        return true;
    };

    componentDidUpdate() {
        if (this.mBtn && this.props.popupover && this.props.showChildren && this.props.showChildren.length > 0) {
            const button: any = ReactDOM.findDOMNode(this.mBtn);
            if (button) {
                const { left, top } = button.getBoundingClientRect();
                if (left !== this.state.left || top !== this.state.top) this.setState({ left, top });
            }
        }
    }

    proxyGoBack = () => {
        this.goBack();
        this.closeAutoScroll(false);
    };

    openBefore = () => {
        ButtonCheck.check();
        this.handleVisibleChange(true);
        // if (this.props.clear) {
        //     //组件里的modal弹框上套modal弹框的打开内层弹框处理
        //     this.goBack();
        // }
        this.pageContext.registerBackListener(this.handleVisibleChange.bind(this, false), !!this.props.clear);
    };
    afterClose = () => {
        const { afterClose } = this.props;
        if (afterClose) afterClose();
    };
    onClose = (check = true) => {
        if (!ButtonCheck.check() && check !== false) return;
        // this.handleVisibleChange(false);
        this.mBtn = undefined;
        this.goBack();
        return true;
    };

    onWrapTouchStart = (e: any) => {
        // fix touch to scroll background page on iOS
        if (!this.props.maskClosable) {
            return;
        }
        const pNode = closest(e.target, ".am-modal-content");
        if (!pNode) {
            // this.goBack(1, true);
            this.proxyGoBack();
        }
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        if (!pNode) {
            e.preventDefault();
        }
    };

    onTouchMove = (e: any) => {
        const { full } = this.props;
        if (!full) e.preventDefault();
    };

    middleTouch = (e: any) => {
        e.preventDefault();
    };

    closeAutoScroll = (type: boolean) => {
        const { scrollTarget } = this.props;
        if (!scrollTarget) return;
        setTimeout(() => {
            const target = document.getElementById(scrollTarget);
            if (!target) return;
            if (type) {
                target.style.overflow = "hidden";
            } else {
                target.style.overflow = "auto";
            }
        }, 300);
    };

    childrenRender = (data: any) => {
        if (!this.props.children || this.props.children.length === 0) return null;
        const isUIItem = Manifest.checkISUIItemList(this.props.children);
        const childProps = {
            data,
            onClose: (check: any) => {
                ButtonCheck.check();
                this.proxyGoBack();
            },
        };
        if (isUIItem) {
            return this.renderChildren(this.props.children, childProps);
        } else {
            return React.Children.map(this.props.children, (child) => {
                return React.cloneElement(child, childProps);
            });
        }
    };

    renderAct() {
        const {
            data,
            showChildren = [],
            maskClosable = true,
            full = false,
            aniType = "default",
            maskTransparent = "false",
            animationType = "",
            maskBg = "",
            maskStyle,
            wrapStyle,
            contentStyle,
            transparentWidthFull = false,
            extUI = [],
            bodyStyle,
            classNames,
        } = this.props;
        let ctrlAni: any = {
            transitionName: "am-zoom",
        };
        // let animation = {};
        // if (animationType) {
        //     animation = { animationType };
        // }
        const mStyle = {};
        if (aniType === "popup") ctrlAni = { popup: true, animationType: "slide-up" };
        if (aniType === "none") ctrlAni = {};
        if (maskTransparent === "true") mStyle["background"] = "rgba(0,0,0,0)";
        if (maskBg) {
            mStyle["background"] = maskBg;
        }
        const { left, top } = this.state;
        if (full) this.closeAutoScroll(this.state.visible);
        let wStyle = contentStyle;
        if (this.props.popupover) {
            wStyle = { position: "fixed", ...contentStyle };
            if (left !== void 0) wStyle.left = left;
            if (top !== void 0) wStyle.top = top;
        }
        return (
            <div style={this.getStyle()} className={`modal-show-${animationType}`}>
                {/* 按钮 */}
                <div ref={Utils.ref(this, "mBtn")} style={{ height: "100%" }}>
                    {this.renderChildren(showChildren, { data, onClick: this.openBefore })}
                </div>
                <AntModal
                    visible={this.state.visible}
                    bodyStyle={bodyStyle}
                    className={`react-modal ${transparentWidthFull ? "transparent-width-full" : ""} ${this.props.popupover ? "popupover" : ""} ${classNames}`}
                    afterClose={this.afterClose}
                    transparent={true}
                    maskClosable={maskClosable}
                    wrapClassName={full ? "full" : ""}
                    wrapStyle={{ ...Modal.style.wrapStyle, ...wrapStyle }}
                    onClose={this.proxyGoBack}
                    // onClose={this.goBack.bind(this, 1, true)}
                    popup={false}
                    title=""
                    {...ctrlAni}
                    maskStyle={{ ...Modal.style.wrapStyle, ...mStyle, ...maskStyle }}
                    wrapProps={{ onTouchStart: this.onWrapTouchStart, onTouchMove: this.onTouchMove }}
                    // {...animation}
                >
                    {this.renderChildren(extUI)}
                    {full ? <div onTouchStart={this.middleTouch} onTouchMove={this.middleTouch} className="full-bg-middle" /> : null}
                    {/* 弹框内容 */}
                    <div style={wStyle}>{this.childrenRender(data)}</div>
                </AntModal>
            </div>
        );
    }
}

//声明
(window as any).ModalStyle = Modal.style;

export default Modal;
