/*
 * @@Author: Do not edit
 * @@Date: Do not edit
 * @@Last Modified by: Do not edit
 * @@Last Modified time: Do not edit
 */
import BridgeConfig from "./BridgeConfig";

/**
 * Android连接桥
 *
 * @export
 * @class AndroidBridge
 */
export default class AndroidBridge {
    // param {*} eventTarget 事件分发器
    //  memberof AndroidBridge
    eventTarget = {};
    constructor(eventTarget, listeners) {
        this.eventTarget = eventTarget;
        //注册发送消息方法
        this.sender = window.JavaScriptInterface || window.CommuneDetailCb || {};
        this.register(listeners);
        //触发初始化成功方案
        setTimeout(() => {
            eventTarget.fire(BridgeConfig.Events.INIT);
        }, 50);
    }
    //注册原生事件监听
    register = (listeners, context = window) => {
        // 注册监听方法;
        const keys = Object.keys(listeners);
        for (let i = 0; i < keys.length; i++) {
            const name = keys[i];
            const eventName = listeners[name];
            if (typeof eventName !== "string") {
                window[name] = {};
                return this.register(eventName, window[name]);
            }
            if (name.indexOf(".") > -1) {
                //处理listeners为{webview.on_pause:'on_pause'}的类型
                let regName = name.split(".");
                window[regName[0]] = {};
                regName = regName.slice(1);
                const regObj = {};
                if (regName.length > 0) {
                    regObj[regName.join(".")] = eventName;
                    return this.register(regObj, window[regName[0]]);
                } else {
                    regObj[regName[1]] = eventName;
                    return this.register(regObj, window[regName[0]]);
                }
            }
            //重写全局方法，监听原生回调，并触发event事件
            context[name] = (...args) => {
                if (this && this.eventTarget) {
                    this.eventTarget.fire(eventName, ...args);
                }
            };
        }
    };

    send = (inter, args, callback) => {
        window.console.log("zxx--android-", "-inter-", inter, "-args-", args, "-callback-", callback);
        if (!(window.JavaScriptInterface && window.JavaScriptInterface[inter]) || (window.CommuneDetailCb && window.CommuneDetailCb[inter])) {
            // 兼容部分老接口
            inter = inter.replace(/([A-Z])/g, "_$1").toLowerCase();
        }
        if ((window.JavaScriptInterface && window.JavaScriptInterface[inter]) || (window.CommuneDetailCb && window.CommuneDetailCb[inter])) {
            if (window.JavaScriptInterface) {
                return window.JavaScriptInterface[inter].apply(window.JavaScriptInterface, args);
            } else if (window.CommuneDetailCb) {
                return window.CommuneDetailCb[inter].apply(window.CommuneDetailCb, args);
            }
        } else {
            return false;
        }
    };
}
