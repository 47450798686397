/*
 * @Author: wugz
 * @Date: 2018-04-25 10:30:50
 * @Last Modified by: zhanghao
 * @Last Modified time: 2021-10-27 11:47:24
 */
import BridgeConfig from "./BridgeConfig";
import BridgeUtil from "./BridgeUtil";
import Utils from "src/lib/utils/Utils";
import EventTarget from "src/lib/utils/EventTarget";
import { BridgeType } from "./RNUtils";
import HandleConfig from "../../components/BridgeConfig/HandleConfig";
// import { BridgeType } from "./RNUtils";

class Bridge {
    static config = {};
    connect = {};
    BridgeConfig = {};

    mBridgeType;
    constructor(bridgeType) {
        this.mBridgeType = bridgeType ?? window.BridgeType;
        this.events = new EventTarget();
        this.bridgeUtil = new BridgeUtil(this);
        //处理config
        this.getConfig();
        this.isGetConfig = false;
        this.register(this.BridgeConfig.Events.INIT, this.initial);
        this.register(this.BridgeConfig.Events.SHOWBACK, this.showBackEvent);
        this.bridge = this.createBridge(this.events);
        this.isIos = Utils.getIos() ? true : false;
        HandleConfig.getConfig(this.setConfig);
        //默认显示返回条
        this.usePageBack = true;
        this.network_status_change();
    }
    networkChange = (fn) => {
        return this.register("network_status_change", fn);
    };
    network_status_change = () => {
        window.network_status_change_connect = true;
        window.network_status_change = function (is_connect) {
            window.console.log(`[network_status_change]`, is_connect);
            window.network_status_change_connect = is_connect;
            this.events.fire("network_status_change", { is_connect });
        };
    };
    setConfig = (config = {}) => {
        this.isGetConfig = true;
        this.events.fire("isGetConfig");
        this.BridgeConfig.Interfaces = {
            ...this.BridgeConfig.Interfaces,
            ...config.Interfaces,
        };
    };
    getConfig = () => {
        this.connect = this.bridgeUtil.useNewConnect();
        this.BridgeConfig = { Events: BridgeConfig.Events, OriginListener: BridgeConfig.OriginListener };
        if (this.connect) {
            this.BridgeConfig.Interfaces = BridgeConfig.ConfigInterfacesNew;
        } else {
            this.BridgeConfig.Interfaces = BridgeConfig.ConfigInterfaces;
        }
    };
    releaseBridgeEvent = (type, callback) => {
        this.events.removeAllListener(type);
    };

    showBackEvent() {
        return null;
    }
    //注册页面返回方法
    registerBack = (callback) => {
        this.bridgeUtil.registerBack(callback);
    };
    //是否显示网页返回条
    checkPageBack = () => {
        if (!this.isInit) {
            this.register(this.BridgeConfig.Events.BACKBAR, () => {
                return this.usePageBack;
            });
        }
        return this.usePageBack;
    };
    //页面返回方法
    goBack = (pageStr) => {
        this.bridgeUtil.goBack(pageStr);
    };
    getApkVer = () => {
        return this.bridgeUtil.getApkVer();
    };
    //打开新窗口
    startWeb = (params = {}, isCheck, isOpenService) => {
        return this.bridgeUtil.startWeb(params, isCheck, isOpenService);
    };
    //分享
    canShareDolphin = (params, isCheck) => {
        return this.bridgeUtil.canShareDolphin(params, isCheck);
    };
    //设置入口页地址
    setRoot = (rootUrl) => {
        this.bridgeUtil.setRoot(rootUrl);
    };
    //判断是否是入口页
    isRootPage = (str) => {
        return this.bridgeUtil.isRootPage(str);
    };

    initial = () => {
        this.isInit = true;
        //设置标题
        this.setTitle("返回");
        //返回条
        this.usePageBack = this.bridgeUtil.getUsePageBack();
        this.register("web_url_reload");
        this.events.fire(this.BridgeConfig.Events.NACKBARCHANGE, { pageShow: this.usePageBack });
    };
    setTitle = (title) => {
        if (this.BridgeConfig.Interfaces) {
            this.send(this.BridgeConfig.Interfaces.setTitle, { title: title });
        }
    };
    goUrl = (url) => {
        if (this.BridgeConfig.Interfaces) {
            this.send(this.BridgeConfig.Interfaces.goUrl, { url: url });
        }
    };
    /**
     *
     * @param {*} type
     * @param {*} listener
     */
    register(type, listener, notSub) {
        this.events.addListener(type, listener);
        if (!notSub) {
            return () => {
                return this.events.removeListener(type, listener);
            };
        }
    }

    createBridge = (events) => {
        return this.bridgeUtil.createBridge(events, this.BridgeConfig.OriginListener, this.mBridgeType);
    };
    //调用原生方法 config 见BridgeConfig.Interfaces中
    send(paramsConfig, args = {}, callback, type, otherParams = {}) {
        if (typeof paramsConfig === "string") {
            paramsConfig = this.BridgeConfig.Interfaces[paramsConfig];
        }
        let config = type ? paramsConfig[type] : paramsConfig || {};
        //判断是否初始化
        if (!this.isInit) {
            //如果没有初始化，则注册一个初始化消息,等待初始化后回调
            this.register(this.BridgeConfig.Events.INIT, (...paramsArgs) => {
                this.send(config, ...paramsArgs);
            });
            return;
        }
        if (!this.isGetConfig) {
            //如果没有获取到config配置，则注册一个配置消息,等待获取配置后回调
            this.register("isGetConfig", (...paramsArgs) => {
                this.send(config, ...paramsArgs);
            });
            return;
        }
        let ret = false;
        if (typeof config === "string") {
            ret = this.bridge.send(config, args, callback);
        } else {
            //判断配置中是否注册了事件监听
            if (config.event) {
                const sub = this.register(
                    config.event,
                    (...paramsArgs) => {
                        if (callback) {
                            callback(...paramsArgs);
                        }
                        if (sub) {
                            sub();
                        }
                    },
                    otherParams.notSub
                );
            }
            //纯回调此处返回
            let params = {};
            if (config.argsSort) {
                params = this._transform(config.argsSort, args);
            } else if (args && (this.connect === BridgeType.PostMessage || this.connect === BridgeType.RNPostMessage)) {
                //没有配置参数且为与rn类型
                params = args;
            }
            ret = this.bridge.send(config.method, params, callback);
        }

        //android可能会有返回值，在照片上传时，会返回照片的list数据
        if (typeof ret === "string") {
            callback(ret);
        }
    }

    /**
     *
     *
     * @param {*} argsSort
     * @param {*} args
     * @returns
     * @memberof Bridge
     */
    //android将参数进行转换排序
    _transform(argsSort, args) {
        // if (this.isIos) return args;
        let maps = {};
        const list = argsSort.map((key, index) => {
            if (typeof key === "string") {
                maps[key] = args[key];
                if (typeof args[key] === "string") {
                    return args[key];
                }
                return typeof args[key] === "object" ? JSON.stringify(args[key]) : args[key];
            } else if (typeof key === "object") {
                if (key.name) {
                    maps[key.name] = key.value || args[key.name];
                } else if (key.key) {
                    maps[key.key] = args[key.value];
                }

                return key.value;
            }
        });
        if (maps.params && Object.keys(maps).length === 1) {
            maps = maps.params;
        }
        //发现ios和安卓都用maps类型参数
        if (this.connect === BridgeType.PostMessage || this.connect === BridgeType.RNPostMessage || this.connect === BridgeType.UnityBridge) {
            return maps;
        } else {
            return this.isIos ? maps : list;
        }
    }
    //打开相册方法
    openPhoto = (params, callback) => {
        //测试
        // setTimeout(() => {
        //     callback();
        // }, 200)

        let p = params;
        if (typeof params === "object") {
            for (let k in params) {
                if (params[k]) {
                    p = params[k];
                }
            }
        }
        this.bridgeUtil.openPhoto(p, (param, type) => {
            this.send(this.BridgeConfig.Interfaces.openPhotoLib, param, callback, type);
        });
    };
    //拍照方法
    openCamera = (params = {}, callback) => {
        this.send(this.BridgeConfig.Interfaces.openCamera, params, callback);
    };
    //获取得到的图片list
    getPicData = (params = {}, callback) => {
        //测试
        // setTimeout(() => {
        //     callback();
        // }, 500)

        this.send(this.BridgeConfig.Interfaces.getPicDataStr, params, (response) => {
            callback(response);
        });
    };
    //打开rn视频
    webOpenVideo = (params = {}, callback) => {
        return this.bridgeUtil.webOpenVideo(params, callback);
    };
}

export default Bridge;
