import React from "react";
import IUIItem from "src/engine/interface/IUIItem";

export interface IPageMgrContext {
    loading: IUIItem[];
    isPC: () => boolean;
    renderPage: (item: { props: any; ename: string; key: number }, index: number, isShow: boolean) => any;
}

const PageMgrContext = React.createContext<IPageMgrContext>({
    loading: [],
    isPC: () => false,
    renderPage: (item: { props: any; ename: string; key: number }) => {
        return null;
    },
});

export default PageMgrContext;
