import Exec from "./Exec";
import { ISourceBean } from "../Manager";

/**
 *
 *
 * @class Local
 */
class Local extends Exec {
    exec(bean: ISourceBean, data: any, callback: any, updateNet?: boolean, refresh?: boolean) {
        if (bean.source.handleData && !updateNet) {
            //判断
            data = bean.source.handleData(data, bean.data.data);
            //处理toSource
            if (typeof bean.source.toSource === "string" && bean.source.toSource !== bean.name) {
                //更新toSource
                this.mManager.execSource(bean.source.toSource, data);
            }
        }
        //更新
        this.mManager.update(bean, {}, data, undefined, refresh);
        if (callback) callback(data);
    }
}

export default Local;
