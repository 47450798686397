import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import TouchFeedback, { ITouchProps } from "../Button/TouchFeedback";
import UIManifest from "src/engine/ui/UIManifest";
import ButtonCheck from "../Button/ButtonCheck";
import AutoConfig from "src/engine/decorator/AutoConfig";
import IUIItem from "src/engine/interface/IUIItem";
import Style from "src/lib/utils/Style";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import "./index.scss";
import { Toast } from "antd-mobile";
import Storage from "src/lib/utils/storage/Storage";

interface IButtonExt extends IBaseUI {
    title?: any;
    activeStyle?: any;
    hoverStyle?: any;
    loadingStyle?: any;
    onClick?: any;
    type?: string;
    stat?: any;
    classNames?: string[];
    hovertToEvent?: boolean;
    configConsumer?: boolean;
    renderToClick?: boolean;
    // 是否移除click事件，只当一个有hover的view来用
    removeClick?: boolean;
    hoverChildren?: IUIItem[] | any;
    transmitHover?: boolean;
    style?: any;
    checkLine?: string;
    disabled?: any;
    checkLogin?: boolean;
    checkLoginKey?: string;
    loginPage?: string;
}

interface IState {
    loading: boolean;
}

function ButtonChild(props: any) {
    const { title, hover, clearHover, renderChild, children, ...other } = props;
    return (
        <div {...other}>
            {title}
            {renderChild(children, { hover, clearHover })}
        </div>
    );
}

@UIManifest.declareGG(["ggValue", "dataValue"], "ButtonExt", "Button组件")
class ButtonExt extends BaseUI<IButtonExt, IState> {
    state = {
        loading: false,
    };

    constructor(props: IButtonExt) {
        super(props);
        this.state = { loading: false };
        const { renderToClick, index } = this.props as any;
        //触发第一次点击
        if (renderToClick && index === 0) {
            setTimeout(() => {
                this.onClick({});
            }, 1);
        }
        this.renderChildren = this.renderChildren.bind(this);
    }

    loading: boolean;

    stat() {
        const { uiItem, stat = {} } = this.props;
        const clickId = stat.clickId || uiItem.info.cname;
        if (this.pageContext && clickId) {
            this.pageContext.stat({ clickId: uiItem.info.cname, ...stat });
        }
    }

    checkLogin() {
        const { checkLogin = false, checkLoginKey = "User_Id", loginPage } = this.props;
        if (!checkLogin || !loginPage) return true;
        //检测cookie中，是否包含某值
        if (new Storage().get(checkLoginKey, "1") === "1") {
            this.startPage(loginPage);
            return false;
        }
        return true;
    }

    onClick = (e: any) => {
        if (this.props.checkLine === "true" || (!this.props.checkLine && (window as any).CHECK_LINE_BEFORE_CLICK)) {
            // 检查
            if (!(window as any).network_status_change_connect) {
                Toast.show("网络连接出错了", 3, true);
                return;
            }
        }

        if (!!this.loading || this.props.disabled) return;
        // 检查不同按钮间的重复点击
        if (!this.props.hovertToEvent && !ButtonCheck.check()) return;

        //调用统计
        this.stat();
        //调用click
        const { onClick } = this.props;

        if (!this.checkLogin()) return;

        if (onClick) {
            onClick((loading: boolean) => {
                this.loading = loading;
                if (this.props.loadingStyle) {
                    this.setState({ loading });
                }
            });
        }
    };

    @AutoConfig.Consumer("configConsumer")
    renderAct() {
        const { title, className = "", activeStyle, hoverStyle, classNames = [], hovertToEvent, hoverChildren, loadingStyle } = this.props;
        const divProps: any = {
            style: this.getStyle(),
            className: `react-button ${className} ${classNames.join(" ")}`,
            // loading: this.state.loading,
        };
        if (!this.props.removeClick) {
            divProps.onClick = this.onClick;
        }

        const touchProps: ITouchProps = {
            disabled: !hoverStyle && !activeStyle && !hovertToEvent,
            activeStyle,
            onHover: this.onClick,
            hoverStyle,
            hovertToEvent,
            ename: this.props.uiItem.info.ename,
        };
        if (this.state.loading) {
            touchProps.disabled = true;
            divProps.style = { ...divProps.style, ...Style(loadingStyle) };
        }
        if (hoverChildren) {
            touchProps.hoverChildren = this.renderChildren(hoverChildren);
        }
        return (
            <TouchFeedback {...touchProps}>
                {this.props.removeClick || this.props.transmitHover ? (
                    <ButtonChild {...divProps} renderChild={this.renderChildren} children={this.props.children} />
                ) : (
                    <div {...divProps}>
                        {title}
                        {this.renderChildren(void 0, { loading: this.state.loading })}
                        {this.checkView()}
                    </div>
                )}
            </TouchFeedback>
        );
    }

    checkView() {
        const { checkView, checkPropsKey } = this.props as any;
        const item = PropsUtils.getValue(this.props, checkPropsKey);
        if (checkView && (typeof item === "undefined" || item === "")) {
            return this.renderChildren(checkView);
        }
        return null;
    }
}

export default ButtonExt;
