import IUIItem from "src/engine/interface/IUIItem";
import { IContext, IInfo } from "../props/PropsGGEditor/exec/ExecScript";

/**
 *
 *
 * @class Script
 */
class Script<T> {
    protected props: T;

    protected mUIItem: IUIItem;

    context: IContext;

    type: string;

    editing: boolean;

    protected lifeCycleCache: string[] = [];

    public getUIItem() {
        return this.mUIItem;
    }

    constructor(uiItem: IUIItem, context: IContext, type: string, editing: boolean) {
        this.mUIItem = uiItem;
        this.props = uiItem.props as any;
        this.context = context;
        this.type = type;
        this.editing = editing;
    }

    exec(config: IInfo, callback: (info: IInfo) => any): boolean {
        config = this.handleConfig(config);
        config.value = this.handle(config.value, this.context.props, config);
        return true;
    }

    /**
     * @override
     * @param value
     */
    protected handleConfig(config: IInfo) {
        return config;
        console.log("[Script]:处理", this.mUIItem);
    }

    protected _lifeCycle(name: string) {
        if (!this.lifeCycleCache) this.lifeCycleCache = [];
        if (name) {
            return this.lifeCycleCache.push(name);
        } else {
            return this.lifeCycleCache;
        }
    }

    /**
     * @override
     * @param value
     */
    protected handle(value: any, props: { [key: string]: any }, config: IInfo) {
        console.log("[Script]:处理", this.mUIItem);
    }
}

export default Script;
