/**
 *
 *
 * @class Inject
 */
class DecoratorUtils {
    static decorator(callback: any) {
        return function(target: any, name: string, descriptor: any) {
            const value = target[name];
            descriptor.value = function(...arg: any) {
                const old = (...newArg: any) => {
                    return value.apply(this, [...newArg, ...arg]);
                };
                return callback(this, old, name);
            };
        };
    }
}

export default DecoratorUtils;
