import * as React from "react";
import * as ReactDOM from "react-dom";
import { Input, InputNumber } from "antd";
import BaseComponent from "src/engine/ui/BaseComponent";
import UIInject, { IHandle } from "../UIInject";
import UIManifest from "src/engine/ui/UIManifest";
import IUIItem from "src/engine/interface/IUIItem";
import AutoBind from "src/engine/decorator/AutoBind";
import SyncUtils from "src/engineEditor/props/SyncUtils";
import Utils from "src/lib/utils/Utils";
import PanelContext, { IPanelContext } from "src/engine/context/PanelContext";
import AutoContext from "src/engine/decorator/AutoContext";

const { TextArea, Search, Group, Password } = Input;

interface IAntInput {
    uiItem: IUIItem;
    onChange?: any;
    onPressEnter?: any;
    onBlur?: any;
    value?: string;
    defaultValue?: string;
    type?: "TextArea" | "Input" | "Search" | "Group" | "Password" | "InputNumber" | "Hidden";
    max?: string | number;
    maxLength?: string | number;
    onChangeType?: "onChange" | "onBlur";
    syncTime?: number;
    onFocus?: any;
    openKeyboard?: boolean;
    allowClear?: boolean;
    extUI?: IUIItem[];
    prefix?: IUIItem[];
    removeCollectionStr?: string;
}

interface IState {
    value?: any;
}

const isChrome = !!(window as any).chrome;
@UIManifest.declareGG(["ggValue"], "AntInput", "Input输入框", UIManifest.Type.AntD)
class AntInput extends BaseComponent<IAntInput, IState> {
    /**
     * 解决输入中文时bug
     */
    isOnComposition: boolean = false;

    mSyncUtils = new SyncUtils(this.props.syncTime || 200);

    isMount = true;

    constructor(props: IAntInput) {
        super(props);
        this.state = { value: props.value || props.defaultValue };
    }

    onChange = (e: any) => {
        const { type = "Input", removeCollectionStr } = this.props as any;
        let value = e;

        if (type === "InputNumber") {
            value = e;
        } else {
            value = e.target.value;
        }

        const { value: oldValue } = this.state;
        //检测是否进行[]移除
        if (removeCollectionStr?.length === 2) {
            const left = removeCollectionStr[0];
            const right = removeCollectionStr[1];
            if (oldValue?.[oldValue.length - 1] === right && oldValue.length - value.length === 1) {
                //找到最后一个[
                const item: string = value;
                const index = item.lastIndexOf(left);
                value = item.slice(0, index);
            }
        }

        this.setState({ value });
        if (!this.isOnComposition || !isChrome) {
            this.onChangeACt(value);
        }
    };

    onBlur = () => {
        const { onChangeType = "onChange" } = this.props;
        if (onChangeType === "onBlur") this.props.onChange?.(this.state.value);
        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }
    };

    onChangeACt(value: any) {
        const { onChangeType = "onChange", value: propsValue } = this.props as any;
        if (onChangeType === "onChange")
            if (!propsValue)
                this.mSyncUtils.sync("value", () => {
                    if (this.isMount) this.props.onChange?.(value);
                });
            else this.props.onChange?.(value);
    }

    static getDerivedStateFromProps(nextProps: IAntInput, state: any) {
        if (nextProps.value !== state.oldValue) {
            return { value: nextProps.value, oldValue: nextProps.value };
        }
        return null;
    }

    @AutoBind
    handleComposition(e: any) {
        if (e.type === "compositionend") {
            // composition is end
            this.isOnComposition = false;

            if (!this.isOnComposition && isChrome) {
                // fire onChange
                this.onChange(e);
            }
        } else {
            // in composition
            this.isOnComposition = true;
        }
    }

    @AutoBind
    onFocus(e: any) {
        const { onFocus, openKeyboard } = this.props;
        if (openKeyboard) this.scrollIntoView();
        if (onFocus) onFocus(e);
    }

    inputdom: any;
    inputRef = (ref: any) => {
        this.inputdom = ref;
    };

    scrollIntoView = () => {
        if (!this.inputdom) return;
        if (Utils.getIos()) return;
        const dom: any = ReactDOM.findDOMNode(this.inputdom);
        if (!dom || !dom.scrollIntoViewIfNeeded || !dom.scrollIntoView) return;
        if (dom.scrollIntoViewIfNeeded) dom.scrollIntoViewIfNeeded(true);
        // 2345类浏览器在键盘弹出后可视区域不变 直接覆盖在页面上 所以scrollIntoViewIfNeeded不生效
        if (dom.parentElement.className.indexOf("control") >= 0) {
            const parent = dom.parentElement.parentElement;
            if (parent) parent.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    onPressEnter = (e: any) => {
        const { type = "Input" } = this.props as any;
        let value = e;

        if (type === "InputNumber") {
            value = e;
        } else {
            value = e.target.value;
        }
        // this.setState({ value });
        this.props.onPressEnter?.(value);
    };

    renderExtUI() {
        const { extUI = [], maxLength } = this.props;
        const value = this.state.value;
        let length = 0;
        if (value === "" || value === null || value === void 0) {
            length = 0;
        } else {
            length = (value + "").length;
        }
        if (extUI.length > 0) return this.mPanelContext.renderUIItem(extUI[0], { value, maxLength, length }, this.props.uiItem);
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    handlePrefix(props: IAntInput) {
        if (props.prefix && typeof props.prefix !== "string") {
            props.prefix = this.mPanelContext.renderUIItem(props.prefix[0], {}, this.props.uiItem);
        }
        return props;
    }

    mPanelContext: IPanelContext;
    @AutoContext(PanelContext, "mPanelContext")
    render() {
        const { type = "Input", children, allStyle, _line, _sameUI, _renderUIItem, onChangeType, onPressEnter, isInt, ...other } = this.props as any;
        const uiMaps = { TextArea, Input, Search, Group, Password, InputNumber, Hidden: Input };
        const handles: IHandle[] = [];
        if (type === "Hidden") {
            other.type = "hidden";
        }
        if (onPressEnter) {
            other.onPressEnter = this.onPressEnter;
        }
        if (type === "InputNumber" && isInt) {
            other.precision = 0;
        }

        this.handlePrefix(other);
        return [
            <UIInject
                ui={uiMaps[`${type}`]}
                {...other}
                value={this.state.value}
                onCompositionStart={this.handleComposition}
                onCompositionUpdate={this.handleComposition}
                onCompositionEnd={this.handleComposition}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                handles={handles}
                ref={this.inputRef}
            />,
            this.renderExtUI(),
        ];
    }
}

export default AntInput;
