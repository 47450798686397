import React from "react";

/**
 *
 *
 * @interface IPanelContext
 */
export interface IConfigContext {
    config: { [key: string]: any };
    getConfig: () => { [key: string]: any };
}

const ConfigContext = React.createContext<IConfigContext>({
    config: {},
    getConfig: () => {
        return {};
    },
});

export default ConfigContext;
