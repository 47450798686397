import * as React from "react";
import DecoratorUtils from "./DecoratorUtils";
import { useContext } from "react";

interface IContext {
    context: any;
    children: (context: any) => any;
}

export const Context = ({ context, children }: IContext) => {
    const props = useContext(context);
    return children(props);
};

/**
 * 自动注解监听TContext
 * @param Con TContext类型
 * @param propsName
 */
const AutoContext = (Con: any, propsName: string) => {
    return DecoratorUtils.decorator((that: any, oldFunc: any) => {
        return (
            <Context context={Con}>
                {(context) => {
                    that[propsName] = context;
                    return oldFunc();
                }}
            </Context>
        );
    });
};

export default AutoContext;
