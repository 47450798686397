import * as React from "react";
import { Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
// import BaseComponent from "src/engine/ui/BaseComponent";
// import UIInject, { IHandle } from "../UIInject";
import UIManifest from "src/engine/ui/UIManifest";
import IUIItem from "src/engine/interface/IUIItem";
// import Manifest from "src/engine/Manifest";
import Utils from "src/lib/utils/Utils";
import EditConfig from "src/engineEditor/config/EditConfig";
import GameConfig from "src/GameConfig";
// import ButtonExt from "src/components/ButtonExt";
import AutoContext from "src/engine/decorator/AutoContext";
import PanelContext, { IPanelContext } from "src/engine/context/PanelContext";
import BaseUI from "src/engine/ui/BaseUI";
import "./AntUpload.scss";
import Config from "src/engine/const/Config";
import { getFiles } from "./upUtils";
import { Toast } from "antd-mobile";

interface IAntUpload {
    uiItem: IUIItem;
    onChange: any;
    btnUIItems: IUIItem[];
    outUI?: IUIItem[];
    url: string;
    argsMap?: any;
    args?: any;
    value?: string | string[];
    defaultValue?: string | string[];
    listTypeLine: string;
    isArray: boolean;
    max: number;
    accept?: string[];
    isDevEnv?: boolean;
    fileSize?: number;
    prefix?: string;
    // 上传解析xls
    parseXls?: boolean;
    // 是否添加https前缀
    protocol?: boolean;
    // 获取较深的返回值
    listKey?: string;
}

interface IState {
    count: number;
    files: any[];
    value: any;
    flag: boolean;
}

@UIManifest.declareGG(["ggValue", "dataValue"], "AntUpload", "AntUpload选择器", UIManifest.Type.AntD)
class AntUpload extends BaseUI<IAntUpload, IState> {
    constructor(props: IAntUpload) {
        super(props);
        const value = props.value || props.defaultValue;
        const files = (getFiles(value) as any) || [];
        this.state = {
            files,
            value,
            flag: false,
            count: files.length,
        };
    }

    static getDerivedStateFromProps(nextProps: IAntUpload, state: IState) {
        const nextValue = nextProps.value || nextProps.defaultValue;
        if (nextValue !== state.value && !state.flag) {
            const files = (getFiles(nextValue) as any) || [];
            return { files, value: nextValue, count: files.length };
        }
        return null;
    }

    beforeUpload = (file: UploadFile) => {
        const { fileSize } = this.props;
        if (fileSize) {
            if (file.size > Number(fileSize) * 1024 * 1024) {
                file.response = { msg: `文件最大不应超出${fileSize}MB` };
                return false;
            } else {
                return true;
            }
        }
        return true;
    };

    getQueryParams() {
        const { args = {}, argsMap = {}, isDevEnv = false } = this.props;
        const propsGameId = args.gameId || argsMap.gameId;
        const propsSname = args.sname || argsMap.sname;
        const { gameId, server } = GameConfig;
        let baseOpt: any = {
            gameId: propsGameId || gameId,
            sname: propsSname || server,
        };
        if (isDevEnv) {
            baseOpt = {
                gameId: 0,
                sname: "upload",
                up: `${propsGameId || GameConfig.gameId}/${propsSname || server}/`,
            };
        }
        const other: any = {};
        const ic = args.imageC || argsMap.imageC;
        if (ic) {
            other.quality = ic;
            other.imageC = true;
        }
        return {
            // 开启自动分类
            classification: true,
            ...baseOpt,
            ...argsMap,
            ...args,
            ...other,
        };
    }

    getAction() {
        let { url, prefix } = this.props;
        if (url && url.indexOf("http") !== 0) url = Config.API + url;
        if (prefix) url = url.replace("/cloudplt/api", prefix);
        return Utils.handleUrl(url || EditConfig.UPLOAD_URL, this.getQueryParams());
    }

    uploadFileDone = (file: UploadFile, urls: any) => {
        const { parseXls, protocol, listKey = "" } = this.props;
        const { response } = file;
        if (parseXls) {
            if (Array.isArray(response.data)) {
                urls.push(...response.data);
            } else {
                file.status = "error";
                file.response = response.msg || "上传图片失败";
            }
        } else {
            const { url } = file;
            let fileUrl = response?.url || Utils.getValue(response, listKey.split(".")) || url;
            if (fileUrl) {
                if (!Utils.stringStartsWith(fileUrl, "http") && protocol) {
                    fileUrl = "https:" + fileUrl;
                }
                urls.push(fileUrl);
            } else {
                file.status = "error";
                file.response = response.msg || "上传图片失败";
            }
        }
    };

    onChange = (info: UploadChangeParam) => {
        const { parseXls } = this.props;
        const { fileList = [] } = info;
        let urls: any = [];
        let count = 0;
        let uploading = false;

        fileList.map((file: UploadFile) => {
            if (file.status === "done") {
                this.uploadFileDone(file, urls);
            }
            if (file.status === "uploading") uploading = true;
            if (file.status === "done" || file.status === "uploading") count++;
            if (file.status === void 0) {
                const { response = {} } = file;
                file.status = "error";
                file.response = response.msg;
            }
            console.log(file);
        });
        const { onChange, isArray = false } = this.props;
        if (!isArray && !parseXls) {
            urls = urls.join(",");
        }

        const newlist = fileList.filter((file) => {
            if (file.status !== "error") {
                return true;
            } else {
                Toast.show(file.response || "上传图片失败", 3, true);
                return false;
            }
        });
        // console.log(newlist);
        this.setState({ count, files: newlist || [], flag: true });
        if (!uploading) onChange?.(urls);
    };

    async onPreview(file: any) {
        const url = file.response?.url || file.url;
        if (url) {
            window.open(url);
            return;
        }
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    renderButton(uis: IUIItem[], max: number = 1000) {
        if (this.state.count >= max) return null;
        if (uis.length === 0) return "点击上传";
        return this.renderChildren(uis);
        // return null;
    }

    renderOutUI() {
        const { outUI } = this.props;
        if (!outUI || outUI.length === 0) return;
        return this.renderChildren(outUI, { fileList: this.state.files, limit: this.props.max });
    }

    itemRender = (originNode: React.ReactElement, file: UploadFile, fileList?: any) => {
        return file.status === "error" ? (
            <>
                {originNode}
                <div className="upload-error-msg">{file.response}</div>
            </>
        ) : (
            originNode
        );
    };

    mPanelContext: IPanelContext;

    @AutoContext(PanelContext, "mPanelContext")
    render() {
        const { uiItem, btnUIItems = [], url, listTypeLine, onChange, max, accept, ...other } = this.props;
        const { files } = this.state;
        const onPreview = this.onPreview.bind(this);
        if (accept && accept.length === 0 && accept[0] === "video/*" && Utils.getIos()) {
            accept[0] = "video/quicktime";
        }
        return (
            <>
                <Upload
                    className={"ant-upload c " + listTypeLine}
                    listType="picture-card"
                    {...other}
                    beforeUpload={this.beforeUpload}
                    accept={accept as any}
                    action={this.getAction()}
                    fileList={files}
                    onChange={this.onChange}
                    onPreview={onPreview}
                    itemRender={this.itemRender}
                >
                    {this.renderButton(btnUIItems, max)}
                </Upload>
                {this.renderOutUI()}
            </>
        );
        // const handles: IHandle[] = [{ type: "ui", key: "children", uiKey: "children", defaultValue: DefaultButton }];
        // return <UIInject action={this.getAction()} ui={Select} {...other} handles={handles} />;
    }
}

export default AntUpload;
