import IUIItem from "src/engine/interface/IUIItem";
// import Utils from "src/lib/utils/Utils";
import GGProps from "src/component/props/PropsGGEditor/exec/GGProps";

export default class UIManifest {
    static Type = {
        Public: "Public",
        PageMgr: "PageMgr",
        Page: "Page",
        Data: "data",
        SourceStyle: "sourcestyle",
        DataHandle: "dataHandle",
        Mobile: "mobile",
        Group: "group",
        PC: "pc",
        JJ: "jj",
        AntD: "AntD",
        Props: "Props",
        Charts: "Charts",
        /**
         * 将要废弃的组件
         */
        Deprecated: "Deprecated",
        /**
         * 废弃组件
         */
        Discard: "discard",
        /**
         * 数据处理组件
         */
        Handle: "handle",
        /**
         * 普通脚本类型
         */
        Script: "script",
        /**
         * 聚合脚本类型
         */
        ScriptAgg: "scriptAgg",
        /**
         * 聚合脚本类型
         */
        ScriptCheck: "scriptCheck",
        /**
         * 回调脚本类型
         */
        scriptCall: "scriptCall",
        /**
         * 聚合脚本类型
         */
        ScriptPrivate: "scriptPrivate",
        /**
         * 添加属性脚本类型
         */
        ScriptAddProps: "scriptAddProps",
    };

    static UITypePath = {
        default: "src/components/",
        AntD: "src/component/antd/",
        jj: "src/component/jj/",
        Charts: "src/component/chart/",
        Props: "src/component/props/",
        script: "src/component/script/",
        /**
         * 聚合脚本类型
         */
        scriptAgg: "src/component/script/",
        /**
         * 聚合脚本类型
         */
        scriptCheck: "src/component/script/",
        scriptCall: "src/component/script/",
        /**
         * 聚合脚本类型
         */
        scriptPrivate: "src/component/script/",
        scriptAddProps: "src/component/script/",
        /**
         * 数据处理器; ( props.stores )
         */
        data: "src/component/source/",
        sourcestyle: "src/component/source/",
        // pc: "src/pc/ui/base/",
    };

    static uiImages = {};

    /**
     * 组件UI图片
     */
    static UIIconMaps = {};
    static UIIconInit: boolean = false;

    /**
     * 组件缓存
     *
     * @static
     * @memberof UIManifest
     */
    static maps: { [key: string]: IUIItem } = {};

    /**
     * 组件缓存
     *
     * @static
     * @memberof UIManifest
     */
    private static mapsType = {};

    static getTypeByUI(ui: string | object) {
        if (typeof ui === "string") return UIManifest.mapsType[ui] || "未声明组件";
        else return "自定义组件";
    }

    /**
     * 组件分类存储
     *
     * @private
     * @static
     * @type {{ [key: string]: IUIItem[] }}
     * @memberof UIManifest
     */
    private static listCache: { [key: string]: IUIItem[] } = {};

    /**
     * 不推荐使用
     */
    public static discards: { [key: string]: boolean } = {};

    private static listCacheMaps: { [key: string]: { [key: string]: { uiItem: IUIItem; index: number } } } = {};

    /**
     * 根据分类查询组件列表 默认查询全部
     *
     * @static
     * @memberof UIManifest
     */
    static list = (type: string = UIManifest.Type.Mobile, list?: IUIItem[]) => {
        if (list) {
            UIManifest.listCache[type] = list;

            const cache = UIManifest.listCacheMaps[type] || {};
            UIManifest.listCacheMaps[type] = cache;
            list.map((uiItem) => {
                cache[uiItem.info.ename] = { uiItem, index: UIManifest.listCache[type].length };
                (uiItem as any)._ename = uiItem.info.ename;
            });
        }
        return UIManifest.listCache[type] || [];
    };

    static getItem(ename: string, type: string = UIManifest.Type.Group) {
        let item = (UIManifest.listCacheMaps[type] || {})[ename];
        if (!item && type === UIManifest.Type.Group) {
            item = (UIManifest.listCacheMaps[UIManifest.Type.Public] || {})[ename];
        }
        if (item) return item.uiItem;
        return item;
    }

    static register(uiItem: IUIItem, type: string = UIManifest.Type.Mobile) {
        // uiItem = Utils.deepClone(uiItem) as IUIItem;
        (uiItem as any)._ename = uiItem.info.ename;
        //往分类中一分
        if (!UIManifest.listCache[type]) {
            UIManifest.listCache[type] = [];
        }
        const cache = UIManifest.listCacheMaps[type] || {};
        UIManifest.listCacheMaps[type] = cache;

        const item = cache[uiItem.info.ename];
        if (!item) {
            cache[uiItem.info.ename] = { uiItem, index: UIManifest.listCache[type].length };
            UIManifest.listCache[type].push(uiItem);
        } else {
            item.uiItem = uiItem;
            UIManifest.listCache[type][item.index] = uiItem;
        }
        // UIManifest.listCacheMaps[type][uiItem.info.ename] = { uiItem, index: UIManifest.listCache[type].length };
        // UIManifest.listCache[type].push(uiItem);
    }

    private static declareCls(cls: any, ename: string, cname?: string, type: string = UIManifest.Type.Mobile, props: any = {}, discard: boolean = false) {
        //创建一个声明组件
        const uiItem: IUIItem = {
            info: {
                ename,
                cname: cname || `${ename}组件`,
            },
            ui: cls,
            props,
        };
        //往分类中一分
        if (!UIManifest.listCache[type]) {
            UIManifest.listCache[type] = [];
        }
        UIManifest.listCache[type].push({ ...uiItem, ui: ename });
        //判断是否废弃
        if (discard || type === UIManifest.Type.Discard) UIManifest.discards[ename] = true;
        //往大maps中放一份
        UIManifest.maps[ename] = uiItem;
        //往大maps中放一份组件类型
        UIManifest.mapsType[ename] = type;
    }

    static declareGG(ggProps: string[], ename: string, cname?: string, type?: string, discard: boolean = false) {
        return (cls: any) => {
            UIManifest.declareCls(GGProps(ggProps)(cls), ename, cname, type, {}, discard);
            return cls;
        };
    }

    static declareGGExtendsUI(ui: string, ggProps: string[], ename: string, cname?: string, type?: string, props?: any) {
        if (!props) props = {};
        props["extendsUI"] = ui;
        return (cls: any) => {
            UIManifest.declareCls(GGProps(ggProps)(cls), ename, cname, type, props);
            return cls;
        };
    }

    /**
     * 注解的方式声明组件
     *
     * @static
     *
     * @param ename 英文名称
     * @param cname 中文名称
     * @param type 组件类型 默认类型 UIManifest.Type.Mobile
     * @memberof UIManifest
     */
    static declare = (ename: string, cname?: string, type?: string, discard: boolean = false) => {
        return (cls: any) => {
            UIManifest.declareCls(cls, ename, cname, type, {}, discard);
            return cls;
        };
    };
}

(window as any)._UIManifest = UIManifest;
