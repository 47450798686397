import IRule from "./IRule";
import Utils from "src/lib/utils/Utils";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
// import CalcScript from "src/component/script/NumberCalcScript/CalcScript";

export interface IRuleShow {
    checkKey: string;
    checkType: string;
    checkValue: string;
    show?: boolean;
    defaultValue?: string;
}

/**
 * 校验是否显示
 */
class RuleShow implements IRule {
    type: "required";

    props: IRuleShow;

    constructor(props: IRuleShow) {
        this.props = props;
    }

    exec(formData: any, expend: any) {
        const { checkKey, checkType, checkValue, show = false, defaultValue } = this.props;
        let getValue = PropsUtils.getValue(formData, checkKey);
        if (typeof getValue === "undefined") getValue = defaultValue;
        if (Utils.compare(checkType, getValue, checkValue)) {
            // if (CalcScript.calc(checkType, formData[checkKey], checkValue)) {
            expend.show = show;
        } else {
            if (expend.show !== true)
                expend.show = !show;
        }
        return false;
    }
}

export default RuleShow;
