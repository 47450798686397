import Hash from "./hash";
// import { Types } from "./AppRoot";
import IGameInfo from "src/engine/interface/IGameInfo";
import EventTarget from "src/lib/utils/EventTarget";
import Utils from "src/lib/utils/Utils";
import { createBrowserHistory } from "history";

const cBH = createBrowserHistory();

export interface IJJHistory {
    //名称
    ename: string;
    key?: number;
    //传递参数
    props?: { [key: string]: any };

    //回调事件
    backListener: any[];
}

function getFirstChangeUrl(page: string, outHash?: string) {
    const pathname = window.location.pathname;
    const search = window.location.search;
    let url = "";

    if (Utils.stringEndWidth(pathname, "/")) {
        url = `${pathname}${page}.html`;
    } else {
        const index = pathname.lastIndexOf("/");
        url = `${pathname.substring(0, index)}/${page}.html`;
    }

    if (search) url += search;

    if (outHash) {
        url += outHash;
    } else {
        url += window.location.hash;
    }

    return url;
}

class JJHistory {
    public hash: Hash;
    private startPage: any;
    events: any;

    /**
     * 历史栈信息
     *
     * @type {*}
     * @memberof JJHistory
     */
    history: IJJHistory[] = [];

    //前进栈历史信息
    private goHistory: IJJHistory[] = [];
    //退出回调
    public exitFunc: any;

    private isEdit = false;

    constructor(isEdit: boolean, gameInfo: IGameInfo, startPage?: any) {
        this.isEdit = isEdit;
        this.events = new EventTarget();
        this.hash = new Hash(this);

        this.startPage = startPage;
        if (gameInfo.homePage) {
            this.history.push({ ename: gameInfo.homePage, props: gameInfo.homePageProps || {}, backListener: [] });
        }
        const router = this.hash.get();
        if (!this.isEdit && router && router.route && router.route !== "Root") {
            // const data = this.hash.get();
            this.history.push({ ename: router.route, props: router.props, backListener: [] });
        }
    }

    getLastRoute = () => {
        return this.history[this.history.length - 1];
    };

    refresh = (count: any = 1) => {
        const history = this.history;
        if (history.length > 0) {
            const last = history[history.length - count];
            if (!last) return;
            const { props = {} } = last;
            //用于实现刷新时不增加详情页浏览功能
            props.isRefresh = 1;
            last.props = {
                ...last.props,
                ...props,
            };
            history[history.length - count] = { ...last, key: (last.key || 0) + 1 };
        }
        return this.history;
    };

    //前进功能
    goForward = () => {
        if (this.goHistory.length === 0) {
            return;
        }
        const history: IJJHistory = this.goHistory.pop() || { ename: "", backListener: [] };
        //前进栈有变化则执行注册的渲染方法
        this.events.fire("history");
        if (history.ename) {
            // this.push(history.ename, history.props);
            this.startPage({ ename: history.ename, props: history.props, init: false });
        }
    };
    //获取前进列表
    getForwardList = () => {
        return this.goHistory;
    };
    /**
     * 打开一个新页面
     */
    push(ename: string, props: any = {}, init?: boolean | number | string, isChange?: boolean, openNew?: boolean, pagesInit?: boolean) {
        const routeName: string = ename;
        const routeParams = props;
        const { staticLinkType = false, wsback = false } = props;
        if (!pagesInit) {
            if (!this.isEdit && !wsback) {
                delete props.staticLinkType;
                const hash = `#/${ename}${this.hash.getHash(props)}`;
                if (staticLinkType && !isChange) {
                    const stateUrl = getFirstChangeUrl(ename, hash);
                    cBH.push(stateUrl);
                }
                const origin = Utils.removePathPage(`${location.origin}${location.pathname}`);
                if (props.openNew || openNew) {
                    let href = "";
                    if (!staticLinkType) href = origin + hash;
                    else href = origin + `${ename}.html` + hash;
                    const newWindow = window.open(href);
                    if (newWindow) newWindow.opener = null;
                    return this.history;
                } else if (props.localHref) {
                    window.location.href = `${origin}?t=${new Date().valueOf()}${hash}`;
                    return this.history;
                } else if (props.replace) {
                    window.location.replace(`${origin}?t=${new Date().valueOf()}${hash}`);
                    return this.history;
                }
            }
            if (!this.isEdit && !isChange && !staticLinkType && !wsback) {
                this.hash.add({ route: routeName, ...routeParams });
            }
        }
        //新打开页面时清空前进栈
        if (openNew) {
            this.events.fire("history");
            this.goHistory = [];
        }

        if (init === true || props.init === "true" || props.init === -1 || init === "-1" || init === -1) {
            //清除全部
            this.history = [];
        } else if (props.init || init) {
            //清除指定数量的页面
            const deletePageNum = props.init || init;
            this.events.fire("history");
            this.history.splice(Math.max(0, this.history.length - deletePageNum), deletePageNum);
            this.goHistory.splice(Math.max(0, this.goHistory.length - deletePageNum), deletePageNum);
        }
        const k = Math.ceil(Math.random() * 1000);
        //入栈
        this.history.push({ ename: routeName, key: k, props: routeParams, backListener: [] });
        return this.history;
    }
    setSingleExit = (exitFunc: any) => {
        this.exitFunc = exitFunc;
    };

    hashChange: any;

    registerHashChange = (hashChange: any) => {
        this.hash.registerHashChange(hashChange);
        // this.hashChange = hashChange;
    };
    length() {
        return this.history.length;
    }

    private popBackLister() {
        if (this.history.length === 0) return false;
        //优先消耗回调事件
        const lastHistory = this.history[this.history.length - 1];
        if (lastHistory.backListener?.length > 0) {
            const backListener = lastHistory.backListener.pop();
            backListener();
            return true;
        }
        return false;
    }

    /**
     * 移除一个页面
     * @param ename
     */
    pop(backProps: any = {}) {
        //游戏消耗回调事件
        if (this.popBackLister()) return;

        if (this.history.length === 1) {
            this.exitFunc?.();
            return;
        }

        //出栈并将出栈页面放入前进栈
        const history: IJJHistory = this.history.pop() || { ename: "", backListener: [] };
        if (history.ename) {
            this.events.fire("history");
            this.goHistory.push(history);
        }
        if (this.history.length === 0) return;
        let name = this.history[this.history.length - 1].ename;
        const p = this.history[this.history.length - 1].props;
        if (backProps.staticLinkType) {
            name = "/" + name;
        }
        //如果为编辑页面或者hash改变造成的回退，则不进行hash变化
        if (!this.isEdit && !backProps.isHash) {
            this.hash.add({
                route: name,
                ...p,
            });
        }
    }
}

export default JJHistory;
