import IRule, { IRuleProps } from "./IRule";
import IUIItem from "src/engine/interface/IUIItem";
import Utils from "src/lib/utils/Utils";
import CalcScript from "src/component/script/NumberCalcScript/CalcScript";

type IcompareType = "includes" | "<" | "<=" | ">" | ">=" | "!!" | "!=" | "=";

export interface IRuleCompareValueBase extends IRuleProps {
    // 比较的类型
    compareType?: IcompareType;
}

interface IRuleCompareValue extends IRuleCompareValueBase {
    // 其他表单项的name
    otherName?: string;
    // 其他表单项的cname
    otherCname?: string;
}

/**
 * 校验 【自定义 值与值的比较】
 */
class RuleCompareFormValue implements IRule {
    type: "compareValue";

    props: IRuleCompareValue;

    constructor(props: IRuleCompareValue) {
        this.props = props;
    }

    defineCompareType(type: IcompareType) {
        let str = "";
        switch (type) {
            case "includes":
                str = "应包含";
                break;
            case "<":
                str = "应小于";
                break;
            case "<=":
                str = "应小于等于";
                break;
            case ">":
                str = "应大于";
                break;
            case ">=":
                str = "应大于等于";
                break;
            case "!=":
                str = "应不等于";
                break;
            case "!!":
            case "=":
            default:
                str = "应等于";
                break;
        }
        return str;
    }

    defineMessage(uiItem: IUIItem) {
        const { compareType = "=", otherName, otherCname } = this.props;
        return `${uiItem.info.cname}${this.defineCompareType(compareType)}${otherCname || otherName}的值`;
    }

    core(compareValue: any, message: string) {
        const { compareType = "=" } = this.props;
        return {
            validator(rule: any, value: any = "", callback: any) {
                console.log('[RuleCompareFormValue]:', value, compareType, compareValue, Utils.compare(compareType, value, compareValue));
                if (!CalcScript.calc(compareType, value, compareValue)) {
                    callback(message);
                    return;
                }
                callback();
            },
        };
    }

    exec(
        // 表单值
        formData: any,
        // 扩展属性, rules同级
        expend: any,
        // 当前表单 UIItem
        uiItem: IUIItem
    ) {
        const { otherName, message } = this.props;
        if (!otherName) return false;
        return this.core(formData[otherName], message ? message : this.defineMessage(uiItem));
    }
}

export default RuleCompareFormValue;
