// import Exec from "../Exec";

import NetHandler from "src/component/source/SourceNet/Handle";
import IUIItem from "src/engine/interface/IUIItem";
import GameConfig from "src/GameConfig";
import IPConfig from "src/IPConfig";
import { CONTENT_TYPE } from "src/lib/http/HttpEmun";
import Yml from "../SourcePublish/files/Yml";
import { TempHandler } from "./TempHandle";

/**
 *
 *
 * @class Handle
 */
class Handle extends NetHandler {
    _getHttp(props: any, headers: any) {
        // return props.http;
        return {
            url: `vine/commit/gitEdit?repositoryId=3&gameId=${GameConfig.gameId}`,
            dataType: CONTENT_TYPE.JSON,
        };
    }

    getAction(manager: any, path: string) {
        const list: any[] = [];
        if (!manager[0]?.props) return list;
        const { pageTemps, pages } = manager[0]?.props;
        pages.map((page: IUIItem) => {
            page = pageTemps[page.info.ename];
            if (page) {
                list.push({ action: "update", content: JSON.stringify(page), file_path: `${path}${page.info.ename}.json` });
            }
        });
        return list;
    }

    returnFalse() {
        return new Promise((resolve, reject) => {
            // console.log('[Handle]222222',);
            setTimeout(() => {
                resolve({ loading: false, data: { CODE: 200, Message: "无模板修改" } });
            });
        });
    }

    handleDataRight(data: any) {
        if (this.tempHandler) {
            if (data.data.Code >= 0) this.tempHandler.success();
            this.tempHandler = null;
        }
    }

    tempHandler: any;

    exec(headers: any, data: any, props: any): any {
        const { domain = "http://git.web.jjweb.cn", isWCP, argActions, projectId, branch, pathFormat = true, path = "", rprojectId } = props;
        let tempHandler;

        let params: any = {};
        if (argActions) {
            params = headers;
        } else if (isWCP) {
            tempHandler = new TempHandler();
            params = tempHandler.getActions(headers);
        } else if (!params.actions) {
            params = { actions: this.getAction(headers, path) };
        }
        if (params === false) return this.returnFalse();
        if (!Array.isArray(params.actions)) params.actions = [params.actions];
        const actions: any = params.actions;
        if (actions === false || actions?.length === 0 || !projectId || (!branch && !params.branch)) return this.returnFalse();
        const toHeaders = {
            commitParam: {
                actions,
                branch: isWCP ? `${GameConfig.server}_${GameConfig.gameId}${IPConfig.release ? "_master" : ""}` : branch,
                commit_message: IPConfig.release ? "修改模板" : "修改模板[skip ci]",
                ...params,
            },
            domain,
            pathFormat,
            projectId: IPConfig.release ? rprojectId || projectId : projectId,
            repositoryId: 3,
        };
        //判断如果为线上环境，则每次更新yml文件,防止yml文件不存在，不执行CICD
        if (isWCP && IPConfig.release) {
            toHeaders.commitParam.actions.push({
                action: "update",
                file_path: ".gitlab-ci.yml",
                content: Yml.createTemplateYml(GameConfig.gameId, GameConfig.server, "master"),
            });
        }
        // return false;
        this.tempHandler = tempHandler;
        // console.log(`[branch]: ${GameConfig.server}_${GameConfig.gameId}`);
        // return null;
        return super.exec(toHeaders, data, { ...props, notSetGameId: true, http: this._getHttp(props, headers) });
    }
}

export default Handle;
