import PanelConfig from "src/engine/ui/PanelConfig";
import IGameInfo from "src/engine/interface/IGameInfo";
import ArtDetail from "src/engine/pages/source/ArtDetail";
import TempManager from "./pages/source/TempManager";
import ModuleQuery from "./pages/source/ModuleQuery";
import ModuleManager from "./pages/source/ModuleManager";
import GameConfig from "src/GameConfig";

class Root extends PanelConfig {
    constructor(game: IGameInfo, isResolution: boolean = true) {
        super();
        if (game.sname) GameConfig.cmsSname = game.sname;
        this.uiItems = [
            {
                info: { ename: "root", cname: "root" },
                ui: "Layout",
                dataSource: "manager",
                props: {},
            },
        ];
        this.sources = {
            manager: new TempManager(game, "app", { isResolution }),
            syncModuleList: new ModuleQuery(game),
            dataList: new ModuleManager(),
            artDetail: new ArtDetail(game),
        };
    }
}
export default Root;
