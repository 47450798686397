import { Source } from "src/engine/interface/Source";

export default class ModuleManager extends Source {
    data = {
        edit: {}
    };
    dataSource = "manager";

    handle(data: any, oldData: any) {
        return oldData;
    }

    changeData(old: any, data: any, key?: string) {
        let oldData = old;
        if (key) {
            oldData = old[key] || {};
        }
        for (const k in data) {
            if (data[k]) {
                if ((k === "pagination" || k === "edit") && oldData[k]) {
                    this.changeData(oldData[k], data[k]);
                    continue;
                }
                oldData[k] = data[k];
            }
        }
    }

    handleData(data: any, oldData: any, headers: any) {
        if (!data || !data.type) return false;
        if (data.type === "init") {
            this.changeData(oldData, data.content);
            // this.changeData(oldData, data.content.pagination, "pagination");
            // this.changeData(oldData, data.content.edit, "edit");
            return oldData;
        } else if (data.type === "save") {
            oldData.edit = {};
            return oldData;
        }
        //找到key只替换他的值
        if (data.type === "pagination") {
            delete data.content.pagination;
            return { ...oldData, ...data.content };
        }
        //加载更多
        if (data.type === "more") {
            const moduleId = data.moduleId;
            oldData[moduleId] = (oldData[moduleId] || []).concat(...data.content[moduleId]);
            return oldData;
        }

        return this.handle(data, oldData);
    }
}
