import * as React from "react";
import UIManifest from "src/engine/ui/UIManifest";
import Utils from "src/lib/utils/Utils";
import "./Label.scss";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";

interface ILabel extends IBaseUI {
    title: string;
    isUnchangeText?: boolean; // 是否是固定数据
    value: string;
    defaultValue: string;
    style: any;
    map: any;
    addNum?: any;
    max?: number;
    //time;
    mapDefault?: string; //map的默认值
    formatter?: string;
    noDataNoRender?: boolean; //是否没数据就不渲染
    //时间段判断
    mathMap?: any[]; //数学计算
    timesFrommater?: any[];
    timesFrommaters?: any[];
    countFrommater?: any[]; //倒计时map
    dataSourceKeys?: any[];
    formatterType?: "true" | "false";
    showZero?: boolean;
    numberFrommater?: any[];
    mapDataType?: any; //映射值的类型
    getValByDot?: boolean;
    getValueFromDate?: boolean;
    countKey?: string; //倒计时对比的时间key
    perMap?: any; //百分比计算key值的map
    useValueAsWidth?: boolean; //是否用value作为元素的样式宽
    inStyle?: any;
    outStyle?: any;
    inAni?: boolean;
    outAni?: boolean;
}

interface IState {
    maps: any;
    value: any;
    inAni: boolean;
    outAni: boolean;
}

@UIManifest.declareGG(["ggValue"], "Label", undefined, UIManifest.Type.Discard)
class Label extends BaseUI<ILabel, IState> {
    state = {
        maps: {},
        value: null,
        inAni: false,
        outAni: false,
    };

    static getDerivedStateFromProps(props: ILabel, state: IState) {
        const { dataSource = [], dataSourceKeys = [] } = props;
        const maps = {};
        if (!dataSource.map) {
            return state;
        }
        dataSource.map((item: any) => {
            dataSourceKeys.map((keys) => {
                maps[item[keys.value] || keys.value] = item[keys.label];
            });
        });
        // if (data.inAni && data.inAni !== state.inAni) {
        //     return { maps, inAni: true };
        // }
        // if (data.outAni && data.outAni !== state.outAni) {
        //     return { maps, outAni: true };
        // }
        return { maps };
    }

    changeToThound = (value: any) => {
        value = value.toString();
        if (value.indexOf(",") < 0) {
            const intArr = value.split(".");
            value = (intArr[0].match(/(^\d{1,2}|\d{3})(?=(\d{3})*$)/g) || []).join(",") + (intArr[1] ? "." + intArr[1] : "");
        }
        return value;
    };

    changePoint = (value: any, useNumberFrommater: any) => {
        const { point = "false", hasZero = "false" } = useNumberFrommater;
        // 不要末位的0 ， 就转成数字， 否则字符串
        if (hasZero === "false") {
            value = Number(value.toFixed(point)) + "";
        } else {
            value = value.toFixed(point) + "";
        }
        return value;
    };

    transformNumber = (value: number | string) => {
        const { numberFrommater } = this.props;
        if (!numberFrommater || !numberFrommater.length) return value;
        let useNumberFrommater: any;
        value = Number(value);
        for (const item of numberFrommater) {
            // 判断在base之上的， base是从高到底排列的
            if (Number(item.base) <= value) {
                useNumberFrommater = item;
                break;
            }
        }
        if (!useNumberFrommater) {
            // 这种情况是value小于所有的base,所以赋值最后一个
            useNumberFrommater = numberFrommater[numberFrommater.length - 1];
        }
        const { base = 1, unit = "", point = "false", hasThound = "false", otherstr = "" } = useNumberFrommater;
        let hasUnit = false;
        if (value >= Number(base)) {
            value = value / Number(base);
            hasUnit = true;
        }
        if (point !== "false") {
            value = this.changePoint(value, useNumberFrommater);
        }
        if (hasThound !== "false") {
            value = this.changeToThound(value);
        }
        if (unit && hasUnit) {
            value += unit;
        }
        if (otherstr) {
            value += otherstr;
        }
        return value;
    };

    getValue() {
        let { map = {} } = this.props;
        const {
            title,
            isUnchangeText = false,
            value,
            defaultValue,
            addNum,
            dataSource,
            mapDefault,
            mapDataType = false, //是否取dataSource做map映射
            showZero = false,
            getValByDot = false,
            getValueFromDate = false,
            ename = "",
            data,
        } = this.props as any;
        let item: any = isUnchangeText ? title : value !== undefined ? value : title;
        if (isUnchangeText) {
            return item;
        }
        if (getValByDot) {
            //如果ename取值有点，则按照点的方式取值
            const localData = getValueFromDate ? this.props : data;
            const dotVal = Utils.getValue(localData, ename.split("."));
            item = dotVal !== undefined ? dotVal : item || defaultValue || value;
        }
        item = Number(addNum) === 0 || Number(addNum) ? Number(item) + Number(addNum) : item;
        // if (typeof item === "number") {
        //     item = this.transformNumber(item);
        // }
        if (mapDataType) {
            map = dataSource;
        }
        const iitem = this.state.maps[item];
        item = iitem !== undefined ? iitem : map[item] || mapDefault || item;
        item = showZero && (item === 0 || item === "0") ? 0 : item || defaultValue;

        return item;
    }

    formatterTime(formatter: string, value: number) {
        const { timesFrommater = [] } = this.props;
        value = (value + "").length > 11 ? Math.floor(value / 1000) : value;
        const delay = Math.floor(new Date().valueOf() / 1000) - value;
        for (const formmat of timesFrommater) {
            if (Utils.compare(formmat.type, delay, formmat.key)) {
                const time = Math.floor(delay / Number(formmat.time));
                return this.formatter("false", formmat.value, Number(time) < 1 ? 1 : time);
            }
        }
        return this.formatter("true", formatter, `${value}`);
    }

    //倒计时的计算
    formatterCountDown(formatter: string, value: number) {
        value = (value + "").length > 11 ? Math.floor(value / 1000) : value;
        const { countFrommater = [], countKey = "", data } = this.props;
        const countTime = countKey ? Utils.getValue(data, countKey.split(".")) || data[countKey] || countKey + "" : new Date().valueOf() + "";

        const delayV = Number(value) - Math.floor((countTime + "").length > 11 ? Number(countTime) / 1000 : Number(countTime));
        const getVal = (delay: any, format: string) => {
            const year = /(y+)/.test(format) ? Math.floor(delay / (365 * 24 * 60 * 60)) : 0;
            const day = /(d+)/.test(format) ? Math.floor((delay - year * 365 * 24 * 60 * 60) / (24 * 60 * 60)) : 0;
            const hour = /(h+)/.test(format) ? Math.floor((delay - year * 365 * 24 * 60 * 60 - day * 24 * 60 * 60) / (60 * 60)) : 0;
            const min = /(m+)/.test(format) ? Math.floor((delay - year * 365 * 24 * 60 * 60 - day * 24 * 60 * 60 - hour * 60 * 60) / 60) : 0;
            const sec: any = delay - year * 365 * 24 * 60 * 60 - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60;
            const arrLabel = [
                {
                    label: /(y+)/,
                    val: year,
                },
                {
                    label: /(d+)/,
                    val: day,
                },
                {
                    label: /(h+)/,
                    val: hour,
                },
                {
                    label: /(m+)/,
                    val: min,
                },
                {
                    label: /(s+)/,
                    val: sec,
                },
            ];
            let res = format;
            arrLabel.forEach((item: any) => {
                let ival = item.val;
                if (ival >= 0 && item.label.test(format) !== -1) {
                    ival = ival < 10 ? `0${ival}` : ival;
                    res = res.replace(item.label, ival);
                }
            });
            return res;
        };

        for (const formmat of countFrommater) {
            if (Utils.compare(formmat.type, delayV, formmat.key)) {
                return getVal(delayV, formmat.value);
            }
        }
        return value + "";
    }
    timesFrommaters = (formatter: string, value: number) => {
        const { timesFrommaters = [] } = this.props;
        for (const formmat of timesFrommaters) {
            if (Utils.formatDateToHaitun(formmat.type, value)) {
                return this.formatter("true", formmat.value, value);
            }
        }
        return this.formatter("true", formatter, `${value}`);
    };

    //计算数据的百分比值
    excPercent(value: any) {
        const { perMap = [], data } = this.props;
        let res: string | number = 0;
        const keymap = perMap[0] || null;
        const getVal = (name: string, dataV: any) => (name.indexOf(".") !== -1 ? Utils.getValue(dataV, name.split(".")) : dataV[name]);
        if (keymap && keymap.label === "/") {
            res = Number(value) === 0 ? 0 : Number(value) / (Number(getVal(keymap.num1, data) || 0) + Number(getVal(keymap.num2, data)) || 0);
            if (Number.isNaN(res)) res = 0;
            if (typeof res === "number") {
                //解决JS计算精度的问题，所以先乘然后再fixed
                res = keymap.percent === "true" ? res * 100 : res;
                res = keymap.point ? Number(res.toFixed(Number(keymap.point))) : res;
                res = keymap.percent === "true" ? res + "%" : res;
                return res;
            }
            return value;
        }
        return value;
    }
    simplyMath(value: any) {
        const { mathMap = [] } = this.props;
        let res = value;
        if (mathMap[0]) {
            const { key1Source = "data", key2Source = "data", tofixed, key1 = "", key2 = "" } = mathMap[0];
            const dataval1 = Utils.getValue(this.props[key1Source], key1.split("."));
            const dataval2 = Utils.getValue(this.props[key2Source], key2.split("."));
            let val1 = Utils.if(dataval1 || dataval1 === 0, dataval1, key1);
            let val2 = Utils.if(dataval2 || dataval2 === 0, dataval2, key2);
            val1 = Utils.if(typeof Number(val1) === "number", Number(val1), 0);
            val2 = Utils.if(typeof Number(val2) === "number", Number(val2), 0);
            const exNum = mathMap[0].addnum || 0;
            switch (mathMap[0].type) {
                case "+":
                    res = val1 + val2 + exNum;
                    break;
                case "-":
                    res = val1 - val2 + exNum;
                    break;
                case "*":
                    res = val1 * val2;
                    break;
                case "/":
                    res = val1 / val2;
                    break;
                default:
                    res = value;
                    break;
            }
            if (tofixed || tofixed === 0) {
                res = Number(res.toFixed(tofixed));
            }
            if (mathMap[0].transformNumber === "true") {
                res = this.transformNumber(Number(res));
            }
        }
        return res;
    }
    formatter(type: string, formatter: string, value: string | number | string[]): string {
        switch (type) {
            //如果时间戳转换
            case "true":
                return Utils.format(value, formatter);
            //截止时间判断
            case "time":
                return this.formatterTime(formatter, Number(value));
            // 时间节点判断
            case "haitun":
                return this.timesFrommaters(formatter, Number(value));
            // 百分比计算
            case "percent":
                return this.excPercent(value);
            // 简单数学计算
            case "simplyMath":
                return this.simplyMath(value);

            // 倒计时
            case "countDown":
                return this.formatterCountDown(formatter, Number(value));
            // 时长
            case "timeLong":
                return Utils.formatTimeLong(value, formatter);
            case "join":
                if (value && typeof value === "object") {
                    return value.join(formatter);
                } else {
                    return value + "";
                }

            default:
                return formatter.replace("xxx", value + "");
        }
    }
    render() {
        const { formatter, formatterType = "true", noDataNoRender, max = 0, lineClamp, useValueAsWidth = false } = this.props as any;
        let value = this.getValue();
        if ((formatter && (value || value === 0)) || formatterType === "percent" || formatterType === "simplyMath") {
            value = this.formatter(formatterType, formatter, value);
        }
        if (typeof value === "number") {
            value = this.transformNumber(value);
        }
        if (typeof value === "string") {
            if (value.indexOf("表情") > -1) {
                value = Utils.handleHtml(value);
            }
            if (max > 0) {
                value = value.slice(0, max);
            }
        } else if (typeof value === "object") {
            value = JSON.stringify(value);
        }
        const styleW = useValueAsWidth ? { width: value } : {};
        return noDataNoRender && !value ? null : (
            <div className={`react-label ${this.props.className}`} style={{ ...this.getStyle(), ...Utils.getLineClampStyle(lineClamp), ...styleW }}>
                {value}
            </div>
        );
    }
}

export default Label;

// const Label = (props: ILabel) => {
//     const { map = {}, title, value, defaultValue, formatter, formatterType = "true", addNum, ref } = props as any;
//     let item: any = value !== undefined ? value : title !== undefined ? title : defaultValue;
//     item = Number(addNum) ? Number(item) + Number(addNum) : item;
//     if (formatter && item) {
//         if (formatterType === "true") {
//             //纯粹时间格式化
//             item = map[item] || item;
//             item = Utils.format(item, formatter);
//         } else {
//             item = map[item] || formatter.replace("xxx", item);
//         }
//     } else {
//         item = map[item] || item;
//     }
//     return (
//         <div style={Style(props.style)} ref={ref}>
//             {item}
//         </div>
//     );
// };

// UIManifest.declare("Label")(Label);

// export default Label;
