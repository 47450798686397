import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import { IInfo } from "src/component/props/PropsGGEditor/exec/ExecScript";

interface ICallbackScript {
    name?: string;
    canExecRet?: boolean;
    // 是否修改当前组件的props : 更新UI组件的state以达到更新目标组件props的目的
    changeProps?: boolean;
    delay?: number;
}

/**
 *
 *
 * @class CallbackScript
 */
@UIManifest.declare("CallbackScript", "回调脚本", UIManifest.Type.scriptCall)
class CallbackScript extends Script<ICallbackScript> {
    exec(info: IInfo) {
        const { name = "onClick", canExecRet = false, changeProps, delay } = this.props;
        info.name = name;
        info.isExec = canExecRet;
        info.params = { changeProps, delay };
        return true;
    }
}

export default CallbackScript;
