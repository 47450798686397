// import Utils from "src/lib/utils/Utils";
import { Types } from "./AppRoot";

function replaceHashPath(path: string) {
    const hashIndex = window.location.href.indexOf("#");
    const pathname = window.location.href.slice(0, hashIndex >= 0 ? hashIndex : 0);
    window.location.replace(pathname + "#" + path);
}
class Hash {
    // private pop: any;
    constructor(history: any) {
        // window.onhashchange = this.hasChange.bind(this);
        let lastURL = document.URL;
        window.onhashchange = (event: any) => {
            // console.log('[hash]', event);

            if (!(window as any).HashChangeEvent) {
                // 兼容 ie8 - 11 等 （ie上没有 oldUrl/newUrl 等属性）
                Object.defineProperty(event, "oldURL", { enumerable: true, configurable: true, value: lastURL });
                Object.defineProperty(event, "newURL", { enumerable: true, configurable: true, value: document.URL });
                lastURL = document.URL;
            }
            if (!history.isEdit) {
                this.hasChange(event, history.getLastRoute, history);
            } else {
                // console.log('[hash]', lastURL, lastURL.endsWith("/page"), lastURL.endsWith("/content"));
                lastURL = lastURL.split("?")[0];
                if (lastURL.endsWith("/page") || lastURL.endsWith("/content") || lastURL.endsWith("/man")) {
                    if (lastURL !== document.URL) {
                        window.location.reload();
                    }
                }

                //如果为编辑器模式，则直接刷新当前页
                // window.location.reload();
            }
        };
    }
    get(lHash?: string) {
        const hash = lHash || window.location.hash;
        const index = hash.indexOf("?");
        const p = hash.substr(index + 1).split("&");
        const start = hash.indexOf("#/") === 0 ? 2 : 1;
        let h = hash.substring(start);
        const params = {};
        if (index > -1) {
            h = hash.substring(start, index);
        }
        p.map((item: any) => {
            const data = item.split("=");
            if (data[0]) {
                params[data[0]] = decodeURIComponent(data[1]);
            }
        });
        // Utils.getUrlParamsMaps
        return { route: h, props: params };
    }

    hashChangeListener: any;

    registerHashChange(hashChange: any) {
        this.hashChangeListener = hashChange;
    }

    private isSameProps(props: any = {}, hashProps: any) {
        //防止hashProps后，会修改number为str，将props进行str处理
        const newProps = {};
        for (let key in props) {
            if (typeof props[key] === "number") {
                newProps[key] = props[key] + "";
            } else newProps[key] = props[key];
        }
        return JSON.stringify(newProps) === JSON.stringify(hashProps);
    }

    hasChange(ev: any, getLastRoute: any, history: any) {
        if (!this.hashChangeListener) return;
        const newUrl = ev.newURL;
        const oldUrl = ev.oldURL;

        if (oldUrl.indexOf("#/") <= -1 || newUrl.indexOf("#/") <= -1) return;
        const first = newUrl.split("#")[1];
        //检测是否为自己主动发起的hash改变
        if (this.hashCache[first]) {
            delete this.hashCache[first];
            return;
        }
        const newL = this.get(`#/${newUrl.split("#/")[1]}`);

        const lastLast = history.history[history.history.length - 2];
        const last = history.history[history.history.length - 1];

        // if (oldL.route !== newL.route && newUrl.indexOf(window.location.hash) > -1) {
        // console.log('[hash]', upRoute, !this.hashChangeListener);

        //如果新跳转页面与当前页面相同，则退出
        if (newL.route === last.ename) {
            // console.log("[hash]", newL.props, last.props);
            //如果参数不一样,或新页面不为单页应用
            if (newL.props["ncpage"] !== "true" && !this.isSameProps(last.props, newL.props)) {
                //跳转新页面
                this.hashChangeListener(Types.StartPage, { ename: newL.route, props: { isHash: true, ...newL.props } });
            }
        }
        //如果当前页面登录新页面，且等于栈记录的上一个
        else if (lastLast && newL.route === lastLast.ename && this.isSameProps(lastLast.props, newL.props)) {
            // console.log("[hash]11111", newL.props, lastLast.props);
            //跳转新页面
            // if (lastLast.props && lastLast.props["init"] === "-1" && lastLast.props["ncpage"] === "true") {
            //     this.hashChangeListener(Types.StartPage, {
            //         ename: newL.route,
            //         props: { ...newL.props, init: -1, wback: true, isHash: true },
            //     });
            // } else {
            this.hashChangeListener(Types.Back, { isHash: true });
            // }
        } else {
            //跳转新页面
            this.hashChangeListener(Types.StartPage, { ename: newL.route, props: { isHash: true, ...newL.props } });
        }
    }
    fromHash() {
        const params = window.location.hash ? window.location.hash.substr(1).split("&") : [],
            paramsObject = {};
        for (const i of params) {
            const a = i.split("=");
            paramsObject[a[0]] = decodeURIComponent(a[1]);
        }
        return paramsObject;
    }
    getHash(params: any) {
        let str = "";
        for (const p in params) {
            if (p && encodeURIComponent(params[p]) !== "undefined") {
                if (p !== "route") {
                    if (str.indexOf("?") <= -1) {
                        str = `${str}?${p}=${encodeURIComponent(params[p])}`;
                    } else {
                        str = `${str}&${p}=${encodeURIComponent(params[p])}`;
                    }
                } else {
                    str = encodeURIComponent(params[p]);
                }
            }
        }
        return str;
    }

    hashCache = {};

    toHash(params: any) {
        let str = "";
        // console.log("[hash] toHashtoHashtoHash", params);
        //如果页面是从hash变化过来的，则不再进行改变hash值
        if (params.isHash) return;
        let changeHashFn = (path: string) => {
            window.location.hash = path;
        };
        // if (params["wback"] === true) {
        //     // pc 不使用缓存页面方式的回退
        //     changeHashFn = replaceHashPath;
        //     delete params["wback"];
        // }
        if (params["staticLinkType"] === true) {
            // 静态化跳转
            changeHashFn = replaceHashPath;
            delete params["staticLinkType"];
        }
        str = this.getHash(params);
        // for (const p in params) {
        //     if (p && encodeURIComponent(params[p]) !== "undefined") {
        //         if (p !== "route") {
        //             if (str.indexOf("?") <= -1) {
        //                 str = `${str}?${p}=${encodeURIComponent(params[p])}`;
        //             } else {
        //                 str = `${str}&${p}=${encodeURIComponent(params[p])}`;
        //             }
        //         } else {
        //             str = params[p];
        //         }
        //     }
        // }
        if (str.indexOf("/") !== 0) str = `/${str}`;
        //
        if (this.hashChangeListener) this.hashCache[str] = true;
        changeHashFn(str);
    }

    add(newParams: any) {
        // const params: any = this.fromHash();
        // for (const p in newParams) {
        //     if (p) {
        //         params[p] = newParams[p];
        //     }
        // }
        this.toHash(newParams);
    }
    // remove(removeParams: any) {
    //     removeParams = typeof removeParams === "string" ? [removeParams] : removeParams;
    //     const params = this.fromHash();
    //     for (const i of removeParams.length) {
    //         if (i) {
    //             delete params[i];
    //         }
    //     }
    //     this.toHash(params);
    // }
    // clear() {
    //     this.toHash({});
    // }
}

export default Hash;
