import IUIItem from "src/engine/interface/IUIItem";

export default class SourceStyleStore {
    static store = new SourceStyleStore();

    sourceStyleMap: any = {};
    sourceStyleList: IUIItem[] = [];

    setSourceStyle(sourceStyle: any) {
        this.sourceStyleList = sourceStyle;
        for (const item of sourceStyle) {
            this.sourceStyleMap[item.info.ename] = item.props;
        }
    }

    getSourceStyle = (name?: string) => {
        if (!name) return this.sourceStyleList;
        return this.sourceStyleMap[name] || {};
    };
}
