export const HandleType = {
    //处理富文本
    handleText: "handleText",

    //处理分享url
    handleHttp: "handleHttp"
};
const RNConfig = {
    savePictureToCamera: {
        params: {
            url: HandleType.handleHttp
        },
        type: "savePictureToCamera"
    },
    shareToPlatform: {
        params: {
            imageurl: HandleType.handleHttp,
            text: HandleType.handleText,
            url: HandleType.handleHttp
        },
        type: "shareToPlatform"
    }

    //android :and_gotopage [1,'返回']   openlib [10,false,0]  fun
    //ios :and_gotopage {type:1}  openPhotoLib {maxCount:10,isOriginal: false, needCut: 0} fun

    //android :quitCommune  onWebBackJson ["{"targetView":"recentContacts","param":{shareType: "3"}}"]
    //ios : quitCommune     onWebBackJson  {param: {},targetView: "recentContacts"}

    // getConfig(server: string, gameId: string, file: string) {
    //     return `json`;
    // }
};

export default RNConfig;
