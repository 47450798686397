import IRule, { IRuleProps } from "./IRule";
import IUIItem from "src/engine/interface/IUIItem";

interface IRuleRegExp extends IRuleProps {
    //  正则 ：  此处正则不需要首尾的//，只需里边的内容
    // 例如:   [0-9]
    pattern: string;
}

/**
 * 校验 【正则】
 */
class RuleRegExp implements IRule {
    type: "regexp";

    props: IRuleRegExp;

    constructor(props: IRuleRegExp) {
        this.props = props;
    }

    exec(
        // 表单值
        formData: any,
        // 扩展属性, rules同级
        expend: any,
        // 当前表单 UIItem
        uiItem: IUIItem
    ) {
        const { cname } = uiItem.info;
        const { message = `${cname}输入格式不符合规则`, pattern } = this.props;
        // 如果 return false 则不添加规则
        if (!pattern) return false;
        if (process.env.NODE_ENV !== "production") {
            if (/^\/.*\/$/.test(pattern)) {
                throw new Error(`此处正则不需要//，只需里边的内容`);
                // console.error(`此处正则不需要//，只需里边的内容`);
                // return;
            }
        }
        return { message, pattern: new RegExp(pattern) };
    }
}

export default RuleRegExp;
