import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import "./PageMgr.scss";
// import { METHOD, CONTENT_TYPE } from "src/lib/http/HttpEmun";
import IUIItem from "src/engine/interface/IUIItem";
import Config from "src/engine/const/Config";
import UIManifest from "src/engine/ui/UIManifest";
import { IPanelContext } from "src/engine/context/PanelContext";
import IGameInfo from "src/engine/interface/IGameInfo";
import { IJJHistory } from "../source/JJHistory";
import PageLoading from "./PageLoading";
import Resolution from "./Resolution";
import SourceEngine from "src/engine/source/SourceEngine";
import SourceCenter from "src/engine/SourceCenter";
import PageMgrContext from "./PageMgrContext";
import { Types } from "../source/AppRoot";
import Manifest from "src/engine/Manifest";
import JsonCenter from "src/component/props/PropsExpend/JsonCenter";
import Style from "src/lib/utils/Style";
import { Toast } from "antd-mobile";
import Utils from "src/lib/utils/Utils";

interface IPCPageMgr extends IBaseUI {
    pages: IUIItem[];

    /**
     * 自定义页面
     *
     * @type {IUIItem[]}
     * @memberof IBaseUI
     */
    pageTemps: { [key: string]: IUIItem };

    history: IJJHistory[];

    jjHistory: any;

    init: false;

    isPC: boolean | "true" | "false";

    offset: number;

    isResolution: boolean;

    gameInfo: IGameInfo;

    sources: IUIItem[];

    sourceStyle?: IUIItem[];

    singleExit: boolean;

    bridgeUI: IUIItem[];
    loadingUI: IUIItem[];
    loadErrorUI?: IUIItem[];
    maxFontSize?: number | undefined; //最大的fontsize
    noJsonToast?: boolean;
    config?: any;
    //项目推出事件
    exitFunc?: any;
}
interface IState {
    pageError: boolean;
    mobileStyle?: any;
}
@UIManifest.declareGG(["ggValue"], "PCPageMgr", "页面管理器", UIManifest.Type.PageMgr)
class PageMgr extends BaseUI<IPCPageMgr, IState> {
    screen: any = {};

    isLandScape: boolean = false;

    constructor(props: IPCPageMgr) {
        super(props);
        this.screen = {
            // 顶部   todo: 参数  NotchH/NavigationH  设置成可配置的
            NotchH: Utils.getUrlParams("NotchH"),
            // 底部
            NavigationH: Utils.getUrlParams("NavigationH"),
        };
        if (this.screen.NotchH || this.screen.NavigationH) {
            (window as any).unnormal_screen = this.screen;
        }

        this.isLandScape = this.getLandScape();
    }

    isWindows() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    }

    getLandScape() {
        if (this.isWindows()) {
            const html = document.documentElement;
            return html.offsetWidth > html.offsetHeight;
        }
        return false;
    }

    componentDidMount() {
        const { source, jjHistory, init } = this.props;
        if (!source || source === "") return;
        if (!init) this.initJson(source);
        //注册应用退出事件
        this.props.jjHistory.exitFunc = this.exitFunc.bind(this);
        jjHistory.registerHashChange((type: string | Types, props?: any) => {
            this.sourceContext.execSource("manager", { type, ...props });
        });
    }
    state = {
        pageError: false,
        mobileStyle: {},
    };
    initJson(source: string) {
        this.initPages();
    }

    initPages = () => {
        const win: any = window;
        this.getJson("pages", win.TEMP_UPDATE_TIME, true, (data: any) => {
            const { sourceStyle = [] } = data.props || {};
            if (sourceStyle.length > 0) {
                this.mPanelContext.setSourceStyle?.(sourceStyle);
            }
        });
    };
    //

    timer: number = 0;

    exitFuncListener: any;

    registerExitFunc = (listener: any) => {
        this.exitFuncListener = listener;
    };

    /**
     * 项目退出
     */
    exitFunc() {
        this._exitFunc(false);
    }

    _exitFunc(isEdit: boolean) {
        const { singleExit = false, exitFunc } = this.props;
        if (exitFunc) {
            exitFunc();
            return;
        }
        if (singleExit) {
            //手机端点退出先弹提示再点才退出
            const delayTime = 3000;
            const time = new Date().valueOf();
            if (!this.timer || time - this.timer > delayTime) {
                Toast.info("再次点击退出应用", 3, () => {}, false);
                this.timer = time;
                return;
            }
            this.timer = time;
        }
        if (this.exitFuncListener) {
            setTimeout(() => {
                this.exitFuncListener();
            }, 100);
        } else {
            //编辑器模式下不能进行回退
            if (!isEdit) window.history.go(-1);
        }
    }

    public shouldComponentUpdate(nextProps: any = {}, nextState = {}) {
        return true;
    }

    /**
     * 获取页面数据
     *
     * @memberof PageMgr
     */
    getJson = (path: string, updateTime: number = new Date().valueOf(), refresh: boolean = true, cb?: (data: any) => void) => {
        const { gameInfo } = this.props;
        const url = Config.getConfig(gameInfo.server, gameInfo.gameId, path);
        JsonCenter.Instance.ajax(
            url,
            (data: any, CODE: number) => {
                if (CODE != 200) {
                    this.setState({ pageError: true });
                    return;
                } else {
                    this.sourceContext.execSource("manager", data, undefined, undefined, undefined, undefined, undefined, refresh);
                    cb?.(data);
                }
            },
            false,
            updateTime,
            1000 * 60 * 60
        );
    };

    componentWillUnmount() {
        this.sourceContext.execSource(this.props.source || "manager", { type: "uninit" });
        super.componentWillUnmount();
    }

    pageChange = (active: any) => {
        const { source } = this.props;
        if (!source || source === "") return;

        this.sourceContext.execSource(source, { type: "pageChange", active });
    };

    renderSinglePage = (historyItem: IJJHistory, index: number = 0, show: boolean = false) => {
        const { props, ename, key } = historyItem;
        const { pageTemps = {}, pageUpdateTime = {}, history = [], jjHistory, loadErrorUI, config = {} } = this.props as any;
        const page = pageTemps[ename];
        //判断当前页面，通过importPage方式引入，则没有backListener，则将当前页面赋予
        if (!historyItem.backListener) {
            historyItem.backListener = history[history.length - 1].backListener;
            // console.log("[PageMgr]", history, loadErrorUI);
        }
        const unUsePageError = config.unUsePageError;
        const isPC = this.isPC();
        if (!page) {
            return (
                <PageLoading
                    uiItem={Manifest.ui(ename, "", { updateTime: pageUpdateTime[ename] || "1" })}
                    loadErrorUI={loadErrorUI}
                    pageError={unUsePageError ? false : this.state.pageError}
                    isPC={isPC}
                    key={`${key}_${ename}`}
                    getJson={this.getJson}
                />
            );
        }
        let hidden = false;
        if (!show) {
            hidden = index < history.length - 2;
            const next = history[index + 1];
            if (next) {
                if (pageTemps[next.ename]) hidden = true;
                if (next.props.showLocalPage) hidden = false;
            }
            //如果当页面设置为一直显示，则不隐藏
            if (page.props.alwaysShow) hidden = false;
        }
        //渲染页面
        return this.mPanelContext.renderUIItem(page, {
            intent: props,
            key: `${ename}_${key || 0}_${index}`,
            hidden,
            last: index === history.length - 1,
            lastPageName: history[history.length - 1].ename,
            index,
            isPC,
            jjHistory,
            screen: this.screen,
            historyItem,

            mobileStyle: this.state.mobileStyle,
            registerModal: this.registerModal,
            registerExitFunc: this.registerExitFunc,
        });
    };

    renderPage(noMenu: boolean = false) {
        const { history = [], pagesStyle, config = {} } = this.props as any;

        const ret = history.map((item: IJJHistory, index: number) => {
            return this.renderSinglePage(item, index);
        });

        if (!noMenu && pagesStyle) {
            const { pagesStyle } = Style.getMainStyle(["pagesStyle"], this.props);
            return <div style={pagesStyle}>{ret}</div>;
        }

        if (this.isLandScape && config.maxWidth > 0) {
            return (
                <div className="pages-layout" style={{ maxWidth: config.maxWidth }}>
                    {ret}
                </div>
            );
        }

        return ret;
    }
    modalBack: any;
    registerModal = (fun: any) => {
        this.modalBack = fun;
    };
    getModal = () => {
        return this.modalBack;
    };
    renderContent = (context: IPanelContext) => {
        const { init = false, uiItem, loadingUI, bridgeUI, pageTemps = {}, jjHistory, loadErrorUI, config = {}, pages } = this.props;
        this.mPanelContext = context;
        const defaultMenu = jjHistory.history[jjHistory.history.length - 1]?.ename;
        const page = pageTemps[defaultMenu];
        const isPC = this.isPC();

        const noMenu = page?.props.menu?.single === "true";
        return (
            <PageMgrContext.Provider value={{ loading: loadingUI, renderPage: this.renderSinglePage as any, isPC: this.isPC }}>
                <div className={`react-pc-pagemgr ${isPC ? "pc" : ""} ${config.isHscreen ? "is-hscreen" : ""}`} style={this.getStyle()}>
                    {noMenu ? null : this.renderChildren(bridgeUI, { registerExitFunc: this.registerExitFunc, defaultMenu, getModal: this.getModal, pages })}
                    {!init ? <PageLoading uiItem={uiItem} isPC={isPC} loadErrorUI={loadErrorUI} /> : this.renderPage(noMenu)}
                </div>
            </PageMgrContext.Provider>
        );
    };

    isPC = () => {
        const { isPC = false } = this.props;
        return isPC === "true" || isPC === true;
    };

    renderCenter(callback: (context: IPanelContext) => any) {
        const { sources = [], updateTheme, stores = [] } = this.props as any;
        if (sources.length === 0 && stores.length === 0) return this.panel(callback);
        return (
            <SourceCenter ename="PageMgr" uiItem={Manifest.ui()} stores={stores} sources={SourceEngine.create(sources)} updateTheme={updateTheme}>
                {this.panel(callback)}
            </SourceCenter>
        );
    }

    mPanelContext: IPanelContext;

    renderAct() {
        const { isResolution = true, config = {} } = this.props as any;
        //PC端不进行分辨率设置
        if (this.isPC() || !isResolution) return this.renderCenter(this.renderContent);

        return (
            <Resolution
                offset={this.props.offset}
                width={config.baseUIWidth || 720}
                hWidth={config.baseUIWidth || 1280}
                isHscreen={config.isHscreen}
                resizeScreen={true}
                minHeight={config.minHeight}
                maxFontSize={this.props.maxFontSize}
                maxWidth={this.isLandScape ? config.maxWidth : 0}
                isResolution={config.noResolution}
                mobStore={config.outToStore}
            >
                {this.renderCenter(this.renderContent)}
            </Resolution>
        );
    }
}

export default PageMgr;
