import { IDataType } from "src/engine/SourceCenter/Manager";
import SourceHandle from "./SourceHandle";

class Exec {
    constructor() {}

    mHandle: SourceHandle;

    setHandle(handle: SourceHandle) {
        this.mHandle = handle;
    }

    init(data: any, props: any): IDataType {
        return { loading: false, data };
    }

    handleHeaders(headers: any, props: any) {
        return headers;
    }

    handleInitHeaders(headers: any, props: any) {
        return headers;
    }

    exec(headers: any, data: IDataType, props: any, callback: any = () => {}): any | IDataType {
        if (Array.isArray(data.data)) {
            return { ...data };
        }
        return { ...data, data: { ...data.data, ...headers } };
    }

    propsListener() {
        return ["initData", "dataValue", "initHandle"];
    }

    propsUpdate() {
        //通知更新初始化数据
        this.mHandle.update({}, this.mHandle.initData());
    }

    destory() {}
}

export default Exec;
