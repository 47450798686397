import IRule, { IRuleProps } from "./IRule";
import IUIItem from "src/engine/interface/IUIItem";

interface IRuleNumber extends IRuleProps {
    // 最大值
    max?: number;
    // 最小值
    min?: number;
    // 数字类型:  数字 | 整数 | 浮点数
    numberType?: "number" | "integer" | "float";
}

/**
 * 校验
 */
class RuleNumber implements IRule {
    type: "number";

    props: IRuleNumber;

    constructor(props: IRuleNumber) {
        this.props = props;
    }

    defineMessage() {
        const { max, min, numberType = "number" } = this.props;
        let numberTs = "数字";
        if (numberType === "integer") {
            numberTs = "整数";
        } else if (numberType === "float") {
            numberTs = "浮点数";
        }
        let selfM = `输入值应为`;
        if (min !== void 0 && max === void 0) {
            selfM += `大于${min}的${numberTs}`;
        } else if (max !== void 0 && min === void 0) {
            selfM += `小于${max}的${numberTs}`;
        } else if (max !== void 0 && min !== void 0) {
            selfM += `在${min}~${max}之间的${numberTs}`;
        }
        return selfM;
    }

    exec(
        // 表单值
        formData: any,
        // 扩展属性, rules同级
        expend: any,
        // 当前表单 UIItem
        uiItem: IUIItem
    ) {
        const { message, max, min, numberType = "number" } = this.props;
        // 如果 return false 则不添加规则
        return { message: message ? message : this.defineMessage(), type: numberType, max, min };
    }
}

export default RuleNumber;
