/*
 * @@Author: Do not edit
 * @@Date: Do not edit
 * @@Last Modified by: Do not edit
 * @@Last Modified time: Do not edit
 */
import BridgeConfig from "./BridgeConfig";
import RNUtils from "./RNUtils";

/**
 * RN连接桥
 *
 * @export
 * @class RNBridge
 */
export default class RNBridge {
    eventTarget = {};
    sender = {};
    rnUtils = {};
    constructor(eventTarget, listeners, RNpostMessage) {
        // RNpostMessage
        this.eventTarget = eventTarget;
        this.rnUtils = new RNUtils();
        //注册发送消息方法
        this.RNpostMessage = RNpostMessage && window.ReactNativeWebView;
        this.sender = this.RNpostMessage ? window.ReactNativeWebView.postMessage : window.postMessage;

        this.register(listeners);
        //触发初始化成功方案
        setTimeout(() => {
            eventTarget.fire(BridgeConfig.Events.INIT);
        }, 50);
    }
    //注册原生事件监听
    register = (listeners, context = window) => {
        // 注册监听方法;
        const keys = Object.keys(listeners);
        for (const name of keys) {
            const eventName = listeners[name];
            if (typeof eventName !== "string") {
                window[name] = {};
                return this.register(eventName, window[name]);
            }
            //重写全局方法，监听原生回调，并触发event事件
            context[name] = (args) => {
                const newArgs = args ? (typeof args === "string" ? JSON.parse(args) : args) : {};
                if (this && this.eventTarget) {
                    this.eventTarget.fire(eventName, newArgs);
                }
            };
        }
    };

    send = (inter, args, callback) => {
        if (!inter) {
            return;
        }
        window.console.log("zxx--RN:send-", inter, "-args", args, "-this.RNpostMessage-", this.RNpostMessage);
        const data = this.rnUtils.handleData(inter, args, callback);
        if (this.sender && data && typeof this.sender === "function") {
            window.console.log("zxx--RN:send:data-", data);
            if (this.RNpostMessage) {
                window.ReactNativeWebView.postMessage(JSON.stringify(data));
            } else {
                window.postMessage(JSON.stringify(data));
            }
        }
    };
}
