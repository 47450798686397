import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import SourceHandle from "./SourceHandle";
import Exec from "./Exec";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import "./BaseSource.scss";

interface IBaseSource extends IBaseUI {
    register: any;
    unregister: any;
    initData: any;
    refresh: string;
    show: boolean;
    manager?: any;
}
interface IState {}

class BaseSource extends BaseUI<IBaseSource, IState> {
    /**
     * 执行器
     */
    mExec: Exec;

    /**
     * 处理器
     */
    mHandle: SourceHandle;

    constructor(props: IBaseSource, exec: Exec) {
        super(props);
        const { uiItem, register } = this.props;
        this.mExec = this.getExec();
        this.mHandle = new SourceHandle(uiItem, this.mExec, this.props, props.manager);
        if (register) register(uiItem.info.ename, this.mHandle);
    }

    protected getExec() {
        return new Exec();
    }

    UNSAFE_componentWillReceiveProps(nextProps: IBaseSource) {
        const list = this.mHandle.getEvents();
        if (nextProps.refresh !== this.props.refresh) {
            this.mHandle.refresh(nextProps);
            return;
        }
        for (const item of list) {
            if (PropsUtils.getValue(nextProps, item) !== PropsUtils.getValue(this.props, item)) {
                this.mHandle.updateProps(nextProps);
                break;
            }
        }
    }

    componentWillUnmount() {
        const { uiItem, unregister } = this.props;
        if (unregister) unregister(uiItem.info.ename);
        this.mHandle.destory();
    }

    render() {
        const { show = false, uiItem } = this.props;
        if (!show) return <div />;

        return <div className="react-source-store">{uiItem.info.cname}</div>;
    }
}

export default BaseSource;
