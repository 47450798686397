import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import { IInfo } from "src/component/props/PropsGGEditor/exec/ExecScript";

interface ISourceScript {
    source: string;
    params?: { [key: string]: any };
    mergeValue: boolean;
    afterMerge?: boolean;
}

/**
 *
 *
 * @class GetSourceScript
 */
@UIManifest.declare("GetSourceScript", "获取Source", UIManifest.Type.Script)
export default class GetSourceScript extends Script<ISourceScript> {
    exec(info: IInfo, callback: (info: IInfo) => any) {
        // const { value } = info;
        const { source, mergeValue, afterMerge } = this.props;
        if (source && this.context.context.sourceContext) {
            const { params } = this.props;
            // if (value) params = { ...params, ...value };
            let value: any = {};
            if (mergeValue) {
                if (Array.isArray(info.value)) {
                    value = [...info.value];
                } else {
                    value = { ...info.value, ...params };
                }
            } else {
                value = { ...params };
            }
            source.split(",").map((itemSource: any) => {
                const bean = this.context.context?.sourceContext?.mManager.getSourceBean(itemSource) || {};
                const data = bean.data || {};
                if (Array.isArray(data.data)) {
                    if (mergeValue) {
                        if (afterMerge) {
                            // console.log(`[GetSourceScript]`, data.data, JSON.stringify(value));
                            value = [...data.data, ...value];
                        } else {
                            value = [...value, ...data.data];
                        }
                    } else {
                        value = [...data.data];
                    }
                } else {
                    if (afterMerge) {
                        value = { ...data.data, ...value };
                    } else {
                        value = { ...value, ...data.data };
                    }
                }
            });
            info.value = value;
            // console.log(`[GetSourceScript]`, value);
            callback({ ...info });
            return false;
        }
        //如果执行回调触发false
        return true;
    }
}
