type IType = "encode" | "decode";

/**
 * 字符串与Hex编码互转
 * @param input
 * @returns {string}
 */
const hexTools = (function (input) {
    const utf8encode = function (str: string, isGetBytes: any) {
        const back = [];
        let byteSize = 0;
        for (let i = 0; i < str.length; i++) {
            const code = str.charCodeAt(i);
            if (0x00 <= code && code <= 0x7f) {
                byteSize += 1;
                back.push(code);
            } else if (0x80 <= code && code <= 0x7ff) {
                byteSize += 2;
                back.push(192 | (31 & (code >> 6)));
                back.push(128 | (63 & code));
            } else if ((0x800 <= code && code <= 0xd7ff) || (0xe000 <= code && code <= 0xffff)) {
                byteSize += 3;
                back.push(224 | (15 & (code >> 12)));
                back.push(128 | (63 & (code >> 6)));
                back.push(128 | (63 & code));
            }
        }
        for (let i = 0; i < back.length; i++) {
            back[i] &= 0xff;
        }
        if (isGetBytes) {
            return back;
        }
        if (byteSize <= 0xff) {
            return [0, byteSize].concat(back);
        } else {
            return [byteSize >> 8, byteSize & 0xff].concat(back);
        }
    };

    const utf8decode = function (arr: any) {
        if (typeof arr === "string") {
            return arr;
        }
        let UTF = "";
        const iArr = arr;
        for (let i = 0; i < iArr.length; i++) {
            const one = iArr[i].toString(2),
                v = one.match(/^1+?(?=0)/);
            if (v && one.length === 8) {
                const bytesLength = v[0].length;
                let store = iArr[i].toString(2).slice(7 - bytesLength);
                for (let st = 1; st < bytesLength; st++) {
                    store += iArr[st + i].toString(2).slice(2);
                }
                UTF += String.fromCharCode(parseInt(store, 2));
                i += bytesLength - 1;
            } else {
                UTF += String.fromCharCode(iArr[i]);
            }
        }
        return UTF;
    };

    const hexEncode = function (str: string) {
        const charBuf = utf8encode(str, true);
        let re = "";
        for (const item of charBuf) {
            let x = (item & 0xff).toString(16);
            if (x.length === 1) {
                x = "0" + x;
            }
            re += x;
        }
        // for (let i = 0; i < charBuf.length; i++) {
        //     let x = (charBuf[i] & 0xff).toString(16);
        //     if (x.length === 1) {
        //         x = "0" + x;
        //     }
        //     re += x;
        // }
        return re;
    };

    const hexDecode = function (str: string) {
        const buf = [];
        for (let i = 0; i < str.length; i += 2) {
            buf.push(parseInt(str.substring(i, i + 2), 16));
        }
        return utf8decode(buf);
    };

    return { hexEncode, hexDecode };
})();

//base64编码字符集
const base64EncodeChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
//base64解码字符集
const base64e =
    "-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63,52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1,-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1,-1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1, -1, -1";
const base64DecodeChars = base64e.split(",");
/**
 * 此方法用于将文字进行base64编码
 * @param {Object} str 源码
 * @return {String} base64码
 */
const base64Encode = function (str: string) {
    let out, i, len;
    let c1, c2, c3;
    len = str.length;
    i = 0;
    out = "";
    while (i < len) {
        c1 = str.charCodeAt(i++) & 0xff;
        if (i === len) {
            out += base64EncodeChars.charAt(c1 >> 2);
            out += base64EncodeChars.charAt((c1 & 0x3) << 4);
            out += "==";
            break;
        }
        c2 = str.charCodeAt(i++);
        if (i === len) {
            out += base64EncodeChars.charAt(c1 >> 2);
            out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4));
            out += base64EncodeChars.charAt((c2 & 0xf) << 2);
            out += "=";
            break;
        }
        c3 = str.charCodeAt(i++);
        out += base64EncodeChars.charAt(c1 >> 2);
        out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4));
        out += base64EncodeChars.charAt(((c2 & 0xf) << 2) | ((c3 & 0xc0) >> 6));
        out += base64EncodeChars.charAt(c3 & 0x3f);
    }
    return out;
};

/**
 * 此方法用于将文字进行base64解码
 * @param {Object} str 源码
 * @return {String} 源码
 */
const base64Decode = function (str: string) {
    let c1, c2, c3, c4;
    let i = 0,
        out = "";
    const len = str.length;

    function getC(c: any, type: any) {
        do {
            if (type === 2) {
                c = str.charCodeAt(i++) & 0xff;
                if (c === 61) return out;
                c = Number(base64DecodeChars[c]);
            } else if (type === 1) {
                c = Number(base64DecodeChars[str.charCodeAt(i++) & 0xff]);
            }
        } while (i < len && c === -1);
        return c;
    }
    while (i < len) {
        /* c1 */
        c1 = getC(c1, 1);
        if (c1 === -1) break;
        /* c2 */
        c2 = getC(c2, 1);
        if (c2 === -1) break;
        out += String.fromCharCode((c1 << 2) | ((c2 & 0x30) >> 4));
        /* c3 */
        c3 = getC(c3, 2);
        if (c3 === -1) break;
        out += String.fromCharCode(((c2 & 0xf) << 4) | ((c3 & 0x3c) >> 2));
        /* c4 */
        c4 = getC(c4, 2);
        if (c4 === -1) break;
        out += String.fromCharCode(((c3 & 0x03) << 6) | c4);
    }
    return out;
};

/**
 * 此方法实现中文向Unicode的转码，与Jre中的"native2ascii"命令一样
 * @param {String} text 需要进行转码的字符串
 * @return {String} Unicode码
 */
const uniEncode = function (text: string) {
    const res = [];
    for (let i = 0; i < text.length; i++) {
        res[i] = ("00" + text.charCodeAt(i).toString(16)).slice(-4);
    }
    return "\\u" + res.join("\\u");
};

/**
 * 此方法用于将Unicode码解码为正常字符串
 * @param {Object} text
 */
const uniDecode = function (text: string) {
    text = text = text.replace(/(\\)?\\u/gi, "%u").replace("%u0025", "%25");
    text = unescape(text.toString().replace(/%2B/g, "+"));

    const matches = text.match(/(%u00([0-9A-F]{2}))/gi);
    if (matches) {
        for (const item of matches) {
            const code = item.substring(1, 3);
            const x = Number("0x" + code);
            if (x >= 128) {
                text = text.replace(item, code);
            }
        }
    }
    text = unescape(text.toString().replace(/%2B/g, "+"));

    return text;
};

export default class EndecodeCore {
    /**
     * Hex加解密
     * @param type IType,
     * @param value string
     */
    static Hex(type: IType, value: string) {
        if (type === "encode") {
            return hexTools.hexEncode(value);
        } else {
            return hexTools.hexDecode(value);
        }
    }

    static URL(type: IType, value: string) {
        if (type === "encode") {
            return encodeURIComponent(value);
        } else {
            return decodeURIComponent(value);
        }
    }

    static Base64(type: IType, value: string) {
        if (type === "encode") {
            if (typeof window.btoa === "function") {
                return window.btoa(encodeURIComponent(value));
            }
            return base64Encode(value);
        } else {
            if (typeof window.atob === "function") {
                return decodeURIComponent(window.atob(value));
            }
            return base64Decode(value);
        }
    }

    static Unicode(type: IType, value: string) {
        if (type === "encode") {
            return uniEncode(value);
        } else {
            return uniDecode(value);
        }
    }
}
