import IUIItem from "src/engine/interface/IUIItem";
import Manifest from "src/engine/Manifest";
// import UIManifest from "src/engine/ui/UIManifest";

export const BlackList = {
    ModuleItem: true,
    SourceItem: true,
    // SourceBridge: true,
    // SourceCookie: true,
    // SourceUtils: true,
    // SourceNative: true,
    // SourceNet: true,
    // SourceStore: true,
    // SourceArtical: true,
    GGEnd: true,
};
// linear-gradient(to bottom, rgb(53,118,27 ) , rgb(31,82,172))
export const BlackChildrenList = {
    SourceBridge: true,
    SourceCookie: true,
    SourceUtils: true,
    SourceNative: true,
    SourceNaitve: true,
    SourceStyle: true,
    // SourceNet: true,
    SourceStore: true,
    SourceArtical: true,
    SourceES: true,
};
/**
 *
 *
 * @class UIImport
 */
class UIImport {
    stack: {
        [key: string]: { [key: string]: number };
    };
    pagesExist: { [key: string]: boolean };

    constructor(stack?: any, pagesExist?: any) {
        this.stack = stack || { _all: {} };
        this.pagesExist = pagesExist;
    }

    private _parserMap(nodes: { [key: string]: IUIItem }, maps: any) {
        const list = [];
        for (const nodeKey in nodes) {
            if (nodes[nodeKey]) list.push(nodes[nodeKey]);
        }
        this._parser(list, maps);
    }

    private saveItem(ui: string | any, maps: any) {
        if (typeof ui === "string") {
            if (!maps[ui]) maps[ui] = 0;
            maps[ui] += 1;
        }
    }

    private checkISUIItemList(item: any) {
        return Array.isArray(item) && item.length > 0 && item[0].info && typeof item[0].ui === "string";
    }

    private _parser(uiItems: IUIItem[] = [], maps = {}) {
        //stores列表
        uiItems.map((uiItem, index) => {
            const { props } = uiItem;
            if (BlackList[uiItem.ui as string] || !props) return;
            //判断组件类型
            if (typeof uiItem.ui === "string") {
                //如果组件类型为数据集合，则不再处理子属性

                if (BlackChildrenList[uiItem.ui as string]) {
                    // if (UIManifest.getTypeByUI(uiItem.ui) === UIManifest.Type.Data) {
                    this.saveItem(uiItem.ui, maps);
                    return;
                }
            }

            this.saveItem(uiItem.ui, maps);

            const keys = Object.keys(props);

            for (const key of keys) {
                //content stores等
                const item = props[key]; //content列表 stores列表
                if (this.checkISUIItemList(item)) {
                    this._parser(item, maps);
                } else if (item && item.ret && item.ret.nodes) {
                    this._parserMap(item.ret.nodes, maps);
                    this._parserMap(item.ret.edges, maps);
                } else {
                    this.checkList(item, maps);
                }
            }
        });
        return maps;
    }

    private checkList(list: any, maps: any) {
        if (typeof list !== "object") return;
        for (const itemKey in list) {
            // if (uiItem.ui === "AntTable") console.log("wugz]  itemKey", itemKey, item[itemKey], this.checkISUIItemList(item[itemKey]));
            if (this.checkISUIItemList(list[itemKey])) this._parser(list[itemKey], maps);
            else this.checkList(list[itemKey], maps);
        }
    }

    parserUIS(uiItem: IUIItem) {
        const { pageTemps = {}, bridgeUI, stores, sourceStyle } = uiItem.props;
        //处理编辑的模板
        const keys = Object.keys(pageTemps);
        for (const page of keys) {
            //home home模板
            if (pageTemps[page] && pageTemps[page].props.init !== false) {
                this.pageParser(pageTemps[page]);
            }
        }

        //处理删除的页面
        const keysAll = Object.keys(this.stack);
        for (const page of keysAll) {
            if (page !== "_all" && this.pagesExist && !this.pagesExist[page]) {
                this.pageParser(Manifest.ui(page, page, {}, "PCPage"));
            }
        }

        //处理扩展菜单
        if (bridgeUI?.length > 0) this.pageParser(Manifest.ui("bridgeUI", "bridgeUI", { content: bridgeUI }, "PCPage"));
        else if (this.stack.bridgeUI) this.pageParser(Manifest.ui("bridgeUI", "bridgeUI", {}, "PCPage"));
        // 处理全局的store/sourceStyle
        this.pageParser(Manifest.ui("PageMgrUIS", "PageMgrUIS", { stores, sourceStyle }, "PCPage"));

        //删除所有PCPage的使用量
        delete this.stack._all?.["PCPage"];
        return this.stack;
    }

    pageParser(uiItem: IUIItem) {
        //解析页面
        const pageUIS = this._parser([uiItem]);
        const old = this.stack[uiItem.info.ename] || {};
        const all = this.stack["_all"];
        this.stack[uiItem.info.ename] = pageUIS;

        if (Object.keys(pageUIS).length <= 1) delete this.stack[uiItem.info.ename];
        //去重
        const merge = Object.keys({ ...old, ...pageUIS });
        for (const key of merge) {
            let count = 0;
            //如果存在于新的
            if (pageUIS[key]) {
                count = pageUIS[key] - (old[key] || 0);
            } else {
                count -= old[key];
            }
            all[key] = (all[key] || 0) + count;
            if (all[key] <= 0) delete all[key];
        }
        return this.stack;
    }
}

export default UIImport;
