import React from "react";
import BaseComponent from "src/engine/ui/BaseComponent";
import PanelContext, { IPanelContext } from "../context/PanelContext";
import IUIItem from "../interface/IUIItem";
import Style, { StyleSheet } from "src/lib/utils/Style";
import { Types } from "src/engine/pages/source/AppRoot";
import PageContext, { IPageContext } from "src/engine/pages/ui/PageContext";
import { ISourceContext, SourceContext } from "../SourceCenter/SourceContext";
import Utils from "src/lib/utils/Utils";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import AutoContext, { Context } from "../decorator/AutoContext";
// import AutoBind from "../decorator/AutoBind";

export interface IAllotItem {
    key: string;
    keyValue: string | IAllotItem[];
}

export interface IBaseUI {
    /**
     * BaseUI必须包含的UIItem项
     *
     * @type {IUIItem}
     * @memberof IBaseUI
     */
    uiItem: IUIItem;

    /**
     * 自定义的UIItem子项
     *
     * @type {IUIItem[]}
     * @memberof IBaseUI
     */
    children?: IUIItem[] | any;

    /**
     * loading状态
     *
     * @type {boolean}
     * @memberof ICard
     */
    loading?: boolean;

    source?: string;

    /**
     * 数据列表
     *
     * @type {any[]}
     * @memberof IBaseUI
     */
    dataSource?: any;

    //对应dataSource
    dataKey?: string[];

    data?: any;

    //数据是否传递给子元素
    dataExtend?: string;

    className?: string;

    //数据分配器
    dataAllot?: { [key: string]: IAllotItem[] };

    parent?: IUIItem;

    lifeCycle?: { [key: string]: () => {} };

    _renderUIItem?: any;
}

class BaseUI<T extends IBaseUI, S = {}> extends BaseComponent<T, S> {
    sourceContext: ISourceContext;
    pageContext: IPageContext;
    styleSheet: StyleSheet[] = [];

    constructor(props: T) {
        super(props);
        const { lifeCycle } = props;

        lifeCycle?.init?.();

        if (lifeCycle?.componentWillUnmount) {
            this.lifePush(lifeCycle?.componentWillUnmount);
        }
    }

    _getProps(): any {
        const { handleRet, dataMgr, _module, ...props } = this.props as any;
        return props;
    }

    log = (ename: string, ...arg: any) => {
        if (this.props.uiItem.info.ename === ename) console.log(...arg);
    };

    getStyle(oldStyle = {}, props = this.props) {
        const { _styleList = [], extStyleString, theme, _computeStyle = [], editStyle = {} } = props as any;
        //
        let list = [..._styleList, "style", ..._computeStyle];
        if (extStyleString) list = [...list, ...(extStyleString || "").split(",")];
        const arr = [];
        for (const key in theme || {}) {
            if (theme[key]) {
                arr.push(theme[key]);
                for (const item of list) {
                    const oKey = (item as any).value || item;
                    if (oKey !== "style" && oKey) arr.push(theme[key] + oKey);
                }
            }
        }
        if (arr.length > 0) list = [...list, ...arr];
        list.map((key: string) => {
            if (props[key]) oldStyle = { ...oldStyle, ...props[key] };
        });

        //计算样式钩子
        if ((props as any)._hookStyle) {
            oldStyle = { ...oldStyle, ...(props as any)._hookStyle() };
        }
        // 生成继承样式
        const sourceStyle = this.createSourceStyle(props, list);
        return Style({ ...sourceStyle, ...oldStyle, ...editStyle });
    }

    createSourceStyle = (props: any, list: string[]) => {
        const { theme, extSourceStyle } = props as any;
        let sourceStyle = {};
        if (extSourceStyle) {
            const baseStyle = extSourceStyle.style || {};
            sourceStyle = { ...baseStyle };
            for (const item of list) {
                const oKey = (item as any).value || item;
                if (oKey !== "style" && oKey) {
                    const chooseStyle = extSourceStyle[oKey];
                    if (chooseStyle) {
                        sourceStyle = { ...sourceStyle, ...chooseStyle };
                    }
                }
            }
            for (const key in theme || {}) {
                if (theme[key]) {
                    const chooseStyle = extSourceStyle[theme[key]];
                    if (chooseStyle) {
                        sourceStyle = { ...sourceStyle, ...chooseStyle };
                    }
                }
            }
        }
        return sourceStyle;
    };

    getComputeStyle() {
        const { _computeStyle, style } = this.props as any;
        return Style({ ...style, ..._computeStyle });
    }

    goBack = (count: number = 1, backProps?: any): any => {
        this.goManger(Types.Back, backProps);
        if (count > 1) this.goBack(count - 1, backProps);
    };

    getIntent() {
        return (this.pageContext && this.pageContext.intent) || {};
    }

    isLastPage() {
        return this.pageContext && this.pageContext.pageLast;
    }

    startPage = (ename: string, props = {}) => {
        // this.sourceContext.execSource("manager", { type: Types.StartPage, ename, props });
        //增加回调函数处理
        const callback = this.props[`${ename}Callback`];
        if (callback) props = { ...callback, callback };
        this.goManger(Types.StartPage, { ename, props });
    };

    goManger = (type: string | Types, props?: any) => {
        this.sourceContext.execSource("manager", { type, ...props });
    };

    /**
     * 获取source context
     *
     * @param {(context: ISourceContext) => any} callback
     * @returns
     * @memberof BaseUI
     */
    source(callback: (context: ISourceContext) => any) {
        return <Context context={SourceContext}>{callback}</Context>;
        // return <SourceContext.context.Consumer>{callback}</SourceContext.context.Consumer>;
    }
    /**
     * 获取Panel context
     * @param callback
     */
    panel(callback: (context: IPanelContext) => any) {
        return <Context context={PanelContext}>{callback}</Context>;
    }

    /**
     * 获取page intent
     * @param callback
     */
    page(callback: (context: IPageContext) => any) {
        return <Context context={PageContext}>{callback}</Context>;
    }

    getChildDataKey() {
        return "data";
    }

    getChildData() {
        return this.props.data;
    }

    private getChildrenProps(allot: IAllotItem[] = [], props: any) {
        const ret = {};

        allot.map((item) => {
            if (Array.isArray(item.keyValue)) {
                ret[item.key] = this.getChildrenProps(item.keyValue, props);
            } else {
                try {
                    const keys = item.key.split(".");
                    let value;
                    if (typeof item.keyValue === "string") {
                        value = PropsUtils.getValue(props, item.keyValue as string, props._parseJson ?? true);
                    } else {
                        value = item.keyValue;
                    }
                    if (value !== undefined) ret[keys[keys.length - 1]] = value;
                } catch (error) {
                    console.log("[BaseUI]:error item", item, props);
                }
            }
        });
        return ret;
    }

    renderUIItem(uiItem: IUIItem, context: IPanelContext, props: any = {}): any {
        const { dataExtend, uiItem: parentItem, parent, dataAllot = {}, _allDataAllot = {} } = this.props as any;
        const data = this.getChildData();
        if (dataExtend === "true" && data) {
            props[this.getChildDataKey()] = data;
            const splitVal = Utils.getValue(data, uiItem.info.ename.split("."));
            if (splitVal !== undefined) {
                props.value = splitVal;
            }
            //total相关处理
            if (this.props["total"]) {
                if (!props.value && uiItem.info.ename === "total") {
                    props.value = this.props["total"];
                }
                props.total = this.props["total"];
            }
        }
        //根据分配器分配数据
        const allot = dataAllot[uiItem.info.ename];

        if (allot) {
            props = { ...props, ..._allDataAllot, ...this.getChildrenProps(allot, this.props) };
        }
        return context.renderUIItem(uiItem, props, parent || parentItem);
    }

    renderUIItems(uiItems: IUIItem[], context: IPanelContext, props?: any) {
        return (uiItems || []).map((item, key) => {
            return this.renderUIItem(item, context, { key: `${item.info.ename}_${key}`, index: key, ...props });
        });
    }

    mPanelContext: IPanelContext;

    @AutoContext(PanelContext, "mPanelContext")
    renderDataItems(list?: any[], uiItem?: IUIItem, callback?: any) {
        return this.renderDataItemsByContext(this.mPanelContext, list, uiItem, callback);
    }

    renderDataItemsByContext(context: IPanelContext, list: any[] = this.props.data || this.props.dataSource, uiItem?: IUIItem, callback?: any, other?: any) {
        if ((list || []).length === 0) return null; //this.renderChildren();
        if (!uiItem) {
            const { children = [] } = this.props as any;
            if (children.length > 0) uiItem = children[0];
        }
        if (!uiItem) return null;
        if (!list.map) {
            return null;
        }
        return list.map((data: any, key: number) => {
            let ret = null;
            if (uiItem) ret = this.renderUIItem(uiItem, this.mPanelContext, { key, index: key, data, ...other });
            if (callback) ret = callback(ret, data, key);
            return ret;
        });
    }

    /**
     * 调用PanelContext渲染组件
     * @param uiItems
     */
    // @AutoBind
    renderChildren(uiItems?: IUIItem[], props?: any, renderFiberFlag: boolean = true, callback?: any): any {
        if (!uiItems) {
            uiItems = this.props.children;
        }
        if (uiItems && !Array.isArray(uiItems)) uiItems = [uiItems];
        if ((uiItems || []).length === 0) return null;
        if (uiItems && (!uiItems[0] || !uiItems[0].info)) {
            return renderFiberFlag ? this.props.children : null;
        }
        if (this.props._renderUIItem) {
            const context = { renderUIItem: this.props._renderUIItem } as any;
            if (callback) return callback(this.renderUIItems(uiItems || [], context, props));
            return this.renderUIItems(uiItems || [], context, props);
        }
        return this.panel((context) => {
            if (callback) return callback(this.renderUIItems(uiItems || [], context, props));
            return this.renderUIItems(uiItems || [], context, props);
        });
    }

    renderAct(): any {}

    render(): JSX.Element | null | any[] {
        return this.page((context: IPageContext) => {
            this.pageContext = context;
            return this.source((contex: ISourceContext) => {
                this.sourceContext = contex;
                return this.renderAct();
            });
        });
    }
}

export default BaseUI;
