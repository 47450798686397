import * as React from "react";
import BaseComponent from "src/engine/ui/BaseComponent";
import "./Panel.scss";
import IHistory from "src/lib/router/IHistory";
import IUIItem from "../interface/IUIItem";
import PanelContext, { IRenderType } from "../context/PanelContext";
import IInfo from "../interface/IInfo";
// import SourceUI from "./SourceUI";
import UIManifest from "src/engine/ui/UIManifest";
import PanelConfig from "./PanelConfig";
import Style from "src/lib/utils/Style";
import SourceUI from "../SourceCenter/SourceUI";
import SourceCenter from "../SourceCenter";
import Manifest from "../Manifest";
import UILoad from "./UILoad";
import AutoBind from "../decorator/AutoBind";
import Storage from "src/lib/utils/storage/Storage";
import SourceStyleStore from "src/component/source/SourceStyle/SourceStyleStore";

/**
 *
 *
 * @interface IPanel
 */
export interface IPanel extends IHistory {
    /**
     *
     * Panel配置
     * @type {PanelConfig}
     * @memberof IPanel
     */
    dataSource: PanelConfig;

    panelProps?: { [key: string]: any };

    // isContent?: boolean;

    renderType?: IRenderType;

    className?: string;

    eprops?: any;

    title?: string;

    style?: any;
}

interface IState {
    dataSource: any;
    [key: string]: any;
    theme: { [key: string]: any };
}

const LOCAL_THEME_KEY = "source_theme";

class Panel extends BaseComponent<IPanel, IState> {
    mCenter: SourceCenter | null;

    constructor(props: IPanel) {
        super(props);
        this.state = {
            dataSource: this.getDataSource(),
            theme: this.getLocalTheme(),
        };

        // (window as any).updateTheme = this.updateTheme;
    }

    getLocalTheme() {
        const storage = new Storage(Storage.StorageType.LocalStorage);
        try {
            const ret = storage.get(LOCAL_THEME_KEY);
            if (ret) {
                return JSON.parse(ret);
            }
        } catch (error) {
            console.log("[Panel]:", error);
        }
        return {};
    }

    @AutoBind
    updateTheme(getTheme: any) {
        const { theme } = this.state;
        const { end, ...other } = getTheme;
        const themeValue = { ...theme, ...other };
        this.setState({ theme: themeValue });
        const storage = new Storage(Storage.StorageType.LocalStorage);
        storage.set(LOCAL_THEME_KEY, JSON.stringify(themeValue));
    }

    getDataSource() {
        return this.props.dataSource;
    }
    getIsEdit() {
        return this.props.renderType || "app";
    }
    renderUIItem = (uiItem: IUIItem, eprops?: any, parent?: IUIItem) => {
        return this.renderUI(uiItem, eprops);
    };

    getUI(ui: string): any {
        // return UIManifest.maps[ui] || Manifest.ui(ui, ui, { _ui: ui }, UILoad);

        if (!ui) return false;
        if (typeof ui === "string") {
            const def: IUIItem = UIManifest.maps[ui] || Manifest.ui(ui, ui, { _ui: ui }, UILoad);
            if (!def) {
                return false;
            }
            if ((def.ui as any) === UILoad) console.log("[Panel]", "组件不存在", ui);
            ui = def as any;
        }
        return ui;
    }

    renderUI = (uiItem: IUIItem, eprops: any = {}) => {
        const { info, props = {}, source = "" } = uiItem;
        let dataSource = uiItem.dataSource || props.dataSource || eprops?.dataSource;
        if (eprops?.dataSource?.length > 0) {
            dataSource = eprops.dataSource;
        }
        // delete eprops.dataSource;
        let { ui } = uiItem;
        if (!ui) return null;
        if (typeof ui === "string") {
            const def: IUIItem = this.getUI(ui);
            if (!def) {
                return null;
            }
            ui = def.ui;
            eprops = { ...def.props, ...eprops };
            delete eprops.dataSource;
        }
        const style = { ...props.style, ...eprops.style };
        const toProps = { uiItem, ...uiItem.info, ...props, ...eprops, style };
        const exts = props.extSourceStyle || eprops.extSourceStyle;
        if (exts) {
            const extsarr = exts.split(",");
            toProps.extSourceStyle = {};
            for (const str of extsarr) {
                const thisThemeStyle = this.getSourceStyle(str);
                Object.keys(thisThemeStyle).map((themeKey: string) => {
                    toProps.extSourceStyle[themeKey] = { ...toProps.extSourceStyle[themeKey], ...thisThemeStyle[themeKey] };
                });
                //toProps.extSourceStyle = { ...toProps.extSourceStyle, ...this.getSourceStyle(str) };
            }
        }
        if (Object.keys(this.state.theme).length > 0) toProps.theme = this.state.theme;
        if ((uiItem as any).children) toProps.children = (uiItem as any).children;
        if (dataSource) toProps.dataSource = dataSource;
        if (source) toProps.source = source;
        if ((typeof dataSource === "string" && dataSource !== "") || (toProps.themeMap && toProps.themeMap.length > 0)) {
            return (
                <SourceUI key={toProps.key || info.ename} uiItem={uiItem} dataSource={dataSource}>
                    {this.renderItem.bind(this, info, ui, toProps)}
                    {/* {this.renderItem(info, ui, toProps, uiItem)} */}
                </SourceUI>
            );
        }
        return this.renderItem(info, ui, toProps);
    };
    renderItem(info: IInfo, ui: any, props: any, other: any = {}) {
        const UI: any = ui;
        if (props.style && other.style) {
            other.style = { ...props.style, ...other.style };
        }
        return <UI key={info.ename} {...props} {...other} _renderUIItem={this.renderUIItem} />;
    }

    getManager(): any {
        return null;
    }

    renderChild() {
        const { dataSource = {} } = this.state;
        return (dataSource || { uiItems: [] }).uiItems?.map((item: IUIItem) => {
            return this.renderUIItem(item, { ...this.props.eprops, ...dataSource.eprops });
        });
    }

    setSourceStyle = (sourceStyle: any) => {
        SourceStyleStore.store.setSourceStyle(sourceStyle);
    };

    getSourceStyle = (name?: string) => {
        return SourceStyleStore.store.getSourceStyle(name);
    };

    renderProvider() {
        const { dataSource = {} } = this.state;
        return (
            <PanelContext.Provider
                value={{
                    theme: this.state.theme,
                    renderUIItem: this.renderUIItem,
                    manager: this.getManager(),
                    getUI: this.getUI,
                    renderType: this.getIsEdit(),
                    setSourceStyle: this.setSourceStyle,
                    getSourceStyle: this.getSourceStyle,
                }}
            >
                <SourceCenter
                    ename={"panel"}
                    uiItem={Manifest.ui()}
                    ref={(ref) => (this.mCenter = ref)}
                    sources={dataSource?.sources}
                    stores={dataSource?.stores}
                    updateTheme={this.updateTheme}
                >
                    {this.renderChild()}
                    {this.props.children}
                </SourceCenter>
            </PanelContext.Provider>
        );
    }

    render() {
        const { className = "", style } = this.props;
        return (
            <div className={`react-panel ${className}`} style={Style(style)}>
                {this.renderProvider()}
            </div>
        );
    }
}

export default Panel;
