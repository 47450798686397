export const JJMATCH_STATIC_PAGE = "_JJMATCH_STATIC_PAGE_";

export const SHOW_STATIC_TO_SPA = "_SHOW_STATIC_TO_SPA_";

export const OPEN_NEW_PAGE_TYPE = "_JJMATCH_OPEN_NEW_PAGE_TYPE_";

export const IMMEDIATE_SCRIPT_FLAG = "_immediateScriptRender";

export const MOUNTED_IMMEDIATE_SCRIPT_FLAG = "_mountedOverImmediateScriptRender";

export const UNMOUNT_IMMEDIATE_SCRIPT_FLAG = "_unmountImmediateScriptRender";

export const POLLING_IMMEDIATE_SCRIPT_FLAG = "_pollingImmediateScriptRender";

export const EVENT_RESIZE_SCRIPT_FLAG = "_eventResize";
