import Handle from "./Handle";
import { IInfo } from "../ExecScript";
import { IGuiType } from "../IExecConfig";

/**
 *
 *
 * @class HandleCheck
 */
class HandleCheck extends Handle {
    /**
     * 执行下一步
     */
    protected execNext(info?: IInfo) {
        let checkFlag = false;
        for (const id of this.nextIds) {
            let to = info;
            if (to) to = { ...to };
            const ids = id.split(",");
            //执行边
            const check = this.execEdge(ids[1], to);
            //如果检测正确,直接执行下去，并不再触发别的检测
            if (check) {
                // console.log("[HandleCheck]:", ids[0], check, to);
                //下一步
                this.execItem(ids[0], to, this.nodeId);
                // 设置flag
                checkFlag = true;
                break;
            }
        }
        if (!checkFlag && info && info.renderForNull) {
            // 全部不匹配时是否不渲染子组件
            if (!this.data) return;
            if (this.data.type === IGuiType.UICheck) {
                this.ret[this.data.endKey || "children"] = [];
            }
        }
    }
}

export default HandleCheck;
