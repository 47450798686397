import * as React from "react";
import Style from "src/lib/utils/Style";

export interface ITouchProps {
    disabled?: boolean;
    activeStyle?: any;
    hoverStyle?: any;
    children?: any;
    hoverChildren?: any;
    onHover?: any;
    data?: any;
    index?: number;
    hoverKey?: string;
    hovertToEvent?: boolean;
    hoverChildrenNotClick?: boolean;
    ename?: string;
}
const hoverMap: any = {};
let hoverIndex: any;
export interface ITouchState {
    active: boolean;
    hover: boolean;
}

export default class TouchFeedback extends React.Component<ITouchProps, ITouchState> {
    static defaultProps = {
        disabled: false,
    };

    state = {
        active: false,
        hover: false,
    };
    componentDidUpdate() {
        if (this.props.disabled && this.state.active) {
            this.setState({
                active: false,
            });
        }
    }

    clearHover = () => {
        if (this.state.hover) this.setState({ hover: false });
    };

    triggerEvent(type: string, isActive: boolean, ev: any, isType = ["active"]) {
        // "MouseLeave", false, e, ["active", "hover"]
        const eventType = `on${type}`;
        const { children, onHover, hoverChildren, hoverChildrenNotClick = false, hovertToEvent } = this.props;

        //继续调用子组件传递的属性
        if (children.props[eventType] && !(this.state.hover && hoverChildren)) {
            children.props[eventType](ev);
        }

        const state = {};
        isType.map((item: string) => {
            if (isActive !== this.state[item]) {
                state[item] = isActive;
            }
        });

        if (
            (state["hover"] === true && hoverChildren && onHover && !hoverChildrenNotClick && hovertToEvent) ||
            (state["hover"] === true && hovertToEvent && onHover)
        ) {
            onHover();
        }
        this.setState(state);
    }

    onTouchStart = (e: any) => {
        this.triggerEvent("TouchStart", true, e);
    };

    onTouchMove = (e: any) => {
        this.triggerEvent("TouchMove", false, e);
    };

    onTouchEnd = (e: any) => {
        this.triggerEvent("TouchEnd", false, e);
    };

    onTouchCancel = (e: any) => {
        this.triggerEvent("TouchCancel", false, e);
    };

    onMouseDown = (e: any) => {
        // pc simulate mobile
        this.triggerEvent("MouseDown", true, e);
    };

    onMouseUp = (e: any) => {
        this.triggerEvent("MouseUp", false, e);
    };

    onMouseEnter = (e: any) => {
        this.triggerEvent("MouseEnter", true, e, ["hover"]);
    };
    onMouseOver = (e: any) => {
        this.triggerEvent("MouseOver", true, e, ["hover"]);
    };

    onMouseLeave = (e: any) => {
        this.triggerEvent("MouseLeave", false, e, ["active", "hover"]);
    };
    onMouseOut = (e: any) => {
        this.triggerEvent("MouseOut", false, e, ["active", "hover"]);
    };
    componentDidMount = () => {
        const { hoverChildren, data = {}, hoverKey = "" } = this.props;
        const key = data[hoverKey] || data.artTitle;
        if (hoverChildren && key) {
            hoverMap[key] = () => {
                this.setState({ hover: false });
            };
        }
    };

    render() {
        const { children, disabled, activeStyle, hoverStyle, hoverChildren, data = {}, hoverKey = "" } = this.props;

        const events = {
            onTouchStart: this.onTouchStart,
            onTouchMove: this.onTouchMove,
            onTouchEnd: this.onTouchEnd,
            onTouchCancel: this.onTouchCancel,
            onMouseDown: this.onMouseDown,
            onMouseUp: this.onMouseUp,
            onMouseEnter: this.onMouseEnter,
            // onMouseOver: this.onMouseOver,
            onMouseLeave: this.onMouseLeave,
            // onMouseOut: this.onMouseOut
        };

        const child = React.Children.only(children);

        if (!disabled) {
            let { style } = child.props;
            if (this.state.hover && hoverStyle) {
                style = { ...style, ...Style(hoverStyle) };
            }
            if (this.state.active && activeStyle) {
                style = { ...style, ...Style(activeStyle) };
            }
            if (this.state.hover && ((Array.isArray(hoverChildren) && hoverChildren.length > 0) || hoverChildren)) {
                const key = data[hoverKey] || data.artTitle;
                if (key) {
                    //关闭其他的hoverChildren显示
                    if (key !== hoverIndex) {
                        if (hoverMap[hoverIndex]) {
                            hoverMap[hoverIndex]();
                        }
                        hoverIndex = key;
                    }
                }

                if (this.props.hoverChildrenNotClick) {
                    return React.cloneElement(child, {
                        style,
                        ...events,
                        hover: this.state.hover,
                        children: React.cloneElement(hoverChildren[0], { data }),
                    });
                }

                return (
                    <div {...events}>
                        {React.cloneElement(hoverChildren[0], { data })}
                        {React.cloneElement(child, {
                            style,
                            // ...events,
                            clearHover: this.clearHover,
                            hover: this.state.hover,
                        })}
                    </div>
                );
            } else {
                return React.cloneElement(child, {
                    style,
                    ...events,
                    hover: this.state.hover,
                    clearHover: this.clearHover,
                });
            }
        }
        return child;
    }
}
