import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import UIManifest from "src/engine/ui/UIManifest";
import IUIItem from "src/engine/interface/IUIItem";
import Loading from "./Loading";
import { IPanelContext } from "src/engine/context/PanelContext";
import Utils from "src/lib/utils/Utils";
import Manifest from "src/engine/Manifest";
import { EmojiCodeMap } from "../emoji/BBSEmoji";

interface IDataItem extends IBaseUI {
    keyVal?: string;
    dataString: string;
    syncSource?: string; //同步的目标数据
    syncMap?: string;
    nullChildren: IUIItem[];
    map: any[];
    loadingChildren: IUIItem[];
    errorChildren: IUIItem[];
    orderStr?: string; //获取数组取数的序号角标，取哪一个
    dataFromIntent: boolean;
    sourceMap?: any;
    useSourceMap?: string;
    combineData?: boolean;
    propOtherSource?: string;
    ispolling?: boolean; // 是否轮询
    pollingTime?: number; // 轮询间隔
    emojiKey?: string;
}

function ObverIntervalTimer(fn: any, time: number) {
    let timer: any;
    fn();
    function interval() {
        timer = setTimeout(() => {
            fn();
            interval();
        }, time);
    }
    interval();

    return {
        clear: () => clearTimeout(timer),
        refire: () => {
            fn();
            interval();
        },
    };
}

@UIManifest.declare("DataItem", "数据监听器", UIManifest.Type.Discard)
class DataItem extends BaseUI<IDataItem> {
    obverIntervalTimer: any;

    lastPage: boolean = true;

    static defaultProps = {
        dataExtend: "true",
    };

    state = {
        this: this,
    };

    reSendDataSource = () => {
        const { uiItem } = this.props;
        const dataSourceName = uiItem.props.dataSource;
        if (dataSourceName && typeof dataSourceName === "string") {
            dataSourceName.split(",").map((item: string) => this.sourceContext.execSource(item));
        }
    };

    componentDidMount = () => {
        const { source, useSourceMap } = this.props;
        if (source) {
            const sources = source.split(",");
            sources.map((item: string, index: number) => {
                if (item) {
                    let params = this.getIntentParamter();
                    if (useSourceMap === item) {
                        params = { ...this.getIntentParamter(true), ...this.getIntentParamter() };
                    }
                    this.sourceContext.execSource(item, params);
                }
            });
        }

        const { ispolling, pollingTime = 5000 } = this.props;

        if (!ispolling) return;
        this.obverIntervalTimer = ObverIntervalTimer(this.reSendDataSource, pollingTime);
    };

    childComponentWillUnmount = () => {
        if (this.obverIntervalTimer) {
            if (this.obverIntervalTimer.clear) {
                this.obverIntervalTimer.clear();
            }
        }
    };

    static getDerivedStateFromProps(props: any, state: any) {
        const { syncSource, syncMap = [], dataSource } = props;
        if (state.this.props.dataSource !== props.dataSource && syncSource) {
            if (syncSource) {
                const resMap = {};
                for (const item of syncMap) {
                    resMap[item.value] = dataSource && dataSource[item.label];
                }
                state.this.sourceContext.execSource(syncSource, resMap);
            }
        }
        return null;
    }
    getIntentParamter = (isFromDataSource?: boolean) => {
        const { map, dataSource, sourceMap, data = {} } = this.props;
        let newMap = {};
        if (map || sourceMap) {
            let intentData = this.getIntent();
            if (isFromDataSource) {
                if (dataSource && Object.keys(dataSource).length > 0) {
                    intentData = dataSource;
                } else {
                    intentData = data || {};
                }
            }
            const pMap = (isFromDataSource ? sourceMap : map) || [];
            if (intentData) {
                for (const item of pMap) {
                    let resVal = intentData[item.label];
                    if (/(.+)/.test(item.label)) {
                        resVal = Utils.getValue(intentData, item.label.split("."));
                    }
                    if (resVal !== undefined) {
                        newMap[item.value] = resVal;
                    }
                }
            }
        } else {
            newMap = this.getIntent();
        }

        return newMap;
    };

    getChildDataKey() {
        const { dataString = "data" } = this.props;
        return dataString;
    }

    getChildData() {
        const { orderStr, data, dataSource = [], dataFromIntent, sourceMap, combineData } = this.props;
        let resData = sourceMap ? this.props.data : this.props.dataSource || this.props.data;

        if (orderStr && data && !sourceMap) {
            //增加取数组数据的处理
            const order = Number(data[orderStr]) - 1;
            resData = dataSource[order] || resData;
            resData.index = data[orderStr];
            resData.index = data[orderStr];
        }
        if (dataFromIntent) {
            //开启从intent中取数据时，将数据合并
            // resData = this.getIntent();
            resData = { ...this.getIntent(), ...resData };
        }
        if (combineData) {
            //dataSource刷新（rn返回均为result）
            resData = { ...data, ...resData, ...dataSource };
        }
        return resData;
    }

    onChange(ename: string, value: any, fromValue?: any) {
        const { source, emojiKey = "" } = this.props;
        if (source) {
            const data = {};
            if (fromValue !== undefined && typeof value === "string") {
                data[value] = fromValue;
            } else {
                data[ename] = value;
            }
            if (emojiKey && (emojiKey === ename || value === emojiKey)) {
                let str = data[emojiKey];
                const reg: any = /\[[^\]]+\]/g;
                str =
                    (str &&
                        str.replace &&
                        str.replace(reg, (char: any) => {
                            const newStr = EmojiCodeMap[char] && EmojiCodeMap[char].dataCode;
                            return newStr !== "undefined" && newStr ? newStr : char;
                        })) ||
                    "";
                if (str !== "undefined" && str) {
                    data[emojiKey] = str;
                }
            }
            this.sourceContext.execSource(source, data);
        }
    }
    renderUIItem(uiItem: IUIItem, context: IPanelContext, props: any = {}): any {
        return super.renderUIItem(uiItem, context, { onChange: this.onChange.bind(this, uiItem.info.ename), ...props });
    }

    renderAct() {
        if (this.obverIntervalTimer) {
            const newLastPage = this.isLastPage();
            if (this.lastPage !== newLastPage) {
                this.lastPage = newLastPage;
                if (newLastPage === false) {
                    this.obverIntervalTimer.clear();
                } else {
                    this.obverIntervalTimer.refire();
                }
            }
        }
        return this.renderAct1();
    }
    renderAct1() {
        const {
            dataSource,
            sourceMap,
            data,
            nullChildren = [],
            keyVal,
            loading = false,
            loadingChildren = [],
            errorChildren,
            error,
            propOtherSource,
            children,
        } = this.props as any;
        let dataVal = "";
        if (!sourceMap) {
            dataVal = keyVal ? (dataSource && dataSource[keyVal]) || (data && data[keyVal]) : dataSource;
        }
        //sourceMap：从本地数据中映射
        if (loading) {
            if (loadingChildren.length === 0) {
                return <Loading uiItem={Manifest.ui()} />;
            }
            return this.renderChildren(loadingChildren);
        } else if (error) {
            return this.renderChildren(errorChildren);
        }
        //
        if (
            nullChildren.length === 0 ||
            (dataVal && (Array.isArray(dataVal) ? dataVal.length > 0 : typeof dataVal === "object" && Object.keys(dataVal).length > 0)) ||
            (dataVal && typeof dataVal === "string") ||
            (dataVal && typeof dataVal === "number") ||
            (dataVal && typeof dataVal === "boolean")
        ) {
            const toP = {};
            if (propOtherSource) {
                propOtherSource.split(",").map((item: string) => {
                    if (this.props[item]) toP[item] = this.props[item];
                });
            }
            return this.renderChildren(children, toP);
        } else {
            return this.renderChildren(nullChildren);
        }
    }
}

export default DataItem;
