/*
 * @@Author: Do not edit
 * @@Date: Do not edit
 * @@Last Modified by: Do not edit
 * @@Last Modified time: Do not edit
 */
import RNConfig, { HandleType } from "./RNConfig";
import Utils from "src/lib/utils/Utils";
import Config from "src/engine/const/Config";

/**
 * RN连接桥
 *
 * @export
 * @class RNBridge
 */
export default class RNUtils {
    eventTarget = {};
    sender = {};
    config = {};
    constructor() {
        this.config = RNConfig;
    }
    handleHttp = (url, headers) => {
        if (!url) {
            return { data: "", headers };
        }

        if (url.indexOf("http") < 0) {
            if (url.indexOf("//") < 0) {
                url = `//${url}`;
            } else {
                if (isHttps) {
                    url = `https:${url}`;
                } else {
                    url = `http:${url}`;
                }
            }
        }
        return { data: url, headers };
    };
    handleText = (text) => {
        if (!text) {
            return { data: "" };
        }
        const data = text.replace(/<\/?.+?>/g, "");
        return { data };
    };

    handleData(inter, args) {
        let data = { params: {} };
        const config = this.config[inter] || {};
        data.type = config.type || inter;
        data.params = args || {};
        if (config.params) {
            const arr = Object.keys(config.params);
            arr.map((item, index) => {
                const value = config.params[item];
                if (HandleType[value]) {
                    if (this[value]) {
                        const newData = this[value](data.params[item], data.params);
                        if (newData.headers) {
                            data.params = newData.headers;
                        }
                        data.params[item] = newData.data;
                    }
                } else {
                    data.params[item] = data.params[item] || value;
                }
            });
        } else if (config.params === null) {
            data.params = null;
        }
        // window.console.log("zxx---转换后参数-", data, "-传递名称-", inter, "-传递参数-", args);
        return data;
    }
}

export const BridgeType = {
    /**
     * 默认最初的ios和安卓连接桥
     */
    false: "",

    /**
     * 536 版ios新连接桥方法
     */
    true: "true",

    /**
     * rn postMessage方法类型
     */
    PostMessage: "postMessage",
    /**
     * rn window.ReactNativeWebView.postMessage
     */
    RNPostMessage: "RNpostMessage",

    /**
     * unity jsbridge
     */
    UnityBridge: "UnityBridge",
};
