import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import { IInfo } from "./ExecScript";
import Utils from "src/lib/utils/Utils";
import CalcScript from "src/component/script/NumberCalcScript/CalcScript";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";

interface IEdgeScript {
    label: string;
    clearSpace: boolean;
    /** 是否为数学计算公式 */
    evalFlag: boolean;
}

const DefaultHeaders = () => {
    let datestr: any = `${new Date().getFullYear()}-01-01 00:00:00`;
    //兼容ios不支持
    datestr = datestr.replace(/\-/g, "/");
    return {
        YearTime: new Date(datestr).valueOf() / 1000,
    };
};

/**
 *
 *
 * @class EdgeScript
 */
@UIManifest.declare("GGEdge", "GGEdge脚本", UIManifest.Type.ScriptPrivate)
class EdgeScript extends Script<IEdgeScript> {
    private handleUrl(url: string, headers: any, useUndefined?: boolean) {
        headers = { ...DefaultHeaders(), ...headers };
        if (url.indexOf("[") >= 0 && headers) {
            const list = Utils.parser(/\[(.+?)\]/gim, url, 1);
            list.map((item) => {
                if (PropsUtils.getValue(headers, item) !== undefined) {
                    url = url.replace(`[${item}]`, PropsUtils.getValue(headers, item));
                } else if (useUndefined) {
                    url = url.replace(`[${item}]`, "undefined");
                }
            });
        }
        return url;
    }

    getItem(str: string, info: IInfo, len: number = 1) {
        // if()
        str = this.handleUrl(str, info.value);
        return str.slice(len, str.length);
    }

    calc(item: string, checkValue: string, info: IInfo) {
        const list = this.handleUrl(item, info.value).split("|");
        let bool;
        for (let i of list) {
            if (i[0] === "{") {
                let left = Utils.parser(/\{=(.+?)=\}/gim, i, 1)?.[0];
                i = i.replace(`{=${left}=}`, "");
                if (left.indexOf("[") >= 0) {
                    left = this.handleUrl(left, info.value, true);
                }
                if (left !== void 0) {
                    checkValue = left;
                    bool = CalcScript.calc(i[0] as any, checkValue, this.handleUrl(i.slice(1, i.length), info.value));
                    if (bool === true) break;
                }
            } else {
                bool = CalcScript.calc(i[0] as any, checkValue, this.handleUrl(i.slice(1, i.length), info.value));
                if (bool === true) break;
            }
        }
        return bool;
    }

    calcAnd(item: string, checkValue: string, info: IInfo) {
        const list = this.handleUrl(item, info.value).split("&");
        let bool;
        for (const i of list) {
            if (i[0] === "{") {
                let left = Utils.parser(/\{=(.+?)=\}/gim, i, 1)?.[0];
                if (left.indexOf("[") >= 0) {
                    left = this.handleUrl(left, info.value, true);
                }
                if (left !== void 0) {
                    checkValue = left;
                    const i2 = i.replace(`{=${left}=}`, "");
                    bool = CalcScript.calc(i2[0] as any, checkValue, this.handleUrl(i2.slice(1, i2.length), info.value));
                    if (bool === false) break;
                }
            } else {
                bool = CalcScript.calc(i[0] as any, checkValue, this.handleUrl(i.slice(1, i.length), info.value));
                if (bool === false) break;
            }
        }
        return bool;
    }

    exec(info: IInfo) {
        const { label, clearSpace = true, evalFlag = false } = this.props;
        if (evalFlag && label) {
            const value = typeof info.value !== "object" ? { xxx: info.value } : info.value;
            // console.log("[EdgeScript]", label.replace(/xxx/g, "[xxx]"), this.handleUrl(label.replace(/xxx/g, "[xxx]"), value), value, label);
            //进行数据字段替换，并计算
            info.value = eval(this.handleUrl(label.replace(/xxx/g, "[xxx]"), value));
            return true;
        }

        const item = clearSpace && label ? label.replace(/\s+/g, "") : label;
        if (item) {
            const checkValue = info.checkType !== undefined ? info.checkValue : info.value;
            //一位执行算法
            const value = item?.indexOf?.("&") > -1 ? this.calcAnd(item, checkValue, info) : this.calc(item, checkValue, info);
            // console.log("[EdgeScript]:", this.context.props.uiItem.ui, info, checkValue, item, value);
            // //取前2位执行算法
            //如果检测失败，则直接退出
            if (value === false) return false;
            if (value === true) return true;
            //如果没有检测，则修改toName
            if (value === undefined) {
                if (label === "xField") {
                    console.log(`[label]`, label, value, JSON.stringify(info));
                }
                info.toName = this.handleUrl(label, typeof value === "object" ? value : { [info.toName || info.name]: info.value }) || info.name;
            }
            //如果进行过计算，则修改value
            else info.value = value;
        }
        return true;
    }
}

export default EdgeScript;
