import Utils from "src/lib/utils/Utils";

const win: any = window;

const ua = win.navigator.userAgent.toLowerCase();

export const DEVICE = {
    ipad: /ipad/.test(ua),
    andrPad: /mi pad/.test(ua),
    //qqBrowser: /qq/.test(ua),
    winPhone: /windows phone/.test(ua),
    //winPc: /windows/.test(ua),
    //vm_2: /arm/.test(ua),
    and: /android/.test(ua) || /adr/.test(ua),
    iphone: /iphone os|iph os|ipod/.test(ua),
    wechat: /micromessenger/.test(ua),
};

export interface IDown {
    debug?: boolean;
}

export interface IActive {
    // url 上的参数
    query?: any;
    // 安卓 4G 下载地址
    andrIn4GPlace?: string;
    // 安卓 wifi 下载地址
    andrInWifiPlace: string;
    // 安卓 微信应用宝地址
    andrInWechatApp?: string;
    // ipad 下载地址
    ipadUrl?: string;
    // iphone 下载地址
    iphUrl?: string;
}

interface IAddress {
    // ipad 下载地址
    ipadUrl?: string;
    // iphone 下载地址
    iphUrl?: string;
    place: string;
    scode: string;
}

const APP_STORE_URL = "https://itunes.apple.com/cn/app/jj-dou-de-zhu/id472885640?mt=8"; // '//www.jj.cn'

export default class Down {
    trcid: string = "";

    opt: IDown;

    isWifi: boolean;

    constructor(opt: IDown) {
        this.opt = opt;
        this.getTrcid();
        this.isWifi = Utils.isWIFI();
    }

    private log = (name: string, message?: any) => {
        if (this.opt.debug) {
        }
    };

    public getTrcid = () => {
        const doc = document;
        if (!doc) {
            this.trcid = "";
            return;
        }
        const script = doc.createElement("script");
        script.type = "text/javascript";
        if (doc && doc.head) {
            doc.head.appendChild(script);
        }
        script.src = "//jj.cn/set_trace_js.php";
        script.onload = () => {
            this.trcid = win.jjcn_trcid || "";
            this.log("trcid", this.trcid);
        };
    };

    public getParams() {
        const hash = Utils.htmlDecode(win.location.hash);
        const index = hash.indexOf("?");
        const p = hash.substr(index + 1).split("&");
        const params = {};
        p.map((item: any) => {
            const data = item.split("=");
            if (data[0]) {
                params[data[0]] = data[1];
            }
        });
        return params;
    }

    private setParams(params: any): string {
        let str = win.location.hash;
        for (const p in params) {
            if (p && encodeURIComponent(params[p]) !== "undefined") {
                if (str.indexOf("?") <= -1) {
                    str = `${str}?${p}=${encodeURIComponent(params[p])}`;
                } else {
                    str = `${str}&${p}=${encodeURIComponent(params[p])}`;
                }
            }
        }
        return str;
    }

    /**
     *
     * @param time 毫秒数
     */
    private sleep(time: number) {
        return new Promise(function (res, rej) {
            setTimeout(() => {
                res(true);
            }, time);
        });
    }

    public core(address: IAddress) {
        const { ipadUrl = APP_STORE_URL, iphUrl = APP_STORE_URL, place, scode } = address;

        if (DEVICE.iphone) {
            return (win.location.href = iphUrl);
        }
        if (DEVICE.ipad) {
            return (win.location.href = ipadUrl);
        }

        if (DEVICE.andrPad || DEVICE.and) {
            let urlData: string = "";
            if (win.Logger) {
                try {
                    urlData =
                        (win.Logger.visitorId ? "&visitorId=" + win.Logger.visitorId : "") +
                        (win.Logger.visitorId ? "&visitorTime=" + win.Logger.visitorTime : "");
                } catch (e) {}
            }
            return (win.location.href = `//support.jj.cn/download/download.php?place=${place}&trcid=${this.trcid || 0}${
                scode ? `&scode=${scode}` : ""
            }${urlData}`);
        }

        if (DEVICE.winPhone) {
            return (win.location.href = "//www.windowsphone.com/zh-cn/store/app/欢乐斗地主-轻松赢话费/fb7c14a5-e013-4a87-bf32-26eee9e27e4a");
        }

        return (win.location.href = "//www.jj.cn");
    }

    private async down(address: IAddress) {
        await this.sleep(1500);
        return this.core(address);
    }

    public openApp = () => {
        const iframe = document.createElement("iframe");
        iframe.style.cssText = "position: fixed;opacity: 0;width: 0;height: 0;";
        iframe.src = "jjlordunion://jj.cn";
        document.body.appendChild(iframe);
        win.location.href = "jjlordunion://jj.cn";
    };

    public active = (params: IActive) => {
        const { query = {}, andrIn4GPlace, andrInWifiPlace, andrInWechatApp, ipadUrl = APP_STORE_URL, iphUrl = APP_STORE_URL } = params;

        let place: string = "";
        if (andrIn4GPlace && !this.isWifi) {
            place = andrIn4GPlace;
        } else {
            place = andrInWifiPlace;
        }
        const hashParam: any = this.getParams();
        place = query["place"] || hashParam["place"] || place || "199";
        const scode = hashParam["scode"] || "";
        // if (scode) {
        //     scode = "&scode=" + scode;
        // }

        if ((DEVICE.andrPad || DEVICE.and) && DEVICE.wechat) {
            if (andrInWechatApp) {
                window.location.href = andrInWechatApp;
                return;
            }
            if (!hashParam["place"]) {
                window.location.hash = this.setParams({ place });
            }
        }

        if (!DEVICE.wechat) {
            this.openApp();
            this.down({ ipadUrl, iphUrl, scode, place });
        }
    };
}
