import * as React from "react";

// import RouterUI from "../lib/router/RouterUI";
// import RouterConfig from "./RouterConfig";
// import "./manifest/ManifestConfig";

import "./App.scss";
import Panel from "src/engine/ui/Panel";
import GameConfig from "src/GameConfig";
import Root from "src/engine/Root";

class App extends React.Component {
    public render() {
        const root = new Root({
            gameId: GameConfig.gameId,
            server: GameConfig.server,
        });
        return <Panel dataSource={root} />;
        // return <RouterUI routers={RouterConfig} />;
    }
}

export default App;
