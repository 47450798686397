import Handle from "./Handle";
import { IInfo } from "../ExecScript";
import { IGuiType } from "../IExecConfig";
import { POLLING_IMMEDIATE_SCRIPT_FLAG } from "src/lib/utils/Const";

/**
 *
 *
 * @class HandleCall
 */
class HandleCall extends Handle {
    isDataAllot() {
        if (this.data && this.data.type === IGuiType.DataAllot) return true;
        return false;
    }

    updateDataAllot(key: string, keyValue: any, ename: string) {
        const { dataAllot = {} } = this.ret;

        if (!dataAllot[ename]) {
            dataAllot[ename] = [];
        }
        dataAllot[ename].push({ key, keyValue });
        this.ret.dataAllot = dataAllot;
    }

    _find(relation: any, start: string, nodes: any, callback: any) {
        if (relation[start]) {
            relation[start].map((next: string) => {
                const id = next.split(",")[0];
                if (nodes[id] && nodes[id].ui === "GGEnd") {
                    callback(nodes[id].info.ename);
                } else {
                    this._find(relation, id, nodes, callback);
                }
            });
        }
    }

    findEndKey(key: string, keyValue: any) {
        const { relation, nodes } = (this.data as any).ret;
        const start = this.script.getUIItem().info.ename;

        this._find(relation, start, nodes, (ename: string) => {
            this.updateDataAllot(key, keyValue, ename);
        });
    }

    exec(info: IInfo) {
        this.script.exec(info, this.execNext.bind(this));

        // const oldFunc = this.script.context.props[info.name];
        const callback = (data: any, type: string = "value") => {
            // 刷新最新数据
            this.updataSource();
            let toInfo: IInfo | undefined;
            toInfo = { value: data, name: "value", isExec: true };
            if (typeof data === "function") toInfo.callback = data;
            if (info.params.delay) {
                setTimeout(() => {
                    this.execNext(toInfo);
                }, info.params.delay);
            } else {
                this.execNext(toInfo);
            }

            // console.log('[HandleCall]:', this.script.context.props, oldFunc, info.name);
            // if (oldFunc) oldFunc(data);
            if (info.params && info.params.changeProps) {
                // 改变props
                this.updataSource({ props: this.ret });
            }
            return this.ret[type];
        };
        //判断是否为数据分配
        if (this.isDataAllot()) {
            this.findEndKey(info.name, callback);
        } else {
            //复制
            this.ret[info.name] = callback;
            if (info.name === POLLING_IMMEDIATE_SCRIPT_FLAG) {
                this.ret[`${POLLING_IMMEDIATE_SCRIPT_FLAG}_time`] = info.params?.pollingTime;
            }
        }

        return true;
    }
}

export default HandleCall;
