import Utils from "src/lib/utils/Utils";
import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import Formmatter from "../DataToExcel/Formmatter";

interface IMapScript {
    maps: { [key: string]: any };
    mapKeys: Array<{ label: string; value: string }>;
    mapType: Array<{ key: string; type: string }>;
    extendsMaps?: { [key: string]: any };
    getValueFromKey?: string;
    optType?: string;
    initMap?: boolean;
    deleteNull?: boolean; // 移除属性值为空的属性
    deleteFalse?: boolean; // 移除boolean为false的属性
    keysKey?: string; //对象key转化成的key
    valuesKey?: string; //对象value转化成的key
    isSpaceRemove?: boolean;
    valueMapsEmpty?: boolean; // 没有映射就undefined
}

/**
 *
 *
 * @class MapScript
 */
@UIManifest.declare("MapScript", "数据映射", UIManifest.Type.Script)
class MapScript extends Script<IMapScript> {
    /**
     * key映射
     * @param value
     * @param maps
     */
    keysMaps(value: object = {}, maps: object = {}) {
        const keys = Object.keys(value);
        const ret = {};
        keys.map((key) => {
            const keyValue = maps[key];
            ret[keyValue || key] = value?.[key];
        });
        return ret;
    }

    /**
     * 对象转数组  全部转换
     * @param value
     * @param maps
     */
    mapToList(value: object = {}, maps: object = {}) {
        const { keysKey = "key", valuesKey = "value" } = this.props;
        if (Utils.isObject(value)) {
            return Object.keys(value).map((key: string) => ({ [keysKey]: key, [valuesKey]: value[key] }));
        } else {
            return value;
        }
    }

    /**
     * value映射
     * @param value
     * @param maps
     */
    valueMaps(value: object = {}, maps: object = {}) {
        let isObject = true;
        const { valueMapsEmpty } = this.props;
        if (typeof value !== "object") {
            isObject = false;
            value = { value };
        }
        const keys = Object.keys(value);
        const ret = {};
        keys.map((key) => {
            const keyValue = value[key];
            if (valueMapsEmpty) {
                ret[key] = maps[keyValue];
            } else {
                ret[key] = maps[keyValue] || keyValue;
            }
        });
        if (!isObject) {
            return ret["value"];
        }
        return ret;
    }

    /**
     * key,解析
     * @param value
     * @param maps
     */
    keysJsonParse(value: object = {}, maps: object = {}) {
        const keys = Object.keys(maps);
        const ret = { ...value };
        keys.map((key) => {
            const keyValue = maps[key];
            const item = PropsUtils.getValue(value, key);
            if (item) {
                PropsUtils.setValue(ret, keyValue, JSON.parse(item));
            }
        });
        return ret;
    }

    /**
     * 数据筛选
     * @param value
     * @param maps
     */
    keysFilter(value: object = {}, maps: object = {}) {
        const keys = Object.keys(maps);
        const { extendsMaps, isSpaceRemove } = this.props;
        const ret = {};
        keys.map((key) => {
            const keyValue = maps[key];
            const lastValue = Utils.getValue(value, key.split("."));
            if (lastValue !== undefined) {
                if (isSpaceRemove) {
                    if (lastValue) ret[keyValue] = lastValue;
                } else {
                    ret[keyValue] = lastValue;
                }
            }
        });
        if (extendsMaps) return { ...extendsMaps, ...ret };
        return ret;
    }

    /**
     * 移除属性
     * @param value
     * @param maps
     */
    deleteKey(value: object = {}, maps: object = {}) {
        const ret = { ...value };
        const keys = Object.keys(maps);
        keys.map((key) => {
            delete ret[key];
        });
        if (this.props.deleteNull) {
            Object.keys(ret).forEach((key) => {
                if (ret[key] === void 0 || ret[key] === null) delete ret[key];
            });
        }
        if (this.props.deleteFalse) {
            Object.keys(ret).forEach((key) => {
                if (!!ret[key] === false) delete ret[key];
            });
        }
        return ret;
    }

    /**
     * 数据继承
     * @param value
     * @param maps
     */
    dataExtend(value: object = {}, maps: object = {}) {
        return { ...value, ...maps };
    }

    /**
     * 数据展开
     * @param value
     * @param maps
     */
    dataExpend(value: object = {}, maps: object = {}) {
        const { getValueFromKey } = this.props;
        if (getValueFromKey) {
            value = { ...value, ...value[getValueFromKey] };
            delete value[getValueFromKey];
        }
        console.log("[MapScript]:", value, getValueFromKey);
        return value;
    }

    /**
     * 数据嵌套   { a: va, b: vb, c:vc } => { va: { b: vb, c:vc } }
     * @param value
     * @param maps
     * @returns
     */
    dataNesting(value: object = {}, maps: string) {
        if (!maps) return value;
        if (typeof maps === "string") {
            const key = value[maps] || "key";
            const cache = { ...value };
            delete cache[maps];
            console.log({ [key]: { ...cache } });
            return { [key]: { ...cache } };
        }
        return value;
    }

    /**
     * 数据替换 { a: old, b: old } => { a: old, b: new }
     * @param value  { a: old, b: old }
     * @param maps  { b: new }
     * @returns
     */
    dataReplace(value: object = {}, maps: object = {}) {
        if (!maps) return value;
        let ret = { ...value };
        const keys = Object.keys(maps);
        keys.forEach((key) => {
            ret[key] = maps[key];
        });
        return ret;
    }

    /**
     * 取值
     * @param value
     */
    getValue(value: any, maps: any) {
        const { getValueFromKey, getValueFromMap = false, defaultValue } = this.props as any;

        if (getValueFromMap) return PropsUtils.getValue(maps, value) || defaultValue;

        if (getValueFromKey) return PropsUtils.getValue(value, getValueFromKey) || defaultValue; // value[getValueFromKey];
    }

    /**
     * 数据转换
     * @param value
     * @param maps
     */
    dataTrans(value: string, maps: object = {}) {
        if (value === "") return maps[""] || "";
        return PropsUtils.getValue(maps, value) || value;
        // return maps[value] || value;
    }

    /**
     * 数据类型转换
     * @param value
     * @param props
     */
    dataTypeTrans(value: object = {}, maps: object = {}) {
        const { mapType } = this.props;
        const ret = { ...value };
        if (Array.isArray(mapType)) {
            mapType.forEach((map) => {
                ret[map.key] = Formmatter.strFormmatter(ret[map.key], map.type);
            });
        }

        return ret;
    }

    protected handle(value: any, props: IMapScript) {
        let { maps } = this.props;

        const { optType, initMap = false } = this.props;
        if (optType) {
            if (value && value.maps) {
                maps = { ...maps, ...value.maps };
                value = value.value;
            }
            if (initMap) value = {};
            if (this[optType]) return this[optType](value, maps);
            return value;
        }
        /**
         * 建议转为list脚本
         */
        if (value && value.list) {
            let list = value.list;
            if (typeof list === "string") list = [];
            return value.list.indexOf(value.value);
        } else {
            /**
             * 兼容旧代码，如不在维护
             */
            return this.oldHandle(value);
        }
    }

    private oldHandle(value: any) {
        let { maps } = this.props;
        const { mapKeys = [], extendsMaps } = this.props;
        if (value && value.maps) {
            maps = { ...maps, ...value.maps };
            value = value.value;
        }
        if (typeof value === "object" && mapKeys.length > 0) {
            const ret = {};
            mapKeys.map((item) => {
                if (value[item.label]) ret[item.value] = value[item.label];
            });
            value = ret;
            //扩展属性
            if (extendsMaps) value = { ...value, ...extendsMaps };
            return value;
        } else if (typeof value === "object" && extendsMaps) {
            return (value = { ...value, ...extendsMaps });
        }
        // console.log("[MapScript]:", maps, value, (maps || {})[value] || "");
        return (maps || {})[value] || "";
    }
}

export default MapScript;
