import Http from "src/lib/http/Http";
import EventTarget from "src/lib/utils/EventTarget";
import WebYun from "src/cms/WebYun";

interface IItem {
    loading: boolean;
    data?: any;
}

/**
 *
 */
class JsonCenter {
    static Instance = new JsonCenter();

    /** 缓存 */
    mCache: { [key: string]: IItem } = {};

    target: EventTarget = new EventTarget();

    /** 清除时间 */
    clearTime = 1000 * 1 * 5;

    /** 清楚定时器 */
    timers: { [url: string]: any } = {};

    ajax(url: string, callback: any, page: boolean = true, time?: number, clearTime?: number) {
        setTimeout(() => {
            const item = this.mCache[url + page];

            //如果已经存在
            if (item) {
                //如果正在加载,添加
                if (item.loading === true) this.target.addListener(url, this.handle.bind(this, callback));
                else callback(item.data, 200);
                //更新json销毁时间
                this.createClear(url + page, clearTime);
            } else {
                //
                this.createItem(url, page, time, clearTime);
                this.target.addListener(url, this.handle.bind(this, callback));
            }
        });
        return this.clearCallback.bind(this, url, callback);
    }

    getData(url: string, page: boolean = true) {
        const item = this.mCache[url + page];
        if (!item || item.loading === true) {
            return void 0;
        }
        return item;
    }

    clearCallback(url: string, callback: any) {
        this.target.removeListener(url, callback);
    }

    handle(callback: any, data: any) {
        callback(data.data, data.CODE || 200);
    }

    destroy() {
        // this.target.remove();
    }

    createClear(url: string, clearTime?: number) {
        //如果存在，则清除旧定时器，重新计时
        if (this.timers[url]) {
            clearTimeout(this.timers[url]);
        }

        //保留2分钟
        const timer = setTimeout(() => {
            delete this.mCache[url];
            delete this.timers[url];
        }, clearTime || this.clearTime);
        this.timers[url] = timer;
    }

    resolveRes = (url: string, parse: boolean, item: IItem, page: any) => {
        try {
            if (url.endsWith(".json")) {
                page = typeof page === "string" ? JSON.parse(page) : page;
                // console.log('[JsonCenter] [TempManager]11111', page);
                if (typeof page === "string") page = JSON.parse(page);
                page = parse ? page.page : page;
            } else if (typeof page === "string") {
                page = { content: page };
            }
            // console.log('[JsonCenter] [TempManager]', parse, item);
            item.data = page;
            // console.log('[JsonCenter1]:', url, item.data);
            item.loading = false;
        } catch (e) {
            // console.log("[JsonCenter2]:", e);
            this.target.fire(url, { CODE: -200 });
        }
        this.target.fire(url, { data: item.data });
        this.target.removeAllListener(url);
    };

    private createItem(url: string, parse: boolean, time?: number, clearTime?: number) {
        const item: IItem = (this.mCache[url + parse] = { loading: true });
        //创建清除定时器
        this.createClear(url + parse, clearTime);
        let ajaxJson: any;
        // console.log("---ssss", url);
        const info = url.slice(0, url.indexOf("/temp")).replace("http://iad.test.jj.cn/source_code/", "").split("/");
        if (WebYun.IS && WebYun.useLocal(info[0], info[1])) {
            ajaxJson = WebYun.loadJson;
        }
        if ((window as any).XDomainRequest) {
            Http.fetchIE9({ url: url + (ajaxJson ? "" : `?time=${time || new Date().valueOf()}`) })
                .then((page: any) => {
                    // console.log("[fetchIE9]", page)
                    this.resolveRes(url, parse, item, page);
                })
                .catch((e) => {
                    console.log("[fetchIE9][error]", e);
                    this.target.fire(url, { CODE: -201 });
                });
        } else {
            (ajaxJson || Http.ajaxJson)({ url: url + (ajaxJson ? "" : `?time=${time || new Date().valueOf()}`), bodyType: "application" })
                .then((page: any) => {
                    // console.log("[JsonCenter1]:", url, page);
                    // page = page.replace(/\.jj\.cn\//g, ".jjmatch.cn/");
                    this.resolveRes(url, parse, item, page);
                })
                .catch((e: any) => {
                    console.log("[JsonCenter]:", e);
                    this.target.fire(url, { CODE: -201 });
                });
        }
    }
}

export default JsonCenter;
