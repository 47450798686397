import SourceHelper from "src/engineEditor/pages/SourceHelper";
import Utils from "src/lib/utils/Utils";

function transJsonToObject(props: any) {
    if (typeof props === "string" && (props.indexOf("[") === 0 || props.indexOf("{") === 0)) {
        try {
            props = JSON.parse(props);
        } catch (e) { }
    }
    return props;
}

const PropsUtils = {
    handleUrl(url: string, headers: any = {}, del: boolean = false) {
        if (url.indexOf("[") > -1) {
            const list = Utils.parser(/\[(.+?)\]/gim, url, 1);

            list.map((item) => {
                let itemList = [],
                    value = headers || {};
                if (item.indexOf(".") > -1) {
                    itemList = item.split(".");
                    itemList.map((itemKey: string) => {
                        if (itemKey && value && typeof value === "object") {
                            value = value[itemKey];
                        }
                    });
                } else {
                    value = headers[item];
                }

                if (value !== undefined) {
                    url = url.replace(`[${item}]`, value);
                    if (del) delete headers[item];
                }
            });
        }
        return url;
    },

    getValue(props: any, ename: string, trans?: boolean) {
        ename = ename + "";
        if (typeof ename !== "string") {
            return props;
        }
        const keys = ename.split(".");
        keys.map((k) => {
            if (k !== "") {
                if (trans) {
                    props = transJsonToObject((props || {})[k]);
                } else {
                    props = transJsonToObject(props);
                    // 如果k为负数，props为数组，则代表取数组的 length - |k|
                    if (/^\-[1-9][0-9]*$/.test(k) && Array.isArray(props)) {
                        k = `${props.length + Number(k)}`
                    }
                    props = (props || {})[k];
                }
            }
        });
        return props;
    },

    setValue(props: any, ename: string, value: any, merge: boolean = false) {
        if (typeof ename !== "string") return;
        const keys = ename.split(".");
        if (keys[keys.length - 1] === "") {
            keys.pop();
        }
        const key: string = keys.pop() || "";
        const lastKey = keys[keys.length - 1] || "";
        const oldProps = props;
        keys.map((k) => {
            if (k !== "") {
                if (!props[k]) props[k] = {};
                props = props[k];
            }
        });

        if (value === undefined) {
            if (Array.isArray(props)) {
                this.setValue(oldProps, keys.join("."), SourceHelper.remove(props, Number(key)));
            } else delete props[key];
        } else {
            if (merge) {
                if (Array.isArray(value)) {
                    const old = props[key] || [];
                    props[key] = [...old, ...value];
                } else if (typeof value === "object") {
                    props[key] = { ...props[key], ...value };
                }
            } else {
                props[key] = value;
            }
        }
        if (lastKey === "style") {
            return { ...props };
        }
        return props;
    },
};

export default PropsUtils;
