// import Storage from "../utils/storage/Storage";
import Http from "./Http";
import IHttp from "./IHttp";
/*
 * @Author: zhangyg
 * @Date: 2018-10-29 14:37:22
 * @Last Modified by: zhanghao
 * @Last Modified time: 2019-05-15 15:32:03
 */
//声明一个Http实例
const http = new Http();
export default class PluginHttp {
    static STATUS = {
        //加载中
        LOADING: 1,
        //加载成功
        SUCCESS: 2,
        //加载失败
        ERROR: 3
    };

    /**
     * ajax请求插件
     * @action in {url:*地址,headers:参数}
     * @action out {loading:*地址,data:参数}
     * @param {*} next
     * @param {*} action
     * @param {*} store
     */
    static ajax(store: any, next: any, action: IHttp) {
        if (!action.url) {
            next(action);
        } else {
            const { headers } = action;
            http.request(
                {
                    ...action,
                    headers
                },
                (resp: any, ...other) => {
                    if (resp && resp.result) {
                        resp.loading = PluginHttp.STATUS.SUCCESS;
                        if (action.success) {
                            action.success(resp, headers);
                        }
                        next({
                            ...action,
                            res: resp.data,
                            loading: PluginHttp.STATUS.SUCCESS
                        });
                    } else {
                        resp = resp || {};
                        resp.loading = PluginHttp.STATUS.ERROR;
                        if (action.error) {
                            action.error(resp || {}, headers);
                        }
                        next({
                            ...action,
                            res: resp || {},
                            loading: PluginHttp.STATUS.ERROR
                        });
                    }
                }
            );
        }
    }
}
(window as any).PluginHttp = PluginHttp;
